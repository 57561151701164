import { t } from "i18next";
import { DiscClass } from "../../model/discs";

export const sliderConfig = {
  speed: {
    default: 9,
    step: 0.5,
    min: 1,
    max: 14,
    marks: [
      { value: 1, label: t("discClass.proper.putter_one") },
      { value: 4, label: t("discClass.proper.putt-approach_one") },
      { value: 5, label: t("discClass.proper.midrange_one") },
      { value: 6, label: t("discClass.proper.fairway_one") },
      { value: 10, label: t("discClass.proper.control_one") },
      { value: 12, label: t("discClass.proper.distance_one") },
    ],
  },
  glide: {
    default: 4,
    step: 0.5,
    min: 0,
    max: 7,
    marks: [
      { value: 0, label: "0" },
      { value: 7, label: "7" },
    ],
  },
  turn: {
    default: -1,
    step: 0.25,
    min: -4,
    max: 1,
    marks: [
      { value: -4, label: "-4" },
      { value: 0, label: "0" },
      { value: 1, label: "1" },
    ],
  },
  weight: {
    default: 175,
    step: 1,
    min: 120,
    max: 180,
    marks: [
      { value: 120, label: "120g" },
      { value: 180, label: "180g" },
    ],
  },
};

export const discClassDefaults: Record<
  DiscClass,
  {
    speed: number;
    glide: number;
    turn: number;
    weight: number;
    rimCoeff: number;
    solidGradient: string;
  }
> = {
  putter: {
    speed: 3,
    glide: 3,
    turn: 0,
    weight: 175,
    rimCoeff: 0.03,
    solidGradient: "65",
  },
  "putt-approach": {
    speed: 4,
    glide: 2,
    turn: 0,
    weight: 175,
    rimCoeff: 0.035,
    solidGradient: "65",
  },
  midrange: {
    speed: 5,
    glide: 5,
    turn: -1,
    weight: 175,
    rimCoeff: 0.04,
    solidGradient: "63",
  },
  fairway: {
    speed: 7,
    glide: 6,
    turn: -1,
    weight: 175,
    rimCoeff: 0.045,
    solidGradient: "60",
  },
  control: {
    speed: 10,
    glide: 5,
    turn: -1,
    weight: 175,
    rimCoeff: 0.05,
    solidGradient: "57",
  },
  distance: {
    speed: 12,
    glide: 4,
    turn: -1,
    weight: 175,
    rimCoeff: 0.06,
    solidGradient: "50",
  },
};

export const discClassRouteConfig: {
  id: DiscClass;
  /**
   * @deprecated
   */
  label: string;
  navLabel: string;
  createLabel: string;
  collectionPath: string;
}[] = [
  {
    id: DiscClass.Putter,
    label: t("discClass.proper.putter_other"),
    navLabel: t("discClass.proper.putter_other"),
    createLabel: t("discClass.proper.putter_one"),
    collectionPath: "./putter",
  },
  {
    id: DiscClass.PuttApproach,
    label: t("discClass.proper.putt-approach_other"),
    navLabel: t("discClass.proper.putt-approach_other"),
    createLabel: t("discClass.proper.putt-approach_one"),
    collectionPath: "./putt-approach",
  },
  {
    id: DiscClass.Midrange,
    label: t("discClass.proper.midrange_other"),
    navLabel: t("discClass.proper.midrange_other"),
    createLabel: t("discClass.proper.midrange_one"),
    collectionPath: "./midrange",
  },
  {
    id: DiscClass.Fairway,
    label: t("discClass.proper.fairway_other"),
    navLabel: t("discClass.proper.fairway_other"),
    createLabel: t("discClass.proper.fairway_one"),
    collectionPath: "./fairway",
  },
  {
    id: DiscClass.Control,
    label: t("discClass.proper.control_other"),
    navLabel: t("discClass.proper.control_other"),
    createLabel: t("discClass.proper.control_one"),
    collectionPath: "./control",
  },
  {
    id: DiscClass.Distance,
    label: t("discClass.proper.distance_other"),
    navLabel: t("discClass.proper.distance_other"),
    createLabel: t("discClass.proper.distance_one"),
    collectionPath: "./distance",
  },
];
