import { List, ListProps, Stack } from "@mui/material";
import React from "react";

import { ReactNode } from "react";

export interface GroupSidebarProps extends ListProps {
  children: ReactNode;
  orientation?: "row" | "column";
}
export default function GroupSidebar(props: GroupSidebarProps) {
  const { children, orientation, ...listProps } = props;
  return (
    <List
      {...listProps}
      sx={{
        display: { mobile: "grid", md: "flex" },
        gridTemplateColumns: {
          mobile: "repeat(2, minmax(0, 1fr))",
          mobileWide: "repeat(3, minmax(0, 1fr))",
          tablet: "repeat(4, minmax(0, 1fr))",
        },
        "& > :first-of-type": {
          gridColumn: { mobile: "span 2", mobileWide: "span 3", tablet: "auto" },
          gridRow: { tablet: "span 2" },
        },
        flexDirection: orientation,
        gap: 1,
        py: 0,
        width: "100%",
        my: 2,
      }}
    >
      {children}
    </List>
  );
}
