import { Stack } from "@mui/material";
import { ThrowSetDetails } from "../../dashboard/ThrowSetDetails";
import { ThrowSetSparklines } from "../../dashboard/ThrowSetSparklines";
import CoreMetricsComponent from "../flight/CoreMetrics";
import { ThrowAnalysis } from "../../firebase/converters/analysisSet";
import { ThrowSummary } from "../../model/throwSummary";
import { basicThrowType } from "../../analysis/ThrowTableInternal";
import { generateTitle } from "./AnalysisSetTable";
import { useUser } from "../../hooks/useUser";
import { Handedness } from "../../model/UserSettings";

export function ThrowSetMetrics(props: {
  throwSet: ThrowAnalysis;
  throws: ThrowSummary[];
  tags: string[];
}) {
  const { throwSet: originalThrowSet, throws = [], tags } = props;
  const [{ userSettings }] = useUser();
  const throwSet = {
    ...originalThrowSet,
    primaryType:
      originalThrowSet.avg?.primaryType ??
      basicThrowType(originalThrowSet, userSettings?.handedness),
    title:
      originalThrowSet.title ??
      generateTitle(originalThrowSet, userSettings?.handedness ?? Handedness.RIGHT),
  };
  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: {
          md: "1fr 3fr",
          mobile: "1fr",
        },
        gap: { mobile: 1.5, md: 2 },
      }}
    >
      {throws && throws.length && throwSet?.title ? (
        throws.length > 1 ? (
          <>
            <ThrowSetDetails throwSet={throwSet} />
            <ThrowSetSparklines coreStats={throws} tags={tags} />
          </>
        ) : (
          <>
            <ThrowSetDetails throwSet={throwSet} />
            <CoreMetricsComponent summary={throwSet} />
          </>
        )
      ) : null}
    </Stack>
  );
}
