import * as y from "yup";

export type StoreProfile = {
  address: StoreAddress;
  availability?: StoreAvailability;
} & StoreBrand &
  StoreInfo &
  StoreVisibility;

export type StoreBrand = y.InferType<typeof brandSchema>;

export type StoreAddress = y.InferType<typeof addressSchema>;

export type StoreInfo = y.InferType<typeof storeInfoSchema>;

export type StoreAvailability = y.InferType<typeof availabilitySchema>;

export type StoreVisibility = y.InferType<typeof visibilitySchema>;

export const addressSchema = y.object({
  street: y.string().optional(),
  city: y.string().optional(),
  region: y.string().optional(),
  country: y.string().optional(),
  postalCode: y.string().optional(),
});

export const brandSchema = y.object({
  storeName: y.string().optional(),
  siteUrl: y.string().url().optional(),
  logoUrl: y.string().optional(),
});

export const storeInfoSchema = y.object({
  phone: y
    .string()
    .matches(/^\+?[1-9]\d{1,14}$/, { excludeEmptyString: true })
    .optional(),
  // This regex checks for a valid international phone number format, allowing an optional leading plus sign.
  // The 'excludeEmptyString: true' option makes the field optional by not applying the regex to empty strings.
  email: y.string().email().optional(),
  // This regex checks for a valid international phone number format, allowing an optional leading plus sign.
});

export const availabilitySchema = y.string().oneOf(["full", "limited", "unavailable"]).optional();

export const visibilitySchema = y.object({ hideMapLocation: y.boolean().optional() });

export const storeProfileSchema = brandSchema
  .concat(storeInfoSchema)
  .concat(visibilitySchema)
  .concat(y.object({ address: addressSchema }))
  .concat(y.object({ availability: availabilitySchema }));
