import React from "react";
import DiscPresetForm from "../../discs/DiscPresetForm";
import { useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useUser } from "../../../hooks/useUser";
import { getUserDiscPreset } from "../../../firebase/docs";
import { ErrorBoundary } from "../../ErrorBoundary";

export default function EditDiscRoute() {
  const { discId } = useParams<{ discId: string }>();
  const [{ userId }] = useUser();
  const [disc, discLoading] = useDocumentData(discId ? getUserDiscPreset(userId, discId) : null);
  return (
    <ErrorBoundary eventName="edit_disc_error">
      <DiscPresetForm userId={userId} preset={disc} />
    </ErrorBoundary>
  );
}
