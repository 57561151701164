import { getUserDiscPresets } from "@/firebase/collections";
import { useUser } from "@/hooks/useUser";
import type { DiscPreset } from "@/model/discs";

import { getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

export const useDiscCollection = () => {
  const [collectionLoading, setCollectionLoading] = useState(true);
  const [discCollection, setDiscCollection] = useState<DiscPreset[] | null>(null);
  const [{ userId }] = useUser();

  useEffect(() => {
    async function getDiscs() {
      const docs = await getDocs(getUserDiscPresets(userId));
      const discs = docs.docs.map((doc) => doc.data()) as DiscPreset[];
      setDiscCollection(discs);
      setCollectionLoading(false);
    }
    getDiscs();
  }, [userId]);

  return [discCollection, collectionLoading] as const;
};
