import { AppBar, Box, Button, Divider, Stack, Toolbar } from "@mui/material";
import { Heavy, Medium, Strong } from "../components/Text";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "../firebaseConfig";
import { ArrowForward } from "@mui/icons-material";
import { getLiveRouteUrl } from "./liveEvents";

export const TechDiscBanner = () => {
  return (
    <AppBar
      position="static"
      sx={{
        color: "white",
        backgroundColor: "primary.main",
        "& .MuiToolbar-root": { minHeight: "0px" },
        "& *": { "&:hover": { textDecoration: "none" } },
      }}
    >
      <Toolbar
        component={Stack}
        sx={{
          width: "100%",
          py: 1.5,
          alignItems: "center",
        }}
      >
        <Stack alignItems="center" justifyContent="center" direction="row" sx={{ width: "100%" }}>
          <BannerItem
            main
            title="SHOP"
            subtitle="Order your TechDisc today"
            eventName="banner_shop_link"
            link="https://shop.techdisc.com/products/techdisc-1"
          />
          <Divider
            sx={{ mx: 2, borderColor: "rgba(255, 255, 255, 0.5)" }}
            orientation="vertical"
            flexItem
          />
          <BannerItem
            title="EXPLORE"
            subtitle="Browse throws from past events"
            eventName="banner_events_link"
            link={getLiveRouteUrl()}
          />
          <Divider
            sx={{ mx: 2, borderColor: "rgba(255, 255, 255, 0.5)" }}
            orientation="vertical"
            flexItem
          />
          <BannerItem
            title="FIND A STORE"
            subtitle="Try a TechDisc near you!"
            eventName="banner_stores_map_link"
            link="https://techdisc.com/stores"
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

interface BannerItemProps {
  title: string;
  subtitle: string;
  eventName: string;
  link: string;
  main?: boolean;
}

const BannerItem = (props: BannerItemProps) => {
  const { title, subtitle, eventName, link, main = false } = props;
  return (
    <Stack
      component={Link}
      sx={{
        py: 1,
        px: { mobile: 1, mobileWide: 2 },
        gap: 0.5,
        borderRadius: 1,
        backgroundColor: main ? "rgba(255, 255, 255, 0.8)" : "inherit",
        "--arrow-display": "none",
        "&:hover": {
          "--arrow-display": "inline-block",
          //   color: "primary.main",
        },
        "& *": {
          textDecoration: "none",
        },
      }}
      onClick={() => {
        logEvent(getAnalytics(firebaseApp), `${eventName}_click`);
      }}
      to={link}
    >
      <Stack direction="row" gap={0.5} alignItems="center">
        <Heavy
          size={{ mobile: 14, mobileWide: 16 }}
          sx={{ color: main ? "primary.main" : "white" }}
          spacing="dense"
        >
          {title}
        </Heavy>
        <ArrowForward
          fontSize="small"
          sx={{
            color: main ? "primary.main" : "rgba(255, 255, 255, 0.9)",
            display: { mobile: "none", mobileWide: "var(--arrow-display)" },
          }}
        />
      </Stack>
      <Strong
        size={{ mobile: 12, mobileWide: 14 }}
        sx={{
          color: main ? "grey.600" : "primary.light",
          display: { mobile: "none", mobileWide: "inline-block" },
        }}
      >
        {subtitle}
      </Strong>
    </Stack>
  );
};
