import { Box, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import FlightNumberQuadrant from "../flight/FlightNumberQuadrant";
import { ThrowSummary } from "../../model/throwSummary";
import { RowExpandedProps } from "./Table";
import Tags from "../../dashboard/Tags";
import { useUser } from "../../hooks/useUser";
import { RowExpandedHeader } from "./RowExpandedHeader";

function ThrowsRowExpanded({ row, index }: RowExpandedProps<ThrowSummary>) {
  const [{ userId, trueUserId }] = useUser();
  return (
    <AnimatePresence>
      <Box
        component={motion.div}
        initial={{ maxHeight: "0px" }}
        animate={{ maxHeight: "500px" }}
        exit={{ maxHeight: "0px" }}
        transition={{ duration: 0.5 }}
        px={2}
        pb={2}
        borderBottom={"1px solid"}
        borderColor="grey.300"
        sx={{
          // TODO: Figure out expanded row rendering issue
          // backgroundColor: rowIndex % 2 === 0 ? "white" : "grey.50",
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        }}
      >
        {userId === trueUserId && (
          <Stack gap={2} alignItems={"center"} flexBasis={"50%"}>
            <>
              <RowExpandedHeader title="TAGS" index={index} />
              <Tags coreStats={row.original} userId={userId} />
            </>
          </Stack>
        )}
        <Stack gap={2} alignItems={"center"} flexBasis={userId === trueUserId ? "50%" : "100%"}>
          <RowExpandedHeader title="SIMULATED FLIGHT NUMBERS" index={index} />
          <Stack
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
            }}
          >
            <FlightNumberQuadrant
              alt={index % 2 === 0}
              metric="SPEED"
              value={row.original.estimatedFlightNumbers?.speed}
            />
            <FlightNumberQuadrant
              alt={index % 2 === 0}
              metric="GLIDE"
              value={row.original.estimatedFlightNumbers?.glide}
            />
            <FlightNumberQuadrant
              alt={index % 2 === 0}
              metric="TURN"
              value={row.original.estimatedFlightNumbers?.turn}
            />
            <FlightNumberQuadrant
              alt={index % 2 === 0}
              metric="FADE"
              value={
                row.original.estimatedFlightNumbers
                  ? Math.max(
                      0,
                      row.original.estimatedFlightNumbers?.turn +
                        Math.round(row.original.estimatedFlightNumbers?.speed) / 4 +
                        1 / 2,
                    )
                  : 0
              }
            />
          </Stack>
        </Stack>
      </Box>
    </AnimatePresence>
  );
}

export default ThrowsRowExpanded;
