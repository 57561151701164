import { useCollectionData } from "react-firebase-hooks/firestore";
import { getUserStockShots } from "../../firebase/collections";

interface StockShotsHookOptions {
  userId: string;
  skip?: boolean;
}

export const useStockShots = ({ userId, skip }: StockShotsHookOptions) => {
  const [stockShots, stockShotsLoading, stockShotsError] = useCollectionData(
    skip ? null : getUserStockShots(userId),
    {
      initialValue: [],
    },
  );

  return [stockShots, stockShotsLoading, stockShotsError];
};
