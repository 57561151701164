import "chartjs-adapter-date-fns";
import { TableRenderProps } from "../ThrowTable";
import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Paper, useTheme } from "@mui/material";
import { TECHDISC_COLOR } from "../../colors";
import { Spinner } from "@blueprintjs/core";
import Title from "../../dashboard/Title";
import {
  getTitle,
  fetchData,
  passesOtherFilters,
  getMetricLabel,
  FilterPropertyType,
} from "../shared/utility";
import { Colors } from "chart.js";
import TagManager from "../../components/TagManager";
import { useGlobal } from "../../components/providers/GlobalProvider";
import Big from "big.js";
import { filterByTags } from "../TableTags";

type Props = TableRenderProps & {
  type: FilterPropertyType;
};

const mphBucketSize = 2;
const kmhBucketSize = 2;
const spinBucketSize = 30;
const noseAngleBucketSize = 2;
const hyzerAngleBucketSize = 4;
const wobbleBucketSize = 2;
const launchAngleBucketSize = 2;
const feetBucketSize = 20;
const metersBucketSize = 6;
const oneDayInMs = 1000 * 60 * 60 * 24;

function getBucketSize(props: Props) {
  if (props.type === "throwTime") {
    if (props.docs.length === 0) {
      return oneDayInMs;
    }
    // get the time difference between first and last date
    const firstDate = props.docs[props.docs.length - 1].throwTime.toDate().getTime();
    const lastDate = props.docs[0].throwTime.toDate().getTime();
    return Math.round((lastDate - firstDate) / 20 / 60_000) * 60_000;
  } else if (props.type === "speedMph") {
    return mphBucketSize;
  } else if (props.type === "speedKmh") {
    return kmhBucketSize;
  } else if (props.type === "spinRpm") {
    return spinBucketSize;
  } else if (props.type === "noseAngle") {
    return noseAngleBucketSize;
  } else if (props.type === "hyzerAngle") {
    return hyzerAngleBucketSize;
  } else if (props.type === "wobble") {
    return wobbleBucketSize;
  } else if (props.type === "launchAngle") {
    return launchAngleBucketSize;
  } else if (props.type === "offAxisDegrees") {
    return 2;
  } else if (props.type === "distanceFeet") {
    return feetBucketSize;
  } else if (props.type === "distanceMeters") {
    return metersBucketSize;
  } else {
    throw new Error(`invalid type ${props.type}`);
  }
}

function getLabel(props: Props, entryElement: number) {
  return entryElement;
}

function getOffset(props: Props) {
  return getBucketSize(props) / 2;
}

function containsFilter(props: Props, bin: number) {
  return props.filters.some((f) => f.property === props.type && f.min <= bin && f.max > bin);
}

export function FrequencyChart(props: Props) {
  const theme = useTheme();
  const { prefersMetric } = useGlobal();
  const dataSet: Map<number, number> = new Map();
  const [sortedData, setSortedData] = useState<[number, number][]>([]);

  useEffect(() => {
    if (props.docs?.length === 0) {
      return;
    }

    filterByTags(props.tags, props.docs).forEach((doc, index) => {
      const data = fetchData(props.type, doc);
      const bucketSize = getBucketSize(props);
      if (typeof data === "number") {
        const bin = new Big(data).div(bucketSize).round().times(bucketSize).toNumber();
        if (!dataSet.has(bin)) {
          // adding this bin preserves the spacing in the histogram so no movement happens in the X axis
          dataSet.set(bin, 0);
        }
        if (passesOtherFilters(props.filters, props.type, doc)) {
          dataSet.set(bin, (dataSet.get(bin) ?? 0) + 1);
        }
      }
    });
    setSortedData([...dataSet.entries()].sort((a, b) => a[0] - b[0]));
  }, [props.docs, props.tags, props.filters, props.type]);

  // remove any filters that are no longer used
  // wrap in useEffect so it runs asynchronously
  useEffect(() => {
    // We either reset all our filters or none of them
    const hasDocument = props.filters.some((f) => {
      if (f.property !== props.type) {
        return false;
      }
      return dataSet.get(typeof f.bin === "number" ? f.bin : parseFloat(f.bin));
    });

    if (!hasDocument) {
      const filtered = props.filters.filter((f) => f.property !== props.type);
      if (filtered.length !== props.filters.length) {
        props.setFilters(filtered);
      }
    }
  }, [props.filters, props.type]);

  // Return after all hooks are called
  if (props.isLoading) {
    return <Spinner />;
  }
  const title = getTitle(props.type);

  const data = {
    labels: sortedData.map((entry) => getLabel(props, entry[0])),
    // set label to only be date and not time

    datasets: [
      {
        // add a background color for each data point
        backgroundColor: (context: any) => {
          const dataPoint = sortedData[context.dataIndex];
          if (!dataPoint) {
            return theme.palette.primary.main;
          }
          const bin = dataPoint[0];
          if (containsFilter(props, bin)) {
            return TECHDISC_COLOR.GREEN;
          } else {
            return TECHDISC_COLOR.BLUE;
          }
        },
        label: undefined,
        axis: "y",
        data: sortedData.map((entry) => entry[1]),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    onClick: function (e: any, item: any) {
      if (item.length === 0) {
        props.setFilters((oldFilters) => oldFilters.filter((f) => f.property !== props.type));
        return;
      }

      const shiftKeyPressed = e.native.shiftKey;
      const bin = sortedData[item[0].index][0];
      const min = bin - getOffset(props);
      const max = bin + getOffset(props);

      const clickedFilter = {
        property: props.type,
        min: min,
        bin: bin,
        max: max,
      };

      props.setFilters((oldFilters) => {
        if (shiftKeyPressed) {
          const prevFilter = oldFilters
            .slice()
            .reverse()
            .find((f) => f.property === props.type);
          if (prevFilter) {
            const rangeMin = Math.min(prevFilter.min, min);
            const rangeMax = Math.max(prevFilter.max, max);
            const bucketSize = getBucketSize(props);

            const newFilters = [];

            for (let newMin = rangeMin; newMin < rangeMax; newMin += bucketSize) {
              const newBin = newMin + getOffset(props);
              const newMax = newMin + 2 * getOffset(props);

              newFilters.push({
                property: props.type,
                min: newMin,
                bin: newBin,
                max: newMax,
              });
            }

            return [...oldFilters.filter((f) => f.property !== props.type), ...newFilters];
          }
        }

        if (oldFilters.some((f) => f.property === props.type && f.min === min && f.max === max)) {
          // old filter is already present so we de-select it
          return oldFilters.filter(
            (f) => f.property !== props.type || f.min !== min || f.max !== max,
          );
        } else {
          return [...oldFilters, clickedFilter];
        }
      });
    },
    devicePixelRatio: window.devicePixelRatio * 2,
    responsive: true,
    maintainAspectRatio: true, // hmm
    plugins: {
      tooltip: {
        titleFont: {
          family: "'HCo Gotham SSm', sans-serif",
          size: 12,
        },
        bodyFont: {
          family: "'HCo Gotham SSm', sans-serif",
          size: 12,
        },
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const bin = Math.round(data.labels[index]);
            const offset = Math.round(getLabel(props, getOffset(props)));
            const min = bin - offset;
            const max = bin + offset;

            if (props.type === "throwTime") {
              const minDate = new Date(min);
              const maxDate = new Date(max);

              const minDateText = minDate.toLocaleDateString();
              const maxDateText = maxDate.toLocaleDateString();

              return `${minDateText} - ${maxDateText}`;
            } else {
              // plusorminus symbol is U+00B1 in js array is 0xB1
              return `${bin} \u00B1 ${offset}`;
            }
          },
        },
      },
      title: {
        display: false,
        text: title,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: props.type === "throwTime" ? "time" : "linear",
        time: {
          // TODO: pick time unit based on date range
          unit: "month",
        },

        display: true,
        ticks: {
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
          source: "auto",
        },
        position: "bottom",
        title: {
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
          display: true,
          text: getMetricLabel(props.type, prefersMetric),
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
          display: true,
          text: "Count",
        },
        ticks: {
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
          source: "auto",
          callback: function (value: any, index: any, values: any) {
            // filter out any values that are not integers for the vertical axis scale
            if (Math.abs(value - Math.round(value)) >= 1e-5) {
              return null;
            }
            return Number(value.toFixed(0));
          },
        },
      },
    },
  };

  if (!data) {
    return null;
  }

  // return <Bar data={data} options={options} plugins={[ChartDataLabels]} />;
  return (
    <Paper
      sx={{
        p: 2,
      }}
    >
      <Title variant="secondary">{getTitle(props.type)}</Title>
      <Bar data={data} options={options} plugins={[Colors]} />
    </Paper>
  );
}
