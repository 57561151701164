import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { motion } from "framer-motion";

interface CompleteNodeProps extends SvgIconProps {
  rectFill?: string;
  pathStroke?: string;
}

const CompleteNode: React.FC<CompleteNodeProps> = ({
  rectFill = "#2A81DE",
  pathStroke = "#FAFAFA",
  ...props
}) => (
  <SvgIcon component={motion.svg} layout {...props} viewBox="0 0 20 21">
    <rect y="0.560059" width="20" height="20" rx="10" fill={"currentColor"} />
    <path
      d="M6.25 10.9351L9.25 13.9351L13.75 7.18506"
      stroke={pathStroke}
      fill={"currentColor"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CompleteNode;
