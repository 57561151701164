import { useBlocker } from "react-router-dom";

import React from "react";

function useChangeWarning({ predicate }: { predicate: () => boolean }) {
  const blocker = useBlocker(() => {
    return predicate();
  });

  const handleProceed = () => {
    blocker?.proceed?.();
  };
  const handleCancel = () => {
    blocker?.reset?.();
  };

  return {
    handleCancel,
    handleProceed,
    isBlocked: blocker.state === "blocked",
  };
}

export default useChangeWarning;
