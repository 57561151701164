import { Stack } from "@mui/material";
import { useMemo } from "react";
import { Heavy } from "../../Text";
import { type LeaderboardResults } from "../../leaderboard/leaderboardUtils";
import LiveLeaderboardPlacement from "../../leaderboard/LiveLeaderboardPlacement";
import { formatNumber } from "../../../utils/measurement";
import { getDisplayNamePlaceholder } from "../../leaderboard/fakeNames";

export const EventLeaderboard = ({
  leaderboard,
  entriesDisplayed,
}: {
  leaderboard?: LeaderboardResults;
  entriesDisplayed: string;
}) => {
  // const [storeMetadata, isMetadataLoading] = useStoreMetadata(LIVE_USER_ID);
  const [speedEntries, spinEntries, distanceEntries] = useMemo(
    () => [
      leaderboard?.bestSpeed?.splice(0, parseInt(entriesDisplayed, 10)),
      leaderboard?.bestSpin?.splice(0, parseInt(entriesDisplayed, 10)),
      leaderboard?.bestDistance?.splice(0, parseInt(entriesDisplayed, 10)),
    ],
    [entriesDisplayed, leaderboard],
  );

  return (
    <Stack sx={{ flexDirection: { mobile: "column", md: "row" } }} gap={4}>
      <Stack gap={1} sx={{ width: "100%" }}>
        <Heavy size={20} color="grey.800" letterSpacing="-0.08rem">
          SPEED
        </Heavy>
        {speedEntries?.length === 0 ? (
          <LiveLeaderboardPlacement placement={1} value={""} unit={"MPH"} displayName={"—"} />
        ) : (
          speedEntries?.map((entry, index) => (
            <LiveLeaderboardPlacement
              key={index}
              placement={++index}
              value={formatNumber(entry.bestSpeedMph, { maximumFractionDigits: 1 })}
              unit={"MPH"}
              displayName={
                entry.displayName ?? getDisplayNamePlaceholder(entry.toUserId ?? entry?.id)
              }
            />
          ))
        )}
      </Stack>
      <Stack gap={1} sx={{ width: "100%" }}>
        <Heavy size={20} color="grey.800" letterSpacing="-0.08rem">
          SPIN
        </Heavy>
        {spinEntries?.length === 0 ? (
          <LiveLeaderboardPlacement placement={1} value={""} unit={"MPH"} displayName={"—"} />
        ) : (
          spinEntries?.map((entry, index) => (
            <LiveLeaderboardPlacement
              key={index}
              placement={++index}
              value={formatNumber(entry.bestSpinRpm, { maximumFractionDigits: 0 })}
              unit={"RPM"}
              displayName={
                entry.displayName ?? getDisplayNamePlaceholder(entry.toUserId ?? entry?.id)
              }
            />
          ))
        )}
      </Stack>
      <Stack gap={1} sx={{ width: "100%" }}>
        <Heavy size={20} color="grey.800" letterSpacing="-0.08rem">
          DISTANCE
        </Heavy>
        {distanceEntries?.length === 0 ? (
          <LiveLeaderboardPlacement placement={1} value={""} unit={"FT"} displayName={"—"} />
        ) : (
          distanceEntries?.map((entry, index) => (
            <LiveLeaderboardPlacement
              key={index}
              placement={++index}
              value={formatNumber(entry.bestDistanceFeet ?? 0, { maximumFractionDigits: 0 })}
              unit={"FT"}
              displayName={
                entry.displayName ?? getDisplayNamePlaceholder(entry.toUserId ?? entry?.id)
              }
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};
