import { Stack } from "@mui/material";
import ThrowInline from "./ThrowInline";
import { ThrowSummary } from "../../../model/throwSummary";
import { AnimatePresence } from "framer-motion";
import { padArray } from "../../../utils/common";

export interface ThrowGroupProps {
  throws: ThrowSummary[];
  padAmount?: number;
  onThrowClick?(summary?: ThrowSummary): void;
}

function ThrowGroup(props: ThrowGroupProps) {
  const { throws, padAmount, onThrowClick } = props;

  return (
    <Stack sx={{ gap: 2, width: "100%" }}>
      <AnimatePresence mode="wait">
        {padArray(throws, padAmount ?? 1).map((d, i) => (
          <ThrowInline empty={!d} key={d?.id ?? i} summary={d} onThrowClick={onThrowClick} />
        ))}
      </AnimatePresence>
    </Stack>
  );
}

export default ThrowGroup;
