import { Box, BoxProps, alpha } from "@mui/material";
import PlaceSharpIcon from "@mui/icons-material/PlaceSharp";

export function StorePinIcon(props: { size?: "small" | "large"; selected?: boolean } & BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        position: "relative",
        transform: "scale(0.8)",
        outline: (theme) =>
          props.selected ? `2px solid ${alpha(theme.palette.primary.main, 0.9)}` : "none",
        outlineOffset: "-2px",
        ...props.sx,
      }}
    >
      <PlaceSharpIcon
        sx={{
          color: "grey.200",
          width: "100%",
          height: "100%",
        }}
      />
      <Box
        sx={{
          width: props.size === "small" ? "6px" : "10px",
          height: props.size === "small" ? "6px" : "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: props.size === "small" ? "translate(-50%, -80%)" : "translate(-50%, -90%)",
          backgroundColor: "primary.main",
          borderRadius: "100%",
        }}
      />
    </Box>
  );
}
