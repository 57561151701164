import { ReactNode } from "react";
import { Strong } from "../Text";
import { Stack, StackProps } from "@mui/material";

export interface HeaderProps {
  display?: "compact" | "default";
  align?: "left" | "right" | "center";
  children: ReactNode;
}

function Header(props: HeaderProps & StackProps) {
  const { children, align = "left", sx, ...rest } = props;

  const alignItems = align === "right" ? "flex-end" : align === "left" ? "flex-start" : "center";

  return (
    <Stack
      {...rest}
      sx={{
        px: 2,
        py: 1,
        alignItems,
        justifyContent: "flex-start",
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderColor: "grey.300",
        backgroundColor: "grey.50",
        userSelect: "none",
        ...sx,
        "&:last-of-type": {
          borderRight: "1px solid",
          borderColor: "grey.300",
        },
        "&:first-of-type": {
          borderLeft: "1px solid",
          borderColor: "grey.300",
        },
      }}
    >
      {typeof children === "string" ? <Strong spacing="dense">{children}</Strong> : children}
    </Stack>
  );
}

export default Header;
