import { Controller, useFormContext } from "react-hook-form";
import { Stack, FormControl, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import Title from "../../../dashboard/Title";
import { Heavy, Medium } from "../../Text";
import { StoreMetadata } from "../../../firebase/converters/leaderboardStoreMetadata";
import { STORE_AVAILABILITY_DESCRIPTIONS } from "./consts";

const AvailabilityOption = (props: { availability: "full" | "limited" | "unavailable" }) => {
  const labels = {
    full: "FULL AVAILABILITY",
    limited: "LIMITED AVAILABILITY",
    unavailable: "TEMPORARILY UNAVAILABLE",
  };
  return (
    <FormControlLabel
      value={props.availability}
      control={<Radio />}
      label={
        <Stack gap={1} my={1}>
          <Heavy spacing="loose" size={14}>
            {labels[props.availability]}
          </Heavy>
          <Medium>{STORE_AVAILABILITY_DESCRIPTIONS[props.availability]}</Medium>
        </Stack>
      }
      sx={{ alignItems: "flex-start", backgroundColor: "grey.50", borderRadius: 1, p: 1 }}
    />
  );
};
export function StoreAvailabilityForm(props: { storeMetadata?: StoreMetadata | null }) {
  const { control } = useFormContext();
  return (
    <Stack gap={3}>
      <Title variant="secondary">Availability</Title>
      <FormControl>
        <Controller
          control={control}
          name="availability"
          render={({ field }) => (
            <RadioGroup {...field} sx={{ display: "flex", gap: 2 }}>
              <AvailabilityOption availability="full" />
              <AvailabilityOption availability="limited" />
              <AvailabilityOption availability="unavailable" />
            </RadioGroup>
          )}
        />
      </FormControl>
    </Stack>
  );
}
