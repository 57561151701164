import NotFound from "@/components/layout/NotFound";
import { getLiveEventsCollection } from "@/firebase/collections";
import { Spinner } from "@blueprintjs/core";
import { Stack, Typography } from "@mui/material";
import { addMonths } from "date-fns/esm";
import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { ThrowTable } from "../../analysis/ThrowTable";
import { LIVE_USER_ID } from "../../dashboard/liveEvents";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";

export default function ThrowsRoute() {
  const [{ trueUserId, externalUserId }, userLoading] = useUser();
  const { isLiveRoute, liveEvent } = useLive();
  const [liveEvents, loading] = useCollectionData(getLiveEventsCollection());
  const event = liveEvents?.find((event) => event.id === liveEvent);

  const defaultLimit = useMemo(() => (isLiveRoute && event ? 5000 : 250), [isLiveRoute, event]);
  const defaultRange: DateRange = [addMonths(new Date(), -6), new Date()];

  if (externalUserId) {
    return (
      // <ErrorBoundary eventName="external_user_throws_error">
      loading || userLoading ? null : (
        <ThrowTable userId={externalUserId} dateRange={defaultRange} queryLimit={defaultLimit} />
      )
      // </ErrorBoundary>
    );
  }

  if (isLiveRoute && !loading) {
    if (!event) {
      return <NotFound />;
    }
    return (
      // <ErrorBoundary eventName="live_user_throws_error">
      loading || userLoading ? (
        <LoadingThrows />
      ) : (
        <ThrowTable
          key={liveEvent}
          userId={LIVE_USER_ID}
          dateRange={[event.startDate.toDate(), event.endDate.toDate()]}
          queryLimit={defaultLimit}
        />
      )
      // </ErrorBoundary>
    );
  }

  if (trueUserId) {
    return (
      // <ErrorBoundary eventName="user_throws_error">
      loading || userLoading ? null : (
        <ThrowTable userId={trueUserId} dateRange={defaultRange} queryLimit={defaultLimit} />
      )
      // </ErrorBoundary>
    );
  }

  return (
    // <ErrorBoundary eventName="default_user_throws_error">
    loading || userLoading ? null : (
      <ThrowTable userId={"unknown"} dateRange={defaultRange} queryLimit={defaultLimit} />
    )
    // </ErrorBoundary>
  );
}

const LoadingThrows = () => {
  return (
    <Stack sx={{ height: "50vh", justifyContent: "center", alignItems: "center", gap: 2 }}>
      <Typography variant="h6">Loading throws...</Typography>
      <Spinner />
    </Stack>
  );
};
