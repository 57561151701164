import { Stack, Typography, Paper } from "@mui/material";
import { SingleFieldTextBox } from "../build/DeviceEditFields";
import Title from "./Title";
import { storeAnalysisSet } from "../summaryUtils";
import { renderSummaryDate } from "../analysis/ThrowTableInternal";
import { useGlobal } from "../components/providers/GlobalProvider";
import { ThrowAnalysis } from "../firebase/converters/analysisSet";

interface ThrowSetDetailsProps {
  throwSet: ThrowAnalysis;
}

export const ThrowSetDetails = ({ throwSet }: ThrowSetDetailsProps) => {
  const { userId, userLoading, externalUserId, isAdmin } = useGlobal();

  return (
    <Paper
      sx={{
        opacity: userLoading ? 0.5 : 1,
        p: { mobile: 1, md: 2 },
      }}
    >
      <Stack justifyContent={"space-between"} height="100%">
        <Stack>
          <Title>Throw Set</Title>
          <Stack direction="row">
            <SingleFieldTextBox<ThrowAnalysis>
              sx={{ minWidth: "auto", width: "100%" }}
              input={throwSet}
              fieldName={"title"}
              allowEmpty={false}
              multiLine={true}
              renderBody={() => (
                <Typography
                  sx={{
                    fontSize: { mobile: "1.4rem", md: "1.6rem" },
                    lineHeight: { mobile: "28px", md: "36px" },
                    fontWeight: 500,
                  }}
                >
                  {throwSet.title}
                </Typography>
              )}
              storeData={(v) =>
                // Allow admins to edit other users' throw sets
                storeAnalysisSet(isAdmin ? (externalUserId ?? userId) : userId, throwSet.id, v)
              }
            />
          </Stack>
        </Stack>
        <Typography
          variant="h6"
          sx={{ fontSize: { mobile: "0.8rem", md: "1rem" }, color: "grey.600" }}
        >
          {renderSummaryDate(throwSet)}
        </Typography>
      </Stack>
    </Paper>
  );
};
