import { Stack, Typography } from "@mui/material";
import StoreAddressForm from "./StoreAddressForm";
import StoreContactForm from "./StoreContactForm";
import AffiliateInfo from "./StoreAffiliateInfo";
import { StoreMetadata } from "../../../firebase/converters/leaderboardStoreMetadata";
import { StoreAvailabilityForm } from "./StoreAvailabilityForm";
import StoreVisibility from "./StoreVisibility";

export default function StoreInfo({ storeMetadata }: { storeMetadata?: StoreMetadata | null }) {
  return (
    <Stack gap={3}>
      {storeMetadata?.affiliateUrl && <AffiliateInfo affiliateUrl={storeMetadata.affiliateUrl} />}
      <StoreContactForm />
      <StoreAddressForm />
      <StoreVisibility />
      <StoreAvailabilityForm storeMetadata={storeMetadata} />
      <Stack direction={"row"} alignItems={"flex-end"} mt={4}>
        <Typography variant="subtitle2" color="primary">
          All information provided on this page will be publicly visible.
        </Typography>
      </Stack>
    </Stack>
  );
}
