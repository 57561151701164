import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, AlertTitle, Button, Link, Paper, Stack } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Black, Medium } from "../Text";
import { AnimatePresence, Variants, motion } from "framer-motion";
import {
  DiscClass,
  DiscAppearance,
  DiscPreset,
  DiscPresetDocumentData,
  DiscWear,
  FlightPlate,
} from "../../model/discs";
import useNotify from "../../hooks/useNotify";
import { Trans, useTranslation } from "react-i18next";
import { TECHDISC_COLOR } from "../../colors";
import { sliderConfig } from "./config";
import { discClassDefaults } from "./config";
import { createUserDiscPreset, saveUserDiscPreset } from "./actions";
import { Timestamp } from "firebase/firestore";
import discData from "./discData";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "../../firebaseConfig";
import { t } from "i18next";
import Stepper from "../Stepper";
import { FlightNumbersForm } from "./FlightNumbersForm";
import CustomizeDiscForm from "./CustomizeDiscForm";
import ReviewDiscForm from "./ReviewDiscForm";
import StockShotFlightChart from "./StockShotFlightChart";
import { DiscsRouteOutletContext } from "../layout/DiscLayout";
import useFlightPath from "../../hooks/useFlightPath/useFlightPath";
import { StockShot } from "../../firebase/converters/stockShot";
import { useGlobal } from "../providers/GlobalProvider";

const discPresetSchema = yup.object().shape({
  nickname: yup.string().required(t("discPreset.feedback.required.nickname")),
  mold: yup.string(),
  plastic: yup.string(),
  manufacturer: yup.string(),
  wear: yup.string<DiscWear>(),
  class: yup.string<DiscClass>(),
  flightPlate: yup.string<FlightPlate>(),
  appearance: yup.string<DiscAppearance>(),
  colors: yup.object({
    primary: yup.string(),
    secondary: yup.string(),
  }),
  flightNumbers: yup.object({
    speed: yup.number().min(sliderConfig.speed.min).max(sliderConfig.speed.max).required(),
    glide: yup.number().min(sliderConfig.glide.min).max(sliderConfig.glide.max).required(),
    turn: yup.number().min(sliderConfig.turn.min).max(sliderConfig.turn.max).required(),
    weight: yup.number().min(sliderConfig.weight.min).max(sliderConfig.weight.max).required(),
  }),
});

export type DiscPresetFields = yup.InferType<typeof discPresetSchema>;

export interface DiscPresetFormProps {
  userId: string;
  presetTemplate?: DiscPreset;
  preset?: DiscPreset;
  discClass?: DiscClass;
}

export enum CreateDiscSteps {
  ChooseFlightNumbers = 1,
  Customize = 2,
  Review = 3,
}

const variants: Variants = {
  rightOffScreen: { opacity: 0.5, x: 100, transition: { duration: 0.2 } },
  leftOffScreen: { opacity: 0.5, x: -100, transition: { duration: 0.2 } },
  active: { opacity: 1, x: 0, transition: { duration: 0.2 } },
};

export default function DiscPresetForm(props: DiscPresetFormProps) {
  const { userId, preset, presetTemplate, discClass = DiscClass.Fairway } = props;
  const isCreate = !preset;
  const [activeStep, setActiveStep] = useState<number>(CreateDiscSteps.ChooseFlightNumbers);
  const [prevStep, setPrevStep] = useState<number>(CreateDiscSteps.ChooseFlightNumbers);
  const navigate = useNavigate();
  const notify = useNotify();
  const { t } = useTranslation();
  const { featureFlags } = useGlobal();
  const stepFormRef = useRef<HTMLDivElement>(null);
  const { stockShots } = useOutletContext<DiscsRouteOutletContext>();
  const { flightPath, fetchFlightPath } = useFlightPath();

  const methods = useForm({
    resolver: yupResolver(discPresetSchema),
    values: {
      appearance: presetTemplate?.appearance ?? preset?.appearance ?? DiscAppearance.Solid,
      class: presetTemplate?.class ?? preset?.class ?? discClass,
      flightPlate: presetTemplate?.flightPlate ?? preset?.flightPlate ?? FlightPlate.Neutral,
      manufacturer: presetTemplate?.manufacturer ?? preset?.manufacturer,
      mold: presetTemplate?.mold ?? preset?.mold,
      nickname: presetTemplate?.name ?? preset?.name ?? "",
      plastic: presetTemplate?.plastic ?? preset?.plastic,
      wear: presetTemplate?.wear ?? preset?.wear ?? DiscWear.New,
      colors: presetTemplate?.colors ??
        preset?.colors ?? {
          primary: TECHDISC_COLOR.LIGHT_BLUE,
          secondary: TECHDISC_COLOR.DARK_BLUE,
        },
      flightNumbers: {
        speed: presetTemplate
          ? presetTemplate.flightNumbers.speed
          : preset
            ? preset.flightNumbers.speed
            : discClass
              ? discClassDefaults?.[discClass]?.speed
              : sliderConfig.speed.default,
        glide: presetTemplate
          ? presetTemplate.flightNumbers.glide
          : preset
            ? preset.flightNumbers.glide
            : discClass
              ? discClassDefaults?.[discClass]?.glide
              : sliderConfig.glide.default,
        turn: presetTemplate
          ? presetTemplate.flightNumbers.turn
          : preset
            ? preset.flightNumbers.turn
            : discClass
              ? discClassDefaults?.[discClass]?.turn
              : sliderConfig.turn.default,
        weight: presetTemplate
          ? Math.round(presetTemplate.flightNumbers.weight * 1000)
          : preset
            ? Math.round(preset.flightNumbers.weight * 1000)
            : discClass
              ? discClassDefaults?.[discClass]?.weight
              : sliderConfig.weight.default,
      },
    },
  });
  const disc = methods.watch();
  const [selectedStockShot, setSelectedStockShot] = useState(stockShots[0]);

  const handleStockShotChange = useCallback(
    (stockShot: StockShot) => {
      setSelectedStockShot(stockShot);
      fetchFlightPath(stockShot, disc);
    },
    [fetchFlightPath, setSelectedStockShot],
  );

  const discNickname = methods.watch("nickname");

  const setCurrentStep = useCallback(
    (nextStep: CreateDiscSteps) => {
      if (stepFormRef.current) {
        const { top } = stepFormRef.current.getBoundingClientRect();

        // Only scroll if the form is off the top of the screen
        if (top < 0) {
          // Adjust the scroll position by adding window.scrollY to the relative top position
          // and subtracting the height of the floating header
          window.scrollTo({
            top: Math.round(top + window.scrollY - 56),
            behavior: "smooth",
          });
        }
      }
      setPrevStep(activeStep);
      setActiveStep(nextStep);
    },
    [activeStep],
  );

  const discCreateSteps = useMemo(
    () => [
      {
        step: CreateDiscSteps.ChooseFlightNumbers,
        label: "Choose Flight Numbers",
        onClick(nextStep: CreateDiscSteps) {
          setCurrentStep(nextStep);
        },
      },
      {
        step: CreateDiscSteps.Customize,
        label: "Customize",
        onClick(nextStep: CreateDiscSteps) {
          setCurrentStep(nextStep);
        },
      },
      {
        step: CreateDiscSteps.Review,
        label: "Review",
        onClick(nextStep: CreateDiscSteps) {
          setCurrentStep(nextStep);
        },
        disabled: !discNickname,
      },
    ],
    [discNickname, setCurrentStep],
  );

  const onSubmit = async (discPresetFields: DiscPresetFields) => {
    const { nickname: name, flightNumbers, ...fields } = discPresetFields;
    const discPreset: DiscPresetDocumentData = {
      id: preset?.id,
      name,
      createTime: isCreate ? Timestamp.now() : undefined,
      updateTime: Timestamp.now(),
      flightNumbers: {
        speed: flightNumbers.speed,
        glide: flightNumbers.glide,
        turn: flightNumbers.turn,
        fade: flightNumbers.turn + Math.round(flightNumbers.speed) / 4 + 1 / 2,
        weight: parseFloat((flightNumbers.weight * 0.001).toFixed(3)),
      },
      ...fields,
    };

    try {
      if (isCreate) {
        await createUserDiscPreset(userId, discPreset);
      } else {
        await saveUserDiscPreset(userId, discPreset);
      }
      const manufacturer = discData.find(
        (manufacturer) => manufacturer.name === discPreset.manufacturer,
      );
      const mold = manufacturer?.molds.find((mold) => mold === discPreset.mold);
      const plastic = manufacturer?.plastics
        .map((p) => p.name)
        .find((plastic) => plastic === discPreset.plastic);

      const eventData: {
        manufacturer?: string;
        mold?: string;
        plastic?: string;
        appearance?: string;
        class?: string;
      } = {
        manufacturer: manufacturer?.name ?? discPreset.manufacturer,
        mold: mold ?? discPreset.mold,
        plastic: plastic ?? discPreset.plastic,
        appearance: discPreset.appearance,
        class: discPreset.class,
      };

      if (!manufacturer) {
        logEvent(getAnalytics(firebaseApp), "unknown_manufacturer", eventData);
      }

      if (!mold) {
        logEvent(getAnalytics(firebaseApp), "unknown_mold", eventData);
      }

      if (!plastic) {
        logEvent(getAnalytics(firebaseApp), "unknown_plastic", eventData);
      }

      logEvent(getAnalytics(firebaseApp), "create_disc", eventData);

      notify(
        "success",
        <span>
          <Trans
            style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}
            i18nKey={
              isCreate ? "discPreset.notification.created" : "discPreset.notification.updated"
            }
            values={{ discName: name, discClass: t(`discClass.proper.${fields.class}_other`) }}
            components={{ 0: <b /> }}
          />
        </span>,
      );
      methods.reset();
      navigate(`/discs/${discPreset.class}`);
    } catch (e) {
      logEvent(getAnalytics(firebaseApp), "create_disc_error", {
        error: JSON.stringify(e, null, 2),
      });
      notify("error", t("discPreset.notification.create_error"));
    }
  };

  return preset || presetTemplate || discClass ? (
    <Paper
      key={preset?.id}
      component="form"
      onSubmit={methods.handleSubmit(onSubmit)}
      sx={{ width: "100%", minHeight: "512px" }}
    >
      <Stack
        gap={2}
        sx={{
          width: "100%",
          px: { mobile: 2, md: 4 },
          pt: { mobile: 2, md: 2.5 },
          pb: { mobile: 2, md: 6 },
          overflowX: "hidden",
        }}
        ref={stepFormRef}
      >
        <AnimatePresence mode="wait">
          {activeStep === CreateDiscSteps.ChooseFlightNumbers && (
            <Stack
              component={motion.div}
              variants={variants}
              initial="leftOffScreen"
              animate="active"
              exit="leftOffScreen"
              gap={{ mobile: 3, md: 6 }}
              id="step-form"
            >
              <Stack gap={1}>
                <Black sx={{ fontSize: { md: 28, mobile: 20 } }} color="grey.700" spacing="dense">
                  {t("flightNumbers.label_capital_other")}
                </Black>
                <Medium color="grey.600" sx={{ fontSize: { md: 16, mobile: 14 } }}>
                  {t("discPreset.instructions.chooseFlightNumbers")}
                </Medium>
              </Stack>
              <FlightNumbersForm methods={methods} discClass={discClass} preset={preset} />
              {featureFlags.lab && (
                <>
                  {stockShots.length > 0 ? (
                    <>
                      <Stack gap={1}>
                        <Black
                          sx={{ fontSize: { md: 28, mobile: 20 } }}
                          color="grey.700"
                          spacing="dense"
                        >
                          {t("flightPreview")}
                        </Black>
                        <Medium color="grey.600" sx={{ fontSize: { md: 16, mobile: 14 } }}>
                          {t("discPreset.instructions.previewFlight")}
                        </Medium>
                      </Stack>
                      <StockShotFlightChart
                        stockShots={stockShots}
                        flightPath={flightPath}
                        selectedStockShot={selectedStockShot}
                        onStockShotChange={handleStockShotChange}
                      />
                    </>
                  ) : (
                    <Alert severity="info" sx={{ mt: { mobile: 2, laptop: 4 } }}>
                      <AlertTitle>{t("discPreset.feedback.noStockShots_title")}</AlertTitle>
                      <Trans
                        i18nKey="discPreset.feedback.noStockShots_message"
                        components={[<Link href="/stock-shots" key="sslink" />]}
                      />
                    </Alert>
                  )}
                </>
              )}
            </Stack>
          )}
          {activeStep === CreateDiscSteps.Customize && (
            <Stack
              component={motion.div}
              variants={variants}
              initial={prevStep < activeStep ? "rightOffScreen" : "leftOffScreen"}
              animate="active"
              exit={prevStep < activeStep ? "leftOffScreen" : "rightOffScreen"}
              gap={{ mobile: 3, md: 6 }}
              id="step-form"
            >
              <Stack gap={1}>
                <Black sx={{ fontSize: { md: 28, mobile: 24 } }} color="grey.700" spacing="dense">
                  {t("customize")}
                </Black>
                <Medium color="grey.600" sx={{ fontSize: { md: 16, mobile: 16 } }}>
                  {t("discPreset.instructions.customize")}
                </Medium>
              </Stack>
              <CustomizeDiscForm methods={methods} />
            </Stack>
          )}
          {activeStep === CreateDiscSteps.Review && (
            <Stack
              component={motion.div}
              variants={variants}
              initial="rightOffScreen"
              animate="active"
              exit="rightOffScreen"
              gap={{ mobile: 3, md: 6 }}
              id="step-form"
            >
              <Stack gap={1}>
                <Black sx={{ fontSize: { md: 28, mobile: 24 } }} color="grey.700" spacing="dense">
                  {t("review")}
                </Black>
                <Medium color="grey.600" sx={{ fontSize: { md: 16, mobile: 16 } }}>
                  {t("discPreset.instructions.review")}
                </Medium>
              </Stack>
              <ReviewDiscForm methods={methods} />
              {featureFlags.lab && (
                <>
                  {stockShots.length > 0 ? (
                    <>
                      <Black
                        sx={{ fontSize: { md: 28, mobile: 20 } }}
                        color="grey.700"
                        spacing="dense"
                      >
                        {t("flightPreview")}
                      </Black>
                      <StockShotFlightChart
                        selectedStockShot={selectedStockShot}
                        stockShots={stockShots}
                        flightPath={flightPath}
                        readOnly
                      />
                    </>
                  ) : (
                    <Alert severity="info" sx={{ mt: { mobile: 2, laptop: 4 } }}>
                      <AlertTitle>{t("discPreset.feedback.noStockShots_title")}</AlertTitle>
                      <Trans
                        i18nKey="discPreset.feedback.noStockShots_message"
                        components={[<Link href="/stock-shots" key="sslink" />]}
                      />
                    </Alert>
                  )}
                </>
              )}
            </Stack>
          )}
        </AnimatePresence>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={4}
        sx={{
          p: { md: 4, mobile: 2 },
          borderTop: (theme) => `1px solid ${theme.palette.grey[200]}`,
          backgroundColor: "grey.50",
          borderBottomRightRadius: 4,
          borderBottomLeftRadius: 4,
        }}
      >
        <Stepper steps={discCreateSteps} activeStep={activeStep} orientation="horizontal" />
        {activeStep === CreateDiscSteps.Review ? (
          <Button
            // Weird crappy hack to prevent form submission when hitting "Next"
            // and transitioning to the final step.
            key={activeStep}
            // Weird crappy hack to prevent form submission when hitting "Next"
            // and transitioning to the final step.
            ref={(el) => {
              if (el) {
                setTimeout(() => {
                  el.type = "submit";
                }, 0);
              }
            }}
            variant="primary"
            sx={{ height: "fit-content" }}
          >
            {t("finish")}
          </Button>
        ) : (
          <Button
            // Weird crappy hack to prevent form submission when hitting "Next"
            // and transitioning to the final step.
            key={activeStep}
            type="button"
            onClick={(e) => {
              setCurrentStep(activeStep + 1);
            }}
            disabled={!discNickname && activeStep === CreateDiscSteps.Customize}
            variant="primary"
            sx={{ height: "fit-content" }}
          >
            {t("next")}
          </Button>
        )}
      </Stack>
    </Paper>
  ) : null;
}
