import { Stack, Box, Tooltip, FormLabel } from "@mui/material";
import Title from "../../../dashboard/Title";
import { Medium } from "../../Text";
import { CopyAll } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import useNotify from "../../../hooks/useNotify";

export default function AffiliateInfo({ affiliateUrl }: { affiliateUrl?: string }) {
  const notify = useNotify();
  return (
    <Stack gap={3}>
      <Title variant="secondary">Affiliates</Title>
      <Stack gap={1}>
        <FormLabel>Affiliate URL</FormLabel>
        <Box
          sx={{
            backgroundColor: "grey.50",
            border: "1px solid",
            borderColor: "grey.200",
            borderRadius: 1,
            p: 1.5,
          }}
        >
          <Stack direction="row" justifyContent={"space-between"}>
            <Medium size={18}>{affiliateUrl}</Medium>
            <Tooltip title="Copy to clipboard">
              <CopyAll
                onClick={() => {
                  copy(affiliateUrl || "");
                  notify("success", "Affiliate URL copied to clipboard");
                }}
                sx={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
