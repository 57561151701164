import React, { useMemo } from "react";
import { ThrowSummary } from "../../../model/throwSummary";
import { add, div } from "../../../utils/math";
import CoreMetricsComponent from "../../flight/CoreMetrics";

export type CompleteStockShotStepProps = {
  throws: ThrowSummary[];
};

export default function CompleteStockShotStep({ throws = [] }: CompleteStockShotStepProps) {
  const stockShot = useMemo(
    () =>
      throws.reduce(
        (avg, summary, i, throws) => {
          const isLastThrow = throws.length - 1 === i;
          const newAvg = {
            speedMph: isLastThrow
              ? div(add(avg.speedMph, summary.speedMph), throws.length).toNumber()
              : add(avg.speedMph, summary.speedMph).toNumber(),
            speedKmh: isLastThrow
              ? div(add(avg.speedKmh, summary.speedKmh), throws.length).toNumber()
              : add(avg.speedKmh, summary.speedKmh).toNumber(),
            spinRpm: isLastThrow
              ? div(add(avg.spinRpm, summary.spinRpm), throws.length).toNumber()
              : add(avg.spinRpm, summary.spinRpm).toNumber(),
            hyzerAngle: isLastThrow
              ? div(add(avg.hyzerAngle, summary.hyzerAngle), throws.length).toNumber()
              : add(avg.hyzerAngle, summary.hyzerAngle).toNumber(),
            distanceFeet: isLastThrow
              ? div(add(avg.distanceFeet, summary.distanceFeet), throws.length).toNumber()
              : add(avg.distanceFeet, summary.distanceFeet).toNumber(),
            distanceMeters: isLastThrow
              ? div(add(avg.distanceMeters, summary.distanceMeters), throws.length).toNumber()
              : add(avg.distanceMeters, summary.distanceMeters).toNumber(),
            noseAngle: isLastThrow
              ? div(add(avg.noseAngle, summary.noseAngle), throws.length).toNumber()
              : add(avg.noseAngle, summary.noseAngle).toNumber(),
            launchAngle: isLastThrow
              ? div(add(avg.launchAngle, summary.launchAngle), throws.length).toNumber()
              : add(avg.launchAngle, summary.launchAngle).toNumber(),
            wobble: isLastThrow
              ? div(add(avg.wobble, summary.wobble), throws.length).toNumber()
              : add(avg.wobble, summary.wobble).toNumber(),
          };

          return newAvg;
        },
        {
          speedMph: 0,
          speedKmh: 0,
          spinRpm: 0,
          hyzerAngle: 0,
          distanceFeet: 0,
          distanceMeters: 0,
          noseAngle: 0,
          launchAngle: 0,
          wobble: 0,
        },
      ),
    [throws],
  );
  return <CoreMetricsComponent summary={stockShot} metricsLoading={false} />;
}
