import { Container, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Variants } from "framer-motion";
import { motion } from "framer-motion";

export const containerVariants: Variants = {
  hidden: { right: 0 },
  visible: {
    right: 0,
    transition: {
      staggerChildren: 0.1, // Reduced stagger time for quicker animations
      // ease: "anticipate"
    },
  },
};

export const itemVariants: Variants = {
  hidden: { x: 100, opacity: 0 }, // Slide in from the right
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5, // Adjust duration for smoother transition
      ease: "easeInOut",
    },
  },
  exit: {
    x: -100, // Slide out to the left
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

function StockShotEntryRoute() {
  return (
    <Container
      sx={{
        p: { mobile: 1, md: 2 },
        my: { mobile: 1, md: 4 },
      }}
    >
      <Stack gap={2}>
        <motion.div initial="hidden" animate="visible" exit="exit" variants={containerVariants}>
          <Outlet />
        </motion.div>
      </Stack>
    </Container>
  );
}

export default StockShotEntryRoute;
