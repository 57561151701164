import NotFound from "@/components/layout/NotFound";
import { Outlet } from "react-router-dom";
import { FeatureFlag } from "../../featureFlags";
import { usePermissions } from "../../hooks/usePermissions/usePermissions";
import Unauthenticated from "../layout/Unauthenticated";
import Unauthorized from "../layout/Unauthorized";
import { USER_ROLES } from "../user/consts";
import { UserRole } from "../user/types";

export type ProtectedRouteProps = {
  requiredRoles?: UserRole[];
  requiredFeatureFlags?: FeatureFlag[];
  hideRoute?: boolean;
  children?: React.ReactNode;
};

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { requiredRoles, requiredFeatureFlags, hideRoute } = props;

  const {
    user,
    isLoading: permissionsLoading,
    isAuthorized,
  } = usePermissions({ requiredRoles, requiredFeatureFlags });

  const isLoading = permissionsLoading;

  if (isLoading) {
    return null;
  }

  if (requiredRoles?.includes(USER_ROLES.USER) && !user) {
    return <Unauthenticated />;
  }

  if (requiredRoles) {
    if (!isAuthorized) {
      if (hideRoute) {
        return <NotFound />;
      }
      return <Unauthorized />;
    }
  }

  return props.children ? props.children : <Outlet />;
}
