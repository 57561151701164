import { Add } from "@mui/icons-material";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { discClassRouteConfig } from "./config";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

function AddDiscButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="primary"
        id="add-disc-button"
        aria-controls={open ? "add-disc-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
      >
        <Add fontSize="small" />
        <Box
          sx={{
            display: { mobile: "none", mobileMax: "block" },
          }}
        >
          {t("addItem", { item: t("disc_capital_one") })}
        </Box>
      </Button>
      <Menu
        id="add-disc-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          mt: 1,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          "aria-labelledby": "add-disc-button",
        }}
      >
        {discClassRouteConfig.map((discClass) => (
          <MenuItem
            sx={{
              py: 1,
            }}
            key={discClass.id}
            onClick={() => {
              handleClose();
              navigate(`/discs/new/${discClass.id}`);
            }}
          >
            {t(`discClass.proper.${discClass.id}_one`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default AddDiscButton;
