import { Spinner } from "@blueprintjs/core";
import { AnimatePresence, motion } from "framer-motion";

function TitleLoadingIndicator() {
  return (
    <AnimatePresence>
      <motion.div
        transition={{ duration: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4 }}
        exit={{ opacity: 0 }}
      >
        <Spinner size={20} />
      </motion.div>
    </AnimatePresence>
  );
}

export default TitleLoadingIndicator;
