import type { LiveEvent } from "@/firebase/converters/events";
import { useRouteLoaderData } from "react-router-dom";
import { LIVE_USER_ID } from "../../dashboard/liveEvents";
import { useUser } from "../../hooks/useUser";
import { QueryBasedDashboard } from "../../latestDashboard";

export default function DashboardRoute() {
  const [{ userId, trueUserId }, userLoading] = useUser();
  const { activeEvent } = useRouteLoaderData("root") as { activeEvent: LiveEvent };

  if (activeEvent && !trueUserId) {
    return (
      // <ErrorBoundary eventName="live_dashboard_error">
      <QueryBasedDashboard userId={LIVE_USER_ID} />
      // </ErrorBoundary>
    );
  }

  return (
    // <ErrorBoundary eventName="dashboard_error">
    userLoading ? null : <QueryBasedDashboard userId={userId} />
    // </ErrorBoundary>
  );
}
