import { Box, Slider, Stack } from "@mui/material";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import AdjustableFlightNumber from "../flight/AdjustableFlightNumber";
import FlightNumber from "../flight/FlightNumber";
import { Heavy } from "../Text";
import { DiscClass, DiscPreset, DiscPresetDocumentData } from "../../model/discs";
import { sliderConfig } from "./config";
import { discClassDefaults } from "./config";
import { t } from "i18next";
import { DiscPresetFields } from "./DiscPresetForm";

export type FlightNumbersFormProps = {
  methods: UseFormReturn<DiscPresetFields>;
  discClass: DiscClass;
  preset?: DiscPreset;
};

function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
  if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
    event.preventDefault();
  }
}

export const FlightNumbersForm = (props: FlightNumbersFormProps) => {
  const { discClass, preset, methods } = props;

  const selectedSpeed = methods.watch("flightNumbers.speed");
  const selectedGlide = methods.watch("flightNumbers.glide");
  const selectedTurn = methods.watch("flightNumbers.turn");
  const selectedWeight = methods.watch("flightNumbers.weight");

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          tablet: "repeat(4, 1fr)",
          mobileMax: "repeat(2, minmax(0, 1fr))",
          mobile: "repeat(1, minmax(0, 1fr))",
        },
        gridTemplateRows: {
          tablet: "128px minmax(376px, 1fr)",
          mobile: "repeat(auto-fill, minmax(0, 1fr)))",
        },
        alignItems: "center",
        justifyItems: "center",
        columnGap: { mobile: 2, tablet: 0 },
        rowGap: { mobile: 2, tablet: 4 },
      }}
    >
      <Stack direction="row" alignItems="center" justifySelf={{ mobileMax: "end", tablet: "auto" }}>
        <AdjustableFlightNumber
          metric={t("flightNumbers.speed.upper")}
          value={selectedSpeed}
          onIncrement={() =>
            methods.setValue(
              "flightNumbers.speed",
              Math.min(selectedSpeed + sliderConfig.speed.step, sliderConfig.speed.max),
              { shouldValidate: false },
            )
          }
          onDecrement={() =>
            methods.setValue(
              "flightNumbers.speed",
              Math.max(selectedSpeed - sliderConfig.speed.step, sliderConfig.speed.min),
              { shouldValidate: false },
            )
          }
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifySelf={{ mobileMax: "start", tablet: "auto" }}
      >
        <AdjustableFlightNumber
          metric={t("flightNumbers.glide.upper")}
          value={selectedGlide}
          onIncrement={() =>
            methods.setValue(
              "flightNumbers.glide",
              Math.min(selectedGlide + sliderConfig.glide.step, sliderConfig.glide.max),
              { shouldValidate: false },
            )
          }
          onDecrement={() =>
            methods.setValue(
              "flightNumbers.glide",
              Math.max(selectedGlide - sliderConfig.glide.step, sliderConfig.glide.min),
              { shouldValidate: false },
            )
          }
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifySelf={{ mobileMax: "end", tablet: "auto" }}>
        <AdjustableFlightNumber
          metric={t("flightNumbers.turn.upper")}
          onIncrement={() =>
            methods.setValue(
              "flightNumbers.turn",
              Math.min(selectedTurn + sliderConfig.turn.step, sliderConfig.turn.max),
              { shouldValidate: false },
            )
          }
          onDecrement={() =>
            methods.setValue(
              "flightNumbers.turn",
              Math.max(selectedTurn - sliderConfig.turn.step, sliderConfig.turn.min),
              { shouldValidate: false },
            )
          }
          value={selectedTurn}
        />
      </Stack>
      <Box justifySelf={{ mobileMax: "start", tablet: "auto" }}>
        <FlightNumber
          auto
          metric={t("flightNumbers.fade.upper")}
          value={selectedTurn + Math.round(selectedSpeed) / 4 + 1 / 2}
        />
      </Box>
      <Box sx={{ display: { mobile: "none", tablet: "block", height: "100%" } }}>
        <Controller
          control={methods.control}
          name="flightNumbers.speed"
          render={({ field }) => (
            <Slider
              {...field}
              min={sliderConfig.speed.min}
              max={sliderConfig.speed.max}
              step={sliderConfig.speed.step}
              marks={sliderConfig.speed.marks}
              orientation="vertical"
              sx={{
                m: 0,
                color: "primary.light",
                minHeight: "256px",
                '& input[type="range"]': {
                  WebkitAppearance: "slider-vertical",
                },
                "& .MuiSlider-thumb": {
                  color: "primary.main",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: { mobile: "14px", md: "16px" },
                  color: "grey.600",
                },
                "& .MuiSlider-markLabelActive": {
                  fontSize: { mobile: "14px", md: "16px" },
                  color: "grey.800",
                },
              }}
              onKeyDown={preventHorizontalKeyboardNavigation}
            />
          )}
        />{" "}
      </Box>
      <Box sx={{ display: { mobile: "none", tablet: "block", height: "100%" } }}>
        <Controller
          control={methods.control}
          name="flightNumbers.glide"
          defaultValue={
            discClass
              ? discClassDefaults?.[discClass]?.glide
              : preset
                ? preset.flightNumbers.glide
                : sliderConfig.glide.default
          }
          render={({ field }) => (
            <Slider
              {...field}
              min={sliderConfig.glide.min}
              max={sliderConfig.glide.max}
              step={sliderConfig.glide.step}
              marks={sliderConfig.glide.marks}
              orientation="vertical"
              sx={{
                m: 0,
                color: "primary.light",
                minHeight: "256px",
                '& input[type="range"]': {
                  WebkitAppearance: "slider-vertical",
                },
                "& .MuiSlider-thumb": {
                  color: "primary.main",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: { mobile: "14px", md: "16px" },
                  color: "grey.600",
                },
                "& .MuiSlider-markLabelActive": {
                  fontSize: { mobile: "14px", md: "16px" },
                  color: "grey.800",
                },
              }}
              onKeyDown={preventHorizontalKeyboardNavigation}
            />
          )}
        />
      </Box>
      <Box sx={{ display: { mobile: "none", tablet: "block", height: "100%" } }}>
        <Controller
          control={methods.control}
          name="flightNumbers.turn"
          defaultValue={
            discClass
              ? discClassDefaults?.[discClass]?.turn
              : preset
                ? preset.flightNumbers.turn
                : sliderConfig.turn.default
          }
          render={({ field }) => (
            <Slider
              {...field}
              min={sliderConfig.turn.min}
              max={sliderConfig.turn.max}
              step={sliderConfig.turn.step}
              marks={sliderConfig.turn.marks}
              orientation="vertical"
              sx={{
                m: 0,
                color: "primary.light",
                minHeight: "256px",
                '& input[type="range"]': {
                  WebkitAppearance: "slider-vertical",
                },
                "& .MuiSlider-thumb": {
                  color: "primary.main",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: { mobile: "14px", md: "16px" },
                  color: "grey.600",
                },
                "& .MuiSlider-markLabelActive": {
                  fontSize: { mobile: "14px", md: "16px" },
                  color: "grey.800",
                },
              }}
              onKeyDown={preventHorizontalKeyboardNavigation}
            />
          )}
        />
      </Box>
      <Stack
        alignItems="center"
        sx={{ height: "100%", gridColumn: { mobileMax: "span 2", tablet: "auto" } }}
      >
        <Stack direction="row" alignItems="center">
          <AdjustableFlightNumber
            borderless
            onIncrement={() =>
              methods.setValue(
                "flightNumbers.weight",
                Math.min(selectedWeight + sliderConfig.weight.step, sliderConfig.weight.max),
                { shouldValidate: false },
              )
            }
            onDecrement={() =>
              methods.setValue(
                "flightNumbers.weight",
                Math.max(selectedWeight - sliderConfig.weight.step, sliderConfig.weight.min),
                { shouldValidate: false },
              )
            }
            metric={t("flightNumbers.weight.upper")}
            value={
              <Stack direction="row" alignItems="baseline">
                {selectedWeight}
                <Heavy color="primary">g</Heavy>
              </Stack>
            }
          />
        </Stack>
        <Box sx={{ display: { mobile: "none", tablet: "block", height: "100%" } }}>
          <Controller
            control={methods.control}
            name="flightNumbers.weight"
            render={({ field }) => (
              <Slider
                {...field}
                min={sliderConfig.weight.min}
                max={sliderConfig.weight.max}
                step={sliderConfig.weight.step}
                marks={sliderConfig.weight.marks}
                orientation="vertical"
                sx={{
                  m: 0,
                  color: "primary.light",
                  minHeight: "128px",
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                  "& .MuiSlider-thumb": {
                    color: "primary.main",
                  },
                }}
                onKeyDown={preventHorizontalKeyboardNavigation}
              />
            )}
          />
        </Box>
      </Stack>
    </Box>
  );
};
