import { useMatches, useParams } from "react-router-dom";
import { LIVE_USER_ID } from "../dashboard/liveEvents";
import { useUser } from "./useUser";

function useLive() {
  const matches = useMatches();
  const [{ userId }] = useUser();
  const { liveEvent } = useParams();

  const isLiveAccount = userId === LIVE_USER_ID;
  const isLiveRoute = matches.some((match) => match.pathname.startsWith("/live"));

  return {
    liveEvent,
    isLiveRoute,
    isLiveAccount,
  };
}

export default useLive;
