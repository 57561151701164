import ThrowGroup from "../review/ThrowGroup";
import ThrowProgress from "./ThrowProgress";
import { Stack } from "@mui/material";
import { useThrowSession } from "../../context/ThrowSessionProvider";

export type ThrowStepProps = {
  userId?: string;
};

function ThrowStep(props: ThrowStepProps) {
  const { startTime } = useThrowSession();
  return <ThrowProgress key={startTime?.seconds} />;
}

export default ThrowStep;
