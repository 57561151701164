import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { AnalyzeDashboard } from "../analysis/AnalyzeDashboard";
import useLive from "../../hooks/useLive";
import { LIVE_USER_ID } from "../../dashboard/liveEvents";
import { ErrorBoundary } from "../ErrorBoundary";

export default function ThrowSetRoute() {
  const { isLiveRoute } = useLive();
  const [{ userId, trueUserId, externalUserId }, userLoading] = useUser();
  const { throwSetId } = useParams();

  if (externalUserId) {
    return (
      // <ErrorBoundary eventName="external_user_throw_set_error">
      userLoading ? null : <AnalyzeDashboard userId={externalUserId} throwSetId={throwSetId} />
      // </ErrorBoundary>
    );
  }

  if (isLiveRoute) {
    return (
      // <ErrorBoundary eventName="live_user_throw_set_error">
      userLoading ? null : <AnalyzeDashboard userId={LIVE_USER_ID} throwSetId={throwSetId} />
      // </ErrorBoundary>
    );
  }

  if (trueUserId) {
    return (
      // <ErrorBoundary eventName="user_throw_set_error">
      userLoading ? null : <AnalyzeDashboard userId={trueUserId} throwSetId={throwSetId} />
      // </ErrorBoundary>
    );
  }

  return (
    // <ErrorBoundary eventName="default_user_throw_set_error">
    userLoading ? null : <AnalyzeDashboard userId={userId} throwSetId={throwSetId} />
    // </ErrorBoundary>
  );
}
