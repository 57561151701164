import { Paper, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { UserRecord } from "./PermissionsManager";
import { UserLookupForm } from "./UserLookupForm";

export const InspectForm = () => {
  const navigate = useNavigate();

  const handleSetUser = (user: UserRecord) => {
    navigate(`/admin/inspect/${user.uid}/throws`);
  };

  return (
    <Paper
      component={Stack}
      direction="row"
      justifyContent={"center"}
      gap={2}
      p={2}
      sx={{ width: "50%", m: "0 auto" }}
    >
      <UserLookupForm setUser={handleSetUser} />
    </Paper>
  );
};
