import { useOutletContext, useParams } from "react-router-dom";
import { DiscsRouteOutletContext } from "../../layout/DiscLayout";
import { DiscClass } from "../../../model/discs";
import { useUser } from "../../../hooks/useUser";
import { ErrorBoundary } from "../../ErrorBoundary";
import DiscCollection from "../../discs/DiscCollection";
import FullDiscCollection from "../../discs/FullDiscCollection";

export default function DiscCollectionRoute() {
  const [{ trueUserId, externalUserId, publicUserId }] = useUser();
  const { discClass } = useParams<{ discClass: DiscClass }>();
  const { discPresetsByClass } = useOutletContext<DiscsRouteOutletContext>();
  const discPresets = discClass ? discPresetsByClass?.[discClass] : [];

  return (
    <ErrorBoundary eventName="disc_collection_error">
      {discClass ? (
        <DiscCollection
          discClass={discClass}
          userId={trueUserId ?? externalUserId ?? publicUserId}
          presets={discPresets}
        />
      ) : (
        <FullDiscCollection />
      )}
    </ErrorBoundary>
  );
}
