import { Paper, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import Simulator from "../Simulator";
import { useUser } from "../../hooks/useUser";
import { ErrorBoundary } from "../ErrorBoundary";

function SimulatorRoute() {
  const [{ userId }] = useUser();
  return (
    // <ErrorBoundary eventName="simulator_page_error">
    <Stack
      component={motion.div}
      sx={{
        my: { mobile: 2, md: 4 },
        mx: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      initial={{ maxHeight: "300px" }}
      animate={{ maxHeight: "100vh" }}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: {
            mobile: "90vw",
            xl: "80vw",
          },
          height: {
            mobile: "calc((90vw * 9) / 16)",
            xl: "45vw",
          },
          aspectRatio: "16/9",
          borderRadius: 0,
        }}
        elevation={3}
      >
        <Simulator mode="single" userId={userId} />
      </Paper>
    </Stack>
    // </ErrorBoundary>
  );
}

export default SimulatorRoute;
