import { Paper, Stack } from "@mui/material";
import { DiscClass, DiscPreset } from "../../model/discs";
import DiscPresetCard from "./DiscPresetCard";
import { useMemo } from "react";
import DiscCollectionHeader from "./DiscCollectionHeader";
import { motion, AnimatePresence, Variants } from "framer-motion";
import { useTranslation } from "react-i18next";
import MigratePresetsModal from "./MigratePresetsModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { DiscsRouteOutletContext } from "../layout/DiscLayout";

export interface DiscCollectionProps {
  userId: string;
  presets: DiscPreset[];
  discClass?: DiscClass;
  limit?: number;
  scrollable?: boolean;
}

const collectionVariants: Variants = {
  initial: { opacity: 0.5 },
  animate: { opacity: 1, transition: { staggerChildren: 0.05 } },
  exit: { opacity: 0.5 },
};

export default function DiscCollection(props: DiscCollectionProps) {
  const { discClass, userId, presets, limit } = props;
  const { t } = useTranslation();

  const collection = useMemo(() => {
    const discs = presets.slice(0, limit ?? presets.length);
    return (
      <>
        {discs?.map((preset, index) => (
          <DiscPresetCard key={preset.id} preset={preset} index={index} userId={userId} />
        ))}
      </>
    );
  }, [presets, userId, limit]);

  const { legacyPresets } = useOutletContext<DiscsRouteOutletContext>();
  const migrateSchema = useMemo(
    () =>
      yup.object().shape(
        legacyPresets.reduce((schema, preset) => {
          return { ...schema, [preset.id]: yup.string().required() };
        }, {}),
      ),
    [legacyPresets],
  );

  const methods = useForm({
    resolver: yupResolver(migrateSchema),
    values: legacyPresets.reduce((values, preset) => {
      return { ...values, [preset.id]: "" };
    }, {}),
  });

  return (
    <>
      <AnimatePresence>
        <Paper
          key="disc-collection"
          component={motion.div}
          sx={{ width: "100%" }}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={collectionVariants}
        >
          <DiscCollectionHeader discClass={discClass}>
            {t(`discClass.proper.${discClass}_other`)}
          </DiscCollectionHeader>
          <Stack
            alignItems={{ mobile: "center" }}
            sx={{
              px: { mobile: 1, md: 3 },
              py: { mobile: 1, md: 3 },
            }}
          >
            <Stack
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(188px, 1fr))",
                gap: 2,
              }}
              component={motion.div}
            >
              {collection}
            </Stack>
          </Stack>
        </Paper>
      </AnimatePresence>
      <FormProvider {...methods}>
        <MigratePresetsModal key="migrate-modal" userId={userId} />
      </FormProvider>
    </>
  );
}
