import TableViewIcon from "@mui/icons-material/TableView";
import { useMemo, useState } from "react";
import { orderBy, query, QueryConstraint, where } from "firebase/firestore";
import { firebaseApp } from "../../firebaseConfig";
import { Paper, Stack, Container, Tab, Grid } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { AnalysisType } from "../../model/throwSummary";
import { ThrowTableInternal, basicThrowType } from "../../analysis/ThrowTableInternal";
import { FilterType } from "../../analysis/ThrowTable";
import { Handedness } from "../../model/UserSettings";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Button } from "@mui/material";
import { getThrowName } from "../../utils/throw";
import { useUser } from "../../hooks/useUser";
import { ThrowAnalysis } from "../../firebase/converters/analysisSet";
import { getAnalysisSetCollection } from "../../firebase/collections";
import { ThrowAnalysisMetrics } from "./ThrowAnalysisMetrics";
import { GridTableRowsIcon } from "@mui/x-data-grid";
import { BarChart, ScatterPlot } from "@mui/icons-material";
import { FrequencyChart } from "../../analysis/charts/FrequencyChart";
import { TimelineChart } from "../../analysis/charts/TimelineChart";
import { useGlobal } from "../providers/GlobalProvider";
import { metricTypeKeys, typeKeys } from "../../analysis/shared/utility";

export function generateTitle(data: ThrowAnalysis, handedness: Handedness) {
  const tags = data.tagIntersect ?? [];
  let title = data.ids.length + " " + getThrowName(data, handedness) + " Throws";
  if (tags.length === 1) {
    title = data.ids.length + " throws with tag " + tags[0];
  } else if (tags.length > 1) {
    title = data.ids.length + " throws with tag " + tags[0] + " and more";
  }
  return title;
}

export function AnalysisSetTable({ userId }: { userId: string }) {
  const [tab, setTab] = useState("table");
  const [tags, setTags] = useState<string[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const [{ trueUserId, userSettings }, userLoading] = useUser();
  const { prefersMetric, isBetaUser } = useGlobal();
  const [filters, setFilters] = useState<FilterType[]>([]);
  const handedness = userSettings?.handedness ?? Handedness.RIGHT;

  const q = useMemo(() => {
    const q1 = getAnalysisSetCollection(userId);
    const filters: QueryConstraint[] = [];
    filters.push(where("type", "==", AnalysisType.MANUAL));
    filters.push(orderBy("createTime", "desc"));

    logEvent(getAnalytics(firebaseApp), "analysis_sets_query");
    return query(q1, ...filters);
  }, [userId]);

  const [analysisSets, isLoading, error] = useCollectionData(q);

  const [latestAnalysisSet, sortedAnalysisSets] = useMemo(() => {
    const sortedAnalysisSets =
      analysisSets
        ?.sort((a, b) => {
          return a.throwTime < b.throwTime ? 1 : -1;
        })
        .map((throwSet) => {
          const hand = throwSet.handedness ?? throwSet.avg?.handedness ?? handedness;
          return {
            ...throwSet,
            primaryType: basicThrowType(throwSet.avg ?? throwSet, hand),
            upsideDown: throwSet.avg?.upsideDown ?? throwSet.upsideDown,
            handedness: hand,
            rotPerSec: throwSet.avg?.rotPerSec ?? throwSet.rotPerSec,
            title: throwSet.title ?? generateTitle(throwSet, handedness),
          };
        }) ?? [];
    const [latestAnalysisSet] = sortedAnalysisSets;
    return [latestAnalysisSet, sortedAnalysisSets];
  }, [analysisSets]);

  const legacyTableProps = useMemo(
    () => ({
      docs: sortedAnalysisSets ?? [],
      filters,
      setFilters,
      tags,
      setTags,
      uid: userId,
      isLoading: userLoading || isLoading,
      userSettings,
    }),
    [userId, analysisSets, filters, tags, userSettings, isLoading],
  );

  return (
    <Container
      sx={{
        mt: { mobile: 2, md: 4 },
        mb: 4,
        display: "flex",
        flexDirection: "column",
        gap: { mobile: 1, md: 2 },
      }}
    >
      <Stack gap={2}>
        {userId === trueUserId && (
          <Button
            startIcon={<TableViewIcon />}
            href={"/throws"}
            variant="outlined"
            sx={{ width: "fit-content" }}
          >
            Create Analysis Set in Throws View
          </Button>
        )}
        <ThrowAnalysisMetrics
          throwSets={sortedAnalysisSets}
          filters={filters}
          setFilters={setFilters}
          limit={250}
        />
        <TabContext value={tab}>
          <Stack direction="row" spacing={0} justifyContent="center">
            <TabList
              // variant="standard"
              onChange={handleTabChange}
              TabIndicatorProps={{ sx: { display: "none" } }}
            >
              <Tab label={<GridTableRowsIcon fontSize="small" />} value="table" />
              <Tab label={<BarChart fontSize="small" />} value="barchart" />
              {isBetaUser && <Tab label={<ScatterPlot fontSize="small" />} value="scatterchart" />}
            </TabList>
          </Stack>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TabPanel sx={{ m: 0, p: 0 }} value="table">
              <ThrowTableInternal userId={userId} {...legacyTableProps} renderSets />
              {/* <ThrowSetTable data={sortedAnalysisSets} id="throw-set-table" display="compact" /> */}
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value="barchart">
              <Grid
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {[
                  "throwTime",
                  prefersMetric ? "distanceMeters" : "distanceFeet",
                  ...Object.keys(prefersMetric ? metricTypeKeys : typeKeys),
                ].map((item: string, index) => (
                  <Stack key={index} sx={{ m: 2 }} spacing={2} flexBasis={"50%"}>
                    <FrequencyChart userId={userId} {...legacyTableProps} type={item} />
                  </Stack>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel sx={{ m: 0, p: 0 }} value="scatterchart">
              <Grid
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xl: "repeat(1, 1fr)",
                    lg: "repeat(1, 1fr)",
                    md: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                    mobile: "repeat(1, 1fr)",
                  },
                }}
              >
                {[
                  ...Object.keys(prefersMetric ? metricTypeKeys : typeKeys),
                  prefersMetric ? "distanceMeters" : "distanceFeet",
                ].map((item: string, index) => (
                  <Stack key={index} sx={{ m: 2 }} spacing={2} flexBasis={"50%"}>
                    <TimelineChart userId={userId} {...legacyTableProps} type={item} />
                  </Stack>
                ))}
              </Grid>
            </TabPanel>
          </Paper>
        </TabContext>
      </Stack>
    </Container>
  );
}
