import { Box, Stack } from "@mui/material";
import { ThrowSummary } from "../../../model/throwSummary";
import { Heavy, Text } from "../../Text";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export interface ThrowInlineProps {
  empty?: boolean;
  summary?: ThrowSummary;
  onThrowClick?(summary?: ThrowSummary): void;
}

function ThrowInline(props: ThrowInlineProps) {
  const { empty, summary, onThrowClick } = props;
  const { t } = useTranslation();

  return (
    <Box
      component={motion.div}
      sx={{
        py: 2,
        pr: { mobile: 0, tablet: 1.5 },
        minHeight: { mobile: "144px", tablet: "56px" },
        border: empty ? "1px dashed" : "1px solid",
        borderColor: "grey.400",
        borderRadius: 2,
        display: "grid",
        gridTemplateColumns: {
          mobile: "repeat(3, minmax(48px, 1fr))",
          tablet: "repeat(auto-fit, minmax(48px, 1fr))",
        },
        gridTemplateRows: "minmax(48px, 1fr)",
        gridColumn: "1/-1",
        justifyItems: { mobile: "center", tablet: "end" },
        rowGap: { mobile: 2, tablet: 0 },
        width: "100%",
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        scrollSnapType: "y mandatory",
        scrollBehavior: "smooth",
        scrollPaddingTop: 2,
      }}
      onClick={onThrowClick ? () => onThrowClick(summary) : undefined}
    >
      <InlineThrowMetric empty={empty} label={t("metrics.speed.short")} value={summary?.speedMph} />
      <InlineThrowMetric empty={empty} label={t("metrics.spin.short")} value={summary?.spinRpm} />
      <InlineThrowMetric empty={empty} label={t("metrics.wobble.short")} value={summary?.wobble} />
      <InlineThrowMetric
        empty={empty}
        label={t("metrics.hyzer.short")}
        value={summary?.hyzerAngle}
      />
      <InlineThrowMetric empty={empty} label={t("metrics.nose.short")} value={summary?.noseAngle} />
      <InlineThrowMetric
        empty={empty}
        label={t("metrics.launch.short")}
        value={summary?.launchAngle}
      />
    </Box>
  );
}

export default ThrowInline;

const InlineThrowMetric = ({
  label,
  value,
  empty,
}: {
  label: string;
  value?: number | string;
  empty?: boolean;
}) => {
  return empty ? null : (
    <Stack
      alignItems={{ mobile: "center", tablet: "flex-end" }}
      justifyContent={{ mobile: "space-between", tablet: "center" }}
      sx={{ width: "100%" }}
    >
      {value ? <Heavy size={20}>{value}</Heavy> : "-"}
      <Text color="grey.600" size={12} sx={{ display: { mobile: "block", tablet: "none" } }}>
        {label}
      </Text>
    </Stack>
  );
};
