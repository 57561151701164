import React, { createContext, ReactNode, useContext, useState } from "react";
import { Device } from "../model"; // Adjust the import paths as necessary

interface DashboardContextProps {
  throwLoading: boolean;
  setThrowLoading: React.Dispatch<React.SetStateAction<boolean>>;
  flightPathLoading: boolean;
  setFlightPathLoading: React.Dispatch<React.SetStateAction<boolean>>;
  devices: Map<string, Device>;
  setDevices: React.Dispatch<React.SetStateAction<Map<string, Device>>>;
  userId: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  singleDoc: boolean;
  setSingleDoc: React.Dispatch<React.SetStateAction<boolean>>;
  isDebugThrows: boolean;
  setIsDebugThrows: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);

export const DashboardProvider: React.FC<{
  initialState?: Partial<DashboardContextProps>;
  children: ReactNode;
}> = ({ initialState, children }) => {
  const [devices, setDevices] = useState<Map<string, Device>>(initialState?.devices ?? new Map());
  const [userId, setUserId] = useState<string>(initialState?.userId ?? "unknown");
  const [singleDoc, setSingleDoc] = useState<boolean>(initialState?.singleDoc ?? false);
  const [isDebugThrows, setIsDebugThrows] = useState<boolean>(initialState?.isDebugThrows ?? false);
  const [throwLoading, setThrowLoading] = useState<boolean>(initialState?.throwLoading ?? false);
  const [flightPathLoading, setFlightPathLoading] = useState<boolean>(
    initialState?.flightPathLoading ?? false,
  );

  return (
    <DashboardContext.Provider
      value={{
        devices,
        setDevices,
        userId,
        setUserId,
        singleDoc,
        setSingleDoc,
        isDebugThrows,
        setIsDebugThrows,
        throwLoading,
        setThrowLoading,
        flightPathLoading,
        setFlightPathLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};
