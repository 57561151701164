import RackIcon from "../icons/RackIcon";
import { Stack, StackProps, useTheme } from "@mui/material";
import { Strong } from "../Text";
import { ArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DiscClass } from "../../model/discs";
import { ReactNode } from "react";
import { MotionProps, motion } from "framer-motion";

export type DiscCollectionHeaderProps = StackProps &
  MotionProps & {
    discClass?: DiscClass;
    children?: ReactNode;
  };

export default function DiscCollectionHeader(props: DiscCollectionHeaderProps) {
  const { discClass, children, ...otherProps } = props;
  const theme = useTheme();

  return !children ? null : (
    <Stack
      component={motion.div}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: "secondary.main",
        px: 3,
        py: 1,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
      }}
      {...otherProps}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <RackIcon color={theme.palette.primary.light} sx={{ fontSize: 12 }} />
        <Strong color="white" size={{ mobile: 16 }} spacing="dense" sx={{ userSelect: "none" }}>
          {children}
        </Strong>
      </Stack>
    </Stack>
  );
}
