import { Stack, Paper } from "@mui/material";
import { SparklineComponent } from "../analysis/charts/SparklineChart";
import { FilterPropertyType, metricTypeKeys, typeKeys } from "../analysis/shared/utility";
import { TableRenderProps } from "../analysis/ThrowTable";
import { useGlobal } from "../components/providers/GlobalProvider";
import { CoreMetrics, ThrowAnalysis } from "../firebase/converters/analysisSet";

type ThrowSetSparklinesProps = Partial<TableRenderProps> & {
  coreStats: CoreMetrics[];
};

export const ThrowSetSparklines = (props: ThrowSetSparklinesProps) => {
  const { coreStats, tags } = props;
  const { prefersMetric } = useGlobal();

  return (
    <Stack
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: {
          md: "repeat(3, 1fr)",
          mobileWide: "repeat(2, 1fr)",
          mobile: "repeat(1, 1fr)",
        },
        gap: { mobile: 1, md: 2 },
      }}
    >
      {Object.keys(prefersMetric ? metricTypeKeys : typeKeys).map((item, index) => (
        <Paper
          sx={{
            width: "100%",
            padding: 0,
            overflow: "hidden",
          }}
          key={index}
        >
          <SparklineComponent
            {...props}
            isThrowSet
            tags={tags ?? []}
            type={item as FilterPropertyType<CoreMetrics>}
            coreStats={coreStats}
          />
        </Paper>
      ))}
    </Stack>
  );
};
