import { FieldErrors } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";

const FormErrorText = (props: { field: string; errors: FieldErrors }) => (
  <ErrorMessage
    name={props.field}
    errors={props.errors}
    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
  />
);

export default FormErrorText;
