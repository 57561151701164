export enum Metric {
  "Speed" = 0,
  "Spin",
  "Nose Angle",
  "Off Axis Torque",
  "Uphill Angle",
  "Distance",
  "Hyzer",
  "Throw Type",
}

export enum MetricUnit {
  "Speed" = "MPH",
  "Spin" = "RPM",
  "Nose Angle" = "DEG",
  "Off Axis Torque" = "DEG",
  "Uphill Angle" = "DEG",
  "Distance" = "FT",
  "Hyzer" = "DEG",
  "Throw Type" = "",
}
