/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Theme, alpha } from "@mui/material";
import { ChartOptions, Scale, ScaleChartOptions } from "chart.js";
import * as THREE from "three";
import { TrackPoint } from "../../../trackUtils";
import { metersToFeet, mod, mul } from "../../../utils/math";
import { formatDistance } from "../../../utils/measurement";

export const M_TO_FT_COEF = 3.28084;

export type XYPoint = {
  x: number;
  y: number;
};

export type XYPointRange = {
  min: number;
  max: number;
};

export type SplitTrack = {
  backswing: TrackPoint[];
  flightPath: THREE.Vector3[];
};

export type XYFlightPathMetadata = {
  apex: THREE.Vector3;
  landingZone: XYPoint;
  fade: XYPointRange;
};

export type XYFlightChartData = {
  chartData: XYPoint[];
  chartMetadata: XYFlightPathMetadata;
};

export const calculateXYScale = (
  landingZone: XYPoint,
  fade: XYPointRange,
  theme: Theme,
  prefersMetric: boolean = false,
): ChartOptions<"line" | "scatter">["scales"] => {
  const { x: distance } = landingZone;
  const yApex = Math.abs(fade.min) > Math.abs(fade.max) ? fade.min : fade.max;
  const absFade = Math.abs(yApex);
  const minMaxY = absFade < 10 ? 15 : Math.ceil(absFade / 10) * 15;

  const x = prefersMetric
    ? {
        min: 0,
        max:
          distance < 3
            ? 3
            : distance < 30
              ? Math.ceil(distance / 3) * 3 + 3
              : Math.ceil(distance / 30) * 30,
      }
    : {
        min: 0,
        max:
          distance < 10
            ? 10
            : distance < 100
              ? Math.ceil(distance / 10) * 10 + 10
              : Math.ceil(distance / 100) * 100,
      };

  const y = {
    min: minMaxY * -1,
    max: minMaxY,
  };

  const scales: ScaleChartOptions<"line">["scales"] = {
    x: {
      type: "linear",
      position: "bottom",
      min: x.min,
      max: x.max,
      // @ts-ignore
      border: {
        width: 1,
        dash(ctx) {
          // Make the grid lines not on the 100ft intervals less emphasized
          return mod(ctx.tick.value, 100).eq(0) ? [0, 0] : [4, 4];
        },
      },
      grid: {
        color: (ctx) =>
          // Make the grid lines at 100ft intervals more emphasized
          mod(ctx.tick.value, 100).eq(0) ? theme.palette.grey[400] : theme.palette.grey[300],
      },
      // @ts-ignore
      ticks: {
        backdropPadding: 0,
        color: theme.palette.grey[600],
        font: {
          size: 12,
        },
        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        callback: (val, index, ticks) => {
          // Hide every 2nd tick label
          return index % 2 === 0 ? `${formatDistance(Math.abs(val as number), prefersMetric)}` : "";
        },
      },
    },
    y: {
      type: "linear",
      min: y.min,
      max: y.max,
      afterBuildTicks: (scale: Scale) => {
        scale.ticks.push({
          value: landingZone.y,
        });
        // const tickValues = scale.ticks.map((s) => s.value).sort(floatSort);
        // if (mod(fade, add(tickValues.at(-1) ?? 0, tickValues.at(1) ?? 0)).gt(1.2)) {
        //   scale.ticks.push({
        //     value: fade,
        //   });
        // }

        scale.ticks.sort((a, b) => a.value - b.value);
      },
      grid: {
        color: theme.palette.grey[200],
      },
      // @ts-ignore
      ticks: {
        backdropPadding: 0,
        font: {
          size: 12,
        },
        color(ctx) {
          // Make the fade tick a different color
          if (ctx.tick.value === landingZone.y) {
            return alpha(theme.palette.primary.main, 0.75);
          }
          return theme.palette.grey[600];
        },
        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        callback: (val, index, ticks) => {
          // If the value is the fade line, and it's not on a major tick, return the value
          if (val === landingZone.y)
            return `${formatDistance(Math.round(Math.abs(val)), prefersMetric)}`;
          // Hide every 2nd tick label
          return [0, ticks.length - 1].includes(index)
            ? `${formatDistance(Math.abs(val as number), prefersMetric)}`
            : "";
        },
      },
    },
  };
  return scales;
};

export const splitFlightTrack = (
  track: TrackPoint[],
  prefersMetric: boolean = false,
): SplitTrack => {
  const tracks: SplitTrack = {
    backswing: [],
    flightPath: [],
  };

  if (!track) return tracks;

  for (const point of track) {
    if (point.time <= 0) {
      tracks.backswing.push(point);
    }
    if (point.time > 0) {
      tracks.flightPath.push(
        new THREE.Vector3(
          prefersMetric ? point.position.x : metersToFeet(point.position.x),
          (prefersMetric ? point.position.y : metersToFeet(point.position.y)) * -1,
          (prefersMetric ? point.position.z : metersToFeet(point.position.z)) * -1,
        ),
      );
    }
  }

  return tracks;
};

export const calculateFlightPathMetadata = (flightPath: THREE.Vector3[]): XYFlightPathMetadata => {
  const metadata = flightPath.reduce(
    (limits: Omit<XYFlightPathMetadata, "landingZone">, point) => {
      const [x, y, z] = point.toArray();
      const heightApex = Math.max(limits.apex.z, z);

      if (z === heightApex) {
        limits.apex.set(x, y, z);
      }

      const min = Math.min(limits.fade.min, y);
      const max = Math.max(limits.fade.max, y);

      return { ...limits, apex: limits.apex, fade: { min, max } };
    },
    {
      apex: new THREE.Vector3(0, 0, 0),
      fade: { min: 0, max: 0 },
    },
  );

  const lastTrackPoint = Array.from(flightPath).at(-1);

  const landingZone = {
    x: lastTrackPoint!.x,
    y: lastTrackPoint!.y,
  };

  return { landingZone, ...metadata };
};
