import { Container, Stack } from "@mui/material";

<Container sx={{my: 4}}>
<Stack gap={4}>

# PRIVACY POLICY

Last updated February 26, 2023

In this document, references to “<u>TechDisc</u>”, “<u>Company</u>”, “<u>we</u>” “<u>us</u>” or “<u>our</u>” means TechDisc Inc., a Delaware corporation; “<u>you</u>” or “<u>your</u>” means the users of or visitors to our website and Services, “<u>Privacy Policy</u>” means this privacy policy; “<u>Services</u>” means our dashboards, applications, websites, content, and products and services provided by us; and “<u>Terms</u>” means our Terms of Service available at [www.techdisc.com/terms].

### How Do We Collect Information, What Do We Collect and How Do We Use It?

We collect and store information that you voluntarily provide to us as well as data related to your use of the Services. Below is a more specific list of the personal information we collect and how we use it with your consent. You may be asked additional questions regarding items on this list to ensure we have your consent to collect and use your personal information for specific purposes.

1. **Email Address**. When you voluntarily provide us with your email address, we collect your email address from which your identity is or may be discernible.
2. **Account Creation**. If you establish an account on the Services or order our products, we may collect your name, email address, mailing address, billing address, and other information to support your use of the Services.
3. **Payment and Transactional Data**. When you pay for use of certain areas or features of the Services, if applicable, or products that are purchased via our Services, our payment processor or the applicable app store collects the financial information required to process your payments, including your name, payment card information, bank account number, shipping address, if applicable, and billing information. In addition to this Privacy Policy and our Terms, information related to your payments or purchases is also processed according to such app store’s services agreement and privacy policy.
4. **Support Requests: **When you contact us for support, we may collect personal information as part of the support process, including but not limited to your name, username, address, telephone number, mobile number, email address, mailing address, order information, and other information from which your identity is or may be discernible.
5. **Surveys and User Research:** We may send you surveys or contact you with questions to provide us with feedback on our Services. We collect any responses that you provide. Participation in surveys or research tasks is elective and does not affect your access to the Services.

In addition, we may collect certain information and data related to your use of the Services (see “<u>What Are Cookies and How Do We Use Them?</u>” below). Such information may include the date and time of your visit, information about your usage of, and interaction with, our software, the Services, the IP address of your computer or mobile device, your computer or mobile device browser information, the internet address that you visited prior to and after reaching the Services, the name of the domain and host you used to access the internet, the type of mobile device you use, your mobile device’s unique device ID, the features of the Services which you accessed, and correspondences between you and us. We may also use cookies and web beacons to track your interaction with the Services, and to customize our Services for you.

## Information Third-Parties Provide About You

We may receive information about you from the Services or app store from where you download Services, and your friends and other third parties when they submit information to us about you or when they post content on the Services. Additionally, we may supplement the information we collect with records from third-parties for a variety of purposes, including to help us correct or supplement our records, enhance our ability to serve you, to help prevent or detect fraud, to tailor our content and ads to you, and to offer you opportunities that may be of interest to you.

To the extent we combine information we receive from those sources with your PII, the combined information will be treated as PII under this Privacy Policy, unless we have disclosed otherwise. In no other circumstances will this Privacy Policy apply to information we receive about you from third parties.

## Interactions with Third-Party Sites

The Services may include functionality that allows certain kinds of interactions between the Services and your account on a third-party site or application. The use of this functionality may involve the third-party providing information to us. If we offer and you choose to use this functionality, the third-party service may send PII about you to us. If so, we will treat it as PII under this Privacy Policy since we are collecting it as a result of your interaction on our Services.

In addition, we may provide third-party sites’ interfaces or links on the Services to facilitate sending a communication from the Services. For example, we may use third parties to facilitate emails, advertising, or postings. These third parties may retain any information used or provided in any such communications and these third parties’ practices are not subject to our Privacy Policy. We may not control or have access to your communications with or through these third parties, and we are not responsible for their practices. You should review the applicable third party privacy policies before using such tools.

## How Do We Use Information We Collect?

Our primary purpose in collecting information is to provide you the Services. We may use the information you provide to us for other purposes as follows:

6. **Provision of Services**. We may use and disclose your PII and usage information to support the delivery of our services on the Services and/or the delivery of any products that you purchase via the Services.
7. **Support the Services:** We may use the information you provide us to provide any customer support you request, including troubleshooting problems, and monitor such requests, and to inform you about updates to the Services.
8. **Improve the Services:** We may use the information you provide us to customize, measure, and improve the Services, including through data analysis, testing, research, statistical and survey processes.
9. **Enforce Rights:** We may use the information you provide us to enforce your and our rights (including payment for the Services in the case of paying users) and protect you against fraud.
10. **Marketing Purposes:** We occasionally may contact you about new product and features. You may remove your name from our mailing lists to stop receiving these emails.

If you have any questions regarding the foregoing, or our privacy practices, you may contact us at admin@techdisc.com.

## Can We Use De-identified Information?

In addition to the uses of personal information above, we may remove the identifiable parts of your information to create de-identified forms (“<u>De-identified Information</u>”). De-identified Information may be compiled with other data in aggregated forms. We use this De-identified Information in the following ways:

11. **Disclosure for Business Purposes:** We may also license, sell, or otherwise share De-identified Information with clients, partners, investors, and contractors for any purposes related to our business practices.
12. **Services Improvement:** We may use De-identified Information for product improvement including to the Services as well as share it with third parties to evaluate their products or services.
13. **Research:** We may use De-identified Information for research whether scientific, marketing, or business in nature. This research may be made public through publication such as within a scientific journal.

## How Do We Share the Information We Collect?

We do not sell, rent, trade, or otherwise transfer personally identifiable information to third parties who are not our affiliates providers without your consent to do so. By using the Services, you accept and agree that we can share your PII as provided in this Privacy Policy. We may disclose PII: to our carriers, third party service providers and/or agents as reasonably necessary to provide you with the Services you have requested; as required by law or legal process; in response to lawful requests for information by governmental agencies, such as law enforcement authorities, authorized to request such information; to enforce our contractual agreements, including investigation of potential violations thereof; to help detect and prevent potentially illegal acts and violations of our policies and to otherwise protect our interests; to help detect and prevent harm to persons or property or otherwise to protect public safety; to third parties that perform functions on our behalf, including, but not limited to, third parties that host or operate our Services, analyze data, perform email list management services, provide customer service, or provide public relations and marketing services; and to vendors of products and services that are offered through the Services.

As with any other business, it is possible that in the future we could sell or buy subsidiaries or business units. In any such transaction, as well in the event all or substantially all of our assets are acquired by a third party, personal information of our users will generally be one of the transferred assets. We reserve the right to include your personal information, collected as an asset, in any such transfer to a third party.

Information collected by or sent to us may be stored and processed in any country in which we and our affiliates, subsidiaries, or service providers maintain facilities. We reserve the right to transfer your personal information outside of the country where you reside and/or from which you use our Services. By using our Services, you consent to any such transfer of information outside of your country.

## How Long Is Your PII Kept By Us?

We will retain your personal information for as long as reasonably necessary to: provide the use of our Services; invoice charges and to maintain records until invoices cannot be lawfully challenged and legal proceedings may no longer be pursued; communicate with you regarding other services that we offer; comply with applicable laws, regulations, and court orders; enforce our contractual agreements; or fulfill any of the purposes for which we collected the personal information as listed in this Privacy Policy.

## What Are Cookies and How Do We Use Them?

A “<u>cookie</u>” is a simple text file stored on your computer or device for use by a browser. Certain features may only be available through the placement of a cookie. We may use cookies to assist in data collection and to track visitor usage and trends to help us better serve you. The use of cookies is a standard practice among internet websites; most internet browsers permit a user to accept, decline or delete cookies. We may use persistent cookies to make it easier for you to navigate the Services. Specifically, we may use persistent cookies to track whether you are logged into the Services. A persistent cookie may remain on your local device for an extended period of time. You can remove persistent cookies by following directions provided in your internet browser’s settings or “help” functions. We may set persistent cookies to store how you came to the Services and what items you interact with during your visit. Persistent cookies also enable tracking and targeting the interests of our users to enhance their experience on the Services. If you reject cookies, you will still be able to view contents through the Services, but your ability to use some areas of the Services may be limited. Some of our third party business partners (_e.g._, analytical tracking software providers) may use cookies and tracking technologies on the Services. We have no access to or control over these cookies or tracking technologies. This Privacy Policy covers the use of cookies on our Services and does not cover the use of cookies or tracking technologies by any third party.

## How Do We Protect Your PII?

We follow commercially accepted industry standards to protect the personal information submitted to or collected by us, both during transmission and once we receive it. However, no method of transmission over the internet, or method of electronic storage, is 100% secure and we cannot guarantee its absolute security. In the event of an inadvertent disclosure of your personal information, we will take all commercially reasonable steps to limit and remedy the disclosure as required by the applicable law. Considering the foregoing, your use and access of the Services is at your own risk. If you have any questions about security of our Services, please contact us in the manner set forth under the heading “<u>How Can You Contact Us?</u>” below.

## Can Children Use the Services?

The Services is intended for the use of users who are at least 18 years of age. The Services are not directed to persons under the age of 18. We do not knowingly collect or solicit information from, market to or accept services from persons under the age of 18 years old. If we become aware that a person under the age of 18 has provided us with personal information, we will take steps to remove such personal information.

## How Far Does Our Responsibility Extend?

This Privacy Policy applies to the Services that are owned and operated by us. The Services and our communications to you may contain links to other third party sites that may offer products or services that our users might find useful. These third party sites may request information from you. In such instances, the collection and use of your personal information will be governed by the privacy policy applicable to that third party site. We do not and cannot control the privacy policies, contents or links that appear on these third party sites. We encourage you to review the privacy policies of any third-party sites or services before providing any of them with your personal information. We accept no responsibility or liability for other third party sites or services.

## How Can You Access or Amend Your PII (Right of Opt-Out, Access and Correction)?

You have the right to view, receive, correct, complete, or remove your personal information. Upon and subjection to verification of your identity, we will respond to requests to view, correct, receive, complete, or remove your personal information within a reasonable time.

We will make all reasonable efforts to accommodate requests to delete personal information unless it is required by law or for any lawful business purpose, and always will delete any personal information that we are required by applicable law to delete at your request.

You can help us maintain the accuracy of your information by notifying us of any changes to your personal information as soon as possible. Your rights to access, change, or delete your personal information are not absolute. We may deny you such rights when required by law or if the request would likely reveal personal information about a third party.

To request opt-out, review, modification or removal of personal information, you should submit a written request to us at: admin@techdisc.com

## Your California Privacy Rights

Under the California Consumer Privacy Act of 2018 (“<u>CCPA</u>”), California residents have certain rights relating to collection, use, and sharing of their personal information for companies that do a minimum amount of business in or with California residents.

We do not meet the minimum necessary requirements to be subject to the CCPA. However, we also do not sell your personal information and will not do so in the future without providing you with notice and an opportunity to opt-out of such sale if required by law. Similarly, we do not offer financial incentives associated with our collection, use, or disclosure of your personal information.

If you are a resident of California, you have the right to request to know what personal information has been collected about you, and to access that information. You also have the right to request deletion of your personal information, though exceptions under the CCPA may allow us to retain and use certain personal information notwithstanding your deletion request. You may also send your request to us by contacting us at the address below under “<u>How Can You Contact Us?</u>”.

Separate from the CCPA, California’s Shine the Light law gives California residents the right to ask companies what personal information they share with third parties for those third parties’ direct marketing purposes. We do not disclose your personal information to third parties for the purpose of directly marketing their goods or services to you unless you request such disclosure. If you have any questions regarding this policy, or would like to change your preferences, you may contact us at the address listed below.

Also, California Civil Code Section 1798.83 permits customers who are California residents and who have provided us with “<u>personal information</u>” (as that term is defined in Section 1798.83) to request certain information about the disclosure of that information to third parties for their direct marketing purposes. If you are a California resident with questions regarding this, please contact us in the manner set forth under the heading “<u>How Can You Contact Us?</u>”.

## Do Not Track

Certain web browsers and other devices you may use to access the Services may permit you to submit your preference that you do not wish to be tracked online. We do not currently commit to responding to these submissions, in part, because no common industry standard for “<u>do not track</u>” has been adopted by industry groups, technology companies, or regulators. We will make efforts to monitor developments around “do not track” browser technology and the implementation of a standard. For information about “do not track”, please visit: <a href="https://www.allaboutdnt.org">www.allaboutdnt.org</a>

## European Users

Our Services are hosted in the United States and generally subject to laws of the United States. Many regions, such as the European Economic Area (“<u>EEA</u>”), have data collection protections that differ from U.S. law. If you are located in the EEA, you may also have certain rights under the General Data Protection Regulation (the “<u>GDPR</u>”). Personal data that you provide to us as described in this Privacy Policy, whether you intentionally submit your personal data to us or not (for instance, we may passively collect information about you), is only collected with your consent and may be transmitted outside of the EEA to us (or computer servers maintained for our benefit) pursuant to that consent.

In general, under the GDPR you may request access to your personal data, have incomplete or incorrect data corrected; have your personal data deleted; suspend or restrict our use of your personal data, or withdraw your consent; request a copy of your personal data; and complain to a supervisory authority if you believe your rights under the GDPR are not being respected.

Should you request a copy of your personal data, we will supply a copy of it to you. The first copy will be provided free of charge, but additional copies may be subject to a reasonable fee. Should you request the deletion of your personal data, we will generally do so as soon as practicable, although your right to have your personal data deleted is subject to exceptions, such as, for example, compliance with a legal obligation or for the establishment, exercise or defense of legal claims.

Note that, if you are in the EEA, we may transfer your personal data outside of the EEA, including to the United States. By way of example, this may happen if your personal data is transferred to our servers located in a country outside of the EEA. These countries may not have similar data protection laws to the EEA. By submitting your personal data, you’re agreeing to this transfer, storing or processing. If we transfer your information outside of the EEA in this way, we will take steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined in this policy.

If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your habitual residence, your place of work or the place of the alleged infringement. If you have concerns regarding your personal data, or wish to exercise any of these listed rights, you may contact us, through our Data Protection Officer at admin@techdisc.com We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy.

## Can This Privacy Policy Be Modified?

Each time you use or access the Services, the current version of the Privacy Policy will apply. Accordingly, when you use the Services, you should check the date of this Privacy Policy (which appears at the top of the Privacy Policy) and review any changes since the last version. We will occasionally update this Privacy Policy to reflect changes in our Services and/or business practices. You are encouraged to check regularly for any changes to this Privacy Policy. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you. We will not materially change our policies and practices to make them less protective of user information collected in the past without the consent of affected users. You agree that your continued use of the Services shall constitute your acceptance of the Privacy Policy as revised.

## What Law Applies to this Privacy Policy?

This Privacy Policy is governed by the laws of the State of Kansas, without giving effect to any principles of conflict of law. If you are outside of the United States, your information will be processed and stored in the United States and you consent to such processing and storage under the laws of the United States.

## How Can You Contact Us?

To contact us with your questions or comments regarding this Privacy Policy or our information collection and dissemination practices, please contact us at: admin@techdisc.com

</Stack>
</Container>
