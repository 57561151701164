import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import React from "react";

export interface DialogProps {
  anchor?: React.ComponentType<{ children: React.ReactNode; onClick: () => void }>;
  title: React.ReactNode;
  children: React.ReactNode;
  actions: (params: { close: () => void }) => React.ReactNode;
  onClose?: () => void;
}

export function DialogCta(props: DialogProps) {
  const { anchor: Anchor, title, onClose, children, actions } = props;

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <>
      {Anchor && <Anchor onClick={handleOpen}>{children}</Anchor>}
      <MuiDialog onClose={handleClose} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actions && <DialogActions>{actions({ close: handleClose })}</DialogActions>}
      </MuiDialog>
    </>
  );
}
