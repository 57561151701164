import { useMemo } from "react";
import { Box } from "@mui/material";
import { TableRenderProps } from "./ThrowTable";
import { TableDataSummary } from "./TableDataSummary";
import { passesOtherFilters } from "./shared/utility";
import TagManager from "../components/TagManager";
import CoreMetricsComponent from "../components/flight/CoreMetrics";
import { ThrowSetSparklines } from "../dashboard/ThrowSetSparklines";

export function TableStats(props: TableRenderProps & { isThrowSets?: boolean }) {
  const docs = useMemo(() => {
    let filtered;
    if (props?.tags && props.tags.length > 0) {
      filtered = TagManager.filterByTags(props.tags, props.docs);
    } else if (props?.filters) {
      filtered = props.docs.filter((doc) => {
        return passesOtherFilters(props.filters, "primaryType", doc, true);
      });
    } else {
      filtered = props.docs;
    }
    return filtered;
  }, [props.filters, props.tags, props.docs]);

  return (
    <Box display="grid" gridTemplateColumns={{ mobile: "1fr", md: "1fr 3fr" }} gap={2}>
      <TableDataSummary {...props} throwSets={docs} />
      {/* for if only one throw / set don't show sparkline */}
      {docs?.length === 1 && <CoreMetricsComponent summary={docs[0]} />}
      {docs?.length !== 1 && <ThrowSetSparklines coreStats={docs} {...props} />}
    </Box>
  );
}
