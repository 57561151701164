import { addDoc, deleteDoc, setDoc } from "firebase/firestore";
import { getUserDiscPresets } from "../../firebase/collections";
import { getUserDiscPreset } from "../../firebase/docs";
import { DiscClass, DiscPresetDocumentData } from "../../model/discs";

export const createUserDiscPreset = async (userId: string, discPreset: DiscPresetDocumentData) => {
  const docRef = getUserDiscPresets(userId);
  if (docRef) {
    const doc = await addDoc(docRef, discPreset);
    return doc.id;
  }
  return null;
};
export const saveUserDiscPreset = async (userId: string, discPreset: DiscPresetDocumentData) => {
  const docRef = getUserDiscPreset(userId, discPreset.id);
  if (docRef) {
    await setDoc(docRef, discPreset, {
      merge: true,
    });
  }
  return true;
};
export const deleteUserDiscPreset = async (userId: string, discPresetId: string) => {
  const docRef = getUserDiscPreset(userId, discPresetId);
  if (docRef) {
    await deleteDoc(docRef);
  }
  return true;
};
