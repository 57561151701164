import { Paper, Stack } from "@mui/material";
import { Metric } from "../model/metrics";
import { motion } from "framer-motion";
import Title from "./Title";
import TitleLoadingIndicator from "../components/TitleLoadingIndicator";
import { CoreMetrics } from "../firebase/converters/analysisSet";
import { Heavy, Text } from "../components/Text";

type MetricDisplayProps = {
  loading?: boolean;
  stats?: CoreMetrics;
  metric: Metric;
  accessor?: (coreMetrics: CoreMetrics) => string | number;
  label?: string;
  unit?: string;
  elevated?: boolean;
  variant?: string;
};

export default function MetricDisplay(props: MetricDisplayProps) {
  const { label: title, accessor, metric, loading, stats, unit, variant, elevated = true } = props;

  const hasIncomingThrow = props.loading;
  const styles = {
    hasIncomingThrow: { opacity: hasIncomingThrow ? 0.5 : 1 },
  };

  return (
    <Paper
      component={motion.div}
      layoutId={`metric-${metric}`}
      elevation={elevated ? 1 : 0}
      sx={{
        p: { mobile: 1, tablet: 2 },
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        minHeight={{ mobile: "78px", md: "96px" }}
        height="100%"
        justifyContent={"space-between"}
        sx={styles.hasIncomingThrow}
      >
        {/* Label & Loading indicator */}
        <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
          <Title variant={variant}>{title}</Title>
          {hasIncomingThrow && <TitleLoadingIndicator />}
        </Stack>

        {/* Metric value & unit, min/max */}
        <Stack direction="row" justifyContent={"flex-start"} alignItems={"flex-end"}>
          <StatDisplay accessor={accessor} stats={stats} unit={unit} loading={loading} />
        </Stack>
      </Stack>
    </Paper>
  );
}

const StatDisplay = (
  props: Pick<MetricDisplayProps, "accessor" | "stats" | "unit" | "loading">,
) => {
  const { accessor, stats, unit } = props;
  const hasIncomingThrow = props.loading;
  return (
    <Stack
      direction="row"
      gap={0.5}
      alignItems="baseline"
      sx={{ opacity: hasIncomingThrow ? 0.5 : 1 }}
    >
      <Heavy
        sx={{ fontSize: { mobile: "1.6rem", mobileWide: "1.8rem", md: "2rem" } }}
        color="grey.900"
      >
        {accessor && stats && accessor(stats)}
      </Heavy>
      <Text
        sx={{ fontSize: { mobile: "0.7rem", mobileWide: "0.8rem", md: "1rem" } }}
        color="grey.500"
      >
        {unit}
      </Text>
    </Stack>
  );
};
