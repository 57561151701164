import { Stack, Paper, Box } from "@mui/material";
import { Heavy, Medium, Strong, Text } from "../../components/Text";

export interface LiveLeaderboardPlacementProps {
  placement: number;
  value: string;
  unit: string;
  displayName: string;
}

function LiveLeaderboardPlacement(props: LiveLeaderboardPlacementProps) {
  return (
    <Paper sx={{ p: 1, px: 2, borderRadius: 0 }}>
      <Stack direction="row" justifyContent={"space-between"} sx={{ userSelect: "none" }}>
        <Stack direction="row" alignItems="center" gap={{ mobile: 1, tablet: 2 }}>
          <Strong size={16} minWidth={{ mobile: "12px" }}>
            {props.placement}.
          </Strong>
          <Medium size={16}>
            <Box
              component="span"
              sx={{
                maxWidth: { mobile: "120px", tablet: "150px" },
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {props.displayName}
            </Box>
          </Medium>
        </Stack>
        <Stack direction="row" alignItems={"baseline"} gap={0.5} sx={{ userSelect: "none" }}>
          <Heavy size={{ mobile: 28, tablet: 32 }}>{props.value}</Heavy>
          <Text size={{ mobile: 12, tablet: 12 }}>{props.unit}</Text>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default LiveLeaderboardPlacement;
