import { SingleDocDashboard } from "../../latestDashboard";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import useLive from "../../hooks/useLive";
import { LIVE_USER_ID } from "../../dashboard/liveEvents";
import { ErrorBoundary } from "../ErrorBoundary";

export default function ThrowRoute() {
  const { throwId } = useParams();
  const [{ userId, externalUserId, trueUserId }, userLoading] = useUser();
  const { isLiveRoute } = useLive();

  if (externalUserId) {
    return (
      // <ErrorBoundary eventName="external_user_throw_error">
      userLoading ? null : <SingleDocDashboard userId={externalUserId} throwId={throwId} />
      // </ErrorBoundary>
    );
  }

  if (isLiveRoute) {
    return (
      // <ErrorBoundary eventName="live_user_throw_error">
      userLoading ? null : <SingleDocDashboard userId={LIVE_USER_ID} throwId={throwId} />
      // </ErrorBoundary>
    );
  }

  if (trueUserId) {
    return (
      // <ErrorBoundary eventName="user_throw_error">
      userLoading ? null : <SingleDocDashboard userId={trueUserId} throwId={throwId} />
      // </ErrorBoundary>
    );
  }

  return (
    // <ErrorBoundary eventName="default_user_throw_error">
    userLoading ? null : <SingleDocDashboard userId={userId} throwId={throwId} />
    // </ErrorBoundary>
  );
}
