import { updateDoc } from "firebase/firestore";
import { StockShot, stockShotConverter } from "../converters/stockShot";
import { getUserStockShot } from "../docs";

export const updateStockShot = async (
  userId: string,
  stockShotId: string,
  updatedData: Partial<StockShot>,
): Promise<void> => {
  const shotRef = getUserStockShot(userId, stockShotId)?.withConverter(stockShotConverter);

  if (shotRef) {
    await updateDoc(shotRef, updatedData);
  }
};
