import * as React from "react";
import { Container, Stack, Typography } from "@mui/material";
import StyledFirebaseAuth from "../../analysis/StyledFirebaseAuth";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../../firebaseConfig";
import { authUiConfig } from "../../authUiConfig";

export default function Unauthenticated() {
  return (
    <Container
      sx={{
        my: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Stack flexBasis="100%">
        <Typography variant="h5" mb={2}>
          To continue, sign in to TechDisc.
        </Typography>
        <StyledFirebaseAuth uiConfig={authUiConfig()} firebaseAuth={getAuth(firebaseApp)} />
      </Stack>
    </Container>
  );
}
