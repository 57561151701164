import { Stack, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { Strong } from "./Text";
import CompleteNode from "./icons/CompleteNode";
import InactiveNode from "./icons/InactiveNode";
import ActiveNode from "./icons/ActiveNode";

export interface StepperStep {
  label: string;
  description?: React.ReactNode;
  onClick?: (activeStep: number) => void;
  disabled?: boolean;
}

export interface StepperProps {
  steps: StepperStep[];
  activeStep: number;
  orientation?: "horizontal" | "vertical";
}

function Stepper(props: StepperProps) {
  const { steps, activeStep, orientation } = props;
  const theme = useTheme();
  return (
    <Stack
      direction={{ mobile: "column", tablet: orientation === "horizontal" ? "row" : "column" }}
      alignItems={{
        mobile: "flex-start",
        tablet: orientation === "horizontal" ? "center" : "flex-start",
      }}
      gap={2}
      sx={{ width: "100%" }}
    >
      {steps.map((step, index) => {
        const isComplete = index < activeStep - 1;
        const isActive = index === activeStep - 1;
        return (
          <Fragment key={index}>
            <Stack
              direction="row"
              gap={1}
              onClick={step.disabled ? void 0 : () => step.onClick?.(index + 1)}
              sx={{
                minWidth: "fit-content",
                userSelect: "none",
                alignItems: "center",
                color: isActive
                  ? "primary.main"
                  : isComplete
                    ? "secondary.main"
                    : step.disabled
                      ? "grey.500"
                      : "grey.600",
                "&:hover": {
                  cursor: isActive ? "inherit" : step.disabled ? "not-allowed" : "pointer",
                  color: isActive ? "primary.main" : isComplete ? "secondary.dark" : "auto",
                },
              }}
            >
              <Stack
                sx={{
                  color:
                    isComplete || isActive
                      ? "primary.main"
                      : step.disabled
                        ? "grey.500"
                        : "grey.600",
                }}
              >
                {isComplete ? <CompleteNode /> : isActive ? <ActiveNode /> : <InactiveNode />}
              </Stack>
              <Strong
                size={14}
                color={isActive ? "primary.main" : step.disabled ? "grey.500" : "inherit"}
              >
                {step.label}
              </Strong>
            </Stack>
            {index < steps.length - 1 && (
              <Stack
                sx={{
                  width: "100%",
                  position: "relative",
                  "&:not(:last-child)": { width: "100%" },
                  "&:not(:last-child)::before": {
                    content: '""',
                    position: "absolute",
                    width: { tablet: "100%", mobile: "2px" },
                    top: orientation === "horizontal" ? "50%" : "100%",
                    right: { mobile: "50%", tablet: "auto" },
                    height: { mobile: "100%", tablet: orientation === "horizontal" ? "2px" : "0%" },
                    transform: { mobile: "rotate(90deg)", tablet: "none" },
                    backgroundImage: `url(${
                      "data:image/svg+xml;base64," +
                      btoa(`
                      <svg width="117" height="3" viewBox="0 0 117 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line x1="1" y1="1.06006" x2="116" y2="1.06006" stroke="${isComplete ? theme.palette.primary.main : theme.palette.grey[400]}" stroke-width="2" stroke-linecap="round" stroke-dasharray="2 4 6 8"/>
                      </svg>                      
                `)
                    })`,
                  },
                }}
              />
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
}

export default Stepper;
