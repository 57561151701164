import { ArrowRight, CloseOutlined, Verified } from "@mui/icons-material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import HomeIcon from "@mui/icons-material/Home";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Button, IconButton, Link, Paper, Stack, Typography, alpha } from "@mui/material";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Link as RouterLink } from "react-router-dom";
import { StoreMetadata } from "../../firebase/converters/leaderboardStoreMetadata";
import { Medium, Strong } from "../Text";
import { STORE_AVAILABILITY_DESCRIPTIONS } from "./settings/consts";

export type StoreInfoCardProps = {
  onClose?: () => void;
  store?: StoreMetadata;
  key?: string;
};

export const StoreInfoCard = (props: StoreInfoCardProps) => {
  const { store, key, onClose } = props;
  const [address1, ...rest] = store?.address?.formattedAddress?.split(",") ?? [];
  const address2 = rest.join(",");

  const formatPhoneNumber = (phone: string) => {
    const phoneNumber = parsePhoneNumberFromString(phone);

    return phoneNumber?.isValid()
      ? phoneNumber?.formatNational()
      : phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  return (
    <Paper
      key={key}
      sx={{
        position: "relative",
        p: 2,
        "&:hover": {
          backgroundColor: (theme) => theme.palette.grey[50],
        },
      }}
      id={store?.id}
    >
      {store && (
        <Stack gap={2}>
          <Stack gap={0.5}>
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
              <Stack
                direction="row"
                sx={{
                  lineHeight: "1.6rem",
                  fontWeight: 500,
                  fontSize: "clamp(1rem, 1.2rem, 1.2rem)",
                }}
                gap={1}
                alignItems="center"
              >
                {store.name}
                {store?.isPremium && <Verified color="primary" />}
              </Stack>
              <IconButton onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            </Stack>
            {store.address?.formattedAddress && (
              <Stack gap={0.5}>
                <Typography color="grey.700" sx={{ fontSize: "clamp(0.8rem, 1rem, 1.2rem)" }}>
                  {address1}
                </Typography>
                <Typography color="grey.700" sx={{ fontSize: "clamp(0.8rem, 1rem, 1.2rem)" }}>
                  {address2}
                </Typography>
              </Stack>
            )}
          </Stack>
          {store.availability && store.availability !== "full" && (
            <Stack
              direction="row"
              gap={1}
              p={1}
              sx={{
                fontSize: { md: "auto", mobile: "14px" },
                border: "1px solid",
                backgroundColor:
                  store.availability === "limited"
                    ? (theme) => alpha(theme.palette.primary.main, 0.05)
                    : (theme) => alpha(theme.palette.warning.main, 0.05),
                borderColor:
                  store.availability === "limited"
                    ? (theme) => alpha(theme.palette.primary.main, 0.2)
                    : (theme) => alpha(theme.palette.warning.main, 0.2),
                borderRadius: 1,
              }}
            >
              <WarningAmberOutlinedIcon
                sx={{ color: store.availability === "limited" ? "primary.main" : "warning.main" }}
                fontSize="small"
              />
              <Stack gap={1}>
                <Medium>{STORE_AVAILABILITY_DESCRIPTIONS[store.availability]}</Medium>
              </Stack>
            </Stack>
          )}
          {(store.phone || store.email || store.website) && (
            <>
              <Stack gap={1}>
                {store.phone && (
                  <Stack direction="row" gap={1}>
                    <CallOutlinedIcon sx={{ color: "grey.500" }} fontSize="small" />
                    <Strong color="grey.800">{formatPhoneNumber(store.phone)}</Strong>
                  </Stack>
                )}
                {store.email && (
                  <Stack direction="row" gap={1}>
                    <MailOutlineIcon sx={{ color: "grey.500" }} fontSize="small" />
                    <Strong color="grey.800" sx={{ fontSize: { md: "auto", mobile: "14px" } }}>
                      {store.email}
                    </Strong>
                  </Stack>
                )}
                {store.website && (
                  <Stack direction="row" gap={1}>
                    <HomeIcon sx={{ color: "grey.500" }} fontSize="small" />
                    <Link
                      component={RouterLink}
                      target="_blank"
                      to={store.website}
                      sx={{ textDecoration: "none", fontWeight: 300, width: "fit-content" }}
                    >
                      Visit Website
                    </Link>
                  </Stack>
                )}
              </Stack>
              {store.isPremium && (
                <Link
                  sx={{
                    textDecoration: "none",
                    fontWeight: 400,
                  }}
                >
                  <Button
                    size="small"
                    variant="primary"
                    component={RouterLink}
                    to={`${window.location.origin}/leaderboards/${store.id}`}
                  >
                    View Leaderboard <ArrowRight fontSize="small" />
                  </Button>
                </Link>
              )}
            </>
          )}
        </Stack>
      )}
    </Paper>
  );
};
