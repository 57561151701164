import { Button, Container, Typography } from "@mui/material";
// @ts-ignore
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center", my: 4, gap: 2 }}
    >
      <img
        src={"https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDisc_Logo_dark.svg"}
        width="300"
        height="300"
        alt="TechDisc logo"
        style={{ opacity: 0.2 }}
      />
      <Typography
        variant="h2"
        textAlign={"center"}
        fontWeight={600}
        fontFamily={"Days One"}
        letterSpacing={-1}
      >
        OOPS!
      </Typography>
      <Typography variant="h4" textAlign={"center"}>
        Looks like your disc landed out of bounds...
      </Typography>
      <Button type="button" variant="contained" sx={{ mt: 4 }} onClick={() => navigate(-1)}>
        Back to the drop zone
      </Button>
    </Container>
  );
}
