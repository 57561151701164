import { Avatar, Link, Skeleton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { StoreMetadata } from "../../firebase/converters/leaderboardStoreMetadata";

// LeaderboardView.tsx
interface LeaderboardViewProps {
  storeMetadata?: StoreMetadata | null;
  isMetadataLoading: boolean;
}

export default function LeaderboardStoreInfo({
  isMetadataLoading,
  storeMetadata,
}: LeaderboardViewProps) {
  const storeAddress = storeMetadata?.address;
  return isMetadataLoading ? (
    <>
      <Avatar variant="square" sx={{ width: "100px", height: "100px" }} />
      <Stack>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={200} />
      </Stack>
    </>
  ) : (
    storeMetadata?.name && (
      <>
        {storeMetadata?.logo && (
          <Box
            component={"img"}
            src={storeMetadata?.logo}
            alt={`${storeMetadata?.name} Logo`}
            width="100px"
            height="100px"
            bgcolor={"white"}
            sx={{ border: (theme) => `1px solid ${theme.palette.grey[600]}` }}
          />
        )}
        {storeMetadata?.name && (
          <Stack justifyContent={"center"} gap={0.5}>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Typography variant="h5">{storeMetadata?.name}</Typography>
            </Stack>
            {storeAddress && (
              <div>
                <Typography variant="subtitle2" color="grey.700">
                  {storeAddress?.addressLines?.[0]}
                </Typography>
                <Typography variant="subtitle2" color="grey.700">
                  {storeAddress?.city &&
                    `${storeAddress?.city}, ${storeAddress?.state} ${storeAddress?.postalCode}`}
                </Typography>
              </div>
            )}
            {storeMetadata?.website && (
              <Typography
                variant="caption"
                component={Link}
                href={storeMetadata?.website}
                target={"_blank"}
                height={"fit-content"}
                aria-label="Website"
                underline="none"
              >
                Visit Website
              </Typography>
            )}
          </Stack>
        )}
      </>
    )
  );
}
