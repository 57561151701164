import { ErrorMessage } from "@hookform/error-message";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeEvent, createRef } from "react";
import { FieldErrors, useFormContext } from "react-hook-form";

export type UserProfileSettingsProps = {
  onSelectPhoto: (file: File) => void;
  onDeletePhoto: () => void;
};

export default function UserProfileSettings(props: UserProfileSettingsProps) {
  const { onSelectPhoto, onDeletePhoto } = props;
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const photo = watch("photo");
  const fileRef = createRef<HTMLInputElement>();

  return (
    <>
      <Stack direction="row" gap={3}>
        <Stack justifyContent={"space-between"} flexBasis={"60%"}>
          <Stack gap={3}>
            <FormControl fullWidth>
              <FormLabel htmlFor="name" sx={{ mb: 1 }}>
                Display Name
              </FormLabel>
              <TextField id="name" size="small" inputProps={register("name")} />
              <FormErrorText field="name" errors={errors} />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel htmlFor="pdgaNumber" sx={{ mb: 1 }}>
                PDGA #
              </FormLabel>
              <TextField id="pdgaNumber" size="small" inputProps={register("pdgaNumber")} />
              <FormErrorText field="pdgaNumber" errors={errors} />
            </FormControl>
          </Stack>
        </Stack>
        <Stack gap={3} flexBasis={"40%"} alignItems={"center"}>
          <FormControl sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Badge
              variant="standard"
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{ color: "grey.700" }}
              badgeContent={
                <IconButton
                  disabled={!photo}
                  onClick={() => onDeletePhoto()}
                  size="small"
                  color="inherit"
                  sx={{
                    bgcolor: (theme) => theme.palette.grey[300],
                    "&:disabled": {
                      bgcolor: (theme) => theme.palette.background.paper,
                      color: "grey.400",
                    },
                    "&:hover": {
                      color: (theme) => theme.palette.error.main,
                      bgcolor: "grey.200",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <Box
                component={Avatar}
                width="200px"
                height="200px"
                sx={{
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.grey[300],
                  aspectRatio: "1/1",
                }}
                src={photo}
              />
            </Badge>
            <Button onClick={() => fileRef.current?.click()} variant="outlined" size="small">
              Upload
              <Input
                size="small"
                inputProps={{ accept: "image/*", ref: fileRef }}
                type="file"
                sx={{
                  display: "none",
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const file = e.target?.files?.item(0);
                  if (file) {
                    onSelectPhoto(file);
                  }
                }}
              />
            </Button>
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
}

const FormErrorText = (props: { field: string; errors: FieldErrors }) => (
  <ErrorMessage
    name={props.field}
    errors={props.errors}
    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
  />
);
