import { Box, PaperProps, Stack, StackProps } from "@mui/material";
import { useMap } from "@vis.gl/react-google-maps";
import { useEffect, useRef, useState } from "react";
import { PremiumStorePin } from "../icons/PremiumStorePin";
import { StorePinIcon } from "../icons/StorePinIcon";

const items = [
  {
    id: "premium_store",
    label: (
      <Stack sx={{ color: "white" }} direction="row" alignItems="center">
        Store
        <Stack
          sx={{ color: "primary.main", fontWeight: 600, fontSize: "1.2rem" }}
          alignItems="baseline"
        >
          +
        </Stack>
      </Stack>
    ),
    Icon: PremiumStorePin,
    icon: (
      <svg width="20" viewBox="0 0 28 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_178_128)">
          <g filter="url(#filter0_f_178_128)">
            <path
              d="M4 14.9824C4 7.00458 10.0022 4.98242 14 4.98242C17.9978 4.98242 24 7.00458 24 14.9824C24 22.9603 17.3051 39.0173 14 39.0173C10.6949 39.0173 4 22.9603 4 14.9824Z"
              fill="#2A81DE"
            />
            <path
              d="M14 4.48242C11.9303 4.48242 9.31324 5.00371 7.2005 6.59098C5.06375 8.19628 3.5 10.8502 3.5 14.9824H4.5C4.5 11.1368 5.93735 8.79073 7.80115 7.39049C9.68895 5.97221 12.0719 5.48242 14 5.48242V4.48242ZM24.5 14.9824C24.5 10.8502 22.9363 8.19628 20.7995 6.59098C18.6868 5.00371 16.0697 4.48242 14 4.48242V5.48242C15.9281 5.48242 18.311 5.97221 20.1988 7.39049C22.0627 8.79073 23.5 11.1368 23.5 14.9824H24.5ZM14 39.5173C14.6045 39.5173 15.1894 39.1602 15.7152 38.6815C16.2563 38.1889 16.8146 37.494 17.3722 36.6621C18.4892 34.9953 19.6504 32.7066 20.701 30.1838C22.7966 25.1512 24.5 19.0699 24.5 14.9824H23.5C23.5 18.8728 21.8559 24.8089 19.7778 29.7993C18.7415 32.288 17.6092 34.5122 16.5415 36.1054C16.0066 36.9035 15.4997 37.5254 15.042 37.9421C14.5691 38.3726 14.2218 38.5173 14 38.5173V39.5173ZM3.5 14.9824C3.5 19.0699 5.20338 25.1512 7.29902 30.1838C8.34959 32.7066 9.51076 34.9953 10.6278 36.6621C11.1854 37.494 11.7437 38.1889 12.2848 38.6815C12.8106 39.1602 13.3955 39.5173 14 39.5173V38.5173C13.7782 38.5173 13.4309 38.3726 12.958 37.9421C12.5003 37.5254 11.9934 36.9035 11.4585 36.1054C10.3908 34.5122 9.25849 32.288 8.22218 29.7993C6.14409 24.8089 4.5 18.8728 4.5 14.9824H3.5Z"
              fill="#2A81DE"
            />
          </g>
          <path
            d="M4 14.9824C4 7.00458 10.0022 4.98242 14 4.98242C17.9978 4.98242 24 7.00458 24 14.9824C24 22.9603 17.3051 39.0173 14 39.0173C10.6949 39.0173 4 22.9603 4 14.9824Z"
            fill="#2A81DE"
          />
          <path
            d="M14 4.48242C11.9303 4.48242 9.31324 5.00371 7.2005 6.59098C5.06375 8.19628 3.5 10.8502 3.5 14.9824H4.5C4.5 11.1368 5.93735 8.79073 7.80115 7.39049C9.68895 5.97221 12.0719 5.48242 14 5.48242V4.48242ZM24.5 14.9824C24.5 10.8502 22.9363 8.19628 20.7995 6.59098C18.6868 5.00371 16.0697 4.48242 14 4.48242V5.48242C15.9281 5.48242 18.311 5.97221 20.1988 7.39049C22.0627 8.79073 23.5 11.1368 23.5 14.9824H24.5ZM14 39.5173C14.6045 39.5173 15.1894 39.1602 15.7152 38.6815C16.2563 38.1889 16.8146 37.494 17.3722 36.6621C18.4892 34.9953 19.6504 32.7066 20.701 30.1838C22.7966 25.1512 24.5 19.0699 24.5 14.9824H23.5C23.5 18.8728 21.8559 24.8089 19.7778 29.7993C18.7415 32.288 17.6092 34.5122 16.5415 36.1054C16.0066 36.9035 15.4997 37.5254 15.042 37.9421C14.5691 38.3726 14.2218 38.5173 14 38.5173V39.5173ZM3.5 14.9824C3.5 19.0699 5.20338 25.1512 7.29902 30.1838C8.34959 32.7066 9.51076 34.9953 10.6278 36.6621C11.1854 37.494 11.7437 38.1889 12.2848 38.6815C12.8106 39.1602 13.3955 39.5173 14 39.5173V38.5173C13.7782 38.5173 13.4309 38.3726 12.958 37.9421C12.5003 37.5254 11.9934 36.9035 11.4585 36.1054C10.3908 34.5122 9.25849 32.288 8.22218 29.7993C6.14409 24.8089 4.5 18.8728 4.5 14.9824H3.5Z"
            fill="#BBDBF9"
          />
          <path
            d="M13.9563 11.0996L13.9563 16.126M13.9563 16.126V21.0996M13.9563 16.126L19 16.126M13.9563 16.126L9 16.126"
            stroke="white"
            strokeWidth="2.5"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_178_128"
            x="-0.5"
            y="0.482422"
            width="29"
            height="43.0347"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_178_128" />
          </filter>
          <clipPath id="clip0_178_128">
            <rect width="28" height="44" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: "store",
    label: <Stack sx={{ color: "white" }}>Store</Stack>,
    Icon: StorePinIcon,
    icon: (
      <svg width="20" viewBox="0 0 28 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 14.9824C4 7.00458 10.0022 4.98242 14 4.98242C17.9978 4.98242 24 7.00458 24 14.9824C24 22.9603 17.3051 39.0173 14 39.0173C10.6949 39.0173 4 22.9603 4 14.9824Z"
          fill={"white"}
        />
        <path
          d="M14 4.48242C11.9303 4.48242 9.31324 5.00371 7.2005 6.59098C5.06375 8.19628 3.5 10.8502 3.5 14.9824H4.5C4.5 11.1368 5.93735 8.79073 7.80115 7.39049C9.68895 5.97221 12.0719 5.48242 14 5.48242V4.48242ZM24.5 14.9824C24.5 10.8502 22.9363 8.19628 20.7995 6.59098C18.6868 5.00371 16.0697 4.48242 14 4.48242V5.48242C15.9281 5.48242 18.311 5.97221 20.1988 7.39049C22.0627 8.79073 23.5 11.1368 23.5 14.9824H24.5ZM14 39.5173C14.6045 39.5173 15.1894 39.1602 15.7152 38.6815C16.2563 38.1889 16.8146 37.494 17.3722 36.6621C18.4892 34.9953 19.6504 32.7066 20.701 30.1838C22.7966 25.1512 24.5 19.0699 24.5 14.9824H23.5C23.5 18.8728 21.8559 24.8089 19.7778 29.7993C18.7415 32.288 17.6092 34.5122 16.5415 36.1054C16.0066 36.9035 15.4997 37.5254 15.042 37.9421C14.5691 38.3726 14.2218 38.5173 14 38.5173V39.5173ZM3.5 14.9824C3.5 19.0699 5.20338 25.1512 7.29902 30.1838C8.34959 32.7066 9.51076 34.9953 10.6278 36.6621C11.1854 37.494 11.7437 38.1889 12.2848 38.6815C12.8106 39.1602 13.3955 39.5173 14 39.5173V38.5173C13.7782 38.5173 13.4309 38.3726 12.958 37.9421C12.5003 37.5254 11.9934 36.9035 11.4585 36.1054C10.3908 34.5122 9.25849 32.288 8.22218 29.7993C6.14409 24.8089 4.5 18.8728 4.5 14.9824H3.5Z"
          fill="#2A81DE"
        />
        <circle cx="14" cy="14.9824" r="4" fill="#2A81DE" />
      </svg>
    ),
  },
];

function StoreMapLegend(props: PaperProps) {
  const [showLegendItemInfo, setShowLegendItemInfo] = useState<"store" | "premium_store" | null>(
    null,
  );
  const itemRef = useRef<HTMLDivElement>(null);
  const map = useMap();

  useEffect(() => {
    // Clear legend info on map click
    map?.addListener("click", () => {
      setShowLegendItemInfo(null);
    });
  }, [map]);

  const handleLegendItemClick = (source: "store" | "premium_store") => {
    setShowLegendItemInfo((prevSource) =>
      prevSource == null ? source : prevSource === source ? null : source,
    );
  };

  return (
    <Stack
      gap={2}
      sx={{
        ...props.sx,
      }}
      alignItems="flex-end"
      height="fit-content"
    >
      <LegendItemInfo
        sx={{ display: { mobile: "flex", md: "none" } }}
        showLegendItemInfo={showLegendItemInfo}
      />
      <Stack
        direction="row"
        sx={{
          px: { mobile: 0.5, tablet: 1 },
          py: 1,
          gap: { mobile: 1, tablet: 2 },
          backgroundColor: "#1C2127",
          border: "1px solid",
          borderColor: "secondary.main",
          borderRadius: 1,
          width: "fit-content",
        }}
      >
        {items.map((item) => (
          <Stack
            id={item.id}
            key={item.id}
            direction="row"
            gap={0.5}
            alignItems="center"
            ref={itemRef}
            onClick={() => handleLegendItemClick(item.id as "store" | "premium_store")}
            sx={{ cursor: "pointer" }}
          >
            {item.icon}
            <Box sx={{ userSelect: "none" }}>{item.label}</Box>
          </Stack>
        ))}
      </Stack>
      <LegendItemInfo
        sx={{ display: { md: "flex", mobile: "none" } }}
        showLegendItemInfo={showLegendItemInfo}
      />
    </Stack>
  );
}

export default StoreMapLegend;

const LegendItemInfo = (
  props: { showLegendItemInfo: "store" | "premium_store" | null } & StackProps,
) => {
  const { showLegendItemInfo, ...rest } = props;
  return (
    <Stack {...rest}>
      {props.showLegendItemInfo === "store" && (
        <Stack
          sx={{
            p: 2,
            gap: 2,
            backgroundColor: "#1C2127",
            border: "1px solid",
            borderColor: "secondary.main",
            borderRadius: 1,
            color: "white",
            fontWeight: 200,
            maxWidth: "256px",
          }}
        >
          <Stack gap={1}>
            <Box sx={{ fontSize: "12px", fontWeight: 600 }}>TEST DRIVE</Box>
            <Box sx={{ fontSize: "14px", color: "grey.300" }}>
              Try the TechDisc, create an analysis set of your throwing session and get the results
              straight to your inbox!
            </Box>
          </Stack>
        </Stack>
      )}
      {props.showLegendItemInfo === "premium_store" && (
        <Stack
          sx={{
            p: 2,
            gap: 2,
            backgroundColor: "#1C2127",
            border: "1px solid",
            borderColor: "secondary.main",
            borderRadius: 1,
            color: "white",
            fontWeight: 200,
            maxWidth: "256px",
          }}
        >
          <Stack gap={1}>
            <Stack direction="row" alignItems="center">
              <Box sx={{ fontSize: "12px", fontWeight: 500 }}>STORE LEADERBOARD</Box>
              <Stack
                sx={{ color: "primary.main", fontWeight: 600, fontSize: "1.2rem" }}
                alignItems="center"
              >
                +
              </Stack>
            </Stack>
            <Box sx={{ fontSize: "14px", color: "grey.300" }}>
              Throw a session of <b>Test Drive</b> and compete against others in the area for a spot
              on the leaderboard! Can you get the top spot?
            </Box>
          </Stack>
          <Stack gap={1}>
            <Box sx={{ fontSize: "12px", fontWeight: 500 }}>TEST DRIVE</Box>
            <Box sx={{ fontSize: "14px", color: "grey.300" }}>
              Try the TechDisc, create an analysis set of your throwing session and get the results
              straight to your inbox!
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
