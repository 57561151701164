import { getAnalytics, logEvent } from "firebase/analytics";
import { BehaviorSubject, from, of } from "rxjs";
import { delay, mergeMap, retry } from "rxjs/operators";
import { firebaseApp } from "../firebaseConfig";
import { TrackPoint } from "../trackUtils";
import { getStorageRef } from "../dashboard/dashboardUtils";
import { getBytes } from "firebase/storage";
import * as THREE from "three";
import { useEffect, useState } from "react";
import { splitFlightTrack } from "../components/flight/XYFlightChart/utils";
import { useGlobal } from "../components/providers/GlobalProvider";

const trackSubject = new BehaviorSubject<TrackPoint[]>([]);

function useTrackSubscription(args: {
  throwId?: string;
  userId?: string;
  skip?: boolean;
  onChange?: () => void;
  type?: DownloadTrackType;
}) {
  const { type, throwId, userId, skip, onChange } = args;
  const [track, setTrack] = useState<TrackPoint[]>([]);
  const [trackId, setTrackId] = useState<string | undefined>(throwId);
  const { prefersMetric } = useGlobal();

  useEffect(() => {
    if (skip) {
      return;
    }
    if (throwId && userId) {
      const subscription = trackSubscription({
        throwId,
        userId,
        type,
      }).subscribe((track) => {
        onChange?.();
        setTrack(track);
        setTrackId(throwId);
      });

      return () => subscription.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throwId, userId, skip]);

  if (throwId !== trackId) {
    return { track: [], backswing: [], flightPath: [] };
  }

  return { track, ...splitFlightTrack(track, prefersMetric) };
}

export default useTrackSubscription;

export function trackSubscription(args: {
  throwId: string;
  userId: string;
  type?: DownloadTrackType;
}) {
  return trackSubject.pipe(
    mergeMap(() => from(downloadTrack(args))),
    mergeMap((track) => {
      if (track?.length > 0) {
        return of(track);
      }
      throw new Error("failed to download track: \n" + JSON.stringify(args, null, 2));
    }),
    // Retrying upon failure, maintaining the delay effect for each retry attempt
    retry({
      count: 5,
      // Using a delay function to apply delay between retries
      delay: (error, retryCount) => {
        console.info(`Attempt ${retryCount}: Retrying after error`, error);
        return of(error).pipe(delay(1000));
      },
    }),
  );
}

export type DownloadTrackType = "backswing" | "throw";

export async function downloadTrack({
  throwId,
  userId,
  type,
}: {
  throwId: string;
  userId: string;
  type?: DownloadTrackType;
}): Promise<TrackPoint[]> {
  try {
    type = type || "throw";
    const throwRef = getStorageRef("/" + type + "-track/" + userId + "/" + throwId + ".throw");

    const bytes = await getBytes(throwRef);

    // const url = await getDownloadURL(throwRef);
    // const response = await fetchWithLogging(url);
    const allItems: TrackPoint[] = [];
    for (const item of iterableFromArray(new Uint8Array(bytes))) {
      allItems.push(item);
    }
    return allItems;
  } catch (e) {
    logEvent(getAnalytics(firebaseApp), "track_download_error", {
      error: e,
      client: "web",
      type: "single_track_download_failure",
    });
    throw new Error("failed to download track: " + throwId);
  }
}

function parseTrackPoint(view: DataView): TrackPoint {
  const t = view.getFloat32(0);
  const x = view.getFloat32(4);
  const y = view.getFloat32(8);
  const z = view.getFloat32(12);

  const qw = view.getFloat32(16);
  const qx = view.getFloat32(20);
  const qy = view.getFloat32(24);
  const qz = view.getFloat32(28);

  const q = new THREE.Quaternion(qx, qy, qz, qw);
  // q = q.multiply(new THREE.Quaternion(1, 0, 0, 0));
  return {
    time: t,
    position: new THREE.Vector3(x, y, z),
    q: q,
  };
}

const trackPointSerializeSize = 32;

function* iterableFromArray(data: Uint8Array) {
  let index = 0;
  while (data.length >= index + trackPointSerializeSize) {
    const view = new DataView(data.buffer, index, trackPointSerializeSize);
    yield parseTrackPoint(view);
    index += trackPointSerializeSize;
  }
  return index;
}
