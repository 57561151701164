import { useState, useMemo, useEffect } from "react";
import { Typography, useTheme, Stack, alpha, Box } from "@mui/material";
import { SparkLineChart } from "@mui/x-charts";
import {
  fetchData,
  formatDate,
  passesOtherFilters,
  getMetricLabel,
  typeKeys,
  metricTypeKeys,
  FilterPropertyType,
} from "../shared/utility";
import { TableRenderProps } from "../ThrowTable";
import * as downsampler from "downsample-lttb";
import Title from "../../dashboard/Title";
import TagManager from "../../components/TagManager";
import TitleLoadingIndicator from "../../components/TitleLoadingIndicator";
import { AnimatePresence, motion } from "framer-motion";
import { div } from "../../utils/math";
import { useGlobal } from "../../components/providers/GlobalProvider";
import { CoreMetrics } from "../../firebase/converters/analysisSet";
import { CoreStats } from "../../model/CoreStats";
import Big from "big.js";

export interface SparklineProps<TData extends CoreStats = CoreMetrics>
  extends Partial<TableRenderProps> {
  type: FilterPropertyType<TData>;
  isThrowSet?: boolean;
  coreStats: TData[];
  tags: string[];
}

export function SparklineComponent<TData extends CoreStats = CoreMetrics>(
  props: SparklineProps<TData>,
) {
  const { type, isThrowSet, coreStats, tags, isLoading, filters } = props;
  const { prefersMetric } = useGlobal();
  const theme = useTheme();

  type SparklineObjectType = {
    data: number[];
    dates: string[];
    labels: string[];
    label: string;
    metricLabel: string;
    high: number;
    avg: number;
    low: number;
  };
  const [sparklineObject, setSparklineObject] = useState<SparklineObjectType>({
    data: [],
    dates: [],
    labels: [],
    label: "",
    metricLabel: getMetricLabel(type),
    high: 0,
    avg: 0,
    low: 0,
  });

  useEffect(() => {
    const docs = isThrowSet ? coreStats : TagManager.filterByTags(tags, coreStats);

    const spark: SparklineObjectType = {
      data: [],
      dates: [],
      labels: [],
      label: (prefersMetric ? metricTypeKeys : typeKeys)[type] || "",
      metricLabel: getMetricLabel(type),
      high: 0,
      avg: 0,
      low: 0,
    };

    let sum = new Big(0);
    let firstRun = true; // for computing average

    if (!docs || (docs && docs.length === 0)) {
      return setSparklineObject(spark);
    }

    // enforcing in order of time
    docs.sort((a, b) => {
      if (a.throwTime < b.throwTime) {
        return 1;
      }
      return -1;
    });

    for (const doc of docs) {
      try {
        if (passesOtherFilters<TData>(filters ?? [], type, doc, true)) {
          const metric = doc[type];

          if (typeof metric === "number") {
            const formattedMetric = new Big(metric.toFixed(1)).toNumber();
            spark.data.unshift(formattedMetric);

            if (firstRun) {
              // this is first run through, so now that we know actual data is in this doc let's set base
              spark.high = formattedMetric;
              spark.low = formattedMetric;
              firstRun = false;
            }
            if (formattedMetric > spark.high) {
              spark.high = formattedMetric;
            }
            if (formattedMetric < spark.low) {
              spark.low = formattedMetric;
            }
            sum = sum.plus(metric); // setting up for average
          }
          // unshifting all to reverse sort docs to be in asc date order

          if (doc.throwTime) {
            spark.dates.unshift(doc.throwTime.toDate().toISOString());
          }

          if (doc.title) {
            spark.labels.unshift(doc.title);
          }
        }
      } catch (e) {
        console.warn("error in sparkline", e);
        // to catch any parsing errors
      }
    }

    spark.avg = parseFloat(div(sum, spark.data.length || 1).toFixed(1));

    setSparklineObject(spark);
  }, [coreStats, filters, type]);

  const [sparklineData, sparklineDates, sparklineLabels] = useMemo(() => {
    if (sparklineObject.data && sparklineObject.data.length > 0) {
      const decimatedData = downsampler.processData(
        sparklineObject.data.map((y, i) => [i, y]),
        Math.min(sparklineObject.data.length, 500),
      );
      return [
        decimatedData.map(([_, y]) => y),
        decimatedData.map(([x]) => new Date(sparklineObject.dates[x])),
        decimatedData.map(([x]) => sparklineObject.labels[x]),
      ];
    }

    return [[], [], []];
  }, [sparklineObject]);

  return (
    <Stack direction="row" height={"100%"} width="100%">
      <Stack sx={{ p: 0, pl: { md: 2, mobile: 1 } }} height={"100%"} width="100%">
        <AnimatePresence>
          <Stack
            direction="row"
            sx={{
              alignSelf: "baseline",
              width: "100%",
              pt: 1,
              pb: 0,
              mb: -1,
              opacity: isLoading ? 0.5 : 1,
            }}
            alignItems="center"
            justifyContent={"space-between"}
            component={motion.div}
            transition={{ duration: 0.05 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Title variant="secondary">{sparklineObject.label}</Title>
            <Box mr={1}>{isLoading && <TitleLoadingIndicator />}</Box>
          </Stack>
        </AnimatePresence>
        <SparkLineChart
          sx={{ p: 0, m: 0 }}
          colors={[alpha(theme.palette.secondary.light, 0.75)]}
          data={sparklineData}
          valueFormatter={(value) => `${value} ${sparklineObject.metricLabel}`}
          xAxis={{
            tickSize: 0,
            valueFormatter: (index): string => {
              if (sparklineLabels?.[index]) {
                return `${sparklineLabels[index]} - ${formatDate(sparklineDates[index])}`;
              } else if (sparklineDates?.[index]) {
                return formatDate(sparklineDates[index]);
              } else {
                return "Missing label";
              }
            },
          }}
          showHighlight={true}
          showTooltip={Boolean(sparklineData.length)}
          height={100}
        ></SparkLineChart>
        <Stack direction="row" alignItems="center" sx={{ pb: 1.75, opacity: isLoading ? 0.5 : 1 }}>
          <Typography
            variant="subtitle2"
            fontWeight="medium"
            color="grey.500"
            letterSpacing={"-0.05rem"}
          >
            {sparklineObject.metricLabel}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ pr: { md: 2, mobile: 1 }, py: 1, height: "100%" }}>
        <Stack justifyContent="center" sx={{ flexBasis: "30%" }}>
          <Typography
            sx={{ textAlign: "right" }}
            variant="caption"
            color="grey.500"
            letterSpacing={"-0.05rem"}
            lineHeight={"10px"}
          >
            MAX
          </Typography>
          <Typography
            // variant="caption"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "right",
              margin: 0,
              color: theme.palette.grey[600],
            }}
          >
            {sparklineObject.high}
          </Typography>
        </Stack>
        <Stack justifyContent="center" sx={{ flexBasis: "40%" }}>
          <Typography
            sx={{ textAlign: "right" }}
            variant="caption"
            color="grey.500"
            letterSpacing={"-0.05rem"}
            lineHeight={"10px"}
          >
            AVG
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "right",
              margin: 0,
              color: theme.palette.primary.main,
            }}
          >
            {sparklineObject.avg}
          </Typography>
        </Stack>
        <Stack justifyContent="center" sx={{ flexBasis: "30%" }}>
          <Typography
            sx={{ textAlign: "right" }}
            variant="caption"
            color="grey.500"
            letterSpacing={"-0.05rem"}
            lineHeight={"10px"}
          >
            MIN
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "right",
              margin: 0,
              color: theme.palette.grey[600],
            }}
          >
            {sparklineObject.low}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
