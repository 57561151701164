import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { motion } from "framer-motion";

interface InactiveNodeProps extends SvgIconProps {
  fillColor?: string;
}

const InactiveNode: React.FC<InactiveNodeProps> = ({ fillColor = "#9E9E9E", ...props }) => (
  <SvgIcon component={motion.svg} layout {...props} viewBox="0 0 20 21">
    <path
      d="M10.7143 10.4801C10.7143 9.52767 9.28572 9.52767 9.28572 10.4801C9.28572 11.4324 10.7143 11.4324 10.7143 10.4801Z"
      fill={"currentColor"}
    />
    <path
      d="M10 20.48C4.48571 20.48 0 15.9943 0 10.48C0 4.96569 4.48571 0.47998 10 0.47998C15.5143 0.47998 20 4.96569 20 10.48C20 15.9943 15.5143 20.48 10 20.48ZM10 1.90855C5.27333 1.90855 1.42857 5.75331 1.42857 10.48C1.42857 15.2066 5.27333 19.0514 10 19.0514C14.7267 19.0514 18.5714 15.2066 18.5714 10.48C18.5714 5.75331 14.7267 1.90855 10 1.90855Z"
      fill={"currentColor"}
    />
    <path
      d="M9.99999 14.7656C7.63671 14.7656 5.71428 12.8432 5.71428 10.4799C5.71428 8.11663 7.63671 6.1942 9.99999 6.1942C12.3633 6.1942 14.2857 8.11663 14.2857 10.4799C14.2857 12.8432 12.3633 14.7656 9.99999 14.7656ZM9.99999 7.62277C8.42447 7.62277 7.14285 8.90439 7.14285 10.4799C7.14285 12.0554 8.42447 13.3371 9.99999 13.3371C11.5755 13.3371 12.8571 12.0554 12.8571 10.4799C12.8571 8.90439 11.5755 7.62277 9.99999 7.62277Z"
      fill={"currentColor"}
    />
  </SvgIcon>
);

export default InactiveNode;
