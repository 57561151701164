import React, { ReactNode } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Container, Stack, Tab, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { useSessionStorage } from "../hooks/useLocalStorage";

interface TabItem {
  label: string;
  content: ReactNode;
}

interface SettingsPageProps<TFieldValues extends FieldValues> {
  id: string;
  title: string;
  tabs: TabItem[];
  methods: ReturnType<typeof useForm<TFieldValues>>;
}

export const SettingsPage = <TFieldValues extends FieldValues>(
  props: SettingsPageProps<TFieldValues>,
) => {
  const { id, title, tabs, methods } = props;
  const [currentTab, setCurrentTab] = useSessionStorage(`${id}-current-tab`, tabs[0].label);

  return (
    <Container
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Typography variant="h5" color="grey.600">
        {title}
      </Typography>
      <TabContext value={currentTab}>
        <Stack
          sx={{
            bgcolor: "white",
            border: "1px solid",
            borderColor: (theme) => theme.palette.grey[300],
          }}
        >
          <Stack direction="row" flexBasis={"100%"}>
            <Stack
              sx={{
                borderColor: (theme) => theme.palette.grey[300],
                bgcolor: (theme) => theme.palette.grey[50],
                flexBasis: "200px",
              }}
            >
              <TabList onChange={(_, newValue) => setCurrentTab(newValue)} orientation="vertical">
                {tabs.map((tab) => (
                  <Tab key={tab.label} label={tab.label} value={tab.label} />
                ))}
              </TabList>
            </Stack>
            <Stack
              flexGrow={3}
              sx={{
                minHeight: "512px",
              }}
            >
              {tabs.map((tab) => (
                <TabPanel value={tab.label} key={tab.label}>
                  {tab.content}
                </TabPanel>
              ))}
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            width="100%"
            justifyContent={"flex-end"}
            sx={{ p: 2, backgroundColor: "grey.100" }}
            gap={1.5}
          >
            <Button
              variant="secondary"
              disabled={
                !Object.keys(methods.formState.dirtyFields).length || methods.formState.isSubmitting
              }
              onClick={() => methods.reset()}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={
                !Object.keys(methods.formState.dirtyFields).length || methods.formState.isSubmitting
              }
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </TabContext>
    </Container>
  );
};
