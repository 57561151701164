export interface DiscData {
  name: string;
  plastics: { name: string }[];
  molds: string[];
}

const discData: DiscData[] = [
  {
    molds: [
      "Gorgon",
      "Scorpion (new)",
      "Racer",
      "Racer 2",
      "Rollo",
      "Alien (new)",
      "Juggernaut",
      "Alien",
      "Rat (New)",
      "Jay (New)",
      "Wombat 3 (New)",
      "Charger",
      "Jay",
      "Hawkeye",
      "Xero",
      "IT - Innova Tech",
      "Grenade",
      "Toro",
      "Animal",
      "Invader",
      "Rhyno X",
      "Invictus",
      "Warhog",
      "Avatar",
      "Croc",
      "Lion",
      "Firefly",
      "Corvette",
      "Bullfrog",
      "Gator3",
      "Caiman",
      "AviarX3",
      "Leopard 3",
      "Mako 3",
      "Roc 3",
      "Shark 3",
      "Teebird 3",
      "TL 3",
      "Rat",
      "Firestorm",
      "Wombat3",
      "RocX3",
      "Aviar 3",
      "Manta",
      "Shryke",
      "Gargoyle",
      "Big Kahuna",
      "Makani",
      "Mirage",
      "Stud",
      "Colossus",
      "Commander",
      "XT V-Aviar",
      "Mystere",
      "Colt",
      "Hawg",
      "Wombat",
      "VRoc",
      "Whale",
      "Savant",
      "Thunderbird",
      "Daedalus",
      "Foxbat",
      "Nova",
      "Atlas",
      "Tern",
      "Krait",
      "Dominator",
      "Mamba",
      "Lycan",
      "TeeDevil",
      "Archon",
      "Ape",
      "Vulcan",
      "Wedge",
      "Wahoo",
      "Katana",
      "Mako",
      "Dart",
      "Groove",
      "Boss",
      "Monarch",
      "Pulsar",
      "Pig",
      "XCaliber",
      "Sonic",
      "Destroyer",
      "Starfire L (SL)",
      "Gremlin",
      "TeeBird L (TL)",
      "Kite",
      "Firebird L (FL)",
      "Max",
      "Skeeter",
      "CRO",
      "Goblin",
      "Tee-Rex",
      "Roadrunner",
      "Wraith",
      "Coyote",
      "Sidewinder",
      "Bulldog",
      "Hero Disc Type 235",
      "Hydra",
      "ORC",
      "Viking",
      "Starfire",
      "Monster",
      "Beast",
      "CE Spider",
      "CE Aero",
      "Ace",
      "Archangel",
      "CE FireBird",
      "CE Aviar",
      "CE TeeBird",
      "Dragon",
      "CE Valkyrie",
      "CE Eagle",
      "CE Leopard",
      "Spider",
      "Valkyrie",
      "Firebird",
      "Gator",
      "Leopard",
      "TeeBird",
      "Puma",
      "Eagle (new)",
      "Wolf",
      "Rhyno",
      "Cheetah (retooled)",
      "Banshee",
      "Pegasus",
      "Moray",
      "Cheetah",
      "Apple",
      "Super Nova",
      "Raven",
      "Gopher",
      "Ram",
      "Gazelle",
      "Dolphin",
      "Python",
      "Pole Cat",
      "Classic Roc",
      "Cobra Classic",
      "Piranha",
      "Griffin",
      "Panther",
      "Zephyr",
      "Whippet",
      "Viper",
      "Jaguar",
      "Birdie",
      "Birdie (retooled)",
      "Condor",
      "Barracuda",
      "Scorpion",
      "Lynx",
      "Aviar XD (retooled)",
      "Shark",
      "Cobra",
      "Phenix",
      "Aviar Putter",
      "Hammer",
      "Roc",
      "Stingray",
      "Classic Ace",
      "Coupe",
      "Aviar",
      "Aviar XD",
      "Aero",
      "Eagle (old)",
      "Khan",
      "Power Disc2",
      "MID-DISC3",
      "Power",
      "Fairway",
    ],
    name: "Innova",
    plastics: [
      {
        name: "Halo Star",
      },
      {
        name: "Star",
      },
      {
        name: "Gstar",
      },
      {
        name: "Champion",
      },
      {
        name: "Metal Flake",
      },
      {
        name: "Blizzard Champion",
      },
      {
        name: "Nexus",
      },
      {
        name: "XT",
      },
      {
        name: "Pro",
      },
      {
        name: "KC Pro",
      },
      {
        name: "R-Pro",
      },
      {
        name: "JK Pro",
      },
      {
        name: "DX",
      },
      {
        name: "DX Glow",
      },
      {
        name: "Shimmer Star",
      },
      {
        name: "StarLite",
      },
      {
        name: "EchoStar",
      },
      {
        name: "Champion Glow",
      },
      {
        name: "Luster Champion",
      },
    ],
  },
  {
    molds: [
      " ES1",
      "Vela",
      "Solstice",
      "Omega4 ",
      "Draco",
      "Falcon",
      "Marksman",
      "Taurus",
      "Sabot",
      "Aquarius",
      "Moab",
      "Veteran",
      "Aries",
      "Mortar",
      "Tank - Panzer",
      "Omega SuperSoft Putter Big Bead",
      "Scorpius",
      "Quasar",
      "Astra",
      "Orion LF",
      "Orion LS",
      "QJLS",
      "QMS",
      "JLS",
      "EXP",
      "Sentinel MF",
      "Polaris LF",
      "Polaris LS",
      "Aurora MF",
      "Midrange MS/Aurora",
      "Omega",
      "Tachyon LS",
      "Tachyon LF",
    ],
    name: "Millennium Golf",
    plastics: [
      {
        name: "Standard",
      },
      {
        name: "Quantum",
      },
      {
        name: "Sirius",
      },
      {
        name: "ET",
      },
      {
        name: "Delta-T",
      },
      {
        name: "Omega",
      },
      {
        name: "Supersoft",
      },
      {
        name: "Hybrid",
      },
      {
        name: "Lunar",
      },
      {
        name: "Zero-G",
      },
      {
        name: "Base",
      },
    ],
  },
  {
    molds: [
      "Trail",
      "Detour",
      "Zenith",
      "Glitch",
      "Watt",
      "Terra",
      "Uplink",
      "Nomad",
      "Cypher",
      "Beam",
      "Reactor",
      "Ohm",
      "Entropy",
      "Deflector",
      "Vertex",
      "Particle",
      "Dimension",
      "Limit",
      "Relativity",
      "Teleport",
      "Catalyst",
      "Energy",
      "Nitro",
      "Octane",
      "Matrix",
      "Signal",
      "Spin",
      "Relay",
      "Orbital",
      "Phase",
      "Photon",
      "Wave",
      "Atom",
      "Impulse",
      "Motion",
      "Tesla",
      "Inertia",
      "Switch",
      "Servo",
      "Resistor",
      "Tensor",
      "Tangent",
      "Shock",
      "Amp",
      "Volt",
      "Anode",
      "Axis",
      "Vector",
      "Ion",
    ],
    name: "MVP",
    plastics: [
      {
        name: "Neutron",
      },
      {
        name: "Neutron Soft",
      },
      {
        name: "Cosmic Neutron",
      },
      {
        name: "Fission",
      },
      {
        name: "Plasma",
      },
      {
        name: "Eclipse",
      },
      {
        name: "Proton",
      },
      {
        name: "Proton Soft",
      },
      {
        name: "Electron Soft",
      },
      {
        name: "Electron Medium",
      },
      {
        name: "Electron Firm",
      },
      {
        name: "Cosmic Electron Soft",
      },
      {
        name: "Cosmic Electron Medium",
      },
      {
        name: "Cosmic Electron Firm",
      },
      {
        name: "R2 Neutron",
      },
    ],
  },
  {
    molds: [
      "Pixel",
      "Time-Lapse",
      "Pitch",
      "Tempo",
      "Trance",
      "Paradox",
      "Rhythm",
      "Hex",
      "Delirium",
      "Pyro",
      "Excite",
      "Tantrum",
      "Mayhem",
      "Tenacity",
      "Panic",
      "Defy",
      "Thrill",
      "Vanish",
      "Fireball",
      "Insanity",
      "Virus",
      "Wrath",
      "Proxy",
      "Theory",
      "Clash",
      "Inspire",
      "Crave",
      "Alias",
      "Envy",
    ],
    name: "Axiom",
    plastics: [],
  },
  {
    molds: [
      "Shift",
      "Parachute",
      "Ascend",
      "Engine",
      "Echo",
      "Jet",
      "Stabilizer",
      "Lift",
      "Flare",
      "Runway",
      "Drift",
      "Trace",
      "Pilot",
    ],
    name: "Streamline",
    plastics: [
      {
        name: "Neutron",
      },
      {
        name: "Neutron Soft",
      },
      {
        name: "Cosmic Neutron",
      },
      {
        name: "Plasma",
      },
      {
        name: "Prism",
      },
      {
        name: "Proton",
      },
      {
        name: "Proton Soft",
      },
      {
        name: "Electron Soft",
      },
      {
        name: "Electron Medium",
      },
      {
        name: "Electron Firm",
      },
      {
        name: "Cosmic Electron Soft",
      },
      {
        name: "Cosmic Electron Medium",
      },
      {
        name: "Cosmic Electron Firm",
      },
    ],
  },
  {
    molds: [
      "Swarm",
      "Zone GT (Ringer Top)",
      "Zone GT (Banger Top)",
      "Drive",
      "Kratos",
      "Cicada",
      "Zone OS",
      "Venom (retooled)",
      "Athena",
      "Captain's Raptor (modified overstable Raptor)",
      "Passion",
      "Scorch  ",
      "Hades",
      "Fierce",
      "Malta (Paul McBeth Proto Mid-Range)",
      "Anax",
      "Zeus (former Kong)",
      "Luna",
      "Raptor",
      "SoL (Ace Race 2018)",
      "Vulture",
      "Sting (Ace Race 2017)",
      "Challenger OS",
      "Challenger SS",
      "Punisher",
      "Ringer GT",
      "Machete",
      "Archer",
      "Thrasher",
      "Undertaker",
      "Roach (Ace Race 2015)",
      "Crank SS",
      "Heat (Ace Race 2014)",
      "Buzzz OS",
      "Mantis (Ace Race 2013)",
      "Crank",
      "Zombee (Ace Race 2012)",
      "Zeppelin (Ace Race 2011)",
      "Nuke OS",
      "Nuke SS",
      "Hornet (Ace Race 2010)",
      "Z-Nuke (retooled)",
      "Nuke",
      "Focus (Ace Race 2009)",
      "Ringer",
      "Stalker",
      "Nebula (Ace Race 2008)",
      "Zone",
      "Avenger-SS",
      "Buzzz-SS",
      "Buzzz-GT",
      "Force",
      "Surge-SS",
      "Impact (Ace Race 2007)",
      "Rogue",
      "Meteor (Ace Race 2006)",
      "Torque",
      "Pulse",
      "Surge",
      "Banger-GT",
      "Slipstream-GT (Ace Race 2005)",
      "Spectra",
      "Tracker",
      "Avenger",
      "Venom",
      "Drone",
      "Glide (Ace Race 2004)",
      "Flick",
      "Tsunami",
      "Flash",
      "Breeze (Ace Race 2003)",
      "Buzzz",
      "Crush",
      "Riptide",
      "Squall",
      "Challenger",
      "Wasp",
      "Talon",
      "Predator",
      "Storm",
      "Wildcat",
      "Putt'r",
      "Reaper",
      "Xpress",
      "XS",
      "XTRA",
      "MRV",
      "Xtreme",
      "APX",
      "MRX",
      "XL",
      "X2",
      "Stratus",
      "Cyclone 2",
      "Comet",
      "X-Clone",
      "Typhoon",
      "Rattler Putter",
      "The Hawk",
      "Cyclone",
      "Magnet",
      "Marauder",
      "Shadow",
      "Tracer",
      "Deuce",
      "Phantom Deuce",
      "Vortex",
      "Cruiser-Windstar",
      "Eclipse",
      "Windstar",
      "Cruiser",
      "Phantom Light",
      "Phantom+",
      "Phantom",
      "Sky Streak",
      "Sky-Star",
      "Ultra-Star",
      "Sky-Styler",
      "Sky Pro",
    ],
    name: "Discraft",
    plastics: [
      {
        name: "Titanium",
      },
      {
        name: "ESP",
      },
      {
        name: "ESP FLX",
      },
      {
        name: "ESP Glo",
      },
      {
        name: "Elite Z",
      },
      {
        name: "Z Lite",
      },
      {
        name: "Z Glo",
      },
      {
        name: "Z FLX",
      },
      {
        name: "Z FLX CryZtal",
      },
      {
        name: "Jawbreaker",
      },
      {
        name: "Jawbreaker Z FLX",
      },
      {
        name: "Tough Jawbreaker",
      },
      {
        name: "X",
      },
      {
        name: "X Soft",
      },
      {
        name: "Pro D",
      },
      {
        name: "Pro D Soft",
      },
      {
        name: "CryZtal",
      },
      {
        name: "Titanium FLX",
      },
      {
        name: "Full Foil Super Color",
      },
    ],
  },
  {
    molds: [
      "Sonar",
      "Surf",
      "Avalanche",
      "Vortex",
      "Hypercane",
      "Rift",
      "Tempest",
      "Steady - Beadless (BL)",
      "Banzai",
      "Tremor",
      "Quake",
      "Pipeline",
      "Sail",
      "Hellfire",
      "Steady",
      "Torrent",
      "Proline Breaker",
      "Undertow (ProLine)",
      "Undertow (SP-line)",
      "Hurricane (SP Line)",
      "Titanic (D-Line)",
      "Hurricane (Proline)",
      "Aftershock (D-Line)",
      "Sparkle Reef",
      "Aftershock",
      "Sparkle Aftershock",
      "Blowfly II",
      "Tsunami (D-Line)",
      "Reef (Atlantis)",
      "Shockwave",
      "Titanic",
      'Blunt "Blowfly"',
      "Ultra Hi Tech, Approach #4",
      "Ultra Hi Tech, Putter #5",
      "DGA Factor I (modified Viper)",
      "DGA Factor I (modified Whippet)",
      "DGA Factor IV (modified Aviar)",
      "#5 Roller-Putter",
      "#1 Hi Tec (modified Stingray)",
      "#1 Professional Driver",
      "#2 Hi Tec (modified Stingray)",
      "#2 Professional Driver",
      "#3 Hi Tec (modified Stingray)",
      "#3 Professional Driver",
      "#4 Approach",
      "#4 Hi Tec (modified Stingray)",
      "#5 Hi Tec (modified Stingray)",
      "Super Drive",
      "Gumbputt",
      "Power Drive",
      "Putterdrive",
      "Driver",
      "Power Putter",
      "Softtouch (smaller)",
      "Softtouch (larger)",
      "Streamliner",
      "Kitty Hawk Softouch",
      "Fader",
      "Fader 3",
      "Fader II",
      "Hooker",
      "Kitty Hawk",
      "Kitty Hawk Driver",
      "Kitty Hawk Putter",
      "Kitty Hawk Softouch Sureshot",
      "Putter",
      "Sureshot",
      "Midnight Flyer (70C, 71C & 71C* molds)",
      "Midnight Flyer (FB 6, FB 19 & FB 20 molds)",
      "Midnight Flyer (80, 80C & 80E molds)",
      "Midnight Flyer (100, 100A, 100AD, 100D, 100E, 100AD & 100E molds)",
      "Midnight Flyer (40, 40A, 40F, 40G, 40G*, 41, 41F, 41G, 42AF, 42AG, 42BF & 42BG molds)",
      "Midnight Flyer (50, 50E & 50G molds)",
      "Night Flyer (40 mold)",
    ],
    name: "DGA",
    plastics: [
      {
        name: "Pro Line",
      },
      {
        name: "SP Line",
      },
      {
        name: "Glow SP Line",
      },
      {
        name: "D Line",
      },
      {
        name: "Flex Performance",
      },
      {
        name: "RDGA Line",
      },
    ],
  },
  {
    molds: [
      "Brave",
      "Strive",
      "Honor",
      "Savior",
      "Glory (Royal Prototype)",
      "Hope (Royal Prototype)",
      "Trust (Royal Prototype)",
      "Grace (Royal Prototype)",
      "Rive (Royal Prototype)",
      "Faith (Royal Prototype)",
      "Sapphire",
      "SPZ 1 (Putter, Zombie)",
      "SPZ 2 (Midrange, Pirate)",
      "SPZ 3 (Driver, Superhero)",
      "Catch",
      "Keystone",
      "Pioneer",
      "Recoil",
      "Musket",
      "Fuji",
      "Ballista Pro",
      "Explorer",
      "Zion",
      "River Pro",
      "Bryce",
      "Gobi",
      "Sarek",
      "Beetle",
      "Anchor",
      "Caltrop",
      "Gladiator",
      "Falchion",
      "Knight",
      "Culverin",
      "Compass",
      "Cutlass",
      "Bite",
      "Gauntlet",
      "Raketen",
      "Spark",
      "Macana",
      "Maul",
      "Ballista",
      "Missilen",
      "Claymore",
      "Dagger",
      "Scythe",
      "Jade",
      "Saint Pro",
      "Stiletto",
      "Fury",
      "Mace",
      "Ruby",
      "Pearl",
      "Mercy",
      "Saint",
      "Villain",
      "Bolt",
      "Diamond",
      "Havoc",
      "Pain",
      "Flow",
      "River",
      "Fuse",
      "Halo",
      "Blitz",
      "Pure",
      "Trident",
      "XXX",
      "Striker",
      "Riot",
      "Core",
      "Spike",
      "Vision",
      "Mirus",
      "Primitus",
      "Medius",
      "Sinus AP",
      "Sinus SP",
    ],
    name: "Latitude 64",
    plastics: [
      {
        name: "2K",
      },
      {
        name: "BioGold",
      },
      {
        name: "Frost",
      },
      {
        name: "Gold",
      },
      {
        name: "Gold-Ice",
      },
      {
        name: "Gold-X",
      },
      {
        name: "Hybrid",
      },
      {
        name: "Moonshine",
      },
      {
        name: "Opto Air",
      },
      {
        name: "Opto Glow",
      },
      {
        name: "Opto-Ice",
      },
      {
        name: "Opto-X",
      },
      {
        name: "Opto",
      },
      {
        name: "Recycled",
      },
      {
        name: "Retro",
      },
      {
        name: "Royal Grand",
      },
      {
        name: "Sense",
      },
      {
        name: "Zero Hard",
      },
      {
        name: "Zero Medium",
      },
      {
        name: "Zero Soft",
      },
    ],
  },
  {
    molds: [
      "Jury",
      "General",
      "Supreme EMAC Judge",
      "Supreme EMAC Truth",
      "Sockibomb Felon",
      "Supreme Fugitive",
      "Supreme Trespass",
      "Supreme Judge",
      "Supreme Escape",
      "Culprit",
      "Sockibomb Slammer",
      "Agent",
      "EMAC Judge",
      "Evader",
      "Sergeant (retest)",
      "Bounty",
      "Sergeant",
      "Raider",
      "Guard",
      "Vandal",
      "Captain",
      "Getaway",
      "Maverick",
      "Patrol",
      "Deputy",
      "Slammer",
      "Criminal",
      "Sheriff",
      "Aviator",
      "Gavel",
      "Proof",
      "EMAC Truth",
      "Defender",
      "Marshal",
      "Warrant",
      "Breakout",
      "Convict",
      "Evidence",
      "Justice",
      "Felon",
      "Freedom",
      "Enforcer",
      "Thief",
      "Witness",
      "Warden",
      "Truth",
      "Verdict",
      "Renegade",
      "Suspect",
      "Escape",
      "Fugitive",
      "Judge",
      "Trespass",
    ],
    name: "Dynamic Discs",
    plastics: [
      {
        name: "Hybrid/Lucid X",
      },
      {
        name: "Fuzion",
      },
      {
        name: "BioFuzion",
      },
      {
        name: "Lucid",
      },
      {
        name: "Lucid Air",
      },
      {
        name: "Moonshine",
      },
      {
        name: "Fluid",
      },
      {
        name: "Prime",
      },
      {
        name: "Prime Moonshine",
      },
      {
        name: "Classic",
      },
    ],
  },
  {
    molds: [
      "Prince",
      "Bear",
      "The Adder",
      "Gatekeeper",
      "Anvil",
      "Maiden",
      "War Horse",
      "Ahti",
      "Fortress (Linnoitus1)",
      "Pine (Hornan Honka1)",
      "Crown (Kruunu1)",
      "Queen (Pohjolan Em\u00e4nt\u00e41)",
      "Catapult (Katapultt1)",
      "Destiny (Kohtalo1)",
      "Longbowman (Jousimies1)",
      "Sling (Linko1)",
      "Sampo",
      "Bard (Laulaja1)",
      "Seer (Ennustaja1)",
      "Swan Reborn (Tuonelan Joutsen1)",
      "Boatman VIP Air (VIP Air Tuonelan Lautturi1)",
      "Northman VIP Air (VIP Air Pohjan Poika1)",
      "Shield (Kilpi1)",
      "Tursas VIP Air (VIP Air Tursas1)",
      "Underworld VIP Air (VIP Air Manala1)",
      "Harp (Kannel1)",
      "Hatchet (Sotakipves1)",
      "Sorcerer (Tiet\u00e4j\u00e41)",
      "World (Maailma1)",
      "Giant VIP Air (VIP Air Vipunen1)",
      "Stag VIP Air (VIP Air Hiiden Hirvi1)",
      "Sword VIP Air (VIP Air Kalevan Miekka1)",
      "Warship VIP Air (VIP Air Pursi1)",
      "King VIP Air (VIP Air Pohjolan Is\u00e4nt\u00e41)",
      "Stag (Hiiden Hirvi1)",
      "Tursas (Tursas1)",
      "Underworld (Manala1)",
      "Giant (Vipunen1)",
      "Warship (Pursi1)",
      "Sword (Kalevan Miekka1)",
      "Boatman (Tuonelan Lautturi1)",
      "King (Pohjolan Is\u00e4nt\u00e41)",
      "Swan (retooled, Tuonelan Joutsen1)",
      "Swan (Tuonelan Joutsen1)",
      "Northman (Pohjan Poika1)",
    ],
    name: "Westside",
    plastics: [
      {
        name: "BT Hard",
      },
      {
        name: "BT Line",
      },
      {
        name: "BT Medium",
      },
      {
        name: "BT Soft",
      },
      {
        name: "Elasto",
      },
      {
        name: "Hybrid",
      },
      {
        name: "Megasoft",
      },
      {
        name: "Moonshine",
      },
      {
        name: "Origio",
      },
      {
        name: "Recycled",
      },
      {
        name: "Revive",
      },
      {
        name: "Tournament",
      },
      {
        name: "VIP Air",
      },
      {
        name: "VIP",
      },
    ],
  },
  {
    molds: [
      "Function",
      "MD4 (new)",
      "Spore",
      "PD2 (new)",
      "TD (new)",
      "P3x (new)",
      "Vanguard",
      "DD (new)",
      "MD5 (new)",
      "Tailor",
      "CD1",
      "DD1",
      "FD1",
      "Mermaid",
      "P1 (new)",
      "Cloudbreaker",
      "FD3 (new)",
      "Paradigm",
      "Rainmaker",
      "New MD1",
      "PD (new)",
      "FD (new)",
      "P2 (new)",
      "DD3 (new)",
      "MD3 (new)",
      "Razor Claw Tactic",
      "Logic",
      "Mutant",
      "Splice",
      "Shogun",
      "Rockstar",
      "Astronaut",
      "Tilt",
      "Majesty",
      "Origin",
      "DD2 (new)",
      "PD3",
      "Essence",
      "Tactic",
      "Sensei (Discmania Active, originally Guardian Lion)",
      "Enigma",
      "Link",
      "Method",
      "DD3 (retest)",
      "Magician (originally Fox Spirit)",
      "Guardian Lion",
      "Mentor (originally Sea Serpent)",
      "Maestro (originally Spring Ox)",
      "Genius (originally Sun Crow)",
      "Instinct (150-175g)",
      "DD3 (Cloud Breaker, Distance Driver)",
      "FD2 (retooled Fairway Driver)",
      "MD5 (Midrange Driver)",
      "MD4 (Midrange Driver)",
      "TDx (Turn Driver)",
      "CD3 (Control Driver)",
      "P3x (Putt & Approach)",
      "DDx (Distance Driver)",
      "PDx (Power Driver)",
      "FD3 (Fairway Driver)",
      "MD (Midrange Driver)",
      "P1x (Beaded Putter)",
      "CD2 (Control Driver)",
      "FD2 (Fairway Driver)",
      "MD3 (Midrange Driver)",
      "P3 (Putt & Approach)",
      "TD2 (Fever, Turn Driver)",
      "FD (Jackal, Fairway Driver)",
      "PD2 (Chaos, Power Driver)",
      "DD2 (Frenzy, Distance Driver)",
      "MD2 (Fiend, Midrange Driver)",
      "TD (Rush, Turn Driver)",
      "DD (Hysteria, Distance Driver)",
      "PD (Freak, Power Driver)",
      "CD (Craze, Control Driver)",
      "P1 (Maniac, Putt & Approach)",
      "P2 (Psycho, Pro Putter)",
      "MD1 (Midrange Driver)",
    ],
    name: "Discmania",
    plastics: [
      {
        name: "C-Line",
      },
      {
        name: "S-Line",
      },
      {
        name: "D-Line",
      },
      {
        name: "Glow C-Line",
      },
      {
        name: "Glow D-Line",
      },
      {
        name: "Color Glow C-Line",
      },
      {
        name: "Metal Flake C-Line",
      },
      {
        name: "Swirl S-Line",
      },
      {
        name: "Special Blend S-Line",
      },
      {
        name: "Chroma",
      },
      {
        name: "Horizon",
      },
      {
        name: "Exo",
      },
      {
        name: "Geo",
      },
      {
        name: "Neo",
      },
      {
        name: "Exo Soft",
      },
      {
        name: "Exo Hard",
      },
      {
        name: "Lux",
      },
      {
        name: "Forge",
      },
      {
        name: "Meta",
      },
      {
        name: "Vapor",
      },
      {
        name: "Lumen",
      },
      {
        name: "Extra Soft Exo",
      },
      {
        name: "Active",
      },
      {
        name: "Active Soft",
      },
      {
        name: "Active Premium",
      },
      {
        name: "Active Premium Glow",
      },
      {
        name: "SG-Line",
      },
      {
        name: "Ladyline",
      },
      {
        name: "X-Line",
      },
      {
        name: "P-Line",
      },
      {
        name: "G-Line",
      },
      {
        name: "Blizzard C-Line",
      },
      {
        name: "Luster C-Line",
      },
      {
        name: "Shimmer S-Line",
      },
    ],
  },
  {
    molds: [
      "M5 (New)",
      "M3 (new) ",
      "Waco",
      "Prodigy Club Putter",
      "Prodigy Club Fairway",
      "Archive",
      "MX-2 (renamed from Prodigy Club Midrange)",
      "Feedback",
      "F9",
      "Stryder",
      "Shadowfax",
      "PA-5",
      "H7",
      "MX-1",
      "FX-4",
      "A5",
      "D2 Pro",
      "D1 (New)",
      "P Model S",
      "FX-3",
      "P Model US+",
      "A Model OS (Distortion)",
      "PX-3",
      "D2 (New)",
      "D3 (New)",
      "D1 Signature (Reverb)",
      "D2 Signature (Falcor)",
      "D Model US++",
      "D Model US+",
      "F Model US",
      "F Model OS",
      "F Model OS+",
      "P Model US",
      "M Model US",
      "D Model #3",
      "D Model #5",
      "D Model OS",
      "M Model S",
      "D Model US",
      "FX-2",
      "MX-3",
      "D1 Max",
      "D2 Max",
      "D3 Max",
      "D4 Max",
      "D6 Max",
      "D Model Distance Driver S",
      "F Model Fairway Driver S",
      "M Model Mid Range OS",
      "P Model Putter OS",
      "D1 V2 (retooled D1)",
      "D2 V2 (retooled D2)",
      "D3 V2 (retooled D3)",
      "D6 V2 (retooled D6)",
      "H2 V2 (retooled H2)",
      "H4 V2",
      "H1 (retooled H1)",
      "H3 V2 (retooled H3)",
      "H5",
      "X3",
      "X4",
      "X6",
      "X2",
      "X5",
      "D6",
      "A4",
      "A2",
      "A3",
      "A1",
      "New M1",
      "H3",
      "H2",
      "H4",
      "H1",
      "X1",
      "PA3",
      "M5",
      "F5",
      "D5 V2 (retooled D5)",
      "D5",
      "F1",
      "F2",
      "F3",
      "F7",
      "M1",
      "M2 (originally M3)",
      "M3 (originally M2)",
      "M4",
      "PA1",
      "PA2",
      "PA4",
      "D1",
      "D2",
      "D3",
      "D4",
    ],
    name: "Prodigy",
    plastics: [
      {
        name: "200",
      },
      {
        name: "300",
      },
      {
        name: "300 Soft",
      },
      {
        name: "300 Firm",
      },
      {
        name: "400",
      },
      {
        name: "400G",
      },
      {
        name: "500",
      },
      {
        name: "750",
      },
      {
        name: "750G",
      },
      {
        name: "AIR",
      },
      {
        name: "Special Blend",
      },
    ],
  },
  {
    molds: [
      "Idog",
      "Nord",
      "\u00c4lva",
      "Berg X",
      "Vass",
      "Kaxe (retooled)",
      "Krut",
      "Stig",
      "Guld",
      "Reko X",
      "J\u00e4rn",
      "Svea",
      "Lots",
      "G\u00f6te",
      "St\u00e5l",
      "Falk",
      "Grym X",
      "Reko",
      "Grym",
      "Berg",
      "Kaxe Z",
      "Kaxe",
      "Rask (new)",
      "Rask (old)",
    ],
    name: "Kastaplast",
    plastics: [
      {
        name: "K1 Line",
      },
      {
        name: "K1 Soft",
      },
      {
        name: "K1 Hard",
      },
      {
        name: "K1 Glow-Line",
      },
      {
        name: "K2",
      },
      {
        name: "K3 Line",
      },
      {
        name: "K3 Hard",
      },
      {
        name: "K3 Glow-Line",
      },
    ],
  },
  {
    molds: [
      "Lotus (Tone variant)",
      "Popcorn (Tone variant)",
      "Soda (Tone variant)",
      "Guava (Tone variant)",
      "Almond",
      "Barley",
      "Millet",
      "Salt (Tone variant)",
      "Guava",
      "Lotus (retooled)",
      "Sage",
      "Peppermint",
      "Cinnamon",
      "Cherry",
      "Candy",
      "Lotus",
      "Fudge",
      "Vanilla",
      "Spice",
      "Peach",
      "Salt",
      "Wild Honey",
      "Cookie",
      "Butter",
      "Soda",
      "Pepper",
      "Ginger",
      "Popcorn",
      "Mint",
      "Mango",
      "Berry",
    ],
    name: "Clash",
    plastics: [],
  },
  {
    molds: [
      "Javelin",
      "Aura",
      "Assassin (retool 2)",
      "Ether",
      "Realm",
      "Spell",
      "Blade V2 (variant)",
      "Speed Demon GT (variant)",
      "Warlock GE (variant)",
      "G-ONE",
      "Houdini",
      "Amphibian",
      "Blade",
      "Spear",
      "Apex",
      "Slayer (retooled)",
      "Prophecy",
      "Diablo",
      "Journey - LS",
      "Ninja",
      "Siren",
      "Voodoo (revised)",
      "Wizard (revised)",
      "Big Dog",
      "Doggy Style",
      "Chief OS",
      "Devilhawk",
      "Warspear",
      "Shaman",
      "Assassin (retooled)",
      "Chief (retooled)",
      "Rage (retooled)",
      "Mystic (retooled)",
      "Samurai",
      "Karma",
      "Mystic",
      "Slayer",
      "Scout (new)",
      "Warrior (new)",
      "Apache (retooled)",
      "Savage",
      "Voodoo",
      "Assassin",
      "Magic",
      "Blast DT",
      "Diablo DT",
      "Rage",
      "Warlock",
      "Blurr",
      "Speed Demon DT",
      "Hybrid",
      "Element X",
      "Element",
      "Illusion",
      "Spirit",
      "Chief",
      "Speed Demon",
      "Wizard",
      "Demon",
      "Scout (old)",
      "Apache",
      "Blaze",
      "Sabre",
      "Warrior (old)",
      "844 Sabre",
    ],
    name: "Gateway",
    plastics: [
      {
        name: "Evolution Platinum",
      },
      {
        name: "Organic",
      },
      {
        name: "Evolution Diamond",
      },
      {
        name: "Diamond Glow",
      },
      {
        name: "Pure White, Firm",
      },
      {
        name: "$$$, Chalk, Eraser",
      },
      {
        name: "Super Soft",
      },
      {
        name: "Super Stupid Soft, SSSS",
      },
      {
        name: "Evolution",
      },
    ],
  },
  {
    molds: [
      "Glyph",
      "Khonsu",
      "Conqueror",
      "Galleon",
      "Roman",
      "Raze",
      "Czar",
      "Dynasty",
      "Kon Tiki",
      "Alpaca",
      "Maya",
      "Inca",
      "Ruin",
      "Aztec",
      "Centurion",
      "Cohort",
      "Scarab",
      "Scepter",
      "Emperor",
      "Ra",
      "Anubis",
      "Slab",
      "Myth",
      "Sphinx",
      "Tomb",
      "Chariot",
      "Pharaoh",
      "Exodus",
    ],
    name: "Infinite",
    plastics: [
      {
        name: "S-Blend",
      },
      {
        name: "Swirly S-Blend",
      },
      {
        name: "Splatter S-Blend",
      },
      {
        name: "Halo S-Blend",
      },
      {
        name: "C-Blend",
      },
      {
        name: "Metal Flake C-Blend",
      },
      {
        name: "Metal Flake Glow C-Blend",
      },
      {
        name: "Glow C-Blend",
      },
      {
        name: "Gummy C-Blend",
      },
      {
        name: "Luster C-Blend",
      },
      {
        name: "Concrete",
      },
      {
        name: "G-Blend",
      },
      {
        name: "I-Blend",
      },
      {
        name: "D-Blend",
      },
      {
        name: "Glow D-Blend",
      },
      {
        name: "P-Blend",
      },
      {
        name: "Glow P-Blend",
      },
      {
        name: "X-Blend",
      },
      {
        name: "N-Blend",
      },
      {
        name: "R-Blend",
      },
    ],
  },
  {
    molds: [
      "Nuance",
      "Pneuma",
      "Crux",
      "Alter",
      "Temple",
      "Construct",
      "Omen",
      "Mana",
      "Coalesce",
      "Synapse",
      "Muse",
      "Praxis",
      "Votum",
      "Animus",
      "Pathfinder",
      "Mantra",
    ],
    name: "Thought Space Athletics",
    plastics: [
      {
        name: "Nebula",
      },
      {
        name: "Aura",
      },
      {
        name: "Ethereal",
      },
      {
        name: "Ethos",
      },
    ],
  },
  {
    molds: ["Ozone", "Dune", "Inner Core"],
    name: "Trash Panda",
    plastics: [],
  },
  {
    molds: [
      "Trinity",
      "Houston",
      "The Tex",
      "Yellow Rose",
      "Spur",
      "Wrangler",
      "Bearkat",
      "Growler",
      "Horny Toad",
      "Artemis",
      "Crockett",
      "Benny",
      "Harrier",
      "Brazos",
      "Frio",
      "Seguin",
      "Nimitz",
      "Bayonet",
      "Lone Wolf",
      "Copperhead",
      "Super Dillo",
      "Bluebonnet",
      "Tombstone",
      "Chupacabra",
      "The Dome",
      "Bull Snake",
      "Walker",
      "Lariat",
      "Ranger",
      "Guadalupe AKA Guad",
      "Tumbleweed",
      "Dos X",
      "Warbird",
      "Armadillo",
      "Jack Rabbit",
      "BB6",
      "Curl",
      "Bowie ",
      "Mad Cat",
      "The Harpoon",
      "The Mockingbird (Ka Kaw)",
      "The Dillard Midrange Md-1 (Middy)",
      "Penny Putter ",
    ],
    name: "Lone Star",
    plastics: [
      {
        name: "Founders",
      },
      {
        name: "Charlie",
      },
      {
        name: "Alpha",
      },
      {
        name: "Beta",
      },
      {
        name: "Gamma",
      },
      {
        name: "Omega",
      },
    ],
  },
  {
    molds: [
      "Mystery Box (2023)",
      "Taco",
      "UFO",
      "Lasso",
      "Salamander",
      "Phoenix",
      "Lobster",
      "Diamondback",
      "Mystery Box",
      "Goat (aka G.O.A.T, aka The GOAT, aka The Greatest of All Time)",
      "Phoenix (old)",
      "Grackle",
      "Jackalope",
      "Longhorn",
      "Bullet",
      "Mustang",
      "Freetail",
      "Profit",
      "Bobcat",
      "Alpha",
    ],
    name: "Mint",
    plastics: [
      {
        name: "Apex",
      },
      {
        name: "Sublime",
      },
      {
        name: "Eternal",
      },
      {
        name: "Nocturnal Glow",
      },
    ],
  },
  {
    molds: [
      "Paladin US",
      "Pacific OS",
      "Paladin OS",
      "Launcher OS",
      "Launcher (new)",
      "Launcher US",
      "Pacific",
      "Paladin",
      "Spy",
      "Zoom",
      "Wingman",
      "Launcher",
    ],
    name: "Northstar",
    plastics: [],
  },
  {
    molds: ["Loop", "Pondskater", "Momentum"],
    name: "Supersonic",
    plastics: [],
  },
  {
    molds: [],
    name: "Hyzerbomb",
    plastics: [
      {
        name: "Frontline",
      },
      {
        name: "Frontline-X",
      },
      {
        name: "Recon",
      },
      {
        name: "Baseline Hard",
      },
      {
        name: "Baseline",
      },
      {
        name: "Baseline Soft",
      },
    ],
  },
  {
    molds: ["Soul (variant)", "Sense (variant)", "Soul", "Seeker (variant)", "Sense", "Seeker"],
    name: "Independent Sweden AB",
    plastics: [],
  },
  {
    molds: ["CNCi", "CNC+ ", "CNC-", "CNC"],
    name: "ProtoFlyte",
    plastics: [],
  },
  {
    molds: ["Lumen"],
    name: "Light Speed",
    plastics: [],
  },
  {
    molds: [
      "Dime",
      "Thread (retooled)",
      "Vibe (retooled)",
      "Rope",
      "Cash",
      "Flip",
      "Thread",
      "YEET",
      "Vibe",
    ],
    name: "Hooligan",
    plastics: [],
  },
  {
    molds: ["Finch", "Owl", "Wren", "Cardinal"],
    name: "Stokely",
    plastics: [],
  },
  {
    molds: ["M1.0", "M3.1", "M 3.0"],
    name: "Tefat",
    plastics: [],
  },
  {
    molds: [
      "Star Bird",
      "Star Hawk",
      "Star Death",
      "Star Glide",
      "Star Bucks",
      "Star Bat",
      "Star Demon",
      "Star Bison",
      "Star Bear",
      "Star Dorado",
    ],
    name: "X-UFO",
    plastics: [],
  },
  {
    molds: ["Johnson", "Einstein", "Bernoulli"],
    name: "Bernoulli",
    plastics: [],
  },
  {
    molds: ["Ravine ", "Current", "Tundra", "Strait "],
    name: "Meridian",
    plastics: [],
  },
  {
    molds: ["Swell", "Shorebreak"],
    name: "3rd Coast",
    plastics: [],
  },
  {
    molds: ["Grey Wolf", "Leatherback", "Flame Skimmer"],
    name: "RIPR",
    plastics: [],
  },
  {
    molds: [
      "Ori (\u730e\u6237\u5ea7)",
      "Scutum (\u76fe\u724c\u5ea7)",
      "Cetus (\u9cb8\u9c7c\u5ea7)",
      "Volans (\u98de\u9c7c\u5ea7)",
      "Dra  (\u5929\u9f99\u5ea7) ",
      "Leo (\u72ee\u5b50\u5ea7) ",
      "Pisces (\u53cc\u9c7c\u5ea7)",
      "Ari (\u767d\u7f8a\u5ea7)",
      "Cancer (\u5de8\u87f9\u5ea7)",
      "Libra (\u5929\u79e4\u5ea7)",
      "Sagittarrius (\u4eba\u9a6c\u5ea7)",
      "Sco (\u5929\u874e\u5ea7)",
      "Ursa Major (\u5927\u718a\u5ea7) (renamed from Armi (\u72b0))",
      "Mackal (\u9c9b)",
      "S-Bennu (\u51e4\u9e23)",
      "Virgo (\u5ba4\u5973\u5ea7) (renamed from Dawn (\u66e6))",
      "Aqu (\u5b9d\u74f6\u5ea7) (renamed from Mose (\u9e8b)",
      "Tau (\u91d1\u725b\u5ea7) (renamed from XPT1/Bennu & Globefish)",
      "Gem (\u53cc\u5b50\u5ea7) (renamed from XPT2/Eden & Dolphin)",
      "Capricornus (\u6469\u7faf\u5ea7) (renamed from XPT3/Griffon & Grampus)",
      "Ophiuchus (\u86c7\u592b\u5ea7) (renamed from XP4/Helios & Sailfish)",
      "XPT5 (Zion, Driver, renamed from Volador)",
    ],
    name: "XCOM",
    plastics: [],
  },
  {
    molds: ["Tunnel Shot", "Barb"],
    name: "Compound",
    plastics: [],
  },
  {
    molds: ["Line Tamer"],
    name: "Finuline",
    plastics: [],
  },
  {
    molds: [
      "Nimbus (retooled)",
      "Psychic",
      "Nimbus",
      "Gecko",
      "Arowana",
      "Binx",
      "Koi",
      "Interceptor",
    ],
    name: "Elevation",
    plastics: [],
  },
  {
    molds: ["Nail"],
    name: "Forge",
    plastics: [],
  },
  {
    molds: ["Seed", "Starship", "Arrowhead", "Oracle", "Gnome"],
    name: "Sacred",
    plastics: [],
  },
  {
    molds: [
      "Ice Age",
      "Solar Death Ray",
      "Proximity Mine",
      "Apocalypse",
      "Rot",
      "Crisis",
      "Gloom",
      "Area 51",
      "Scavenger",
      "Famine",
      "Bunker Buster",
      "Wasteland",
      "Lockdown",
      "Desolation",
      "Depth Charge",
      "Pestilence",
      "Scope",
      "Cataclysm",
      "Frag",
      "Land Mine",
      "Blackout",
      "Bleak",
      "Despair",
      "Dystopia",
      "Flat Earth",
      "Plague",
    ],
    name: "Doomsday",
    plastics: [],
  },
  {
    molds: ["Chickadee"],
    name: "Grassland",
    plastics: [],
  },
  {
    molds: ["Howler", "Chango", "Silverback"],
    name: "Gorilla Performance",
    plastics: [],
  },
  {
    molds: ["Ritual"],
    name: "Negative",
    plastics: [],
  },
  {
    molds: [
      "Waymaker",
      "Ark",
      "Slingshot",
      "Chainbreaker",
      "Rapture",
      "Redemption ",
      "Pillar",
      "Ambush",
      "Enoch",
      "Genesis",
      "Jireh",
    ],
    name: "Goliath",
    plastics: [],
  },
  {
    molds: ["Amethyst "],
    name: "Visionary",
    plastics: [],
  },
  {
    molds: ["Taiga", "Arctic", "Biome", "Marine"],
    name: "Rogue Iron",
    plastics: [],
  },
  {
    molds: ["Cherry Blossom"],
    name: "Goeringer",
    plastics: [],
  },
  {
    molds: ["Sea Turtle"],
    name: "Ocean ",
    plastics: [],
  },
  {
    molds: ["Readiness"],
    name: "Evolvent",
    plastics: [],
  },
  {
    molds: [
      "Kataw",
      "Wyrm",
      "Minotaur",
      "Tiyanak",
      "Lawin",
      "Basilisk",
      "Golem",
      "Nuno",
      "Kapre",
      "Kraken",
      "Alpas",
      "Leviathan",
      "Narwhal",
    ],
    name: "Divergent",
    plastics: [],
  },
  {
    molds: [
      "Pyramid",
      "Unicorn",
      "Amulet",
      "Totem",
      "Empire",
      "Talisman",
      "Origo",
      "MidariX (beaded)",
      "JokeriX (beaded)",
      "Troija",
      "FASTi",
      "FLIPPERi",
      "STARi",
      "Rocket",
      "Razeri",
      "Titan",
      "Sparta",
      "Laseri",
      "Slaidi",
      "Legenda",
      "Midari",
      "Respecti",
      "Jokeri",
    ],
    name: "Prodiscus",
    plastics: [
      {
        name: "Ultrium",
      },
      {
        name: "Premium",
      },
      {
        name: "Basic",
      },
      {
        name: "Slippery",
      },
    ],
  },
  {
    molds: ["Extruder"],
    name: "PrintPractical",
    plastics: [],
  },
  {
    molds: ["Ire Gear Shut The Putt Up"],
    name: "Ire Gear",
    plastics: [],
  },
  {
    molds: ["Dispatch", "Gain", "Impetus"],
    name: "Momentum AB",
    plastics: [],
  },
  {
    molds: [
      "Money Tree",
      "Dogwood",
      "Acacia",
      "Locust x (variant)",
      "Douglas Fir",
      "Redwood",
      "Spruce",
      "Beech",
      "Locust",
      "Baobab",
      "Ponderosa",
      "Cedar",
      "Sycamore",
      "Magnolia",
      "Madrone",
      "Manzanita",
      "Cypress",
    ],
    name: "Above Ground Level",
    plastics: [
      {
        name: "Treeline",
      },
      {
        name: "Woodland",
      },
      {
        name: "Pine",
      },
    ],
  },
  {
    molds: [
      "Kea (MR2) (retooled)",
      "Tara Iti (Fairy Tern, DGFD3)",
      "Takapu (PA3)",
      "Pekapeka (DGFD2)",
      "Kotuku (MR3)",
      "Kotare (DGD3, retooled)",
      "Kiwi (DGD1, retooled)",
      "Kotare (DGD3)",
      "Huia (DGFD1)",
      "Kea (MR2)",
      "Ruru (PA2)",
      "Kahu (DGD2)",
      "Piwakawaka (MR1, originally Arcturus)",
      "Rippa (DGR1)",
      "Kiwi (DGD1, former Raptor)",
      "New Zealand 175g Ultimate Disc (RU1)",
      "Te Moko (DGR2)",
      "Tui (PA1)",
    ],
    name: "RPM/Disc Golf Aotearoa",
    plastics: [
      {
        name: "Atomic",
      },
      {
        name: "Cosmic",
      },
      {
        name: "Magma",
      },
      {
        name: "Magma Soft",
      },
      {
        name: "Strata",
      },
    ],
  },
  {
    molds: [
      "Carnivore ",
      "Iguana ",
      "Komodo ",
      "Chameleon ",
      "Gila",
      "Tyrant",
      "Stego",
      "Ptero",
      "Velociraptor",
      "Serpent",
      "Scale",
    ],
    name: "Reptilian",
    plastics: [
      {
        name: "Amber",
      },
      {
        name: "Fossil/Fossil Fuel",
      },
      {
        name: "Prehistoric, Organic",
      },
    ],
  },
  {
    molds: ["9 Iron Chip & Putt", "Gello Shot Chip & Putt"],
    name: "Tomahawk Specialty",
    plastics: [],
  },
  {
    molds: [
      "NSH14 Nin'Jai",
      "NSH13 Gin'Tak",
      "NSH12 Bat'Leth",
      "NSH11 Bird of Prey",
      "NX-01",
      "NSH 1701",
      "NSH9 Hexxus",
      "NSH0 Nexxus",
      "NSH8 Defiant",
      "NSH7 Gunnz",
      "NSH 8472",
      "NSH5 Sweed",
      "NSH6 StackS",
      "NSH1 TheTeleporter",
      "NSH3 Dwayne",
      "NSH2 The Borg",
      "NSH4 Mr. Putt",
    ],
    name: "Newb SimHouse Custom",
    plastics: [],
  },
  {
    molds: ["Petra"],
    name: "Yeet Street",
    plastics: [],
  },
  {
    molds: ["Yosemite", "Big Bend", "Death"],
    name: "National Perk Service",
    plastics: [],
  },
  {
    molds: ["Neon", "Titanium", "Xenon", "Silicon", "Bohrium", "Hydrogen"],
    name: "L\u00f8ft",
    plastics: [],
  },
  {
    molds: ["Goose", "Husky", "Pike", "Santa"],
    name: "Osuma",
    plastics: [],
  },
  {
    molds: ["Reach ", "Ultra", "Weapon", "Strike", "Marvel "],
    name: "Birdie",
    plastics: [],
  },
  {
    molds: ["Daytona", "Interval", "Torque", "Rally", "Pace", "Supra", "Era"],
    name: "Finish Line",
    plastics: [],
  },
  {
    molds: ["Dream Weaver", "Peace Train", "Love"],
    name: "Jester",
    plastics: [],
  },
  {
    molds: ["Gong", "Cowbell", "Bongo", "Skool"],
    name: "Goto",
    plastics: [],
  },
  {
    molds: ["G7R10", "G1R1"],
    name: "Sector",
    plastics: [],
  },
  {
    molds: [
      "Sumo",
      "Rebel",
      "Badger",
      "Vengeance",
      "Pursuit",
      "Recluse",
      "Valor",
      "Aftermath",
      "Recon",
      "Fighter",
      "Enemy",
      "Phenom",
      "Prowler",
      "Hunter",
      "Nemesis",
      "Outlaw (retooled)",
      "Outlaw",
      "Bandit",
      "Gauge",
      "Mongoose",
      "Patriot",
      "Rival",
      "Ghost",
      "Clozer",
      "Clutch",
      "Cannon",
      "Rampage",
    ],
    name: "Legacy",
    plastics: [
      {
        name: "Icon",
      },
      {
        name: "Legend",
      },
      {
        name: "Pinnacle",
      },
      {
        name: "Ultralight",
      },
      {
        name: "Protégé",
      },
      {
        name: "Gravity",
      },
      {
        name: "Excel",
      },
    ],
  },
  {
    molds: ["Bank Robber", "Treasure Hunt", "Money Shot"],
    name: "Lucky",
    plastics: [],
  },
  {
    molds: ["Mimic"],
    name: "Kraken",
    plastics: [],
  },
  {
    molds: ["Mercury"],
    name: "ITC-Discs",
    plastics: [],
  },
  {
    molds: ["Xianglei", "Feilei"],
    name: "Ningbo Junye Stationery & Sports Articles Co., Ltd.",
    plastics: [],
  },
  {
    molds: ["Torch"],
    name: "Torch",
    plastics: [],
  },
  {
    molds: ["Golden Gate Bridge", "U.S. Capitol", "Statue of Liberty"],
    name: "Giant Industry",
    plastics: [],
  },
  {
    molds: ["Dlask", "Havran"],
    name: "Wingman",
    plastics: [],
  },
  {
    molds: [
      "Infection",
      "War Hammer",
      "Shotgun",
      "Flame Thrower",
      "Berzerker",
      "Crossbow",
      "Chainsaw",
    ],
    name: "Black Zombie",
    plastics: [],
  },
  {
    molds: ["Pursue", "Dream ", "Stratosphere"],
    name: "Wing It",
    plastics: [],
  },
  {
    molds: ["Conspiracy", "Spire", "Uncle", "Grunt", "Bagger"],
    name: "Pie Pan",
    plastics: [],
  },
  {
    molds: ["BumbleBob", "ChooChooBob", "ParkingBob", "GlowBob"],
    name: "FotCot",
    plastics: [],
  },
  {
    molds: [
      "Valhalla Reaper",
      "Deranged Squirrel ",
      "Viperwolf",
      "Ignition",
      "Euphoria",
      "Bliss",
      "Solar Eclipse",
      "Hyrrokin",
      "Kore",
    ],
    name: "Orbital",
    plastics: [],
  },
  {
    molds: [
      "Ronin",
      "Goliath",
      "Haymaker",
      "Gladius",
      "Troll",
      "Mercenary",
      "Behemoth",
      "Eldritch",
      "Skirmisher",
      "Rumble",
      "Creature",
      "Detonator",
      "Mastodon",
      "Spartan",
      "Torpedo",
      "Battleship",
      "Heavy Metal",
    ],
    name: "Remix",
    plastics: [],
  },
  {
    molds: ["EVO"],
    name: "OZDG",
    plastics: [],
  },
  {
    molds: ["Kelo", "Tuohi", "Puro", "Nila", "Korpi", "Kanto", "Havu"],
    name: "Exel",
    plastics: [],
  },
  {
    molds: ["Hobbysport Driver", "Hobbysport Putter", "HS2020 Midrange"],
    name: "Hobbysport",
    plastics: [],
  },
  {
    molds: [
      "Woodpecker (R\u00e4hn)",
      "Nightjar (\u00d6\u00f6sorr)",
      "Stork (Toonekurg)",
      "Sparrow (Varblane)",
      "Skylark (L\u00f5oke)",
      "Starling (Kuldnokk)",
    ],
    name: "Disctroyer O\u00dc",
    plastics: [],
  },
  {
    molds: ["Splash", "Nautilus", "Marlin", "Squid"],
    name: "Neptune ",
    plastics: [],
  },
  {
    molds: ["Honu", "Frog", "Tiki"],
    name: "AstroDisc",
    plastics: [],
  },
  {
    molds: [
      "F\u00fa X\u012b (\u4f0f\u7fb2)",
      "Sh\u00e8 G\u014dng (\u5c04\u5f13)",
      "J\u012bn W\u016b (\u91d1\u4e4c)",
      "F\u00fa S\u0101ng (\u6276\u6851)",
      "Ho\u00fa Y\u00ec (\u540e\u88d4)",
      "N\u00fc W\u0101 (\u5973\u5a32)",
      "X\u012b H\u00e9 (\u7fb2\u548c))",
      "Ti\u0101n Sh\u00ed (\u5929\u77f3)",
      "Yi (\u6bd4\u7ffc\u9e1f Bi Yi Niao)",
      "Bi (\u6bd5\u65b9 Bi Fang)",
      "Zheng (\u72f0 Zheng)",
      "Kang (\u5f53\u5eb7 Dang Kang)",
      "Rong (\u795d\u878d Zhu Rong)",
      "Long (\u5e94\u9f99 Ying Long)",
      "Ling (\u9675\u9c7c Ling Yu)",
      "Zhu (\u592b\u8bf8 Fu Zhu)",
      "Qi (\u7a77\u5947, Qiong Qi)",
      "Xing (\u5211\u5929, Xing Tian)",
      "Yan (\u6731\u538c, Zhu Yan)",
      "Shu (\u8033\u9f20, Er Shu)",
      "Fu (\u5938\u7236, Kua Fu)",
      "Meteor Hammer (\u6d41\u661f\u9524)",
      "Tomahawk (\u516b\u5366\u94ba)",
      "Crossbow (Nu, \u8fde\u5f29)",
      "Hammer (Chui, \u6218\u9524)",
      "Twin Swords (\u53cc\u5203\u5251)",
      "Kui (\u5914\u725b)",
      "Lu (Luan, \u9e3e\u9e1f)",
      "Jiao (\u72e1)",
      "Wei (\u7cbe\u536b)",
      "Da'e (\u5927\u9e57)",
      "Gou (\u5929\u72d7)",
      "Hu (\u4e5d\u5c3e\u72d0)",
      "Jun (\u9d55\u9e1f)",
      "Claws (\u722a)",
      "Gui (\u65cb\u9f9f)",
      "Wings (\u7ffc)",
      "Yao (\u6587\u9cd0\u9c7c)",
      "135G UltiPro Junior Ultimate Disc (\u514b\u6807\u51c6\u9752\u5c11\u5e74\u98de\u76d8)",
      "150G WaKa Freestyle Disc (\u514b\u82b1\u5f0f\u98de\u76d8)",
      "175G UltiPro Ultimate Disc (\u514b\u6807\u51c6\u6781\u9650\u98de\u76d8)",
      "View (\u89c6)",
    ],
    name: "Yikun",
    plastics: [],
  },
  {
    molds: ["Badia", "Cloud Ripper", "Osiris", "War Bonnet", "Overwatch"],
    name: "STM",
    plastics: [],
  },
  {
    molds: ["Timberline Apollo (variant)", "Galileo", "Atlantis", "Theios", "Cosmic", "Apollo"],
    name: "Alfa",
    plastics: [],
  },
  {
    molds: ["X 090"],
    name: "Shenzhen Reanson Products Co., Ltd",
    plastics: [],
  },
  {
    molds: ["Oxygen", "Purpose", "Peace", "Harmony", "Rain", "Solara", "Honey"],
    name: "Essential",
    plastics: [],
  },
  {
    molds: ["Tasty", "Misty"],
    name: "Kliffa",
    plastics: [],
  },
  {
    molds: [
      "Guide",
      "Beacon",
      "Crusade",
      "Shortcut",
      "Manifest",
      "Expedition",
      "Outlander (originally named Nomad)",
      "Voyage",
      "Cruise",
      "Drifter",
      "Navigator",
      "Passport",
      "Traveler",
    ],
    name: "Full Turn",
    plastics: [
      {
        name: "Express",
      },
      {
        name: "Priority",
      },
      {
        name: "Ground",
      },
    ],
  },
  {
    molds: [
      "War Bear",
      "War Dove (Zulu Line, stiff variant)",
      "War Pigeon (stiff variant)",
      "War Pigeon",
      "War Dove",
    ],
    name: "Premier",
    plastics: [],
  },
  {
    molds: [
      "Tasmanian Devil V2",
      "Addax V2",
      "Hyena",
      "Sea Otter",
      "Angler",
      "Great White",
      "Tasmanian Devil",
      "Hummingbird",
      "Orca",
      "Addax",
    ],
    name: "Wild",
    plastics: [],
  },
  {
    molds: ["Cervini", "Ursus"],
    name: "Terminal Velocity",
    plastics: [],
  },
  {
    molds: [
      "TOSY Smart S12",
      "TOSY UltraLED S3",
      "TOSY Smart S3",
      "TOSY Smart S10",
      "TOSY UltraLED S10",
      "TOSY UltraLED S7",
      "TOSY Smart S7",
      "TOSY UltraLED S12",
    ],
    name: "TOSY",
    plastics: [],
  },
  {
    molds: [
      "Tarkka (Premium variant)",
      "Tarkka (Ultrium variant)",
      "Tarkka (Basic Soft)",
      "Varma",
      "T\u00e4sm\u00e4",
      "Tykki",
    ],
    name: "Taito Frisbeegolf",
    plastics: [],
  },
  {
    molds: [
      "Hellbender (Beluga variant)",
      "Hellbender (Killer Whale variant)",
      "Hellbender (Memphr\u00e9 variant)",
      "Hellbender (Salamander variant)",
      "Hellbender (Razorback variant)",
      "Hellbender",
      "Gray Jay",
      "Pteranodon",
      "Albatross",
      "Merlin",
      "Bighorn",
      "Great Horned Owl",
      "Walrus",
      "Buffalo",
      "Mammoth",
      "Ogopogo",
      "Sabertooth",
      "Bigfoot",
      "Yeti",
      "Sasquatch",
      "Caribou",
      "Grizzly",
      "Moose",
      "Polar Bear",
      "Beaver",
      "Gamedisc",
      "Swift Fox",
      "Timberwolf",
      "Wolverine",
      "Woodchuck",
    ],
    name: "Daredevil",
    plastics: [
      {
        name: "High Performance",
      },
      {
        name: "Flex Performance",
      },
      {
        name: "Grip Performance",
      },
    ],
  },
  {
    molds: ["Arc"],
    name: "Prysm",
    plastics: [],
  },
  {
    molds: ["Z Flyer"],
    name: "Precision Flytes",
    plastics: [],
  },
  {
    molds: [
      "Adventure Driver S",
      "Space Driver",
      "Driver Pro",
      "Mid Range Pro",
      "Putter Entry",
      "Be Wild and Free-Mid Range",
      "Crossfit - Mid Range",
      "Morning - Mid Range",
      "Take Off - Putter",
      "Adventure - Driver",
      "Adventure - Putter",
      "Adventure - Mid Range",
    ],
    name: "Crown Me",
    plastics: [],
  },
  {
    molds: ["Sombra"],
    name: "Chumba",
    plastics: [],
  },
  {
    molds: ["Cricket"],
    name: "Hummingbird Flight Tech",
    plastics: [],
  },
  {
    molds: ["Galaxy", "Asteroid", "Haze"],
    name: "All 4 Frisbee",
    plastics: [],
  },
  {
    molds: ["Orsus Compost", "Orsus"],
    name: "LB Sport Loisir",
    plastics: [],
  },
  {
    molds: ["Dynamo", "Blue Moon"],
    name: "GT",
    plastics: [],
  },
  {
    molds: ["Tyrfing", "N\u00ed\u00f0h\u00f6ggr", "Skegg\u00f8x"],
    name: "Nordisc",
    plastics: [],
  },
  {
    molds: ["Telos ", "Mobius ", "Phi", "Penrose OG Firm", "Penrose "],
    name: "EV-7",
    plastics: [],
  },
  {
    molds: ["Monsoon", "Blizzard", "Sirocco", "Chinook"],
    name: "Windward",
    plastics: [],
  },
  {
    molds: ["Hydro", "Ryuu", "Falkor"],
    name: "Franklin Sports",
    plastics: [],
  },
  {
    molds: ["G1"],
    name: "ODDGRIP",
    plastics: [],
  },
  {
    molds: [
      "Fragment",
      "Lava",
      "Tremor",
      "Magma",
      "Shard",
      "Rift",
      "Cinder",
      "Caldera",
      "Splinter",
    ],
    name: "Obsidian Oy",
    plastics: [],
  },
  {
    molds: ["Zeal"],
    name: "Universal Play",
    plastics: [],
  },
  {
    molds: ["Elder", "Frontier", "Horizon", "Cipher", "Lander", "Code", "Code X"],
    name: "Launch",
    plastics: [],
  },
  {
    molds: ["Fly", "Huntsman Zero1", "Huntsman", "Firehawk", "Dingo", "Taipan"],
    name: "Fourth Circle",
    plastics: [],
  },
  {
    molds: ["Circle Two"],
    name: "Cheap",
    plastics: [],
  },
  {
    molds: [
      " Conquest",
      " White Raven",
      "Lockdown",
      "Cumulus",
      "Contrail",
      "Flow Motion",
      "Mad Mission",
      "Inter Locked",
      "Silent Cruiser",
      "Night Trooper",
      "Short Slacker",
      "Super Soft Golf",
      "Fenris",
      "Sleipner",
      "Midgard",
      "Mj\u00f8lner",
      "Thor",
    ],
    name: "Guru",
    plastics: [],
  },
  {
    molds: ["Slim", "Pretty Fly", "Nice!"],
    name: "Momentum AB",
    plastics: [],
  },
  {
    molds: ["Wheelie "],
    name: "Parked LLC",
    plastics: [],
  },
  {
    molds: ["Company", "Openwater", "Shadowplay", "Vigil", "Lucky", "Credo"],
    name: "Crosslap Parks",
    plastics: [],
  },
  {
    molds: ["Ultimate Zingback", "Zingback", "I-One"],
    name: "Cheengz/Hyperflite",
    plastics: [],
  },
  {
    molds: [
      "Jetski",
      "Bowrider",
      "Ironclad",
      "Trireme",
      "Albatrossdisc Driver",
      "Albatrossdisc Midrange",
      "Albatrossdisc Putter",
      "Caravel",
      "Frigate",
      "Galley",
    ],
    name: "Albatross",
    plastics: [],
  },
  {
    molds: ["Swinhoe Pheasant"],
    name: "Tornado Throw",
    plastics: [],
  },
  {
    molds: [
      "Flying Squirrel (Brachiosaurus variant)",
      "Mission (Stegosaurus variant)",
      "Bee Line",
      "Mission",
      "Flying Squirrel",
      "Secret Weapon",
      "Gamma Ray",
      "Money",
    ],
    name: "ABC",
    plastics: [],
    plastics: [],
  },
  {
    molds: [
      "Hero Bullseye Putt & Approach",
      "Hero Stinger Mid-Range",
      "Hero Maximus Distance Driver",
    ],
    name: "Hero",
    plastics: [],
  },
  {
    molds: [
      "Odin",
      "Fenrir",
      "Barbarian",
      "Loki",
      "Valhalla",
      "Ragnarok",
      "Berserker",
      "Nordic Warrior",
      "Rune",
      "Thunder God Thor",
      "Axe",
      "Cosmos",
      "Knife",
    ],
    name: "Viking (IP-Agency Finland Oy)",
    plastics: [],
  },
  {
    molds: ["Wall Cloud", "Maelstrom", "Radar", "Abyss", "Jet Stream", "The Crater", "The Eye"],
    name: "Storm",
    plastics: [],
  },
  {
    molds: ["Mj\u00f6lnir"],
    name: "Synergy",
    plastics: [],
  },
  {
    molds: ["Rei (\u96f6)"],
    name: "Tokyo",
    plastics: [],
  },
  {
    molds: ["The Baron", "The Count", "The Duchess", "The Duke"],
    name: "Disc Golf UK Ltd.",
    plastics: [],
  },
  {
    molds: ["Bull Bay", "Oak", "Willow"],
    name: "FlyTec",
    plastics: [],
  },
  {
    molds: ["Beginner Fairway Driver", "Beginner Mid-Range", "Beginner Putter"],
    name: "Kestrel Outdoors",
    plastics: [],
  },
  {
    molds: ["Hyperion", "Andro C (retooled)", "Andro C", "Andro 1"],
    name: "Ozone",
    plastics: [],
  },
  {
    molds: ["uControl", "uDrive", "uPutt"],
    name: "Sportme",
    plastics: [],
  },
  {
    molds: [
      "Fairway Driver (new)",
      "Mid-Range",
      "Putter",
      "Fairway Driver (old)",
      "S Driver",
      "Pluto Platter",
      "Superturn Driver",
      "Mid Range Approach",
      "Turnover Driver",
      "Touchline Over",
      "Touchline Under",
      "Touchline Bank/Long Range Driver",
      "Touchline Putter/Putter",
      "Touchline True/Mid Range Approach",
      "Gemini",
      "Max Flight",
      "Voyager II",
      "Apollo",
      "Voyager",
      "The Tool (T501 Mold)",
      "44 Mold",
      "86 Softie",
      "77 Mold",
      "78 Mold",
      "91 Mold",
      "86 Mold",
      "90 Mold",
      "133g Night Lighter",
      "Olympic/Collegiate (100, 100A, 100D, 100E & 100AD molds)",
      "97g (70, 70C, 71 & 71C* molds)",
      "97g (71 Mold)",
      "119 G (40, 40F, 40G, 40G*, 41, 41 F, 41G, 42AF, 42AG, 42BF, 42BG & 40F molds)",
      "141 G (50, 50E, 50G, 51, 52AE, 52BE, 52AG, 52BG & Farusa 5 molds)",
      "165g (80, 81, 81C, 81E, 82E & 900 molds & High Rigidity)",
      "Super Pro (no #, 50, 60, 61, 61N, 61B, 62, 62N, 63A P, 63B P & 63 P molds)",
      "Fastback (all molds)",
      "Master",
      "Professional (1, 4, 10, 14, 15, 16, 17, 20, 20A, 21, 22A, 22B, 23A, 23B, 24A, 24B & Pro Classic molds)",
    ],
    name: "Wham-O, Inc.",
    plastics: [],
  },
  {
    molds: ["50 Cal", "Air Strike", "C4", "Cyborg", "Morningstar"],
    name: "Arsenal",
    plastics: [],
  },
  {
    molds: [
      "Crag",
      "Launch",
      "Onyx",
      "Vamp",
      "Arch",
      "Notch",
      "Valley",
      "Solace",
      "Four20",
      "O-Lace",
      "unLace",
      "Lace",
      "Obex",
      "Sole",
      "Trak",
      "Ibex",
      "Trek",
      "Ascent",
      "Ridge",
      "Summit",
      "V.P.",
    ],
    name: "Vibram",
    plastics: [],
  },
  {
    molds: ["MaxGlide"],
    name: "Best",
    plastics: [],
  },
  {
    molds: ["Meteorite", "Magneton", "Orbit", "Satellite", "Shooting Star"],
    name: "Galaxy",
    plastics: [
      {
        name: "E Class",
      },
    ],
  },
  {
    molds: ["Lex"],
    name: "Disc King",
    plastics: [],
  },
  {
    molds: ["Chromium", "Radium", "Uranium", "Lithium", "Plutonium", "Iridium"],
    name: "Element",
    plastics: [
      {
        name: "Hydra",
      },
      {
        name: "Terra Firma",
      },
      {
        name: "Terra",
      },
    ],
  },
  {
    molds: ["Habit", "Intervention"],
    name: "Plastic Addicts",
    plastics: [
      {
        name: "Top Line",
      },
      {
        name: "Main Line",
      },
    ],
  },
  {
    molds: ["Osprey", "Pelican", "Dragonfly", "Peace Frog", "Swift"],
    name: "AquaFlight",
    plastics: [],
  },
  {
    molds: ["Wo-Hoo", "Ka-kaa"],
    name: "Las Aves",
    plastics: [],
  },
  {
    molds: ["Power Roller (renamed from Kyng Roller)", "Charge"],
    name: "Tobu",
    plastics: [],
  },
  {
    molds: ["Pure O.G.", "AK-47", "Diesel", "Jack the Ripper", "KGB", "Train Wreck", "White Widow"],
    name: "Fly High",
    plastics: [],
  },
  {
    molds: [
      "Supreme Oracle",
      "Supreme Legacy",
      "Supreme Genesis",
      "Fusion Genesis",
      "Fusion Legacy",
      "Power Precision",
      "Ching Big Heavy",
      "Ching Ultimate",
      "Power Genesis",
      "Power Celerity",
      "Power Juju",
      "Power Prodigy",
      "Power Velocity",
      "Supreme Celerity",
      "Supreme Prodigy",
      "Prodigy",
      "Power Legacy (Legacy)",
      "Power Oracle (Oracle)",
      "Fusion Velocity",
      "Supreme Velocity",
      "Supreme Juju",
      "Fusion Juju",
      "Stinger",
      "Bomb",
      "Tank",
      "Sniper",
    ],
    name: "CHING Sports",
    plastics: [],
  },
  {
    molds: ["Chainzilla", "L13 (retooled)", "L13", "Medusa"],
    name: "Skyquest",
    plastics: [],
  },
  {
    molds: ["All in One"],
    name: "Hole19 sarl",
    plastics: [],
  },
  {
    molds: ["Touch", "Backdraft", "Vein", "Antidote", "Prometheus"],
    name: "Salient",
    plastics: [],
  },
  {
    molds: ["Bird-Dog", "Terrapin", "Tiger"],
    name: "Paradigm",
    plastics: [],
  },
  {
    molds: ["Lex", "Monstrum", "Procul"],
    name: "UB - Hand Candy",
    plastics: [],
  },
  {
    molds: ["Chimera"],
    name: "Deity",
    plastics: [],
  },
  {
    molds: [
      "Delivery Organic",
      "Transition Organic",
      "Transporter Organic",
      "Delivery",
      "Transition",
      "Transporter",
    ],
    name: "Eurodisc",
    plastics: [],
  },
  {
    molds: ["Assault", "Sabotage", "Vendetta"],
    name: "DMI Sports",
    plastics: [],
  },
  {
    molds: ["Ballistic", "Graboide", "Relic"],
    name: "Rip",
    plastics: [],
  },
  {
    molds: ["ESP Driver", "ESP Midrange", "ESP Putter"],
    name: "EMSCO Group",
    plastics: [],
  },
  {
    molds: ["Imagine"],
    name: "Ferris State University",
    plastics: [],
  },
  {
    molds: ["Flagship"],
    name: "Skyiron",
    plastics: [],
  },
  {
    molds: [
      "Boom",
      "Kraken",
      "Merc",
      "Prot\u00e9g\u00e9",
      "Tempest",
      "Hail Mary",
      "Miracle",
      "Wing & A Prayer",
    ],
    name: "DKG",
    plastics: [],
  },
  {
    molds: ["Fluent", "Mark", "Vantage", "Crosshair", "Quarter K II", "Transcend", "Quarter K"],
    name: "Discwing, Ltd.",
    plastics: [],
  },
  {
    molds: ["Guardian"],
    name: "Hero Leports Co., Ltd.",
    plastics: [],
  },
  {
    molds: [
      "Maxline -- 1",
      "#2 Helix",
      "#2 Putter",
      "#2 Upshot",
      "#4 Driver",
      "#3 Driver",
      "#2 Driver",
      "#2 Hookshot",
      "#2 Roller",
      "#2 Slice",
      "#3 Flyer",
      "#3 Hookshot",
      "The Driver",
      "#1 Helix",
      "#1 Hyzer",
      "#2 Hyzer",
      "#1 Roller",
      "#1 Hookshot",
      "#1 Slice",
      "B-25 Mitchell (retooled)",
      "#1 Flyer",
      "B-25 Mitchell",
      "Rubber Putter",
      "P-38J Lightning",
      "Upshot",
      "F-6 Hellcat",
      "Dauntless",
      "B-2 Stealth",
      "F-18 Hornet",
      "F-14 Tomcat",
      "Spitfire",
      "F-4-U Corsair",
      "AT-6 Texan",
      "B-17 Flying Fortress",
      "F-8-F Bearcat",
      "P-40 Flying Tiger",
      "P-51 Mustang",
      "SR-71 Blackbird",
      "F-15 Eagle",
      "Falcon",
      "X-15",
      "Thunderbolt",
      "P-38 Lightning",
    ],
    name: "Lightning",
    plastics: [],
  },
  {
    molds: ["Helios", "Whitler"],
    name: "Snapports",
    plastics: [],
  },
  {
    molds: [
      "Double D",
      "Rock-It",
      "T-Bone",
      "100 Meter Lazer",
      "Backbone",
      "Wildfire",
      "10 Meter Crossfire",
      "Crossfire",
      "Raging Inferno DT",
      "Ragning Inferno",
      "10 Meter Brick",
      "Scorcher",
      "Inferno",
      "Scream DT Ultra 2",
      "Odyssey Power Driver",
      "Odyssey True Line Putter",
      "Scream II",
      "Low Speed Driver",
      "Odyssey Approach & Putter",
      "Odyssey Driver",
      "Odyssey Putter",
      "Scream DT",
      "Scream DT Max",
      "Scream DT Ultra",
      "Nighthawk",
      "Odyssey Midrange",
      "Scream",
    ],
    name: "Quest Applied Technologies",
    plastics: [],
  },
  {
    molds: ["Radius"],
    name: "1080",
    plastics: [],
  },
  {
    molds: ["Mercury Putter", "Pluto Driver", "Saturn Mid Range"],
    name: "Pacific Cycle",
    plastics: [],
  },
  {
    molds: [
      "Sharpshooter #3",
      "Sharpshooter #1",
      "Sharpshooter #2",
      "Arrow - Approach & Putter",
      "Epic Driver",
    ],
    name: "Aerobie",
    plastics: [],
  },
  {
    molds: ["Maximizer", "Optimizer"],
    name: "Plastic Paradise",
    plastics: [],
  },
  {
    molds: ["Jeteye", "Long Ranger", "Puppy", "Super Puppy", "Floater"],
    name: "Destiny/Dynamic",
    plastics: [],
  },
];

export default discData;
