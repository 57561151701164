import { StoreMetadata } from "@/firebase/converters/leaderboardStoreMetadata";
import useScreen from "@/hooks/useScreen";
import { type Marker, MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { useMap } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PreservePinsRenderer } from "./PreservePinsRenderer";
import { StorePin } from "./StorePin";

export type ClusteredStorePinsProps = {
  stores: StoreMetadata[];
  setSelectedStore: (store: StoreMetadata | undefined) => void;
};

/**
 * The ClusteredTreeMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredStorePins = ({ stores, setSelectedStore }: ClusteredStorePinsProps) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(null);
  const { isMobile } = useScreen();

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
      map,
      algorithm: new SuperClusterAlgorithm({
        minZoom: 4,
        maxZoom: 11,
        radius: 32,
      }),
      renderer: new PreservePinsRenderer(),
      onClusterClick: (event, cluster, map) => {
        map?.fitBounds(cluster.bounds ?? new google.maps.LatLngBounds(), isMobile ? 100 : 200);
      },
    });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);
  const handleMarkerClick = useCallback(
    (store: StoreMetadata) => {
      if (selectedStoreId === store.id) {
        setSelectedStoreId(null);
        setSelectedStore(undefined);
      } else {
        setSelectedStoreId(store.id);
        setSelectedStore(store);
      }
    },
    [selectedStoreId],
  );

  return (
    <>
      {stores.map((store) => (
        <StorePin
          key={store.id}
          store={store}
          isSelected={selectedStoreId === store.id}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}
    </>
  );
};
