import { Cluster, ClusterStats, Renderer } from "@googlemaps/markerclusterer";

export class PreservePinsRenderer implements Renderer {
  render(
    cluster: Cluster,
    stats: ClusterStats,
    map: google.maps.Map,
  ): google.maps.marker.AdvancedMarkerElement {
    const glyphElement = document.createElement("div");
    glyphElement.style.width = "44px";
    glyphElement.style.height = "53px";
    glyphElement.innerHTML = `<svg width="44" height="53" viewBox="0 0 44 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.1428 20.9179C24.1428 14.5719 28.8588 12.9634 32 12.9634C35.1411 12.9634 39.8571 14.5719 39.8571 20.9179C39.8571 27.2639 34.5968 40.0366 32 40.0366C29.4031 40.0366 24.1428 27.2639 24.1428 20.9179Z" fill="white"/>
<path d="M32 12.4634C30.3574 12.4634 28.2751 12.8818 26.5908 14.1629C24.8831 15.4618 23.6428 17.6041 23.6428 20.9179H24.6428C24.6428 17.8858 25.7606 16.0508 27.1962 14.9589C28.6551 13.8492 30.5014 13.4634 32 13.4634V12.4634ZM40.3571 20.9179C40.3571 17.6041 39.1168 15.4618 37.4092 14.1629C35.7248 12.8818 33.6425 12.4634 32 12.4634V13.4634C33.4986 13.4634 35.3448 13.8492 36.8038 14.9589C38.2394 16.0508 39.3571 17.8858 39.3571 20.9179H40.3571ZM32 40.5366C32.5182 40.5366 33.0039 40.2284 33.422 39.843C33.8558 39.4432 34.2998 38.8828 34.7401 38.2177C35.6227 36.8844 36.5379 35.0574 37.3648 33.0472C39.0131 29.0398 40.3571 24.1883 40.3571 20.9179H39.3571C39.3571 23.9936 38.071 28.7014 36.4399 32.6668C35.6271 34.643 34.7403 36.4058 33.9063 37.6657C33.4882 38.2972 33.0952 38.7843 32.7443 39.1077C32.3776 39.4457 32.131 39.5366 32 39.5366V40.5366ZM23.6428 20.9179C23.6428 24.1883 24.9868 29.0398 26.6352 33.0472C27.462 35.0574 28.3772 36.8844 29.2598 38.2177C29.7001 38.8828 30.1441 39.4432 30.5779 39.843C30.996 40.2284 31.4818 40.5366 32 40.5366V39.5366C31.8689 39.5366 31.6223 39.4457 31.2557 39.1077C30.9047 38.7843 30.5117 38.2972 30.0937 37.6657C29.2596 36.4058 28.3729 34.643 27.56 32.6668C25.929 28.7014 24.6428 23.9936 24.6428 20.9179H23.6428Z" fill="#2A81DE"/>
<ellipse cx="32" cy="20.9181" rx="3.14286" ry="3.18182" fill="#2A81DE"/>
<path d="M6.14282 20.9179C6.14282 14.5719 10.8588 12.9634 14 12.9634C17.1411 12.9634 21.8571 14.5719 21.8571 20.9179C21.8571 27.2639 16.5968 40.0366 14 40.0366C11.4031 40.0366 6.14282 27.2639 6.14282 20.9179Z" fill="white"/>
<path d="M14 12.4634C12.3574 12.4634 10.2751 12.8818 8.59078 14.1629C6.88309 15.4618 5.64282 17.6041 5.64282 20.9179H6.64282C6.64282 17.8858 7.76056 16.0508 9.19616 14.9589C10.6551 13.8492 12.5014 13.4634 14 13.4634V12.4634ZM22.3571 20.9179C22.3571 17.6041 21.1168 15.4618 19.4092 14.1629C17.7248 12.8818 15.6425 12.4634 14 12.4634V13.4634C15.4986 13.4634 17.3448 13.8492 18.8038 14.9589C20.2394 16.0508 21.3571 17.8858 21.3571 20.9179H22.3571ZM14 40.5366C14.5182 40.5366 15.0039 40.2284 15.422 39.843C15.8558 39.4432 16.2998 38.8828 16.7401 38.2177C17.6227 36.8844 18.5379 35.0574 19.3648 33.0472C21.0131 29.0398 22.3571 24.1883 22.3571 20.9179H21.3571C21.3571 23.9936 20.071 28.7014 18.4399 32.6668C17.6271 34.643 16.7403 36.4058 15.9063 37.6657C15.4882 38.2972 15.0952 38.7843 14.7443 39.1077C14.3776 39.4457 14.131 39.5366 14 39.5366V40.5366ZM5.64282 20.9179C5.64282 24.1883 6.98684 29.0398 8.63517 33.0472C9.46199 35.0574 10.3772 36.8844 11.2598 38.2177C11.7001 38.8828 12.1441 39.4432 12.5779 39.843C12.996 40.2284 13.4818 40.5366 14 40.5366V39.5366C13.8689 39.5366 13.6223 39.4457 13.2557 39.1077C12.9047 38.7843 12.5117 38.2972 12.0937 37.6657C11.2596 36.4058 10.3729 34.643 9.55999 32.6668C7.92896 28.7014 6.64282 23.9936 6.64282 20.9179H5.64282Z" fill="#2A81DE"/>
<ellipse cx="14" cy="20.9181" rx="3.14286" ry="3.18182" fill="#2A81DE"/>
<path d="M12.7292 18.9824C12.7292 11.0046 18.7314 8.98242 22.7292 8.98242C26.7271 8.98242 32.7292 11.0046 32.7292 18.9824C32.7292 26.9603 26.0343 43.0173 22.7292 43.0173C19.4242 43.0173 12.7292 26.9603 12.7292 18.9824Z" fill="white"/>
<path d="M22.7292 8.48242C20.6596 8.48242 18.0425 9.00371 15.9297 10.591C13.793 12.1963 12.2292 14.8502 12.2292 18.9824H13.2292C13.2292 15.1368 14.6666 12.7907 16.5304 11.3905C18.4182 9.97221 20.8011 9.48242 22.7292 9.48242V8.48242ZM33.2292 18.9824C33.2292 14.8502 31.6655 12.1963 29.5288 10.591C27.416 9.00371 24.7989 8.48242 22.7292 8.48242V9.48242C24.6574 9.48242 27.0403 9.97221 28.9281 11.3905C30.7919 12.7907 32.2292 15.1368 32.2292 18.9824H33.2292ZM22.7292 43.5173C23.3337 43.5173 23.9187 43.1602 24.4445 42.6815C24.9855 42.1889 25.5439 41.494 26.1014 40.6621C27.2185 38.9953 28.3797 36.7066 29.4302 34.1838C31.5259 29.1512 33.2292 23.0699 33.2292 18.9824H32.2292C32.2292 22.8728 30.5852 28.8089 28.5071 33.7993C27.4708 36.288 26.3385 38.5122 25.2707 40.1054C24.7358 40.9035 24.2289 41.5254 23.7712 41.9421C23.2983 42.3726 22.951 42.5173 22.7292 42.5173V43.5173ZM12.2292 18.9824C12.2292 23.0699 13.9326 29.1512 16.0283 34.1838C17.0788 36.7066 18.24 38.9953 19.3571 40.6621C19.9146 41.494 20.473 42.1889 21.014 42.6815C21.5398 43.1602 22.1248 43.5173 22.7292 43.5173V42.5173C22.5075 42.5173 22.1602 42.3726 21.6873 41.9421C21.2296 41.5254 20.7227 40.9035 20.1878 40.1054C19.12 38.5122 17.9877 36.288 16.9514 33.7993C14.8733 28.8089 13.2292 22.8728 13.2292 18.9824H12.2292Z" fill="#2A81DE"/>
<circle cx="22.7292" cy="18.9824" r="4" fill="#2A81DE"/>
</svg>

`;

    return new google.maps.marker.AdvancedMarkerElement({
      map,
      collisionBehavior: google.maps.CollisionBehavior.REQUIRED,
      position: cluster.position,
      content: glyphElement,
      zIndex: 0 - cluster.position.lat(),
    });
  }
}
