import { useParams } from "react-router-dom";
import { SingleDocDashboard } from "../../latestDashboard";
import NotFound from "../layout/NotFound";
import { ErrorBoundary } from "../ErrorBoundary";

export default function ShareThrowRoute() {
  const { userId, throwId } = useParams();
  return userId && throwId ? (
    // <ErrorBoundary eventName="shared_throws_error">
    <SingleDocDashboard userId={userId} throwId={throwId} />
  ) : (
    // </ErrorBoundary>
    <NotFound />
  );
}
