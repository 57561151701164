import { AnimatePresence } from "framer-motion";
import { LIVE_USER_ID } from "../../dashboard/liveEvents";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";
import { ErrorBoundary } from "../ErrorBoundary";
import { AnalysisSetTable } from "../analysis/AnalysisSetTable";
import { motion } from "framer-motion";

export default function ThrowSetsRoute() {
  const [{ userId, trueUserId, externalUserId }, userLoading] = useUser();
  const { isLiveRoute } = useLive();

  // Top Priority: External User
  // Necessary for loading public throws and inspecting users
  if (externalUserId) {
    return (
      // <ErrorBoundary eventName="external_user_throw_sets_error">
      userLoading ? null : <AnalysisSetTable userId={externalUserId} />
      // </ErrorBoundary>
    );
  }

  // Live User & Live Event Routes
  if (isLiveRoute) {
    return (
      // <ErrorBoundary eventName="live_user_throw_sets_error">
      userLoading ? null : <AnalysisSetTable userId={LIVE_USER_ID} />
      // </ErrorBoundary>
    );
  }

  // Must be after live route check so logged in users can see live route data
  if (trueUserId) {
    return (
      // <ErrorBoundary eventName="user_throw_sets_error">
      userLoading ? null : <AnalysisSetTable userId={trueUserId} />
      // </ErrorBoundary>
    );
  }

  // User
  return (
    // <ErrorBoundary eventName="default_user_throw_sets_error">
    userLoading ? null : <AnalysisSetTable userId={"unknown"} />
    // </ErrorBoundary>
  );
}
