import { MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

function DiscClassDropdown(props: { id: string }) {
  const methods = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      control={methods.control}
      name={props.id}
      rules={{ required: true }}
      render={({ field }) => (
        <Select
          {...field}
          onChange={(e) => {
            field.onChange(e.target.value);
          }}
          inputRef={field.ref}
          fullWidth
          size="small"
          sx={{ backgroundColor: "white", width: "196px" }}
          error={!!methods.formState.errors[props.id]}
        >
          <MenuItem value="putter">{t("discClass.proper.putter_one")}</MenuItem>
          <MenuItem value="putt-approach">{t("discClass.proper.putt-approach_one")}</MenuItem>
          <MenuItem value="midrange">{t("discClass.proper.midrange_one")}</MenuItem>
          <MenuItem value="fairway">{t("discClass.proper.fairway_one")}</MenuItem>
          <MenuItem value="control">{t("discClass.proper.control_one")}</MenuItem>
          <MenuItem value="distance">{t("discClass.proper.distance_one")}</MenuItem>
        </Select>
      )}
    />
  );
}

export default DiscClassDropdown;
