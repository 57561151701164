import { Box } from "@mui/material";
import { Medium } from "../Text";

export const RowExpandedHeader = ({ title, index }: { title: string; index: number }) => {
  return (
    <Box
      sx={{
        userSelect: "none",
        border: "1px solid",
        borderColor: "grey.300",
        borderTopColor: index % 2 === 0 ? "white" : "grey.100",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
        px: 1,
        pt: 1,
        pb: 0.5,
        mt: "-1px",
        backgroundColor: index % 2 === 0 ? "white" : "grey.100",
        filter: "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
      }}
    >
      <Medium spacing="loose" size={12}>
        {title}
      </Medium>
    </Box>
  );
};
