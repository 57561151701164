import { BoxProps, Stack, SxProps, Theme } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { ElementType, ReactNode } from "react";
import { MotionProps, motion } from "framer-motion";

export interface BaseTextProps extends Omit<BoxProps, "component"> {
  as?: ElementType;
  children?: ReactNode;
  size?: ResponsiveStyleValue<number | string>;
  spacing?: "loose" | "dense" | "normal";
  motionProps?: MotionProps;
}

export function Text(props: BaseTextProps) {
  const { children, sx, ...rest } = props;

  const textSx: SxProps<Theme> = {
    ...sx,
    textDecoration: "none",
    "&:focus": { textDecoration: "none" },
    "&:visited": { textDecoration: "none" },
    "&:active": { textDecoration: "none" },
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 200,
  } as const;

  return (
    <BaseText {...rest} sx={textSx}>
      {children}
    </BaseText>
  );
}

export function Medium(props: BaseTextProps) {
  const { children, sx, ...rest } = props;

  const textSx: SxProps<Theme> = {
    ...sx,
    textDecoration: "none",
    "&:focus": { textDecoration: "none" },
    "&:visited": { textDecoration: "none" },
    "&:active": { textDecoration: "none" },
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 300,
  } as const;

  return (
    <BaseText {...rest} sx={textSx}>
      {children}
    </BaseText>
  );
}

export function Strong(props: BaseTextProps) {
  const { children, sx, ...rest } = props;

  const textSx: SxProps<Theme> = {
    ...sx,
    textDecoration: "none",
    "&:focus": { textDecoration: "none" },
    "&:visited": { textDecoration: "none" },
    "&:active": { textDecoration: "none" },
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 400,
  } as const;

  return (
    <BaseText {...rest} sx={textSx}>
      {children}
    </BaseText>
  );
}

export function Heavy(props: BaseTextProps) {
  const { children, sx, ...rest } = props;

  const textSx: SxProps<Theme> = {
    ...sx,
    textDecoration: "none",
    "&:focus": { textDecoration: "none" },
    "&:visited": { textDecoration: "none" },
    "&:active": { textDecoration: "none" },
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 500,
  } as const;

  return (
    <BaseText {...rest} sx={textSx}>
      {children}
    </BaseText>
  );
}

export function Black(props: BaseTextProps) {
  const { children, sx, ...rest } = props;

  const textSx: SxProps<Theme> = {
    ...sx,
    textDecoration: "none",
    "&:focus": { textDecoration: "none" },
    "&:visited": { textDecoration: "none" },
    "&:active": { textDecoration: "none" },
    "&:hover": {
      textDecoration: "none",
    },
    fontWeight: 600,
  } as const;

  return (
    <BaseText {...rest} sx={textSx}>
      {children}
    </BaseText>
  );
}

function BaseText(props: BaseTextProps) {
  const { as, motionProps, children, size, spacing, color, sx, ...rest } = props;

  // Separate motion styles if needed
  const regularStyles = {
    color,
    fontSize: size ?? "1rem",
    letterSpacing: spacing === "loose" ? "0.75px" : spacing === "dense" ? "-0.75px" : "auto",
    ...sx,
  };

  return (
    <Stack component={as ?? motion.span} sx={regularStyles} {...rest}>
      {children}
    </Stack>
  );
}
