import { CollectionHook, useCollection } from "react-firebase-hooks/firestore";
import {
  LeaderboardComputedRank,
  LeaderboardEntry,
  LeaderboardEntryWithId,
  LeaderboardSortKeys,
  LeaderboardUserMetadata,
} from "../../model/leaderboard";
import { firebaseApp } from "../../firebaseConfig";
import { useMemo } from "react";
import {
  collection,
  DocumentData,
  documentId,
  getFirestore,
  limit,
  orderBy,
  Query,
  query,
  QueryConstraint,
  QuerySnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getLeaderboardWeeklyCollection,
  getUserId,
  storeAnalysisSet,
  storeLeaderboardUserMetadata,
} from "../../summaryUtils";

export interface LeaderboardResults {
  isLoading: boolean;
  error: any;
  bestSpin?: LeaderboardEntryWithId[];
  bestSpeed?: LeaderboardEntryWithId[];
  bestDistance?: LeaderboardEntryWithId[];

  avgSpin?: LeaderboardEntryWithId[];
  avgSpeed?: LeaderboardEntryWithId[];
  avgDistance?: LeaderboardEntryWithId[];
}

function retainFirstEntryPerUser(entries: LeaderboardEntryWithId[], limit: number = 10) {
  const userIds = new Set<string>();
  const bestEntries: LeaderboardEntryWithId[] = [];
  for (const entry of entries) {
    if (!entry.toUserId || !userIds.has(entry.toUserId)) {
      if (entry.toUserId) {
        userIds.add(entry.toUserId);
      }
      bestEntries.push(entry);
      if (bestEntries.length >= limit) {
        break;
      }
    }
  }
  return bestEntries;
}

const NUM_SEARCH_BACK_FOR_RANK = 20;

function getLeaderboardQuery(
  type: LeaderboardSortKeys,
  userId?: string,
  dateRange?: [Date, Date],
): Query | null {
  const q1: Query = getLeaderboardWeeklyCollection();
  const filters: QueryConstraint[] = [];
  if (userId) {
    filters.push(where("userId", "==", userId));
  }
  filters.push(orderBy(type, "desc"));
  filters.push(limit(NUM_SEARCH_BACK_FOR_RANK));
  if (dateRange && dateRange.length === 2) {
    filters.push(where("throwTime", ">=", Timestamp.fromMillis(dateRange[0].getTime())));
    filters.push(where("throwTime", "<=", Timestamp.fromMillis(dateRange[1].getTime())));
  }

  logEvent(getAnalytics(firebaseApp), "leaderboard_query");
  return query(q1, ...filters);
}

function getEstimatedRankQuery(
  type: LeaderboardSortKeys,
  value: number | undefined | null,
  userId?: string,
): Query | null {
  if (!value) {
    return null;
  }
  const q1: Query = getLeaderboardWeeklyCollection();
  const filters: QueryConstraint[] = [];
  if (userId) {
    filters.push(where("userId", "==", userId));
  }
  filters.push(where(type, ">", value));
  filters.push(orderBy(type, "asc"));
  filters.push(limit(NUM_SEARCH_BACK_FOR_RANK));

  logEvent(getAnalytics(firebaseApp), "leaderboard_estimated_rank_query");
  return query(q1, ...filters);
}

function extractResults(
  value: QuerySnapshot | undefined,
): (LeaderboardEntryWithId & LeaderboardComputedRank)[] | undefined {
  return value?.docs.map((doc) => {
    const data = doc.data() as LeaderboardEntry;
    return {
      ...data,
      id: doc.id,
    };
  }) as LeaderboardEntryWithId[] | undefined;
}

export function useTopLeaderboard(userId?: string): LeaderboardResults {
  const speedQuery = useMemo(() => getLeaderboardQuery("bestSpeedMph", userId), [userId]);
  const spinQuery = useMemo(() => getLeaderboardQuery("bestSpinRpm", userId), [userId]);
  const distQ = useMemo(() => getLeaderboardQuery("bestDistanceFeet", userId), [userId]);
  const avgSpeedQuery = useMemo(() => getLeaderboardQuery("avgSpeedMph", userId), [userId]);
  const avgSpinQuery = useMemo(() => getLeaderboardQuery("avgSpinRpm", userId), [userId]);
  const avgDistQ = useMemo(() => getLeaderboardQuery("avgDistanceFeet", userId), [userId]);

  const [value, isLoading, error] = useCollection(speedQuery);
  const [valueSpin, isLoadingSpin, errorSpin] = useCollection(spinQuery);
  const [valueDist, isLoadingDist, errorDist] = useCollection(distQ);
  const [avgSpeed, avgLoadingSpeed, avgErrorSpeed] = useCollection(avgSpeedQuery);
  const [avgValueSpin, avgLoadingSpin, avgErrorSpin] = useCollection(avgSpinQuery);
  const [avgValueDist, avgLoadingDist, avgErrorDist] = useCollection(avgDistQ);

  const bestSpeedsRaw = extractResults(value);
  const bestSpinRaw = extractResults(valueSpin);
  const bestDistRaw = extractResults(valueDist);
  const avgSpeedsRaw = extractResults(avgSpeed);
  const avgSpinRaw = extractResults(avgValueSpin);
  const avgDistRaw = extractResults(avgValueDist);

  if (errorDist || error || errorSpin || avgErrorDist || avgErrorSpeed || avgErrorSpin) {
    logEvent(getAnalytics(firebaseApp), "leaderboard_top_query_error", {
      errorDist,
      error,
      errorSpin,
      avgErrorDist,
      avgErrorSpeed,
      avgErrorSpin,
    });
  }

  return {
    isLoading:
      isLoading ||
      isLoadingSpin ||
      isLoadingDist ||
      avgLoadingSpeed ||
      avgLoadingSpin ||
      avgLoadingDist,
    error: error ?? errorSpin ?? errorDist,
    bestSpeed: bestSpeedsRaw && retainFirstEntryPerUser(bestSpeedsRaw),
    bestSpin: bestSpinRaw && retainFirstEntryPerUser(bestSpinRaw),
    bestDistance: bestDistRaw && retainFirstEntryPerUser(bestDistRaw),
    avgSpeed: avgSpeedsRaw && retainFirstEntryPerUser(avgSpeedsRaw),
    avgSpin: avgSpinRaw && retainFirstEntryPerUser(avgSpinRaw),
    avgDistance: avgDistRaw && retainFirstEntryPerUser(avgDistRaw),
  };
}

function populateResults(
  valueDocs: QuerySnapshot<DocumentData> | undefined,
  key: keyof LeaderboardComputedRank,
  result: LeaderboardComputedRank,
) {
  if (!valueDocs) {
    return;
  }
  const docs = valueDocs.docs;
  const skippedToUser = new Map<string, number>();
  let skipped = 0;
  for (let i = 0; i < docs?.length; i++) {
    const doc = docs[i];
    const data = doc.data() as LeaderboardComputedRank & LeaderboardEntry;
    if (data.toUserId) {
      if (!skippedToUser.has(data.toUserId)) {
        skippedToUser.set(data.toUserId, skipped);
        skipped++;
      }
    } else {
      skipped++;
    }

    if (data[key]) {
      // @ts-ignore
      result[key] = skipped + data[key];
      break;
    }
  }

  if (!result[key]) {
    result[key] = 1 + skipped;
    if (docs.length == NUM_SEARCH_BACK_FOR_RANK) {
      logEvent(getAnalytics(firebaseApp), "leaderboard_rank_failed", { docs });
    }
  }
}

export function useEstimatedRank(
  value: LeaderboardEntry | undefined,
  userId?: string,
): LeaderboardComputedRank | undefined {
  const speedQuery = useMemo(
    () => getEstimatedRankQuery("bestSpeedMph", value?.bestSpeedMph, userId),
    [value, userId],
  );
  const spinQuery = useMemo(
    () => getEstimatedRankQuery("bestSpinRpm", value?.bestSpinRpm, userId),
    [value, userId],
  );
  const distQ = useMemo(
    () => getEstimatedRankQuery("bestDistanceFeet", value?.bestDistanceFeet, userId),
    [value, userId],
  );
  const avgSpeedQuery = useMemo(
    () => getEstimatedRankQuery("avgSpeedMph", value?.avgSpeedMph, userId),
    [value, userId],
  );
  const avgSpinQuery = useMemo(
    () => getEstimatedRankQuery("avgSpinRpm", value?.avgSpinRpm, userId),
    [value, userId],
  );
  const avgDistQ = useMemo(
    () => getEstimatedRankQuery("avgDistanceFeet", value?.avgDistanceFeet, userId),
    [value, userId],
  );

  const [valueSpeed, isLoadingSpeed, errorSpeed] = useCollection(speedQuery);
  const [valueSpin, isLoadingSpin, errorSpin] = useCollection(spinQuery);
  const [valueDist, isLoadingDist, errorDist] = useCollection(distQ);
  const [avgValueSpeed, avgIsLoadingSpeed, avgErrorSpeed] = useCollection(avgSpeedQuery);
  const [avgValueSpin, avgIsLoadingSpin, avgErrorSpin] = useCollection(avgSpinQuery);
  const [avgValueDist, avgIsLoadingDist, avgErrorDist] = useCollection(avgDistQ);

  if (
    isLoadingSpeed ||
    isLoadingSpin ||
    isLoadingDist ||
    avgIsLoadingDist ||
    avgIsLoadingSpeed ||
    avgIsLoadingSpin
  ) {
    return undefined;
  }

  if (errorDist || errorSpeed || errorSpin || avgErrorDist || avgErrorSpeed || avgErrorSpin) {
    logEvent(getAnalytics(firebaseApp), "leaderboard_rank_error", {
      errorDist,
      errorSpeed,
      errorSpin,
      avgErrorDist,
      avgErrorSpeed,
      avgErrorSpin,
    });
    return undefined;
  }

  const result: LeaderboardComputedRank = {};
  if (userId) {
    populateResults(valueSpeed, "userBestSpeedRank", result);
    populateResults(valueSpin, "userBestSpinRank", result);
    populateResults(valueDist, "userBestDistanceRank", result);
    populateResults(avgValueSpeed, "userAvgSpeedRank", result);
    populateResults(avgValueSpin, "userAvgSpinRank", result);
    populateResults(avgValueDist, "userAvgDistanceRank", result);
  } else {
    populateResults(valueSpeed, "globalBestSpeedRank", result);
    populateResults(valueSpin, "globalBestSpinRank", result);
    populateResults(valueDist, "globalBestDistanceRank", result);
    populateResults(avgValueSpeed, "globalAvgSpeedRank", result);
    populateResults(avgValueSpin, "globalAvgSpinRank", result);
    populateResults(avgValueDist, "globalAvgDistanceRank", result);
  }

  return result;
}

export function storeNameForSingleLeaderboardEntry(
  userId: string,
  setId: string,
  displayName: string,
) {
  return storeAnalysisSet(userId, setId, { leaderboardEntry: { displayName } });
}

export function storeSharedLeaderboardName(displayName: string) {
  return storeLeaderboardUserMetadata(getUserId(), { displayName });
}

export function useLeaderboardUserMetadata(
  userIds: string[] | undefined = undefined,
): CollectionHook<LeaderboardUserMetadata> {
  const q = useMemo(() => {
    if (!userIds) {
      return undefined;
    }
    const collectionPath = "/leaderboard/users/userMetadata";
    const q1: Query = collection(getFirestore(firebaseApp), collectionPath);
    const filters: QueryConstraint[] = [];
    if (userIds.length > 30) {
      // NB: firebase in query has a limit of 30
      console.error("Too many ids");
      userIds = userIds.slice(0, 30);
    }
    filters.push(where(documentId(), "in", userIds));
    return query<LeaderboardUserMetadata>(q1, ...filters);
  }, [userIds]);
  return useCollection(q);
}
