import { Box, Stack } from "@mui/material";
import React from "react";
import { Black, Heavy, Medium, Strong } from "../Text";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

export interface AdjustableFlightNumberProps {
  value: React.ReactNode;
  metric?: React.ReactNode;
  onIncrement: () => void;
  onDecrement: () => void;
  borderless?: boolean;
}

function AdjustableFlightNumber(props: AdjustableFlightNumberProps) {
  const { metric, value, onIncrement, onDecrement, borderless = false } = props;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        userSelect: "none",
        aspectRatio: "1/1",
        width: "128px",
        maxWidth: "128px",
        border: borderless ? "none" : "1px solid",
        borderColor: "grey.400",
        color: "primary.main",
        backgroundColor: "white",
        p: 1,
        borderRadius: 1,
      }}
      gap={0.5}
    >
      <Black size={32}>{value}</Black>

      <Stack direction="row" gap={0.5}>
        <AdjustmentButton handleClick={onDecrement}>
          <ArrowLeft />
        </AdjustmentButton>
        {metric && (
          <Strong color="grey.600" spacing="dense">
            {metric}
          </Strong>
        )}
        <AdjustmentButton handleClick={onIncrement}>
          <ArrowRight />
        </AdjustmentButton>
      </Stack>
    </Stack>
  );
}

export default AdjustableFlightNumber;

const AdjustmentButton = ({
  children,
  handleClick,
}: {
  children: React.ReactNode;
  handleClick: () => void;
}) => {
  return (
    <Stack
      component="button"
      onClick={handleClick}
      type="button"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        aspectRatio: "1",
        width: "24px",
        backgroundColor: "rgba(0, 0, 0, 0)",
        p: 0,
        borderRadius: "100%",
        "&:hover": {
          backgroundColor: "grey.100",
        },
      }}
    >
      {children}
    </Stack>
  );
};
