import { Black } from "../../Text";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { containerVariants } from "../variants";
import { Paper } from "@mui/material";
import { motion } from "framer-motion";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { getUserStockShots } from "../../../firebase/collections";
import { useUser } from "../../../hooks/useUser";
import StockShotsTable from "../../tables/StockShotsTable";
import { Link } from "react-router-dom";
import { ErrorBoundary } from "../../ErrorBoundary";
import { LIVE_USER_ID } from "../../../dashboard/liveEvents";
import useLive from "../../../hooks/useLive";
import { useGlobal } from "../../providers/GlobalProvider";
import { createDebugStockShot } from "../../../firebase/stock-shots/createDebugStockShot";
import { deleteStockShot } from "../../../firebase/stock-shots";
import { Table } from "@tanstack/react-table";
import { StockShot } from "../../../firebase/converters/stockShot";

function StockShotIndexRoute() {
  const [{ userId, trueUserId, externalUserId }, userLoading] = useUser();
  const { isLiveRoute } = useLive();

  if (externalUserId) {
    return (
      <ErrorBoundary eventName="external_user_stock_shots_error">
        {userLoading ? null : <RouteContent userId={externalUserId} />}
      </ErrorBoundary>
    );
  }

  if (isLiveRoute) {
    return (
      <ErrorBoundary eventName="live_user_stock_shots_error">
        {userLoading ? null : <RouteContent userId={LIVE_USER_ID} />}
      </ErrorBoundary>
    );
  }

  if (trueUserId) {
    return (
      <ErrorBoundary eventName="user_stock_shots_error">
        {userLoading ? null : <RouteContent userId={trueUserId} />}
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary eventName="default_user_stock_shots_error">
      {userLoading ? null : <RouteContent userId={userId} />}
    </ErrorBoundary>
  );
}

export default StockShotIndexRoute;

const RouteContent = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const [stockShots] = useCollectionData(getUserStockShots(userId));
  const { isDeveloper } = useGlobal();

  const deleteRows = (table: Table<StockShot>) => {
    table.getSelectedRowModel().rows.forEach(async (row) => {
      if (row.getIsSelected()) {
        await deleteStockShot(userId, row.original.id);
      }
    });
    table.resetRowSelection();
  };

  return (
    <Paper
      component={motion.div}
      variants={containerVariants}
      sx={{
        width: "100%",
        minHeight: "512px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: { mobile: 1, tablet: 2 },
      }}
    >
      <Stack gap={{ mobile: 2, tablet: 4 }}>
        <Stack direction="row" justifyContent="space-between">
          <Black size={{ mobile: 20, tablet: 28 }} color="grey.600" spacing="dense">
            {t("stockShots")}
          </Black>
          <Stack direction="row" gap={2}>
            {isDeveloper && (
              <Button variant="secondary" onClick={() => createDebugStockShot(userId)}>
                {t("addTestStockShot")}
              </Button>
            )}
            <Button variant="primary" component={Link} to="/stock-shots/create">
              {t("addStockShot")}
            </Button>
          </Stack>
        </Stack>
        <StockShotsTable
          id="stock-shots"
          userId={userId}
          stockShots={stockShots}
          options={{ handleDeleteRows: deleteRows }}
        />
      </Stack>
    </Paper>
  );
};
