import { Box, BoxProps } from "@mui/material";

export function TechDiscHQPin(props: BoxProps & { selected?: boolean }) {
  return (
    <Box {...props}>
      <Box
        component="img"
        src="https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDiscLight_noWords.svg"
        width="28px"
      />
    </Box>
  );
}
