import React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { motion } from "framer-motion";

interface ActiveNodeProps extends SvgIconProps {
  circleFill?: string;
  pathFill?: string;
}

const ActiveNode: React.FC<ActiveNodeProps> = ({
  circleFill = "white",
  pathFill = "#2A81DE",
  ...props
}) => (
  <SvgIcon component={motion.svg} layout {...props} viewBox="0 0 20 21">
    <circle cx="10" cy="10.48" r="10" fill={circleFill} />
    <path
      d="M20 10.48C20 16.0028 15.5228 20.48 10 20.48C4.47715 20.48 0 16.0028 0 10.48C0 4.95713 4.47715 0.47998 10 0.47998C15.5228 0.47998 20 4.95713 20 10.48ZM4 10.48C4 13.7937 6.68629 16.48 10 16.48C13.3137 16.48 16 13.7937 16 10.48C16 7.16627 13.3137 4.47998 10 4.47998C6.68629 4.47998 4 7.16627 4 10.48Z"
      fill={pathFill}
    />
  </SvgIcon>
);

export default ActiveNode;
