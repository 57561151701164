import { Container, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import { Outlet, useMatches } from "react-router-dom";

const steps = ["Register", "Throw", "Results"];

export enum TestDriveStep {
  START,
  THROWING,
  ANALYZE,
}

export default function TestDriveRoute() {
  const matches = useMatches();

  const { currentStep } = matches.reduce((acc, match) => {
    return { ...acc, ...(match?.handle ?? {}) };
  }, {}) as { currentStep: number };

  return (
    <Container sx={{ my: 4 }}>
      <Stepper activeStep={currentStep} sx={{ mb: 4, gap: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Outlet />
    </Container>
  );
}
