import { Box, Button, Paper, Stack } from "@mui/material";
import { Link, useOutletContext } from "react-router-dom";
import DiscCollection from "./DiscCollection";
import { discClassDefaults, discClassRouteConfig } from "./config";
import { useUser } from "../../hooks/useUser";
import { DiscsRouteOutletContext } from "../layout/DiscLayout";
import { Heavy, Medium, Strong, Text } from "../Text";
import { useTranslation } from "react-i18next";
import DiscCollectionHeader from "./DiscCollectionHeader";
import { motion } from "framer-motion";

export default function FullDiscCollection() {
  const [{ trueUserId, externalUserId, publicUserId }] = useUser();
  const { discPresets, discPresetsByClass } = useOutletContext<DiscsRouteOutletContext>();
  const { t } = useTranslation();
  const hasDiscs = discPresets.length > 0;

  return hasDiscs ? (
    <Stack component={motion.div} gap={6}>
      {discClassRouteConfig.map((routeConfig) => (
        <Paper sx={{ width: "100%" }} key={`discCollection-${routeConfig.id}`}>
          <DiscCollection
            limit={4}
            userId={trueUserId ?? externalUserId ?? publicUserId}
            presets={discPresetsByClass?.[routeConfig.id]}
            discClass={routeConfig.id}
          />
        </Paper>
      ))}
    </Stack>
  ) : (
    <Paper sx={{ width: "100%" }}>
      <Stack
        component={motion.div}
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "384px",
          gap: 4,
        }}
      >
        <Heavy size={32} spacing="dense" color="secondary.main">
          {t("createDisc")}
        </Heavy>
        <Stack alignItems="center" justifyContent="center" gap={4} sx={{ height: "100%" }}>
          <Strong size={14} color="grey.700">
            {t("createDiscDescription")}
          </Strong>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
            {discClassRouteConfig.map((config) => (
              <Button
                component={Link}
                to={`./new/${config.id}`}
                relative="path"
                variant="outlined"
                color="primary"
                key={`create-btn-${config.id}`}
              >
                {config.createLabel}
              </Button>
            ))}
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
}
