import React, { useEffect, useRef, useState } from "react";
import { DiscClass, DiscAppearance, DiscPreset } from "../../model/discs";
import { alpha, darken } from "@mui/system";
import { discClassDefaults } from "./config";
import { TECHDISC_COLOR } from "../../colors";

export interface DiscRenderProps {
  discClass?: DiscClass;
  appearance?: DiscAppearance;
  colors?: DiscPreset["colors"];
  autoSize?: boolean;
  width?: number | { [key: string]: number };
  height?: number | { [key: string]: number };
  layoutId?: string;
  shadowSize?: number;
}

export const shadowSteps = [
  "0 1px 1px hsl(0deg 0% 0% / 0.075)",
  "0 2px 2px hsl(0deg 0% 0% / 0.075)",
  "0 4px 4px hsl(0deg 0% 0% / 0.075)",
  "0 8px 8px hsl(0deg 0% 0% / 0.075)",
  "0 16px 16px hsl(0deg 0% 0% / 0.075)",
];

export default function DiscRender(props: DiscRenderProps) {
  const {
    discClass,
    appearance,
    colors,
    autoSize,
    width,
    height,
    layoutId,
    shadowSize = 0,
  } = props;
  const [rimWidth, setRimWidth] = useState(0);
  const [solidGradient, setSolidGradient] = useState("");
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const primaryColor = colors?.primary ?? TECHDISC_COLOR.DARK_BLUE;
  const secondaryColor = colors?.secondary ?? TECHDISC_COLOR.GREEN;
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries, obs) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
        setCanvasSize({ width: Math.max(width, height), height: Math.max(width, height) });
        obs.disconnect();
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current.parentElement!);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current.parentElement!);
      }
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      const drawCanvas = ref.current;
      const ctx = drawCanvas.getContext("2d");
      if (ctx) {
        const { width, height } = canvasSize;
        drawCanvas.width = width;
        drawCanvas.height = height;

        if (discClass) {
          setRimWidth(discClassDefaults?.[discClass]?.rimCoeff * Math.min(width, height)); // Calculate border size
          setSolidGradient(discClassDefaults?.[discClass]?.solidGradient ?? 50);
        }

        // Clear canvas
        ctx.clearRect(0, 0, width, height);

        // Draw disc based on appearance
        if (appearance === DiscAppearance.Solid) {
          const gradient = ctx.createRadialGradient(
            width / 2,
            height / 2,
            0,
            width / 2,
            height / 2,
            width / 2,
          );
          gradient.addColorStop(0, primaryColor);
          gradient.addColorStop(solidGradient / 100, primaryColor);
          gradient.addColorStop(1, darken(primaryColor, 0.15));
          ctx.fillStyle = gradient;
          ctx.beginPath();
          ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2);
          ctx.fill();
        } else if (appearance === DiscAppearance.Overmold) {
          ctx.fillStyle = "#FFFFFF";
          ctx.beginPath();
          ctx.arc(width / 2, height / 2, width / 2 - rimWidth, 0, Math.PI * 2);
          ctx.fill();

          const gradient = ctx.createRadialGradient(
            width / 2,
            height / 2,
            0,
            width / 2,
            height / 2,
            width / 2 - rimWidth,
          );
          gradient.addColorStop(0, primaryColor);
          gradient.addColorStop(solidGradient / 100, primaryColor);
          gradient.addColorStop(1, darken(primaryColor, 0.15));
          ctx.fillStyle = gradient;
          ctx.beginPath();
          ctx.arc(width / 2, height / 2, width / 2 - rimWidth, 0, Math.PI * 2);
          ctx.fill();

          ctx.strokeStyle = secondaryColor;
          ctx.lineWidth = rimWidth;
          ctx.beginPath();
          ctx.arc(width / 2, height / 2, width / 2 - rimWidth / 2, 0, Math.PI * 2);
          ctx.stroke();
        } else if (appearance === DiscAppearance.Burst) {
          const gradient = ctx.createRadialGradient(
            width / 2,
            height / 2,
            0,
            width / 2,
            height / 2,
            width / 2,
          );
          gradient.addColorStop(0, alpha(primaryColor, 1));
          gradient.addColorStop(0.5, alpha(primaryColor, 0.5));
          gradient.addColorStop(0.75, alpha(secondaryColor, 0.5));
          gradient.addColorStop(1, alpha(secondaryColor, 1));
          ctx.fillStyle = gradient;
          ctx.beginPath();
          ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2);
          ctx.fill();
        }
      }
    }
  }, [discClass, appearance, primaryColor, secondaryColor, solidGradient, rimWidth, canvasSize]);

  return (
    <canvas
      ref={ref}
      style={{
        minWidth: autoSize ? "auto" : width,
        minHeight: autoSize ? "auto" : height,
        width: autoSize ? "auto" : width,
        height: autoSize ? "auto" : height,
        boxShadow: shadowSteps.slice(0, shadowSize).join(", "),
        aspectRatio: "1/1",
        borderRadius: "50%",
      }}
    />
  );
}
