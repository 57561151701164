import { useUser } from "@/hooks/useUser";
import Map from "@mui/icons-material/MapTwoTone";
import { Box, darken, Stack } from "@mui/material";
import { limit, query, QueryConstraint, where } from "firebase/firestore";
import { useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import StoreMap from "../../dashboard/StoreMap";
import { getLeaderboardStoreMetadataCollection } from "../../firebase/collections";
import {
  leaderboardStoreMetadataConverter,
  StoreMetadata,
} from "../../firebase/converters/leaderboardStoreMetadata";
import { StoreInfoCard } from "../stores/StoreInfoCard";
import StoreMapLegend from "../stores/StoreMapLegend";
import { ClusteredStorePins } from "./ClusteredStorePins";

const StoreLocatorRoute = () => {
  const [selectedStore, setSelectedStore] = useState<StoreMetadata>();
  const [{ user }] = useUser();

  const storesWithValidAddresses = useMemo(() => {
    const filters: QueryConstraint[] = [];
    filters.push(where("addressValidity.result.geocode", "!=", null));
    filters.push(limit(200));
    return query(getLeaderboardStoreMetadataCollection(), ...filters).withConverter(
      leaderboardStoreMetadataConverter,
    );
  }, []);

  const [stores] = useCollectionData(storesWithValidAddresses);

  const visibleStores = useMemo(() => {
    return stores?.filter((store) => !store.hideMapLocation);
  }, [stores]);

  return (
    // <ErrorBoundary eventName="store_map_error">
    <Stack>
      <Stack
        gap={4}
        direction="row"
        width="100%"
        height={{
          tablet: user ? "calc(90dvh - 48px)" : "calc(90dvh - 132px)",
          mobile: user ? "calc(100dvh - 48px)" : "calc(100dvh - 128px)",
        }}
        position="relative"
        justifyContent="center"
      >
        <StoreMap>
          <StoreMapLegend
            sx={{
              position: "absolute",
              top: 12,
              right: 12,
              display: { mobile: "none", tablet: "block" },
            }}
          />
          {selectedStore && (
            <Box
              sx={{
                position: "absolute",
                top: { mobile: 12, tablet: 16 },
                left: { mobile: 12, tablet: 16 },
                right: { mobile: 12, tablet: "auto" },
                minWidth: { tablet: "320px" },
                maxWidth: { tablet: "412px" },
              }}
            >
              <StoreInfoCard store={selectedStore} onClose={() => setSelectedStore(undefined)} />
            </Box>
          )}
          <Box sx={{ position: "absolute", bottom: 12, left: 12 }}>
            <Box
              component="img"
              src="https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDiscLight_onlyWords.svg"
              sx={{
                maxWidth: { md: "256px", mobile: "112px" },
                filter: "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
              }}
            />
          </Box>
          <ClusteredStorePins stores={visibleStores ?? []} setSelectedStore={setSelectedStore} />
        </StoreMap>
      </Stack>
      <Box
        sx={{
          my: 4,
          display: "grid",
          gridAutoFlow: "column",
          width: "fit-content",
          alignItems: "center",
          px: 2,
          gap: 4,
        }}
      >
        <Map fontSize="large" sx={{ width: 48, height: 48, color: "primary.main" }} />

        <Stack gap={0.5}>
          <Stack
            direction="row"
            sx={{
              fontSize: "28px",
              fontWeight: 500,
              letterSpacing: "-0.02em",
              color: "secondary.main",
            }}
            alignItems="center"
          >
            Get on the Map
          </Stack>
          <Box sx={{ fontSize: "16px", fontWeight: 300, color: "grey.600" }}>
            Add Your Store to Our TechDisc Locator
          </Box>
        </Stack>

        <Box
          component={Link}
          to="mailto:help@techdisc.com?subject=Store%20Map%20Request"
          sx={{
            px: 2,
            py: 1.5,
            color: "white",
            textDecoration: "none",
            "&:hover": {
              color: "white",
              textDecoration: "none",
              backgroundColor: (theme) => darken(theme.palette.primary.main, 0.2),
            },
            backgroundColor: "primary.main",
            borderRadius: 1,
            width: "fit-content",
          }}
        >
          Contact Us
        </Box>
      </Box>
    </Stack>
    // </ErrorBoundary>
  );
};

export default StoreLocatorRoute;
