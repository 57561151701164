import { AnimatePresence } from "framer-motion";
import StockShots from "../../stock-shots/StockShots";
import { ThrowSessionProvider } from "../../context/ThrowSessionProvider";
import { useUser } from "../../../hooks/useUser";
import useLive from "../../../hooks/useLive";
import { LIVE_USER_ID } from "../../../dashboard/liveEvents";
import { ErrorBoundary } from "../../ErrorBoundary";
import { MAX_SESSION_THROWS, MIN_SESSION_THROWS } from "../../stock-shots/config";

const RouteContent = ({ userId }: { userId: string }) => {
  return (
    <ThrowSessionProvider
      userId={userId}
      initialState={{
        minThrows: MIN_SESSION_THROWS,
        maxThrows: MAX_SESSION_THROWS,
      }}
    >
      <AnimatePresence>
        <StockShots userId={userId} />
      </AnimatePresence>
    </ThrowSessionProvider>
  );
};

function CreateStockShotRoute() {
  const [{ userId, trueUserId, externalUserId }, userLoading] = useUser();
  const { isLiveRoute } = useLive();

  if (externalUserId) {
    return (
      <ErrorBoundary eventName="external_user_create_stock_shot_error">
        {userLoading ? null : <RouteContent userId={externalUserId} />}
      </ErrorBoundary>
    );
  }

  if (isLiveRoute) {
    return (
      <ErrorBoundary eventName="live_user_create_stock_shot_error">
        {userLoading ? null : <RouteContent userId={LIVE_USER_ID} />}
      </ErrorBoundary>
    );
  }

  if (trueUserId) {
    return (
      <ErrorBoundary eventName="user_create_stock_shot_error">
        {userLoading ? null : <RouteContent userId={trueUserId} />}
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary eventName="default_user_create_stock_shot_error">
      {userLoading ? null : <RouteContent userId={userId} />}
    </ErrorBoundary>
  );
}

export default CreateStockShotRoute;
