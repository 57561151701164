import { Divider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LayoutGroup } from "framer-motion";
import { StockShotIntro } from "./StockShotIntro";
import { Black, Medium, Strong } from "../../Text";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import { ShotType, ThrowStyle } from "../../../model/stockShot";
import DiscAutocompleteInput from "../../discs/DiscAutocompleteInput";
import { Handedness } from "../../../model/UserSettings";
import ChangeDetector from "../../dialogs/ChangeDetector";
import { useCallback } from "react";

function GettingStartedStep() {
  const { t } = useTranslation();
  const methods = useFormContext();
  const throwStyles = [
    {
      label: t("shotTypes.hyzer"),
      value: ShotType.Hyzer,
    },
    {
      label: t("shotTypes.anhyzer"),
      value: ShotType.Anhyzer,
    },
    {
      label: t("shotTypes.neutral"),
      value: ShotType.Neutral,
    },
    {
      label: t("shotTypes.grenade"),
      value: ShotType.Grenade,
    },
    {
      label: t("shotTypes.thumber"),
      value: ShotType.Thumber,
    },
    {
      label: t("shotTypes.tomahawk"),
      value: ShotType.Tomahawk,
    },
  ];

  const unsavedChanges = useCallback(() => methods.formState.isDirty, [methods.formState.isDirty]);
  return (
    <>
      <ChangeDetector
        predicate={unsavedChanges}
        title={t("confirmStockShotCancel")}
        message={t("loseProgressMessage")}
      />
      <LayoutGroup>
        <Stack
          sx={{ width: "100%", gap: { mobile: 6, tablet: 4 }, mb: 4 }}
          direction={{ mobile: "column", tablet: "row-reverse", laptop: "column" }}
        >
          <StockShotIntro />
          <Divider
            sx={{ display: { mobile: "none", tablet: "block", laptop: "none" } }}
            orientation="vertical"
            flexItem
          />
          <Stack
            direction="column"
            gap={{ mobile: 4, tablet: 4 }}
            flexBasis={{ tablet: "60%", laptop: "auto" }}
          >
            <Stack gap={1}>
              <Black size={{ mobile: 20, tablet: 28 }} color="grey.600" spacing="dense">
                {t("setup")}
              </Black>
              <Medium
                sx={{
                  lineHeight: { mobile: "1.6em", tablet: "1.6em" },
                  letterSpacing: { mobile: "0.01em", tablet: "0.01em" },
                }}
                size={14}
                color="grey.600"
              >
                {t("throwProfile.instructions.setup")}
              </Medium>
            </Stack>
            <Stack
              sx={{ width: "100%" }}
              direction={{ mobile: "column", laptop: "row" }}
              gap={{ mobile: 4, laptop: 12 }}
            >
              <Stack gap={2}>
                <Strong size={{ mobile: 18, tablet: 20 }} color="grey.600">
                  {t("throwingHand")}
                </Strong>
                <Controller
                  name="handedness"
                  control={methods.control}
                  render={({ field }) => (
                    <ToggleButtonGroup exclusive {...field}>
                      <ToggleButton value={Handedness.LEFT}>{t("left")}</ToggleButton>
                      <ToggleButton value={Handedness.RIGHT}>{t("right")}</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Stack>
              <Stack gap={2}>
                <Strong size={{ mobile: 18, tablet: 20 }} color="grey.600">
                  {t("throwStyle")}
                </Strong>
                <Controller
                  name="throwStyle"
                  control={methods.control}
                  render={({ field }) => (
                    <ToggleButtonGroup exclusive {...field}>
                      <ToggleButton value={ThrowStyle.Backhand}>{t("backhand")}</ToggleButton>
                      <ToggleButton value={ThrowStyle.Forehand}>{t("forehand")}</ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Stack>
              <Stack gap={2}>
                <Strong size={{ mobile: 18, tablet: 20 }} color="grey.600">
                  {t("shotType")}
                </Strong>
                <DiscAutocompleteInput
                  data={throwStyles.map((style) => style.label)}
                  methods={methods}
                  name="shotType"
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </LayoutGroup>
    </>
  );
}

export default GettingStartedStep;
