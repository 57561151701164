import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Query, QueryConstraint, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebaseApp } from "../../../firebaseConfig";
import { AnalysisSet, AnalysisType } from "../../../model/throwSummary";
import { exportJSONToCSV } from "../../../utils/csvUtil";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useUser } from "../../../hooks/useUser";
import { throwAnalysisConverter } from "../../../firebase/converters/analysisSet";
import { getAnalysisSetCollection } from "../../../firebase/collections";

export default function TestDriveEmailList() {
  const [{ userId }] = useUser();

  const q = useMemo(() => {
    const q1: Query = getAnalysisSetCollection(userId);
    const filters: QueryConstraint[] = [];
    filters.push(where("type", "==", AnalysisType.KIOSK));
    filters.push(orderBy("createTime", "desc"));

    logEvent(getAnalytics(firebaseApp), "analysis_sets_query");
    return query(q1, ...filters).withConverter(throwAnalysisConverter);
  }, [userId]);

  const [value, isLoading] = useCollection(q);

  const sets =
    value?.docs.map((v) => {
      return { ...v.data(), id: v.id } as AnalysisSet;
    }) ?? [];
  const dateFormatter = new Intl.DateTimeFormat("en", { dateStyle: "short" });

  const csvSets = sets.map((set) => ({
    id: set.id,
    visitDate: dateFormatter.format(set.createTime.toDate()),
    email: set.email,
  }));

  const columns: GridColDef[] = [
    {
      field: "visitDate",
      minWidth: 150,
      align: "right",
      headerAlign: "right",
      renderHeader() {
        return (
          <Typography variant="subtitle1" color="grey.800" fontWeight={500} sx={{}}>
            Visit Date
          </Typography>
        );
      },
    },
    {
      field: "email",
      flex: 80,
      renderHeader() {
        return (
          <Typography variant="subtitle1" color="grey.800" fontWeight={500} sx={{}}>
            Email Address
          </Typography>
        );
      },
    },
  ];

  return (
    <Stack gap={4} height="30.7rem">
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Button
          sx={{ display: "flex", gap: 1 }}
          variant="outlined"
          onClick={() => {
            exportJSONToCSV(csvSets, "TechDiscEmailList");
          }}
        >
          <FileDownloadIcon fontSize="small" />
          Download CSV
        </Button>
      </Stack>
      <DataGrid
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
          },
        }}
        slots={{
          columnUnsortedIcon: null,
          columnSortedAscendingIcon: () => <ArrowUpward height={1} width={1} />,
          columnSortedDescendingIcon: () => <ArrowDownward height={1} width={1} />,
        }}
        columns={columns}
        rows={csvSets}
        loading={isLoading}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        autoHeight
      />
    </Stack>
  );
}
