import { getUserThrowSummaries } from "../collections";
import { createStockShot } from ".";
import { StockShot } from "../converters/stockShot";
import { limit, orderBy, query, getDocs } from "firebase/firestore";
import { averageThrowMetrics } from "../../utils/math";
import { ShotType, ThrowStyle } from "../../model/stockShot";
import { Handedness } from "../../model/UserSettings";

export const createDebugStockShot = async (userId: string) => {
  const throwSummariesSnapshot = await getDocs(
    query(getUserThrowSummaries(userId), orderBy("throwTime", "desc"), limit(5)),
  );
  const throwSummaries = throwSummariesSnapshot.docs.map((d) => d.data());
  console.log(throwSummaries);
  if (throwSummaries.length === 0) {
    return;
  }
  const debugStockShot: StockShot = {
    throwStyle: ThrowStyle.Backhand,
    shotType: ShotType.Hyzer,
    handedness: Handedness.RIGHT,
    avg: averageThrowMetrics(throwSummaries),
    throwIds: throwSummaries.map((throwSummary) => throwSummary.id),
  };

  await createStockShot(userId, debugStockShot);
};
