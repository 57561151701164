import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UserRecord, getAuthClaims } from "./PermissionsManager";

export function UserLookupForm(props: { setUser: (user: UserRecord) => void }) {
  const { setUser } = props;

  const lookupSchema = React.useMemo(() => {
    return yup.object({
      email: yup.string().optional(),
      userId: yup.string().optional(),
    });
  }, []);

  const getUser = async (payload: FieldValues) => {
    const { email, userId } = payload;
    if (!email && !userId) return;
    const result = await getAuthClaims(email, userId);
    setUser(result.user);
  };
  const { handleSubmit, register, formState } = useForm({
    resolver: yupResolver(lookupSchema),
  });

  return (
    <Stack component="form" gap={2} onSubmit={handleSubmit(getUser)} sx={{ width: "100%" }}>
      <TextField
        label="User Email"
        inputProps={{ ...register("email") }}
        autoComplete="inspect-user-email"
      />
      <TextField
        label="User ID"
        inputProps={{ ...register("userId") }}
        autoComplete="inspect-user-id"
      />
      <Button
        type="submit"
        variant="primary"
        disabled={!formState.isValid || !formState.isDirty || formState.isSubmitting}
      >
        Inspect
      </Button>
    </Stack>
  );
}
