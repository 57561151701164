import { addDoc } from "firebase/firestore";
import { StockShot, stockShotConverter } from "../converters/stockShot";
import { getUserStockShots } from "../collections";

export const createStockShot = async (userId: string, stockShot: StockShot): Promise<StockShot> => {
  const shotsRef = getUserStockShots(userId);

  if (shotsRef) {
    const docRef = (await addDoc(shotsRef, stockShot)).withConverter(stockShotConverter);

    return docRef.id;
  }
};
