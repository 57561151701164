import { useSyncExternalStore } from "react";

// Custom hook to get and set a value in localStorage
function useLocalStorage(key: string, initialValue: string): [string, (newValue: string) => void] {
  // Initialize the state
  const getLocalStorageValue = () => {
    const storedValue = localStorage.getItem(key);
    return storedValue ?? initialValue;
  };

  // Subscribe to changes in localStorage
  const subscribe = (onStoreChange: () => void) => {
    const onStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        onStoreChange();
      }
    };
    window.addEventListener("storage", onStorageChange);
    return () => window.removeEventListener("storage", onStorageChange);
  };

  // Read value from localStorage
  const state = useSyncExternalStore(subscribe, getLocalStorageValue);

  // Set value in localStorage
  const setState = (newValue: string) => {
    localStorage.setItem(key, newValue);
    dispatchEvent(new StorageEvent("storage", { key, newValue }));
  };

  return [state, setState];
}

export default useLocalStorage;

// Custom hook to get and set a value in sessionStorage
function useSessionStorage(
  key: string,
  initialValue: string,
): [string, (newValue: string) => void] {
  // Initialize the state
  const getSessionStorageValue = () => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ?? initialValue;
  };

  // Subscribe to changes in sessionStorage
  const subscribe = (onStoreChange: () => void) => {
    const onStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        onStoreChange();
      }
    };
    window.addEventListener("storage", onStorageChange);
    return () => window.removeEventListener("storage", onStorageChange);
  };

  // Read value from sessionStorage
  const state = useSyncExternalStore(subscribe, getSessionStorageValue);

  // Set value in sessionStorage
  const setState = (newValue: string) => {
    sessionStorage.setItem(key, newValue);
    dispatchEvent(new StorageEvent("storage", { key, newValue }));
  };

  return [state, setState];
}

export { useSessionStorage, useLocalStorage };
