import usePublicThrowSet from "../../hooks/usePublicThrowSet";
import { ErrorBoundary } from "../ErrorBoundary";
import { ShareAnalyzeDashboard } from "../analysis/AnalyzeDashboard";
import NotFound from "../layout/NotFound";
import { useParams } from "react-router-dom";

function ShareThrowSetRoute() {
  const { userId, throwSetId } = useParams();
  const [{ throws, throwSet }, throwsLoading] = usePublicThrowSet({ userId, throwSetId });
  return throwsLoading ? null : userId && throws?.length ? (
    // <ErrorBoundary eventName="shared_throw_set_error">
    <ShareAnalyzeDashboard throwSet={throwSet} throws={throws} throwsLoading={throwsLoading} />
  ) : (
    // </ErrorBoundary>
    <NotFound />
  );
}

export default ShareThrowSetRoute;
