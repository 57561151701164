import { Box, Button, ButtonGroup, Divider, IconButton, Paper, Stack } from "@mui/material";
import React from "react";
import { Black, Heavy, Medium, Strong, Text } from "../Text";
import { DiscClass, DiscAppearance, DiscPreset } from "../../model/discs";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { ContentCopy, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import DiscRender from "./DiscRender";
import { TECHDISC_COLOR } from "../../colors";
import { useTranslation } from "react-i18next";
import Delete from "@mui/icons-material/Delete";
import DeleteDiscConfirmDialog from "../dialogs/DeleteDiscConfirmDialog";

export interface DiscPresetCardProps {
  index?: number;
  userId: string;
  preset: DiscPreset;
}
const cardVariants: Variants = {
  initial: { y: 5, opacity: 0.5, transition: { duration: 0.05 } },
  animate: { y: 0, opacity: 1, transition: { ease: "easeIn", duration: 0.05 } },
  exit: { y: 5, opacity: 0.5, transition: { duration: 0.1 } },
};

export default function DiscPresetCard({ index, userId, preset }: DiscPresetCardProps) {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      <Paper
        component={motion.div}
        variant="outlined"
        sx={{
          width: "100%",
          minWidth: "186px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
          borderColor: "grey.400",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            py: 0.75,
            backgroundColor: "grey.50",
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            borderBottom: "1px solid",
            borderColor: "grey.300",
          }}
        >
          <Heavy
            as="div"
            sx={{
              userSelect: "none",
              fontSize: "16px",
              color: "grey.800",
              textWrap: "nowrap",
              textAlign: "center",
              overflow: "hidden", // Prevents text from spilling out
              textOverflow: "ellipsis", // Adds ellipsis when text overflows
              whiteSpace: "nowrap", // Keeps the text in a single line
              width: "100%", // Ensures the text has a boundary to respect
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            }}
            spacing="dense"
          >
            {preset.name}
          </Heavy>
        </Stack>
        <Stack
          alignItems="center"
          gap={{ mobile: 1, sm: 2 }}
          sx={{
            width: "100%",
            height: "100%",
            py: 2,
            px: { mobile: 1, md: 0 },
            gap: { mobile: 1, md: 2.5 },
            flexDirection: { mobile: "row", mobileMax: "column" },
          }}
        >
          {preset.colors ? (
            <DiscRender
              width={120}
              height={120}
              discClass={preset.class}
              colors={preset.colors}
              appearance={preset.appearance}
              shadowSize={3}
            />
          ) : (
            <DiscRender
              width={120}
              height={120}
              discClass={DiscClass.Putter}
              colors={{ primary: "#FFFFFF", secondary: TECHDISC_COLOR.DARKER_BLUE }}
              appearance={DiscAppearance.Burst}
              shadowSize={3}
            />
          )}
          <Stack
            sx={{
              gap: 2,
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis", // Adds ellipsis when text overflows
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Strong
                as="div"
                sx={{
                  userSelect: "none",
                  fontSize: "14px",
                  color: "grey.700",
                  textWrap: "nowrap",
                  textAlign: "center",
                  overflow: "hidden", // Prevents text from spilling out
                  textOverflow: "ellipsis", // Adds ellipsis when text overflows
                  whiteSpace: "nowrap", // Keeps the text in a single line
                  width: "100%", // Ensures the text has a boundary to respect
                }}
              >
                {preset.plastic ? preset.plastic : ""} {preset.mold}
              </Strong>
              <Medium
                as="div"
                sx={{
                  userSelect: "none",
                  fontSize: "14px",
                  color: "grey.700",
                  textWrap: "nowrap",
                  textAlign: "center",
                  overflow: "hidden", // Prevents text from spilling out
                  textOverflow: "ellipsis", // Adds ellipsis when text overflows
                  whiteSpace: "nowrap", // Keeps the text in a single line
                  width: "100%", // Ensures the text has a boundary to respect
                }}
              >
                {preset.manufacturer ? preset.manufacturer : ""}
              </Medium>
            </Stack>
            <Stack
              direction="row"
              width="100%"
              sx={{
                justifyContent: "space-evenly",
                userSelect: "none",
              }}
            >
              <Stack alignItems="center" justifyContent="center">
                <Black color="secondary.main" size={20}>
                  {preset.flightNumbers.speed}
                </Black>
                <Medium size={12} color="grey.600">
                  {t("flightNumbers.speed.short")}
                </Medium>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Black color="secondary.main" size={20}>
                  {preset.flightNumbers.glide}
                </Black>
                <Medium size={12} color="grey.600">
                  {t("flightNumbers.glide.short")}
                </Medium>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <Black color="secondary.main" size={20}>
                  {preset.flightNumbers.turn}
                </Black>
                <Medium size={12} color="grey.600">
                  {t("flightNumbers.turn.short")}
                </Medium>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <ButtonGroup
          component={Stack}
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            backgroundColor: "grey.100",
            borderTop: "1px solid",
            borderColor: "grey.300",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            py: 1,
          }}
        >
          <DeleteDiscConfirmDialog
            userId={userId}
            discPreset={preset}
            anchor={(props) => (
              <Button
                sx={{
                  color: "grey.500",
                  "&:hover": {
                    color: "error.main",
                    backgroundColor: "grey.100",
                  },
                }}
                variant="secondary"
                {...props}
              >
                <Delete sx={{ fontSize: "20px" }} />
              </Button>
            )}
          />
          <Button
            component={Link}
            to={`/discs/new`}
            state={{ presetTemplate: preset }}
            sx={{
              color: "grey.500",
              "&:hover": {
                color: "primary.main",
                backgroundColor: "grey.100",
              },
            }}
            variant="secondary"
          >
            <ContentCopy sx={{ fontSize: "20px" }} />
          </Button>
          <Button
            component={Link}
            to={`/discs/edit/${preset.id}`}
            sx={{
              color: "grey.500",
              "&:hover": {
                color: "primary.main",
                backgroundColor: "grey.100",
              },
            }}
            variant="secondary"
          >
            <Edit sx={{ fontSize: "20px" }} />
          </Button>
        </ButtonGroup>
      </Paper>
    </AnimatePresence>
  );
}
