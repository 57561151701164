import type { StoreMetadata } from "@/firebase/converters/leaderboardStoreMetadata";
import type { Marker } from "@googlemaps/markerclusterer";
import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import { useCallback } from "react";

export type StorePinProps = {
  store: StoreMetadata;
  onClick: (store: StoreMetadata) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
  isSelected: boolean;
};

/**
 * Wrapper Component for an AdvancedMarker for a single tree.
 */
export const StorePin = (props: StorePinProps) => {
  const { store, onClick, setMarkerRef, isSelected } = props;

  const map = useMap();
  const handleClick = useCallback(() => onClick(store), [onClick, store]);
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => setMarkerRef(marker, store.id),
    [setMarkerRef, store.id],
  );

  return (
    <AdvancedMarker
      position={{ lat: store.geo?.latitude ?? 0, lng: store.geo?.longitude ?? 0 }}
      ref={ref}
      onClick={handleClick}
      zIndex={0 - (store.geo?.latitude ?? 0)}
    >
      {store.isPremium ? (
        <svg
          width="28"
          height="44"
          viewBox="0 0 28 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_178_128)">
            <g filter="url(#filter0_f_178_128)">
              <path
                d="M4 14.9824C4 7.00458 10.0022 4.98242 14 4.98242C17.9978 4.98242 24 7.00458 24 14.9824C24 22.9603 17.3051 39.0173 14 39.0173C10.6949 39.0173 4 22.9603 4 14.9824Z"
                fill={isSelected ? "#1C2127" : "#2A81DE"}
              />
              <path
                d="M14 4.48242C11.9303 4.48242 9.31324 5.00371 7.2005 6.59098C5.06375 8.19628 3.5 10.8502 3.5 14.9824H4.5C4.5 11.1368 5.93735 8.79073 7.80115 7.39049C9.68895 5.97221 12.0719 5.48242 14 5.48242V4.48242ZM24.5 14.9824C24.5 10.8502 22.9363 8.19628 20.7995 6.59098C18.6868 5.00371 16.0697 4.48242 14 4.48242V5.48242C15.9281 5.48242 18.311 5.97221 20.1988 7.39049C22.0627 8.79073 23.5 11.1368 23.5 14.9824H24.5ZM14 39.5173C14.6045 39.5173 15.1894 39.1602 15.7152 38.6815C16.2563 38.1889 16.8146 37.494 17.3722 36.6621C18.4892 34.9953 19.6504 32.7066 20.701 30.1838C22.7966 25.1512 24.5 19.0699 24.5 14.9824H23.5C23.5 18.8728 21.8559 24.8089 19.7778 29.7993C18.7415 32.288 17.6092 34.5122 16.5415 36.1054C16.0066 36.9035 15.4997 37.5254 15.042 37.9421C14.5691 38.3726 14.2218 38.5173 14 38.5173V39.5173ZM3.5 14.9824C3.5 19.0699 5.20338 25.1512 7.29902 30.1838C8.34959 32.7066 9.51076 34.9953 10.6278 36.6621C11.1854 37.494 11.7437 38.1889 12.2848 38.6815C12.8106 39.1602 13.3955 39.5173 14 39.5173V38.5173C13.7782 38.5173 13.4309 38.3726 12.958 37.9421C12.5003 37.5254 11.9934 36.9035 11.4585 36.1054C10.3908 34.5122 9.25849 32.288 8.22218 29.7993C6.14409 24.8089 4.5 18.8728 4.5 14.9824H3.5Z"
                fill={isSelected ? "#1C2127" : "#2A81DE"}
              />
            </g>
            <path
              d="M4 14.9824C4 7.00458 10.0022 4.98242 14 4.98242C17.9978 4.98242 24 7.00458 24 14.9824C24 22.9603 17.3051 39.0173 14 39.0173C10.6949 39.0173 4 22.9603 4 14.9824Z"
              fill={isSelected ? "#1C2127" : "#2A81DE"}
            />
            <path
              d="M14 4.48242C11.9303 4.48242 9.31324 5.00371 7.2005 6.59098C5.06375 8.19628 3.5 10.8502 3.5 14.9824H4.5C4.5 11.1368 5.93735 8.79073 7.80115 7.39049C9.68895 5.97221 12.0719 5.48242 14 5.48242V4.48242ZM24.5 14.9824C24.5 10.8502 22.9363 8.19628 20.7995 6.59098C18.6868 5.00371 16.0697 4.48242 14 4.48242V5.48242C15.9281 5.48242 18.311 5.97221 20.1988 7.39049C22.0627 8.79073 23.5 11.1368 23.5 14.9824H24.5ZM14 39.5173C14.6045 39.5173 15.1894 39.1602 15.7152 38.6815C16.2563 38.1889 16.8146 37.494 17.3722 36.6621C18.4892 34.9953 19.6504 32.7066 20.701 30.1838C22.7966 25.1512 24.5 19.0699 24.5 14.9824H23.5C23.5 18.8728 21.8559 24.8089 19.7778 29.7993C18.7415 32.288 17.6092 34.5122 16.5415 36.1054C16.0066 36.9035 15.4997 37.5254 15.042 37.9421C14.5691 38.3726 14.2218 38.5173 14 38.5173V39.5173ZM3.5 14.9824C3.5 19.0699 5.20338 25.1512 7.29902 30.1838C8.34959 32.7066 9.51076 34.9953 10.6278 36.6621C11.1854 37.494 11.7437 38.1889 12.2848 38.6815C12.8106 39.1602 13.3955 39.5173 14 39.5173V38.5173C13.7782 38.5173 13.4309 38.3726 12.958 37.9421C12.5003 37.5254 11.9934 36.9035 11.4585 36.1054C10.3908 34.5122 9.25849 32.288 8.22218 29.7993C6.14409 24.8089 4.5 18.8728 4.5 14.9824H3.5Z"
              fill="#BBDBF9"
            />
            <path
              d="M13.9563 11.0996L13.9563 16.126M13.9563 16.126V21.0996M13.9563 16.126L19 16.126M13.9563 16.126L9 16.126"
              stroke="white"
              strokeWidth="2.5"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_178_128"
              x="-0.5"
              y="0.482422"
              width="29"
              height="43.0347"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_178_128" />
            </filter>
            <clipPath id="clip0_178_128">
              <rect width="28" height="44" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="28"
          height="44"
          viewBox="0 0 28 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 14.9824C4 7.00458 10.0022 4.98242 14 4.98242C17.9978 4.98242 24 7.00458 24 14.9824C24 22.9603 17.3051 39.0173 14 39.0173C10.6949 39.0173 4 22.9603 4 14.9824Z"
            fill={isSelected ? "#1C2127" : "white"}
          />
          <path
            d="M14 4.48242C11.9303 4.48242 9.31324 5.00371 7.2005 6.59098C5.06375 8.19628 3.5 10.8502 3.5 14.9824H4.5C4.5 11.1368 5.93735 8.79073 7.80115 7.39049C9.68895 5.97221 12.0719 5.48242 14 5.48242V4.48242ZM24.5 14.9824C24.5 10.8502 22.9363 8.19628 20.7995 6.59098C18.6868 5.00371 16.0697 4.48242 14 4.48242V5.48242C15.9281 5.48242 18.311 5.97221 20.1988 7.39049C22.0627 8.79073 23.5 11.1368 23.5 14.9824H24.5ZM14 39.5173C14.6045 39.5173 15.1894 39.1602 15.7152 38.6815C16.2563 38.1889 16.8146 37.494 17.3722 36.6621C18.4892 34.9953 19.6504 32.7066 20.701 30.1838C22.7966 25.1512 24.5 19.0699 24.5 14.9824H23.5C23.5 18.8728 21.8559 24.8089 19.7778 29.7993C18.7415 32.288 17.6092 34.5122 16.5415 36.1054C16.0066 36.9035 15.4997 37.5254 15.042 37.9421C14.5691 38.3726 14.2218 38.5173 14 38.5173V39.5173ZM3.5 14.9824C3.5 19.0699 5.20338 25.1512 7.29902 30.1838C8.34959 32.7066 9.51076 34.9953 10.6278 36.6621C11.1854 37.494 11.7437 38.1889 12.2848 38.6815C12.8106 39.1602 13.3955 39.5173 14 39.5173V38.5173C13.7782 38.5173 13.4309 38.3726 12.958 37.9421C12.5003 37.5254 11.9934 36.9035 11.4585 36.1054C10.3908 34.5122 9.25849 32.288 8.22218 29.7993C6.14409 24.8089 4.5 18.8728 4.5 14.9824H3.5Z"
            fill={isSelected ? "#BBDBF9" : "#2A81DE"}
          />
          <circle cx="14" cy="14.9824" r="4" fill={isSelected ? "#BBDBF9" : "#2A81DE"} />
        </svg>
      )}
    </AdvancedMarker>
  );
};
