import { Box, Stack } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import FlightNumberBox from "../flight/FlightNumberBox";
import { Strong } from "../Text";
import DiscRender from "./DiscRender";
import { useTranslation } from "react-i18next";
import { DiscPresetFields } from "./DiscPresetForm";

export type ReviewDiscFormProps = {
  methods: UseFormReturn<DiscPresetFields>;
};

function ReviewDiscForm(props: ReviewDiscFormProps) {
  const { methods } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          mobile: "repeat(1, minmax(0, 1fr))",
          tablet: "repeat(3, minmax(0, 1fr))",
        },
        columnGap: 4,
        rowGap: 4,
        alignItems: "center",
        justifyItems: "stretch",
        height: "100%",
      }}
    >
      <Stack alignItems="center" gap={2}>
        <DiscRender
          discClass={methods.getValues("class")}
          appearance={methods.getValues("appearance")}
          colors={methods.getValues("colors")}
          height={{ md: 256, mobile: 192 }}
          width={{ md: 256, mobile: 192 }}
        />
        <Strong color="grey.800" size={20}>
          {methods.getValues("nickname")}
        </Strong>
      </Stack>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0,1fr))",
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          justifyContent="space-evenly"
          alignItems="flex-end"
          sx={{ width: "100%", height: "100%", minHeight: "196px" }}
        >
          <Strong color="grey.500">Plastic</Strong>
          <Strong color="grey.500">Mold</Strong>
          <Strong color="grey.500">Manufacturer</Strong>
          <Strong color="grey.500">Wear</Strong>
          <Strong color="grey.500">Flight Plate</Strong>
        </Stack>
        <Stack
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ width: "100%", height: "100%", minHeight: "156px" }}
        >
          <Strong color="secondary.main">{methods.getValues("plastic") || "—"}</Strong>
          <Strong color="secondary.main">{methods.getValues("mold") || "—"}</Strong>
          <Strong color="secondary.main">{methods.getValues("manufacturer") || "—"}</Strong>
          <Strong color="secondary.main">
            {methods.getValues("wear") ? t(`discWear.${methods.getValues("wear")}`) : "—"}
          </Strong>
          <Strong color="secondary.main">
            {methods.getValues("flightPlate")
              ? t(`discFlightPlate.${methods.getValues("flightPlate")}`)
              : "—"}
          </Strong>
        </Stack>
      </Box>
      <Stack sx={{ justifySelf: "center", width: "100%", alignItems: "center" }} gap={2}>
        <Box
          sx={{
            borderRadius: 1,
            color: "secondary.main",
            backgroundColor: "grey.50",
            border: "1px solid",
            borderColor: "grey.400",
            textAlign: "center",
            width: "100%",
            maxWidth: "256px",
            py: 1,
          }}
        >
          <Strong>{t(`discClass.proper.${methods.getValues("class")}_one`)}</Strong>
        </Box>
        <FlightNumberBox variant="primary" flightNumbers={methods.getValues("flightNumbers")} />
      </Stack>
    </Box>
  );
}

export default ReviewDiscForm;
