import { updateDoc } from "firebase/firestore";
import { getThrowSummary } from "../docs";
import { throwSummaryConverter } from "../converters/throwSummary";
import { ThrowSummary } from "../../model/stockShot";

export const updateThrowSummary = async (
  userId: string,
  throwId: string,
  updatedData: Partial<ThrowSummary>,
): Promise<void> => {
  const summaryRef = getThrowSummary(userId, throwId)?.withConverter(throwSummaryConverter);

  if (summaryRef) {
    await updateDoc(summaryRef, updatedData);
  }
};
