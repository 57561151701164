import { Container, Stack, Typography } from "@mui/material";
import StripePricingTable from "../leaderboard/StripePricingTable";
import NotFound from "./NotFound";
import { useGlobal } from "../providers/GlobalProvider";

export default function Unauthorized() {
  const { isStore } = useGlobal();
  return (
    <Container sx={{ pt: 4, gap: 5, display: "flex", flexDirection: "column" }}>
      {isStore ? (
        <>
          <Stack gap={2}>
            <Typography
              fontSize={32}
              color="primary"
              fontFamily={"Days One"}
              textTransform={"uppercase"}
              textAlign={"center"}
              letterSpacing={-0.5}
            >
              Looks like you&apos;ve landed in an exclusive zone!
            </Typography>
            <Typography variant="subtitle1" fontSize={20} textAlign={"center"}>
              To unlock this feature and enjoy the full flight of our premium features, upgrade your
              membership and let your TechDisc experience soar to new heights!
            </Typography>
          </Stack>
          <StripePricingTable />
        </>
      ) : (
        <NotFound />
      )}
    </Container>
  );
}
