import { useMemo } from "react";

import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { getAnalysisDoc } from "../summaryUtils";
import { ThrowSummary } from "../model/throwSummary";
import { collection, documentId, getFirestore, query, where } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { throwSummaryConverter } from "../firebase/converters/throwSummary";
import { ThrowAnalysis } from "../firebase/converters/analysisSet";

export default function usePublicThrowSet({
  userId,
  throwSetId,
}: {
  userId?: string;
  throwSetId?: string;
}): [{ throws: ThrowSummary[]; throwSet: ThrowAnalysis }, boolean] {
  const docId = useMemo(() => {
    if (userId && throwSetId) {
      return getAnalysisDoc(userId, throwSetId);
    }
    return null;
  }, [throwSetId, userId]);
  const [throwSet, throwSetLoading] = useDocumentData<ThrowAnalysis>(docId);

  const throwsHalfPt1 = throwSet?.ids?.slice(0, Math.min(throwSet.ids.length, 15)) ?? [];

  const throwsHalfPt2 =
    throwsHalfPt1?.length && throwsHalfPt1?.length === 15 ? throwSet?.ids?.slice(14, -1) : null;
  const throwsHalfPt2Filters = [];

  const throwPt1Query =
    throwsHalfPt1?.length && throwsHalfPt1?.length > 0
      ? query(
          collection(getFirestore(firebaseApp), "users/" + userId + "/throw-summary").withConverter(
            throwSummaryConverter,
          ),
          where(documentId(), "in", throwsHalfPt1),
        )
      : null;

  if (throwsHalfPt2) {
    throwsHalfPt2Filters.push(where(documentId(), "in", throwsHalfPt2));
  }
  const throwPt2Query =
    throwsHalfPt2?.length && throwsHalfPt2?.length > 0
      ? query(
          collection(getFirestore(firebaseApp), "users/" + userId + "/throw-summary").withConverter(
            throwSummaryConverter,
          ),
          ...throwsHalfPt2Filters,
        )
      : null;

  const [throwsPt1, throwsPt1Loading] = useCollectionData<ThrowSummary>(throwPt1Query, {
    initialValue: [],
  });
  const [throwsPt2, throwsPt2Loading] = useCollectionData<ThrowSummary>(throwPt2Query, {
    initialValue: [],
  });

  const throwsLoading = throwSetLoading || throwsPt1Loading || throwsPt2Loading;
  const throws = [...(throwsPt1 ?? []), ...(throwsPt2 ?? [])];
  return [{ throws, throwSet }, throwsLoading];
}
