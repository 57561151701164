import { Variants } from "framer-motion";

export const containerVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1, // Reduced stagger time for quicker animations
      // ease: "anticipate"
    },
  },
};

export const itemVariants: Variants = {
  hidden: { y: 10, opacity: 0 }, // Reduced movement for quicker rendering
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2, // Reduced duration for quicker animations
      ease: "easeIn",
    },
  },
};
