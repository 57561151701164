import { useTopLeaderboard } from "../../leaderboard/leaderboardUtils";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { LIVE_USER_ID } from "../../../dashboard/liveEvents";
import { EventLeaderboard } from "./EventLeaderboard";
import { LiveEventLeaderboardTable } from "../../tables/LiveEventLeaderboardTable";
import { Stack, Container } from "@mui/material";
import { Heavy } from "../../Text";

function LiveLeaderboardRoute() {
  const leaderboard = useTopLeaderboard(LIVE_USER_ID);
  const [entriesDisplayed] = useLocalStorage("live:leaderboard-entries", "5");

  return (
    <Container sx={{ my: 2 }}>
      <Stack gap={4}>
        <EventLeaderboard leaderboard={leaderboard} entriesDisplayed={entriesDisplayed} />
        <Stack height="100%" gap={1}>
          <Heavy size={20} color="grey.800" letterSpacing="-0.08rem">
            LATEST DRIVES
          </Heavy>
          <LiveEventLeaderboardTable />
        </Stack>
      </Stack>
    </Container>
  );
}

export default LiveLeaderboardRoute;
