import { Tune } from "@mui/icons-material";
import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import { TableSearchProps } from "./ThrowTable";
import { Dialog, DialogContent } from "@mui/material";
import ThrowFilters from "./ThrowFilters";
import { useState } from "react";
import Title from "../dashboard/Title";

export default function ThrowsToolbar(props: TableSearchProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Paper sx={{ width: "100%", p: 1, pl: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Title variant="secondary">Filter</Title>
        <Stack direction="row" alignItems="center" gap={2}>
          <Select
            value={props.limit}
            label="Limit"
            onChange={(e) => {
              props.setLimit(e.target.value as number);
            }}
            size="small"
            variant="standard"
          >
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={250}>250</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
            <MenuItem value={2000}>2000</MenuItem>
          </Select>
          <InputLabel> throws</InputLabel>
          <IconButton onClick={handleOpen} sx={{ aspectRatio: "1/1", width: "56px" }}>
            <Tune />
          </IconButton>
        </Stack>
      </Stack>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogContent sx={{ px: 4, py: 4 }}>
          <ThrowFilters {...props} />
        </DialogContent>
      </Dialog>
    </Paper>
  );
}
