import Papa from "papaparse";

export function exportJSONToCSV<T = object>(jsonArray: T[], filename: string) {
  const csv = Papa.unparse(jsonArray);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
