import type { LiveEvent } from "@/firebase/converters/events";
import { Box } from "@mui/material";
import { ReactNode, useCallback } from "react";
import { Link, useRouteLoaderData } from "react-router-dom";
import { currentEvent } from "../../dashboard/liveEvents";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";
import { ThrowSummary as ThrowAnalysis } from "../../model/throwSummary";
import { useGlobal } from "../providers/GlobalProvider";
import { Medium } from "../Text";

type ThrowTypeCellProps = {
  children: ReactNode;
  throwSet: ThrowAnalysis;
};
export const ThrowSetCell = (props: ThrowTypeCellProps) => {
  const { throwSet, children } = props;
  const { isInspectRoute, featureFlags } = useGlobal();
  const [{ trueUserId }] = useUser();
  const { isLiveRoute } = useLive();
  const { activeEvent } = useRouteLoaderData("root") as { activeEvent: LiveEvent };

  const getThrowLink = useCallback(
    (throwSet: ThrowAnalysis) => {
      const link = `/throws/sets/${throwSet.id}`;
      if (isInspectRoute) {
        return `/admin/inspect${link}`;
      }
      if (isLiveRoute || (activeEvent && !trueUserId)) {
        return `/live/${currentEvent?.event}${link}`;
      }
      return link;
    },
    [isInspectRoute, isLiveRoute, trueUserId, activeEvent],
  );

  return (
    <Medium spacing="dense">
      <Box
        component={Link}
        sx={{
          fontWeight: 400,
          color: "primary.main",
          textDecoration: "none",
          "&:visited": {
            textDecoration: "none",
          },
          "&:hover": { color: "primary.dark", textDecoration: "none" },
        }}
        // Prevent the event from triggering row expansion
        onClick={(e) => e.stopPropagation()}
        to={getThrowLink(throwSet)}
      >
        {children}
      </Box>
    </Medium>
  );
};
