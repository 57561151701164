import React from "react";

import { Variants, motion } from "framer-motion";

export default function RouteLayout(props: {
  children: React.ReactNode;
  containerVariants: Variants;
}) {
  const { children, containerVariants } = props;
  return (
    <motion.div variants={containerVariants} initial="hidden" animate="visible">
      {children}
    </motion.div>
  );
}
