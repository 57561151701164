import { uploadBytes } from "firebase/storage";
import { getStorageRef } from "../../dashboard/dashboardUtils";
import { getUserId } from "../../summaryUtils";

const CDN_URL = "https://storage.googleapis.com/throw-log.appspot.com";
const UPLOAD_METADATA = { cacheControl: "public,max-age=3600" };

export const uploadUserProfilePhoto = async (photo: File) => {
  const userId = getUserId();
  const fileExtension = photo.name.split(".").at(-1);
  const storage = getStorageRef(
    `/user-assets/${userId}/profile/photo-${new Date().getTime()}.${fileExtension}`,
  );

  const {
    metadata: { fullPath },
  } = await uploadBytes(storage, photo, UPLOAD_METADATA);

  return getCdnUrlsFromFullPath(fullPath);
};

export const uploadStoreLogo = async (logo: File) => {
  const userId = getUserId();
  const fileExtension = logo.name.split(".").at(-1);
  const storage = getStorageRef(
    `/user-assets/${userId}/store-square/logo-${new Date().getTime()}.${fileExtension}`,
  );

  const {
    metadata: { fullPath, ...meta },
    ...rest
  } = await uploadBytes(storage, logo, UPLOAD_METADATA);
  return getCdnUrlsFromFullPath(fullPath);
};

const getCdnUrlsFromFullPath = (fullPath: string) => {
  const parts = fullPath.split(".");
  const fileUrl = parts.slice(0, -1).join(".");

  return {
    200: `${CDN_URL}/${fileUrl}_200x200.png`,
    512: `${CDN_URL}/${fileUrl}_512x512.png`,
  };
};
