import { FieldErrors, useFormContext } from "react-hook-form";
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, createRef } from "react";
import { ErrorMessage } from "@hookform/error-message";
import DeleteIcon from "@mui/icons-material/Delete";
import { Photo } from "@mui/icons-material";

export type BrandFormProps = {
  onSelectLogo: (logo: File) => void;
  onDeleteLogo: () => void;
};
export default function BrandForm(props: BrandFormProps) {
  const { onSelectLogo, onDeleteLogo } = props;
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const logoUrl = watch("logoUrl");
  const fileRef = createRef<HTMLInputElement>();

  return (
    <Stack direction="row" gap={3}>
      <Stack justifyContent="space-between" flexBasis={"60%"}>
        <Stack gap={3}>
          <Stack gap={1.5}>
            <FormControl>
              <FormLabel htmlFor="storeName" sx={{ mb: 1 }}>
                Display Name
              </FormLabel>
              <TextField id="storeName" size="small" inputProps={register("storeName")} />
              <FormErrorText field="storeName" errors={errors} />
            </FormControl>
          </Stack>
          <Stack gap={1.5}>
            <FormControl>
              <FormLabel htmlFor="name" sx={{ mb: 1 }}>
                Website
              </FormLabel>
              <TextField size="small" inputProps={register("siteUrl")} placeholder="https://" />
              <FormErrorText field="siteUrl" errors={errors} />
            </FormControl>
          </Stack>
        </Stack>
        <Typography variant="subtitle2" color="primary">
          All information provided on this page will be publicly visible.
        </Typography>
      </Stack>
      <Stack gap={3} flexBasis={"40%"} alignItems={"center"}>
        <Stack gap={1.5}>
          <FormControl sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Badge
              variant="standard"
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{ color: "grey.700" }}
              badgeContent={
                <IconButton
                  disabled={!logoUrl}
                  onClick={() => onDeleteLogo()}
                  size="small"
                  color="inherit"
                  sx={{
                    bgcolor: (theme) => theme.palette.grey[300],
                    "&:disabled": {
                      bgcolor: (theme) => theme.palette.background.paper,
                      color: "grey.400",
                    },
                    "&:hover": {
                      color: (theme) => theme.palette.error.main,
                      bgcolor: "grey.200",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <Box
                component={Avatar}
                variant="square"
                width="200px"
                height="200px"
                sx={{
                  aspectRatio: "1/1",
                }}
                src={logoUrl}
              >
                <Photo fontSize="large" />
              </Box>
            </Badge>
            <Button onClick={() => fileRef.current?.click()} variant="outlined">
              Upload
              <Input
                size="small"
                inputProps={{ accept: "image/*", ref: fileRef }}
                type="file"
                sx={{
                  display: "none",
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const file = e.target?.files?.item(0);
                  if (file) {
                    onSelectLogo(file);
                  }
                }}
              />
            </Button>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
}

const FormErrorText = (props: { field: string; errors: FieldErrors }) => (
  <ErrorMessage
    name={props.field}
    errors={props.errors}
    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
  />
);
