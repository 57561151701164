import { Box, Stack } from "@mui/material";
import FlightNumberQuadrant from "./FlightNumberQuadrant";
import { FlightNumbers } from "../../model/throwSummary";

function FlightNumberBox(props: {
  flightNumbers: FlightNumbers;
  variant?: "primary" | "secondary";
}) {
  const { flightNumbers, variant = "primary" } = props;
  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 128px)",
        gridTemplateRows: "repeat(2, 128px)",
      }}
    >
      <FlightNumberQuadrant variant={variant} metric="SPEED" value={flightNumbers.speed} />
      <FlightNumberQuadrant variant={variant} metric="GLIDE" value={flightNumbers.glide} />
      <FlightNumberQuadrant variant={variant} metric="TURN" value={flightNumbers.turn} />
      <FlightNumberQuadrant
        variant={variant}
        metric="FADE"
        value={
          flightNumbers
            ? Math.max(0, flightNumbers.turn + Math.round(flightNumbers.speed) / 4 + 1 / 2)
            : 0
        }
      />
    </Stack>
  );
}

export default FlightNumberBox;
