import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
} from "@mui/material";
import DiscClassDropdown from "./DiscClassDropdown";
import { useOutletContext } from "react-router-dom";
import { DiscsRouteOutletContext } from "../layout/DiscLayout";
import { FieldValues, useFormContext } from "react-hook-form";
import useNotify from "../../hooks/useNotify";
import { saveUserDiscPreset } from "./actions";
import { DiscClass } from "../../model/discs";

function MigratePresetsModal({ userId }: { userId: string }) {
  const notify = useNotify();
  const methods = useFormContext();
  const { legacyPresets } = useOutletContext<DiscsRouteOutletContext>();

  const onSubmit = async (fields: FieldValues) => {
    try {
      await Promise.all(
        Object.entries(fields as Record<string, DiscClass>).map(([id, discClass]) => {
          const preset = legacyPresets.find((p) => p.id === id);
          if (preset) {
            return saveUserDiscPreset(userId, {
              id,
              class: discClass,
              name: preset.name,
              flightNumbers: preset.flightNumbers,
            });
          } else {
            notify("error", "Failed to migrate discs");
          }
        }),
      );
    } catch (e) {
      notify("error", "Failed to migrate discs");
    }

    notify("success", "Discs saved!");
  };
  return (
    <Dialog
      component="form"
      onSubmit={methods.handleSubmit(onSubmit)}
      open={legacyPresets.length > 0}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">Classify Your Discs</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        {legacyPresets.map((old) => (
          <FormControl
            key={old.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FormLabel sx={{ fontWeight: 400 }}>{old.name}</FormLabel>
            <DiscClassDropdown id={old.id} />
          </FormControl>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="primary" type="submit">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MigratePresetsModal;
