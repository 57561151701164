import { DashboardProvider } from "@/dashboard/DashboardContext";
import { addMonths } from "date-fns";
import {
  limit,
  orderBy,
  query,
  QueryConstraint,
  QuerySnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { useEffect, useMemo } from "react";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { DateRange } from "rsuite/esm/DateRangePicker";
import TagManager from "./components/TagManager";
import NotFound from "./components/layout/NotFound";
import { useGlobal } from "./components/providers/GlobalProvider";
import Dashboard from "./dashboard/Dashboard2";
import { getLiveEventsCollection, getUserThrowSummaries } from "./firebase/collections";
import { getThrowSummary } from "./firebase/docs";
import useLive from "./hooks/useLive";
import { Device } from "./model/device";
import { ThrowSummary } from "./model/throwSummary";
import { throwSubject$ } from "./throwSubject";

export type ThrowSummaryAndId = ThrowSummary & { id: string };
type JustTimestamp = { uploadTime: Timestamp };
export type LoadingThrow = { loading: true } & JustTimestamp;
type ThrowSummaryOrLoading = ThrowSummary | LoadingThrow;

export type ThrowSummaryOrLoadingAndId = ThrowSummaryOrLoading & { id: string };

export type ThrowSummaryAndIdAndVideo = ThrowSummaryAndId & {
  videoPath: string;
};

export type SetTags = (tags: string[]) => void; // also used for another func than locked

export interface DashboardProps {
  singleDoc?: boolean;
  value: ThrowSummaryAndId[];
  devices: Map<string, Device>;
  userId: string;
  userLoading: boolean;
  latestThrowLoading: boolean;
  setLockedTags?: SetTags;
  isThrowSet?: boolean;
}

export const SingleDocDashboard = (props: { userId: string; throwId?: string }) => {
  const { userId, throwId } = props;

  const docId = useMemo(() => {
    if (!throwId) {
      return;
    }
    const res = getThrowSummary(userId, throwId);

    return res;
  }, [userId, throwId]);

  const [summary, summaryLoading] = useDocumentData(docId);

  if (summaryLoading) {
    return null;
  }
  if (!summary) {
    return <NotFound />;
  }

  return (
    <DashboardProvider initialState={{ userId, singleDoc: true }}>
      <Dashboard throws={[summary]} />
    </DashboardProvider>
  );
};

export function useCollectionDocData(
  value: QuerySnapshot<ThrowSummary> | undefined,
  filterLoading: boolean = false,
): Partial<ThrowSummary>[] {
  return useMemo(() => {
    const throws =
      value?.docs
        .map((doc) => doc.data())
        .map((throwSummary) => {
          if ("rotPerSec" in throwSummary) {
            return { ...throwSummary, id: throwSummary.id };
          }
          // This throw is loading.
          return {
            loading: true,
            uploadTime: throwSummary.uploadTime,
            id: throwSummary.id,
          };
        }) ?? [];
    if (filterLoading) {
      return throws?.filter((v) => !v.loading);
    }
    return throws;
  }, [value, filterLoading]);
}

export const QueryBasedDashboard = (props: {
  userId: string;
  setThrows?: (throws: ThrowSummary[]) => void;
  addedFilters?: QueryConstraint[];
  noThrowComponent?: JSX.Element;
  setLockedTags?: SetTags;
}) => {
  const DOC_LIMIT = 10;
  const { userId, addedFilters } = props;
  const { setLatestThrowId: setThrow } = useGlobal();

  const q = useMemo(() => {
    const q1 = getUserThrowSummaries(userId);
    const filters: QueryConstraint[] = [];
    if (addedFilters && addedFilters.length) {
      addedFilters.forEach((f) => filters.push(f));
    }
    // unknown userId will yield true --> limit: 10
    filters.push(limit(TagManager.isLoaded(userId) ? DOC_LIMIT : 2000));
    filters.push(orderBy("throwTime", "desc"));
    return query(q1, ...filters);
  }, [userId, addedFilters]);

  const [docs, isLoading, error] = useCollectionData(q);

  useEffect(() => {
    // appropriate timing to do our stuff
    if (!isLoading) {
      const tagsLoaded = TagManager.isLoaded(userId);
      if (!tagsLoaded) {
        TagManager.initSuggestedTags(userId, docs || []);
      } else {
        // can catch up for lost time
        TagManager.updateSuggestedTags(userId, docs || []);
      }
    }
  }, [isLoading, docs, userId]);

  useEffect(() => {
    if (!docs) {
      return;
    }
    throwSubject$.next(docs.slice(0, 1));
    props.setThrows?.(docs.slice(0, DOC_LIMIT));
    if (docs.length && setThrow) {
      setThrow(docs[0].id);
    }
  }, [docs, setThrow, props]);

  if (!docs) {
    return null;
  }

  return (
    <DashboardProvider
      initialState={{
        devices: new Map(),
        userId,
        singleDoc: false,
      }}
    >
      <Dashboard throws={docs.slice(0, DOC_LIMIT)} noThrowComponent={props.noThrowComponent} />
    </DashboardProvider>
  );
};

export const LiveQueryBasedDashboard = (props: {
  userId: string;
  setThrows?: (throws: ThrowSummary[]) => void;
  addedFilters?: QueryConstraint[];
  noThrowComponent?: JSX.Element;
  setLockedTags?: SetTags;
}) => {
  const DOC_LIMIT = 10;
  const { userId: userIdProp, addedFilters } = props;
  const { setLatestThrowId: setThrow } = useGlobal();
  const { isLiveRoute, liveEvent } = useLive();
  const [liveEvents, loading] = useCollectionData(getLiveEventsCollection());
  const event = liveEvents?.find((event) => event.id === liveEvent);
  const userId = event ? userIdProp : "unknown";
  const defaultRange: DateRange = useMemo(
    () =>
      isLiveRoute && event
        ? [event.startDate.toDate(), event.endDate.toDate()]
        : [addMonths(new Date(), -6), new Date()],
    [isLiveRoute, event],
  );

  const q = useMemo(() => {
    const q1 = getUserThrowSummaries(userId);
    const filters: QueryConstraint[] = [];
    if (addedFilters && addedFilters.length) {
      addedFilters.forEach((f) => filters.push(f));
    }
    // unknown userId will yield true --> limit: 10
    filters.push(limit(TagManager.isLoaded(userId) ? DOC_LIMIT : 2000));
    filters.push(orderBy("throwTime", "desc"));
    filters.unshift(where("throwTime", ">=", Timestamp.fromMillis(defaultRange[0].getTime())));
    filters.unshift(where("throwTime", "<=", Timestamp.fromMillis(defaultRange[1].getTime())));
    return query(q1, ...filters);
  }, [userId, defaultRange, addedFilters]);

  const [docs, isLoading, error] = useCollectionData(q);

  useEffect(() => {
    // appropriate timing to do our stuff
    if (!isLoading) {
      const tagsLoaded = TagManager.isLoaded(userId);
      if (!tagsLoaded) {
        TagManager.initSuggestedTags(userId, docs || []);
      } else {
        // can catch up for lost time
        TagManager.updateSuggestedTags(userId, docs || []);
      }
    }
  }, [isLoading, docs, userId]);

  useEffect(() => {
    if (!docs) {
      return;
    }
    props.setThrows?.(docs.slice(0, DOC_LIMIT));
    if (docs.length && setThrow) {
      setThrow(docs[0].id);
    }
  }, [docs, setThrow, props]);

  if (!docs) {
    return null;
  }

  return (
    <DashboardProvider
      initialState={{
        devices: new Map(),
        userId,
        singleDoc: false,
      }}
    >
      <Dashboard throws={docs.slice(0, DOC_LIMIT)} noThrowComponent={props.noThrowComponent} />
    </DashboardProvider>
  );
};
