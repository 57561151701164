import { Chip, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useThrowSession } from "../../context/ThrowSessionProvider";
import ThrowGroup from "./ThrowGroup";
import { ReactNode, useMemo } from "react";
import { ThrowSummary } from "../../../model/throwSummary";
import Title from "../../../dashboard/Title";
import { LayoutGroup } from "framer-motion";
import ThrowHeaderInline from "./ThrowHeaderInline";
import { MAX_STAGED_THROWS } from "../config";

function ReviewThrowsStep({
  stageThrow,
  unstageThrow,
  stagedThrows,
}: {
  stageThrow: (summary?: ThrowSummary) => void;
  unstageThrow: (summary?: ThrowSummary) => void;
  stagedThrows: ThrowSummary[];
}) {
  const { t } = useTranslation();
  const { throws, minThrows } = useThrowSession();
  const unstagedThrows = useMemo(
    () => throws.filter((t) => !stagedThrows.includes(t)),
    [throws, stagedThrows],
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const padAmount = isMobile ? 2 : 3;

  return (
    <LayoutGroup>
      <Stack sx={{ width: "100%", gap: 4, mt: 2, mb: 4 }} direction="column">
        <Stack gap={2}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
            <Title variant="secondary">{t("sessionThrows")}</Title>
            <Chip size="medium" label={unstagedThrows.length} />
          </Stack>
          <ThrowGroupContainer>
            <ThrowGroup
              throws={unstagedThrows}
              padAmount={padAmount}
              onThrowClick={(summary) => {
                stageThrow(summary);
              }}
            />
          </ThrowGroupContainer>
        </Stack>
        <Stack gap={2}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
            <Title variant="secondary">{t("stagedThrows")}</Title>
            <Chip
              size="medium"
              label={`${stagedThrows.length}/${minThrows}`}
              color={stagedThrows.length < (minThrows ?? Infinity) ? "default" : "primary"}
            />
          </Stack>
          <ThrowGroupContainer>
            <ThrowGroup
              throws={stagedThrows}
              padAmount={Math.min(MAX_STAGED_THROWS, throws.length)}
              onThrowClick={(summary) => {
                unstageThrow(summary);
              }}
            />
          </ThrowGroupContainer>
        </Stack>
      </Stack>
    </LayoutGroup>
  );
}

export default ReviewThrowsStep;

const ThrowGroupContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Stack gap={2} sx={{ width: "100%" }}>
      <ThrowHeaderInline />
      <Stack
        direction="column"
        sx={{
          gap: 4,
          minHeight: { mobile: "304px", tablet: "288px" },
          maxHeight: { mobile: "304px", tablet: "288px" },
          overflowY: "auto",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
