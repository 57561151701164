import { Stack, Typography } from "@mui/material";
import React from "react";
import { Black, Heavy, Strong } from "../Text";

export interface FlightNumberProps {
  value?: number;
  metric?: React.ReactNode;
  alt?: boolean;
  variant?: "primary" | "secondary";
}

function FlightNumberQuadrant(props: FlightNumberProps) {
  const { metric, value, alt, variant = "primary" } = props;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        userSelect: "none",
        aspectRatio: "1/1",
        border: "1px solid",
        borderColor: "grey.400",
        color: `${variant}.main`,
        backgroundColor: "white",
        p: 2,
        gap: 1,
        "&:first-of-type": { borderRight: "none", borderTopLeftRadius: 4 },
        "&:last-of-type": { borderTop: "none", borderBottomRightRadius: 4 },
        "&:nth-of-type(3)": {
          borderTop: "none",
          borderRight: "none",
          borderBottomLeftRadius: 4,
        },
        "&:nth-of-type(2)": {
          borderTopRightRadius: 4,
        },
      }}
    >
      <Black size={32}>{value ?? "—"}</Black>
      {metric && <Strong color={"grey.600"}>{metric}</Strong>}
    </Stack>
  );
}

export default FlightNumberQuadrant;
