/**
 * Formats distance numbers using the Intl.NumberFormat.
 * @param value - The distance value to be formatted.
 * @param system - The measurement system ("metric" or "us").
 * @returns The formatted distance string.
 */
export function formatDistance(value: number, prefersMetric: boolean = false): string {
  const unit = prefersMetric ? "meter" : "foot";
  const options: Intl.NumberFormatOptions = {
    style: "unit",
    unit,
    unitDisplay: "short",
    maximumFractionDigits: 0, // No decimal places for distance
  };

  // Use the Intl.NumberFormat for locale-specific formatting
  const formatter = new Intl.NumberFormat("en-US", options);
  return formatter.format(value);
}

/**
 * Formats speed numbers using the Intl.NumberFormat.
 * @param value - The speed value to be formatted.
 * @param system - The measurement system ("metric" or "us").
 * @returns The formatted speed string.
 */
export function formatSpeed(value: number, prefersMetric: boolean = false): string {
  const unit = prefersMetric ? "kilometer-per-hour" : "mile-per-hour";
  const options: Intl.NumberFormatOptions = {
    style: "unit",
    unit,
    unitDisplay: "short",
    maximumFractionDigits: 0, // One decimal place for speed
  };

  // Use the Intl.NumberFormat for locale-specific formatting
  const formatter = new Intl.NumberFormat("en-US", options);
  return formatter.format(value);
}

/**
 * Formats any number with specified options.
 * @param value - The number value to be formatted.
 * @param options - Formatting options including style, unit, and maximumFractionDigits.
 * @returns The formatted number string.
 */
export function formatNumber(value: number, options: Intl.NumberFormatOptions): string {
  if (!value) {
    return "";
  }
  // Default locale is set to 'en-US', can be parameterized as needed
  const formatter = new Intl.NumberFormat("en-US", options);
  return formatter.format(value);
}
