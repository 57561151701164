import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Strong, Text } from "../../Text";
import { useThrowSession } from "../../context/ThrowSessionProvider";
import BoxCounter from "../../BoxCounter";
import CoreMetricsComponent from "../../flight/CoreMetrics";

function ThrowProgress() {
  const { t } = useTranslation();
  const {
    maxThrows,
    minThrows,
    throws,
    latestThrow,
    latestThrowLoading: throwLoading,
  } = useThrowSession();

  return (
    <Stack sx={{ width: "100%", gap: 4, mt: 2, mb: 4 }} alignItems="center" direction="column">
      <CoreMetricsComponent summary={latestThrow} metricsLoading={throwLoading} />
      <Stack
        sx={{
          maxWidth: { mobile: "256px", laptop: "512px" },
          height: "100%",
          alignItems: "center",
          justifyContent: "space-evenly",
          minHeight: 196,
          gap: { mobile: 3, laptop: 6 },
        }}
      >
        <Strong color="grey.600" sx={{ textAlign: "center" }} size={{ mobile: 14, laptop: 16 }}>
          {t("throwProfile.instructions.throwNextStep", { minimum: minThrows })}
        </Strong>
        <BoxCounter min={minThrows} max={maxThrows} current={throws.length} />
        <Text
          color="grey.800"
          size={{ mobile: 14, laptop: 16 }}
          sx={{ textAlign: "center", lineHeight: "20px" }}
          spacing="loose"
        >
          {t("throwProfile.instructions.throwAmount")}
        </Text>
      </Stack>
    </Stack>
  );
}

export default ThrowProgress;
