const displayNames = [
  "Ace Hyzer",
  "Birdie Chains",
  "Eagle Roller",
  "Par Flick",
  "Anhyzer Flight",
  "S-Curve Slinger",
  "Flex Forehand",
  "Fade Fairway",
  "Skip Scoober",
  "Spinout Stalker",
  "Hyzerflip Hawk",
  "Mandoline Maneuver",
  "Roller Ripper",
  "Tomahawk Toss",
  "Tailwind Turner",
  "Albatross Arm",
  "Disc Diver",
  "Bogey Puttman",
  "Glide Green",
  "Pivot Pitcher",
];

export const getDisplayNamePlaceholder = (userId?: string) => {
  if (!userId) {
    return "—";
  }
  return displayNames[hashAndMapNumber(convertStringToNumber(userId))];
};

function hashAndMapNumber(input: number): number {
  if (input < 1 || input > 1000000) {
    throw new Error("Input must be between 1 and 1,000,000");
  }

  // Simple hash function
  let hash = 0;
  let tempInput = input;
  while (tempInput) {
    hash += tempInput % 10;
    tempInput = Math.floor(tempInput / 10);
  }

  // Map the hash to a number between 0 and 19
  return Math.round(hash % displayNames.length);
}

function convertStringToNumber(input: string): number {
  let hash = 0;

  for (let i = 0; i < input.length; i++) {
    hash = (hash + input.charCodeAt(i)) % 1000000;
  }

  // Ensuring the result is within 1 to 1,000,000
  return hash + 1;
}
