import React, { useCallback, useState } from "react";
import { fetchFlight } from "../../utils/throw";
import { FlightPathParams } from "../../dashboard/Simulate3D";
import { TrackPoint } from "../../trackUtils";
import { mphToMps, radPerSecToRpm } from "../../summaryUtils";
import { DiscPreset } from "../../model/discs";
import { ThrowRecord, ThrowSummary } from "../../model/stockShot";
import { splitFlightTrack } from "../../components/flight/XYFlightChart/utils";
import { Vector3 } from "three";
import { DEFAULT_DISC_STATE } from "../../dashboard/SimDashboard";

export const DEFAULT_THROW_STATE: FlightPathParams = {
  v: 55 * mphToMps,
  runup: 0,
  spin: -1000 / radPerSecToRpm,
  uphill_degrees: 5,
  hyzer_degrees: 10,
  nose_up_degrees: -2.5,
  flight_numbers: DEFAULT_DISC_STATE,
  wind_angle: 0,
  wind_speed: 0,
};

const flightValuesToFlightParams = (
  summary: ThrowRecord,
  disc: Pick<DiscPreset, "flightNumbers">,
): FlightPathParams => {
  return {
    ...DEFAULT_THROW_STATE,
    hyzer_degrees: summary.hyzerAngle ?? 0,
    nose_up_degrees: summary.noseAngle ?? 0,
    uphill_degrees: summary.launchAngle ?? 0,
    spin: ((summary.spinRpm ?? 0) * -1) / radPerSecToRpm,
    v: (summary.speedMph ?? 0) * mphToMps,
    flight_numbers: {
      speed: disc.flightNumbers.speed ?? 9,
      glide: disc.flightNumbers.glide ?? 5,
      turn: disc.flightNumbers.turn ?? -1,
      weight: disc.flightNumbers.weight * 0.001 ?? 0.175,
    },
  };
};

export default function useFlightPath() {
  const [flightPath, setFlightPath] = useState<Vector3[]>([]);
  const [track, setTrack] = useState<TrackPoint[]>([]);

  const fetchFlightPath = useCallback(
    async (summary: ThrowRecord, disc: Pick<DiscPreset, "flightNumbers">) => {
      const fetch = async () => {
        const track = await fetchFlight(flightValuesToFlightParams(summary, disc));
        const { flightPath } = splitFlightTrack(track);
        setFlightPath(flightPath);
        setTrack(track);
      };
      fetch();
    },
    [],
  );
  return {
    track,
    flightPath,
    fetchFlightPath,
  };
}
