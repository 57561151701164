import { FlightChart } from "./FlightChart";
import { useMediaQuery } from "@mui/system";
import { useTheme } from "@mui/material";
import useTrackSubscription from "../../../hooks/useTrackSubscription";
import { ThrowSummary } from "../../../model/throwSummary";
import React from "react";

export const XYFlightChart = React.memo(
  function XYFlightChart(props: { userId: string; throwSummary: ThrowSummary }) {
    const { throwSummary, userId } = props;

    const { flightPath } = useTrackSubscription({
      userId,
      throwId: throwSummary.id,
      skip: !throwSummary.distanceFeet,
    });

    return throwSummary.distanceFeet || throwSummary.distanceMeters ? (
      <FlightChart flightPath={flightPath} throwSummary={throwSummary} />
    ) : null;
  },
  (prev, next) => {
    return (
      JSON.stringify(prev.throwSummary) === JSON.stringify(next.throwSummary) &&
      prev.userId === next.userId
    );
  },
);
