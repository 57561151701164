import { getUserAdminDoc } from "@/firebase/docs";
import { doc, getFirestore, runTransaction } from "firebase/firestore";

import {
  getAdminSimulatorCourseCollection,
  getAdminSimulatorPermissions,
  getAdminUserRolesCollection,
} from "@/firebase/collections";

export const getUserPermissions = async (userId: string) => {
  try {
    return await runTransaction(getFirestore(), async (transaction) => {
      const courseIds: Set<string> = new Set();
      const simualtorPermissionIds: Set<string> = new Set(["public"]);

      // If the user is logged in, we need to check their roles for additional permissions
      if (userId) {
        const userAdminDoc = await transaction.get(getUserAdminDoc(userId));

        if (userAdminDoc.exists()) {
          const userRoles = await Promise.all(
            userAdminDoc
              .data()
              ?.roles?.map((roleId) =>
                transaction.get(doc(getAdminUserRolesCollection(), roleId)),
              ) ?? [],
          );

          for (const userRole of userRoles) {
            const roleBasedPermissions = userRole.data()?.permissions?.simulator ?? [];
            for (const permissionId of roleBasedPermissions) {
              simualtorPermissionIds.add(permissionId);
            }
          }

          const userPermissions = userAdminDoc.data()?.permissions?.simulator ?? [];

          for (const permissionId of userPermissions) {
            simualtorPermissionIds.add(permissionId);
          }
        }
      }

      const permissions = await Promise.all(
        Array.from(simualtorPermissionIds).map((id) =>
          transaction.get(doc(getAdminSimulatorPermissions(), id)),
        ),
      );
      // Get the courses that the user is allowed to use, always including the public permissions
      for (const permission of permissions) {
        for (const courseId of permission.data()?.allowedCourses ?? []) {
          courseIds.add(courseId);
        }
      }

      // Format the course data to include the display name
      const courseData = Array.from(courseIds).map(async (courseId) => {
        const course = await transaction.get(doc(getAdminSimulatorCourseCollection(), courseId));
        return {
          id: courseId,
          displayName: course.data()?.name ?? null,
          holes: course.data()?.holes ?? null,
          assetGuid: course.data()?.assetGuid ?? null,
        };
      });

      return await Promise.all(courseData);
    });
  } catch (error) {
    console.error(error);
  }
};
