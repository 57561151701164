import { Box, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { DocumentData, getDoc } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import UserAvatar from "../../components/Avatar";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useStoreMetadata } from "../../hooks/useStoreMetadata";
import { LeaderboardEntryWithId, LeaderboardUserMetadata } from "../../model/leaderboard";
import {
  POWER_USER_IDS,
  getLeaderboardUserMetadata,
  getTrueUserId,
  getUserId,
} from "../../summaryUtils";
import { LeaderboardHeader } from "./LeaderboardHeader";
import LeaderboardStoreInfo from "./LeaderboardStoreInfo";
import { getDisplayNamePlaceholder } from "./fakeNames";
import { LeaderboardResults, useTopLeaderboard } from "./leaderboardUtils";
import NotFound from "../../components/layout/NotFound";
import { leaderboardUserMetadataConverter } from "../../firebase/converters/leaderboardUserMetadata";

const LEADERBOARD_VIEW = {
  STORE: "STORE",
  GLOBAL: "GLOBAL",
};
const getTop3Value: <T>(first: T, second: T, third: T) => (place: number) => T =
  (first, second, third) => (place) => (place === 0 ? first : place === 1 ? second : third);

export type LeaderboardView = (typeof LEADERBOARD_VIEW)[keyof typeof LEADERBOARD_VIEW];

export default function Leaderboards() {
  const isPowerUser = POWER_USER_IDS.has(getTrueUserId());
  const params = useParams();
  const [view, setView] = useLocalStorage("leaderboardView", LEADERBOARD_VIEW.STORE);
  const leaderboardId =
    view === LEADERBOARD_VIEW.GLOBAL ? void 0 : (params.leaderboardId ?? getUserId());
  const leaderboard = useTopLeaderboard(
    isPowerUser ? leaderboardId : (params.leaderboardId ?? getUserId()),
  );
  const [storeMetadata, isMetadataLoading] = useStoreMetadata(params.leaderboardId ?? getUserId());

  if (!isMetadataLoading && !storeMetadata?.isPremium) {
    return <NotFound />;
  }

  return isMetadataLoading ? null : (
    <Stack height={"100%"} flexBasis="100%" flexGrow={2} sx={{ mx: { mobile: 2, tablet: 6 } }}>
      {/* Page Container */}
      <Container
        component={"main"}
        sx={{
          py: 4,
        }}
      >
        <Stack gap={storeMetadata?.name ? 5 : 4} width={"100%"}>
          <LeaderboardHeader
            isPowerUser={isPowerUser}
            setView={setView}
            view={view}
            userId={getUserId()}
          />
          {/* Store/Company Container */}
          <Stack direction={"row"} gap={2}>
            {view === LEADERBOARD_VIEW.STORE && (
              <LeaderboardStoreInfo
                storeMetadata={storeMetadata}
                isMetadataLoading={isMetadataLoading}
              />
            )}
            {view === LEADERBOARD_VIEW.GLOBAL && (
              <>
                <Box
                  component={"img"}
                  src={
                    "https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDisc_Logo_dark.svg"
                  }
                  alt={`TechDisc Logo`}
                  width="100px"
                  height="100px"
                  bgcolor={"white"}
                  sx={{ border: (theme) => `1px solid ${theme.palette.grey[600]}` }}
                />
                <Stack justifyContent={"center"}>
                  <Typography variant="h5">TechDisc</Typography>
                  <Typography variant="subtitle2" color="grey.700">
                    P.O. Box 11464
                  </Typography>
                  <Typography variant="subtitle2" color="grey.700">
                    Overland Park, KS 66207
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          {/* Leaders Container */}
          <Stack direction={{ mobile: "column", lg: "row" }} gap={4}>
            {/* Distance Leaders */}
            <Stack width="100%" gap={2}>
              <Typography variant="h5" sx={{ fontFamily: "Days One" }}>
                DISTANCE
              </Typography>
              <LeaderEntries category="distance" results={leaderboard} />
            </Stack>
            {/* Speed Leaders */}
            <Stack width="100%" gap={2}>
              <Typography variant="h5" sx={{ fontFamily: "Days One" }}>
                SPEED
              </Typography>
              <LeaderEntries category="speed" results={leaderboard} />
            </Stack>
            {/* Spin Leaders */}
            <Stack width="100%" gap={2}>
              <Typography variant="h5" sx={{ fontFamily: "Days One" }}>
                SPIN
              </Typography>
              <LeaderEntries category="spin" results={leaderboard} />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}

const LeaderEntries = (props: {
  results: LeaderboardResults;
  category: "distance" | "speed" | "spin";
}) => {
  const { results, category } = props;
  const [leaders, setLeaders] = useState<(LeaderboardUserMetadata & LeaderboardEntryWithId)[]>([]);
  const resultKey: {
    [key: string]: keyof Pick<LeaderboardResults, "bestDistance" | "bestSpeed" | "bestSpin">;
  } = useMemo(
    () => ({
      distance: "bestDistance",
      speed: "bestSpeed",
      spin: "bestSpin",
    }),
    [],
  );

  const entryKey: {
    [key: string]: keyof Pick<
      LeaderboardEntryWithId,
      "bestDistanceFeet" | "bestSpeedMph" | "bestSpinRpm"
    >;
  } = {
    distance: "bestDistanceFeet",
    speed: "bestSpeedMph",
    spin: "bestSpinRpm",
  };

  const getLeaders = useCallback(async () => {
    setLeaders(
      await Promise.all(
        results[resultKey[category]]
          ?.slice(0, 5)
          ?.map(async (entry) => {
            const ref = getLeaderboardUserMetadata(entry?.toUserId ?? entry?.userId)?.withConverter(
              leaderboardUserMetadataConverter,
            );

            const metadataDoc = await getDoc<LeaderboardUserMetadata, DocumentData>(ref!);
            const metadata = metadataDoc?.data();

            return {
              ...entry,
              ...(metadata ?? {}),
              displayName:
                entry.displayName ||
                metadata?.name ||
                getDisplayNamePlaceholder(entry?.toUserId ?? entry?.userId),
            };
          })
          ?.reduce((top5, leader, index) => top5.with(index, leader), Array(5).fill({})) ?? [],
      ),
    );
  }, [setLeaders, results, category, resultKey]);

  useEffect(() => {
    getLeaders();
  }, [getLeaders]);

  return (
    <Box
      sx={{
        border: "1px solid",
        "&:first-of-type": { borderTop: "1px" },
        "&:last-child": { borderBottom: "0px" },
        borderColor: (theme) => theme.palette.grey[300],
      }}
      width={"100%"}
    >
      {leaders?.map((leader, index) => {
        return (
          <Stack
            direction={"row"}
            px={2}
            gap={2}
            sx={{
              py: 3,
              alignItems: "center",
              bgcolor: leader?.id ? "white" : "grey.50",
              borderBottom: "1px solid",
              borderColor: (theme) => theme.palette.grey[300],
              position: "relative",
            }}
            key={`${category}-${leader.id ?? index}`}
            zIndex={1}
          >
            {/* Position Rank */}
            {index < 3 && (
              <>
                <Box
                  position={"absolute"}
                  left={-10}
                  width="55px"
                  height="35px"
                  zIndex={1}
                  boxShadow={`1px 1px 0px ${getTop3Value(
                    "var(--gold-shadow)",
                    "var(--silver-shadow)",
                    "var(--bronze-shadow)",
                  )(index)}`}
                  bgcolor={getTop3Value("var(--gold)", "var(--silver)", "var(--bronze)")(index)}
                />
                <Box
                  position={"absolute"}
                  top={"calc(50% + 17.5px)"}
                  left={-10}
                  width="10px"
                  height="10px"
                  bgcolor={getTop3Value("var(--gold)", "var(--silver)", "var(--bronze)")(index)}
                  sx={{
                    clipPath: "polygon(0 0, 10px 10px, 10px 0)",
                    // transformOrigin: "bottom left",
                    // transform: "rotate(0.1turn)",
                    backgroundImage: `linear-gradient(135deg, ${getTop3Value(
                      "var(--gold-shadow)",
                      "var(--silver-shadow)",
                      "var(--bronze-shadow)",
                    )(index)} 10%, rgba(0, 0, 0, 0))`,
                  }}
                />
              </>
            )}
            <Stack sx={{ minWidth: "35px" }} zIndex={2}>
              <Typography
                fontWeight={600}
                fontSize={index < 3 ? 24 : 16}
                color={
                  index < 3
                    ? getTop3Value(
                        "var(--gold-text)",
                        "var(--silver-text)",
                        "var(--bronze-text)",
                      )(index)
                    : "grey.700"
                }
                fontFamily={"Days One"}
                sx={{ userSelect: "none" }}
              >
                {index + 1}
              </Typography>
            </Stack>
            {/* User */}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              flexBasis={"50%"}
              flexGrow={1}
            >
              {/* Photo & Name */}
              <UserAvatar
                displayName={(leader?.id && leader?.displayName) ?? ""}
                src={leader?.id && leader?.photo}
              />
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"baseline"}
              justifyContent={"flex-end"}
              flexGrow={1}
              textAlign={"center"}
              gap={0.5}
              flexBasis={"50%"}
            >
              {leader?.id ? (
                <Typography
                  color={leader?.id ? "grey.800" : "grey.600"}
                  sx={{
                    fontSize: getTop3Value("40px", "36px", "32px")(index),
                    userSelect: "none",
                  }}
                  fontWeight={700}
                  letterSpacing={"1px"}
                >
                  {Math.ceil(leader[entryKey[category]] ?? 0)
                    .toString()
                    .padStart(category === "speed" ? 2 : 3, "0")}
                </Typography>
              ) : (
                "—"
              )}
              <Typography
                sx={{ fontSize: "14px", userSelect: "none" }}
                color={leader?.id ? "grey.600" : "grey.600"}
                fontWeight={300}
                fontFamily={"Days One"}
              >
                {category === "distance" ? "FT" : category === "speed" ? "MPH" : "RPM"}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Box>
  );
};
