import { Box, Stack, alpha, useMediaQuery, useTheme } from "@mui/material";
import { AnimationOptions, Chart, ChartConfiguration, registerables } from "chart.js";
import { easingEffects } from "chart.js/helpers";
import AnnotationPlugin from "chartjs-plugin-annotation";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { div, feetToMeters, mul } from "../../../utils/math";

import * as downsampler from "downsample-lttb";
import { calculateFlightPathMetadata, calculateXYScale } from "./utils";
import { useGlobal } from "../../providers/GlobalProvider";
import { formatDistance } from "../../../utils/measurement";
import { ThrowRecord } from "../../../model/stockShot";

const chartPlugins = [...registerables, AnnotationPlugin];

Chart.register(...chartPlugins);

Chart.defaults.font = {
  family: "'HCo Gotham SSm', sans-serif",
  size: 14,
};

const getChart = () => Chart.getChart("2d-canvas");

export type FlightChartProps = {
  throwSummary: ThrowRecord | null;
  flightPath: THREE.Vector3[] | null;
};

export const FlightChart = (props: FlightChartProps) => {
  const { flightPath, throwSummary } = props;
  const { prefersMetric } = useGlobal();
  const chartRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (!flightPath?.length || !chartRef.current) return;

    const DISTANCE_POINT_RADIUS = isMobile ? 4 : 6;
    const APEX_POINT_RADIUS = isMobile ? 8 : 10;
    const DISTANCE_LINE_WIDTH = isMobile ? 4 : 6;

    const { apex, fade, landingZone } = calculateFlightPathMetadata(flightPath);

    // Reduce the number of track points for performance
    const decimatedFlightPath: THREE.Vector3[] = downsampler
      .processData(
        flightPath.map(({ x, y, z }) => [x, y, z]),
        // down sample to roughly the distance per 100ft
        mul(Math.ceil(div(landingZone.x, 100).toNumber()), 100).toNumber(),
      )
      .map((v) => new THREE.Vector3(v[0], v[1], 0));

    const chartData = decimatedFlightPath.map((point) => ({
      x: point.x,
      y: point.y,
    }));

    const totalDuration = Math.ceil((throwSummary?.distanceFeet ?? landingZone.x) / 100) * 500;
    const discLandedLeft = landingZone.y > 0;

    // https://www.chartjs.org/docs/latest/samples/animations/progressive-line-easing.html
    const easing = easingEffects.easeInOutQuad;
    const duration = (ctx) =>
      (easing(ctx.index / decimatedFlightPath.length) * totalDuration) / decimatedFlightPath.length;
    const delay = (ctx) => easing(ctx.index / decimatedFlightPath.length) * totalDuration;
    const previousY = (ctx) =>
      ctx.index === 0
        ? ctx.chart.scales.y.getPixelForValue(100)
        : ctx.chart.getDatasetMeta(ctx.datasetIndex)?.data?.[ctx.index - 1]?.getProps(["y"], true)
            ?.y;
    const animation: AnimationOptions<"line" | "scatter"> = {
      animation: {
        x: {
          easing: "linear",
          duration: duration,
          from: NaN, // the point is initially skipped
          delay(ctx) {
            if (ctx.type !== "data" || ctx.xStarted) {
              return 0;
            }
            ctx.xStarted = true;
            return delay(ctx);
          },
        },
        y: {
          easing: "linear",
          duration: duration,
          from: previousY,
          delay(ctx) {
            if (ctx.type !== "data" || ctx.yStarted) {
              return 0;
            }
            ctx.yStarted = true;
            return delay(ctx);
          },
        },
      },
    };

    const apexHeight = prefersMetric
      ? formatDistance(Math.round(feetToMeters(apex.z)), prefersMetric)
      : formatDistance(Math.round(apex.z));
    const flightDistance = prefersMetric
      ? formatDistance(Math.round(throwSummary?.distanceMeters || landingZone.x), prefersMetric)
      : formatDistance(Math.round(throwSummary?.distanceFeet || landingZone.x));

    const chartOptions: ChartConfiguration<"line" | "scatter"> = {
      data: {
        datasets: [
          {
            type: "line",
            data: chartData,
            tension: 0.5,
            pointRadius: 0,
            borderColor: theme.palette.primary.main,
            borderWidth: DISTANCE_LINE_WIDTH,
            borderCapStyle: "round",
            order: 3,
          },
          {
            type: "scatter",
            data: [apex],
            pointRadius: APEX_POINT_RADIUS,
            pointHoverRadius: APEX_POINT_RADIUS,
            backgroundColor: "#FFFFFF",
            pointHoverBackgroundColor: "#FFFFFF",
            pointHoverBorderWidth: 3,
            pointHitRadius: 16,
            borderColor: theme.palette.primary.main,
            borderWidth: isMobile ? 2 : 3,
            pointStyle: "triangle",
            label: "Apex",
            order: 1,
          },
          {
            type: "scatter",
            data: [landingZone],
            pointRadius: DISTANCE_POINT_RADIUS,
            pointHoverRadius: DISTANCE_POINT_RADIUS,
            pointHoverBackgroundColor: theme.palette.primary.main,
            pointHoverBorderWidth: 3,
            pointHitRadius: 16,
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            borderWidth: 3,
            label: "Distance",
            order: 2,
          },
        ],
      },
      options: {
        ...animation,
        normalized: true,
        resizeDelay: 1,
        layout: {
          padding: 0,
        },
        font: {
          family: "'HCo Gotham SSm', sans-serif",
          size: 16,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            labels: {
              font: {
                size: 14,
                weight: "bold",
              },
              usePointStyle: true,
              generateLabels(chart) {
                return chart.data.datasets
                  .filter((dataset) => Boolean(dataset.label))
                  .map((dataset) => {
                    if (dataset.label === "Apex") {
                      return {
                        text: "Apex",
                        fillStyle: "white",
                        strokeStyle: theme.palette.primary.main,
                        pointStyle: "triangle",
                        lineWidth: 3,
                      };
                    }
                    return {
                      text: "Distance",
                      fillStyle: theme.palette.primary.main,
                      strokeStyle: theme.palette.primary.main,
                      lineWidth: 3,
                      usePointStyle: true,
                      pointStyle: "circle",

                      borderRadius: 5,
                    };
                  });
              },
              filter(item) {
                return item.datasetIndex !== 0;
              },
            },
          },
          tooltip: {
            enabled: false,
            animation: false,
            backgroundColor: "white",
            borderColor: theme.palette.grey[400],
            borderWidth: 1,
            bodyColor: theme.palette.grey[700],
            padding: 10,
            boxPadding: 2,
            // Use the same point style as the chart
            usePointStyle: true,
            displayColors: false,
            callbacks: {
              label: (label) => {
                return label.datasetIndex === 1
                  ? `Apex: ${apexHeight}`
                  : `Distance: ${flightDistance}`;
              },
            },
            // Disable tooltips for the individual track points
            filter(e) {
              return e.datasetIndex !== 0;
            },
            caretPadding: 6,
          },
          annotation: {
            annotations: {
              fadeLine: {
                type: "line",
                drawTime: "afterDraw",
                yMin: landingZone.y,
                yMax: landingZone.y,
                borderWidth: 2,
                borderColor: alpha(theme.palette.primary.main, 0.33),
                borderDash: [10, 30],
                z: -1,
              },
              apex: {
                drawTime: "afterDraw",
                backgroundColor: theme.palette.common.white,
                borderWidth: 1,
                borderColor: theme.palette.primary.main,
                borderRadius: 4,
                color: theme.palette.secondary.main,
                font: {
                  size: isMobile ? 14 : 16,
                  weight: 600,
                },
                type: "label",
                xMin: 0,
                xMax: landingZone.x,
                yMin: fade.min,
                yMax: fade.max,
                xValue: apex.x,
                yValue: apex.y,
                xAdjust: -8,
                yAdjust: apex.y < 0 ? 8 : -8,
                position: { x: "end", y: apex.y < 0 ? "start" : "end" },
                content: `${apexHeight}`,
                padding: isMobile
                  ? { top: 4, right: 8, bottom: 4, left: 8 }
                  : { top: 6, right: 10, bottom: 6, left: 10 },
              },
              distance: {
                drawTime: "afterDraw",
                backgroundColor: theme.palette.common.white,
                borderWidth: 1,
                borderColor: theme.palette.primary.main,
                borderRadius: 4,
                color: theme.palette.secondary.main,
                font: {
                  size: isMobile ? 14 : 16,
                  weight: "bold",
                },
                type: "label",
                xMin: 0,
                xMax: landingZone.x,
                yMin: fade.min,
                yMax: fade.max,
                xValue: landingZone.x,
                yValue: landingZone.y,
                yAdjust: DISTANCE_POINT_RADIUS * (discLandedLeft ? -2 : 2),
                // xAdjust(ctx) {
                //   // Calculate the pixel offset for the x-axis based on the DISTANCE_POINT_RADIUS
                //   // Adjust the label position horizontally to avoid overlapping with the distance point
                //   const pixelOffsetX =
                //     ctx.chart.scales.x.getPixelForValue(landingZone.x) -
                //     ctx.chart.scales.x.getPixelForValue(landingZone.x + DISTANCE_POINT_RADIUS * 2);
                //   return pixelOffsetX;
                // },
                position: {
                  x: discLandedLeft ? "end" : "end",
                  y: discLandedLeft ? "end" : "start", // Adjust the vertical position based on the y value
                },
                content: flightDistance,
                padding: isMobile
                  ? { top: 4, right: 8, bottom: 4, left: 8 }
                  : { top: 6, right: 10, bottom: 6, left: 10 },
              },
            },
          },
        },
        parsing: false,
        scales: calculateXYScale(landingZone, fade, theme, prefersMetric),
      },
    };

    new Chart<"line" | "scatter">(chartRef.current, chartOptions);

    return () => {
      getChart()?.destroy();
    };
  }, [chartRef, flightPath, theme, isMobile, throwSummary, prefersMetric]);

  return (
    <Stack
      id="flight-path-canvas"
      sx={{
        position: "relative",
        minHeight: { mobile: "225px", md: "375px" },
      }}
    >
      {flightPath && flightPath?.length > 0 && (
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Box
              component="canvas"
              id="2d-canvas"
              ref={chartRef}
              sx={{ height: { mobile: "225px", md: "375px" } }}
            ></Box>
          </motion.div>
        </AnimatePresence>
      )}
    </Stack>
  );
};
