import { t } from "i18next";
import { CoreMetrics } from "../firebase/converters/analysisSet";
import { Metric } from "../model/metrics";

export const TD_HQ_USER_ID = "quZSnZ8lKLSVEeBh8A9EpfL8cjv1";
export const TD_HQ_GEO = {
  lat: 38.9850629,
  lng: -94.71204,
};
export const FILTER_THROW_LIMIT_DEFAULT = 250;

export const METRICS_INFO = [
  {
    metric: Metric.Speed,
    label: t("metrics.speed.full"),
    accessor: (metric: CoreMetrics) => metric.speedMph,
  },
  {
    metric: Metric.Spin,
    label: t("metrics.spin.full"),
    accessor: (metric: CoreMetrics) => metric.spinRpm,
  },
  {
    metric: Metric["Off Axis Torque"],
    label: t("metrics.wobble.full"),
    accessor: (metric: CoreMetrics) => metric.launchAngle,
  },
  {
    metric: Metric.Hyzer,
    label: t("metrics.hyzer.full"),
    accessor: (metric: CoreMetrics) => metric.hyzerAngle,
  },
  {
    metric: Metric["Nose Angle"],
    label: t("metrics.nose.full"),
    accessor: (metric: CoreMetrics) => metric.noseAngle,
  },
  {
    metric: Metric["Uphill Angle"],
    label: t("metrics.launch.full"),
    accessor: (metric: CoreMetrics) => metric.launchAngle,
  },
];
