import { useMemo } from "react";
import { useGlobal } from "../providers/GlobalProvider";
import { Device } from "../../model/device";
import { Table, TableData, TableProps } from "./Table";
import * as throwColumns from "./helpers";
import { StockShot } from "../../firebase/converters/stockShot";
import { useTranslation } from "react-i18next";

function StockShotsTable(props: {
  userId: string;
  id: string;
  stockShots?: StockShot[];
  display?: "compact" | "default";
  devices?: Map<string, Device>;
  options?: Partial<TableProps<StockShot>>;
}) {
  const { id, options, stockShots = [] } = props;
  const { t } = useTranslation();
  const { prefersMetric } = useGlobal();

  // Define the columns based on the ThrowSummary model
  const columns = useMemo(() => {
    const columns: any[] = [
      throwColumns.columnStockShotName(),
      throwColumns.columnSpeed(prefersMetric),
      throwColumns.columnHyzer(),
      throwColumns.columnSpin(),
      throwColumns.columnAdvanceRatio(),
      throwColumns.columnNoseAngle(),
      throwColumns.columnLaunchAngle(),
      throwColumns.columnWobble(),
    ];

    return columns;
  }, [prefersMetric]);

  return (
    <Table<StockShot>
      id={id}
      data={stockShots}
      columns={columns}
      context={t("stockShot")}
      options={{ enableRowSelection: true, enableMultiRowSelection: true, ...options }}
    />
  );
}

export default StockShotsTable;
