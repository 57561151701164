import { Container, Stack } from "@mui/material";

<Container sx={{my: 4}}>
<Stack gap={4}>

# TERMS OF SERVICE

Last updated: February, 26 2023

The following Terms of Service (“<u>Terms</u>”) governs the access and use by Users (defined below) of the Site and Services (as such terms are defined below) provided by TechDisc Inc., a Delaware corporation (“<u>Company</u>”, “<u>TechDisc</u>”, “<u>us</u>”, “<u>our</u>”, “<u>we</u>”). This is a legal agreement between you, the User and incorporates by reference the Privacy Policy located at www.techdisc.com/privacy-policy (the “<u>Privacy Policy</u>”, and collectively with these Terms, the “<u>Agreement</u>”); <u>provided</u>, <u>however</u>, that to the extent that there are any conflicts between any of the terms or conditions of these Terms and any of the terms or conditions of the Privacy Policy (provided that the inclusion of additional terms in the Privacy Policy that are not included in these Terms shall not be deemed to constitute a conflict, and such additional terms shall apply to User’s access and use of the Site (defined below) and the Services), the applicable terms and provisions of these Terms shall control.

##### 1. Consent and Use of the Site and Services

By registering to use, using, ordering from, subscribing to, or logging into the website at www.techdisc.com, related subdomains, and the related TechDisc mobile aliplications (collectively the “<u>Site</u>”) and related Services (defined below), you agrees to the terms and conditions of the Agreement, governing User’s access to and use of the Services, including but not limited to limitations of liability, indemnification, and User’s agreement to arbitrate disputes relating to the Site and/or the Services, and User agrees to use commercially reasonable efforts to cause each of its Users to comply with these Terms.

If you are entering into these Terms on behalf of a User that is a company or other legal entity, you represent and warrant that you have the authority to bind such entity to these Terms, in which case the terms “<u>you</u>” or “<u>your</u>” also shall refer to such entity. These Terms are effective as of the date you accept these Terms by accessing the Services (“<u>Effective Date</u>”).

IF YOU DO NOT HAVE SUCH AUTHORITY TO ACCEPT THE AGREEMENT, OR IF YOU DO NOT AGREE WITH THE AGREEMENT, YOU (AND THE ENTITY ON WHOSE BEHALF YOU ARE SEEKING TO ENTER INTO THE AGREEMENT) MAY NOT USE THE SITE OR SERVICES.

##### 2. Use of the Site is limited to persons who are EIGHTEEN (18) years of age or older.

The Site is not directed to persons under the age of 18 and the policy of the Company is NOT to knowingly collect any personal information from persons under the age of 18. If you are under the age of 18, you should leave the Site without providing any information about yourself. By accessing or using the Site, you represent and warrant to us that you are either a legal adult in the jurisdiction in which you reside or, if under the age of majority, have obtained proper consent from a parent or legal guardian to your use of the Site in accordance with these Terms.

##### 3. Definitions

**A**. “Confidential Information” means any information, technical data, or know-how considered proprietary or confidential by either party to these Terms including, but not limited to, either party’s research, services, inventions, processes, specifications, designs, drawings, diagrams, concepts, marketing, techniques, documentation, source code, customer information, personally identifiable information, pricing information, procedures, menu concepts, business and marketing plans or strategies, financial information, and business opportunities disclosed by either party before or after the Effective Date of these Terms, either directly or indirectly in any form whatsoever, including in writing, orally, machine-readable form or through access to either party’s premises.

**B**. “Intellectual Property” means (a) all inventions (whether patentable or unpatentable and whether or not reduced to practice), all improvements thereto, and all patents, patent applications, and patent disclosures, together with all reissuances, continuations, continuations in part, revisions, extensions, and reexaminations thereof, (b) all trademarks, service marks, trade dress, logos, trade names, and corporate names, together with all translations, adaptations, derivations, and combinations thereof and including all goodwill associated therewith, and all applications, registrations, and renewals in connection therewith, (c) all copyrightable works, all copyrights, and all applications, registrations, and renewals in connection therewith, (d) all mask works and all applications, registrations, and renewals in connection therewith, (e) all trade secrets and confidential business information (including ideas, research and development, know how, formulas, compositions, manufacturing and production processes and techniques, technical data, designs, drawings, specifications, customer and supplier lists, pricing and cost information, and business and marketing plans and proposals), (f) all computer software (including data and related documentation) including all computer software applications, source code and objects, models, algorithms, specifications, designs, including all inventions, improvements, discoveries, or developments contained in any software, and all the documentation related thereto, (g) all other proprietary rights, (h) all works derivative of any of the foregoing and (i) all copies and tangible embodiments thereof (in whatever form or medium).

**C**. “Intellectual Property Rights” means all ownership, authorship, license, interest, distribution, contractual and other rights to own, use, possess, hold, develop, license, sublicense, sell, assign, transfer, encumber, distribute, exploit, enjoy, display, publish, reproduce, or otherwise take or refrain from taking any action, with respect to, of, for, over, or relating to any Intellectual Property.

**D**. “Security Emergency” means a violation by User of these Terms that (i) could disrupt (x) TechDisc’s provision of the Services; (y) the business of other subscribers to the Services; or (z) the network or servers used to provide the Services; or (ii) provides unauthorized third-party access to the Services.

**E**. “Services” means the software, applications, dashboard, platform, and/or services provided by TechDisc.

**F**. “Statistical Data” means aggregated and anonymized statistical, transactional, usage and performance information based on and/or related to User’s use of the Services, which does not contain any personally identifying information and is compiled using a sample size large enough to ensure the underlying data cannot be attributed to User.

**G**. “User” shall refer to the person using the Services provided by TechDisc that is entering into the Agreement, and shall also include any present or former agent, representative, independent contractor, employee, servant, attorney and any entity or person who had authority to act on behalf of User.

**H**. “User Content” means any information, text, images, or logos that User uploads, displays, posts, publishes, or otherwise makes available on or submits through the Service (collectively, “submit”), including, without limitation, throw data, contact information, preferences, transactional information, tax data, employee data, and other content provided by User to TechDisc in connection with the Services. User Content excludes Account Information.

##### 4. Limited License & Use of the Site and the Services

**A**. We hereby grant to User a non-exclusive, non-transferable, limited, revocable, worldwide right to use the Site and the Services, solely for such User’s own internal, non-commercial purposes, subject to the terms and conditions of these Terms. All rights not expressly granted to Users are reserved by us and our licensors and sublicensors. In addition, User shall not:

- use, license, sublicense, sell, resell, transfer, assign, distribute, share, or otherwise commercially exploit or make available to any third-party (other than to Users) the Site or the Services in any way;
- modify or make derivative works based upon the Site or the Services;
- embed the Site as a frame from within another website or application;
- access the Site or the Services for purposes of monitoring its availability, performance, functionality, or for any other benchmarking;
- reverse engineer or access the Site or the Services in order to (i) build a competitive product or service, (ii) build a product using ideas, features, functions, or graphics that are similar to those related to the Site or the Services, or (iii) copy any ideas, features, functions or graphics of the Site or the Services;
- send to or store on the Site or the Services any material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs;
- interfere with or disrupt the integrity, security or performance of the Site or the Services, or any data contained therein, or our servers or networks, including, without limitation, by means of submitting a virus to the Site or the Services, overloading, “flooding,” “spamming,” “mail bombing” or “crashing” or “DDoS” attacks;
- attempt to gain unauthorized access to the Site or the Services or any of their related systems or networks;
- take any action that imposes an unreasonably or disproportionately large load on our infrastructure;
- use the Site or the Services for any unlawful purpose;
- violate any local, provincial, state, federal or international rules, regulations, laws or ordinances using the Site or the Services;
- provide any information to us that is false or misleading, that attempts to conceal User’s identity or that User does not have the right to disclose; or
- solicit others to perform or participate in any of the foregoing actions.

**B**. We shall be entitled to adjust the scope of the Site, the Services and the underlying technical infrastructure to reflect the continuing development of the Site, the Services and technical advances.

**C**. User grants to us a non-exclusive, royalty-free right during User’s use of the Services, to use the User Content and Confidential Information for the sole purpose of performing our obligations under these Terms in accordance with the terms of these Terms. Such rights shall include permission for us to generate and publish aggregated and anonymized reports on system usage and User Content trends and type, during and beyond the term of these Terms, provided they do not conflict with the section entitled “Confidentiality.”

##### 5. Content You Submit

**A**. We may, but will not have any obligation to, review, monitor, display, post, store, maintain, accept, or otherwise make use of, any of your User Content, and we may, in our sole discretion, reject, delete, move, re-format, remove or refuse to post or otherwise make use of User Content without notice or any liability to you or any third party in connection with our operation of User Content venues in an appropriate manner. Without limitation, we may do so to address content that comes to our attention that we believe is incomplete, inaccurate, out-of-date or otherwise objectionable or inappropriate, or to enforce the rights of third parties or these Terms. Such User Content submitted by you or others need not be maintained on the Service by us for any period of time, and you will not have the guaranteed right, once submitted, to access, archive, maintain, or otherwise use such User Content on the Service.

**B**. Each time you submit any User Content, you represent and warrant that you are at least the age of majority in the jurisdiction in which you reside and are an authorized officer, employee or agent of any company, corporation, partnership, limited liability company, or other business entity who owns and/or controls any User Content or any elements thereto you submit, and that, as to that User Content: (i) you and/or such entity are the sole author and owner of the Intellectual Property and other rights to the User Content, or you have a lawful right to submit the User Content and grant to us the rights to it that you are granting by these Terms, all without any TechDisc obligation to obtain consent of any third party and without creating any obligation or liability of TechDisc; (ii) the User Content is accurate; (iii) the User Content does not and, as to our permitted uses and exploitation set forth in these Terms, will not infringe any Intellectual Property or other right of any third party (iv) the submission of the User Content does not violate any agreement with any third-party; and (v) the User Content will not violate these Terms, or cause injury or harm to any person.

##### 6. Access to the Services

User is only permitted to access and use the Services once User registers as a User on the Site. Each User is required to provide User’s full legal name, a valid email address, and any other information reasonably requested by TechDisc.

Each User will choose or be provided with a unique identifier to access and use the Services (“Username”). Each Username shall only be used by the applicable User, and shall not be shared with, or used by any other person, including other Users.

All access to and use of the Services via mechanical, programmatic, robotic, scripted or any other automated means not provided as part of the Services is strictly prohibited.

We reserve the right to deny registration of any account or Username at our discretion.

##### 7. User’s Responsibilities

User is responsible for all of User’s activity on the Site and the Services and for all activity occurring under User’s account, and User shall abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with User’s use of the Site and the Services, including those related to data privacy, international communications and the transmission of technical or personal data.

User is solely responsible for all User Content provided to the Site and the Services in connection with User’s use of the Site and Services. TechDisc does not own any User Content provided hereunder; <u>provided</u> that User hereby grants to TechDisc a worldwide, royalty-free, non-exclusive, sublicensable right to use, reproduce, create derivative works of, distribute, perform, transmit and publish all User Content for the purpose of providing the Site and the Services.

##### 8. Account Information, Password and Security

In the course of User’s use of the Site or the Services, we may ask User for certain information, including User’s name, email address, phone number, mailing address, billing address, shipping address, and payment card information (collectively, “Account Information”). If User opens an account or commences any transaction of business at, on or via the Site or the Services, User may be required to complete a registration process by providing certain additional information and registering a Username and password. User’s Account Information must be up-to-date and accurate at all times. Should any such Account Information change, User must update it in User’s user profile. User agrees that User is solely responsible for the accuracy and content of User’s Account Information. User is further responsible for maintaining the confidentiality of Username and password and also for all activities that take place under Username and/or account.

Consistent with and without limiting the scope of provisions limiting liability elsewhere in these Terms, in no event will TechDisc be liable for any indirect or consequential loss or damage of any kind whatsoever resulting from the disclosure of User’s Username and/or password or User’s failure to comply with this section.

User shall notify TechDisc immediately of any unauthorized use of any password or account used for access to the Site or the Services, or any other known or suspected breach of security related to the Site or the Services.

##### 9. Payment, Refunds, and Subscription Changes

If and to the extent that User subscribes for a paid subscription to use one or more functions, features or services of the Site or the Services, or is a purchaser of any other services or any goods or products that are offered on our Site, User will provide TechDisc with a valid credit card for payment of the applicable fees. All fees are exclusive of all federal, state, provincial, municipal, or other taxes which User agrees to pay based on where the User is located. Invoices will include (a) fees and (b) all applicable sales taxes, as amended from time to time, for the jurisdiction in which User is located. In the event of updated tax rates, TechDisc will apply the new tax rate without notice to User.

All prices are subject to change upon notice. Such notice may be provided by an e-mail message to the User, or in the form of an announcement on the Services.

User is responsible for paying all taxes associated with the subscription to and the usage of the Services and the provision of any products, goods or other services on the Site, whether included on an invoice or not. If TechDisc has the legal obligation to pay or collect taxes for which User is responsible under this section, the appropriate amount shall be charged to and paid by User unless User provides TechDisc with a valid tax exemption certificate authorized by the appropriate taxing authority. Any and all payments by or on account of the compensation payable under these Terms shall be made free and clear of and without deduction or withholding for any taxes.

##### 10. Other Fees

In addition to any fees User pays to access and use the Site and the Services, when making payments for the Services, industry-standard transaction fees may apply and will be added to your fees.

##### 11. Cancellation and Termination

Users are solely responsible for canceling User’s subscriptions and may cancel its subscription at any time by accessing the Services and visiting [https://shop.techdisc.com/pages/contact]. The User may be directed, within the Services, to call support to complete the cancellation. Unless and except to the extent we otherwise agree in writing, cancellations shall not be accepted by any other means.

TechDisc in its sole discretion has the right to suspend or discontinue providing the Services to any User without notice for actions that are (a) in violation of these Terms and (b) create a Security Emergency.

##### 12. Representations & Warranties

Each User and each of User’s agents, employees, or contractors acting on behalf of User in entering into these Terms, individually represents and warrants that they have the legal power and authority to enter into these Terms and that they (a) are an individual who is at least 18 years of age; (b) have not falsely identified themselves, nor provided any false information to gain access to the Site or the Services; (c) will provide current, complete and accurate payment and Account Information in connection with User’s use of the Site and the Services; and (d) will promptly update Account Information and other User information, including but not limited to User’s email address and credit card information, so that TechDisc may facilitate User’s requested transactions and contact User as needed.

##### 13. Intellectual Property

**A**. <u>User’s Property</u>. By using the Services, submitting any User Content through the Services,
or providing any User Content to TechDisc, User hereby grants TechDisc a perpetual worldwide,
non-exclusive, royalty-free, fully paid, sublicensable and transferable license to process,
store, modify, combine, reproduce, distribute, display, publicly perform, publicly display,
host, communicate, and prepare derivative works of the User Content in connection with the
Services. User represents and warrants that User has all rights to grant such licenses to
TechDisc without infringement or violation of moral rights or any third-party rights, including
without limitation, any privacy rights, publicity rights, contract rights, or Intellectual
Property Rights.

**B**. <u>Account Information</u>. User represents and warrants that it has complied with all laws
(including, as appropriate, with respect to providing any necessary notices and obtaining any
necessary consents) to permit TechDisc to use the Account Information and any technical
information about its use of the Services for the limited purposes of tailoring the user
experience of the Services to the User, facilitating User’s use of the Services, and
communicating with User. Further, User represents and warrants that it has taken necessary
steps, in compliance with applicable laws, to ensure that TechDisc may use such information to
identify and understand trends in the various interactions with TechDisc’s Services and to
conduct internal business analysis based on meta-data about usage, feature adoption and
forecasting, on an anonymized, aggregated basis.

**C**. TechDisc<u>’s Property</u>. User acknowledges and agrees that all rights, title and interest in and to the Site and the Services are the exclusive property of TechDisc or its affiliates, licensors or suppliers. Unless stated otherwise, TechDisc and its licensors retain all Intellectual Property Rights in and to the Site and the Services, including, without limitation, all logos, graphics, software, algorithms, functionality and content (other than User Content) included in or comprising the Site and the Services. All rights not expressly granted herein are reserved by TechDisc.

**D**. <u>Statistical Data</u>. Notwithstanding anything to the contrary in this Agreement, and
consistent with the activities described in the Privacy Policy, TechDisc may monitor, compile,
analyze, and use Statistical Data. User agrees that TechDisc may make such Statistical Data
publicly available. TechDisc and/or its licensors own all right, title and interest in and to
the Statistical Data and all related software, technology, documentation, and content provided
in connection with, or developed using, the TechDisc, including all Intellectual Property Rights
in the foregoing, and Statistical Data derived from User Content.

**E**. <u>Feedback</u>. User may provide feedback, suggestions, and comments to TechDisc regarding the
Services (“Feedback”). User hereby grants to TechDisc a worldwide, non-exclusive, perpetual,
irrevocable, royalty-free, fully paid, sublicensable and transferable license to use, process,
store, edit, modify, aggregate, combine, reproduce, distribute, display, perform, prepare
derivative works, and otherwise fully exploit such Feedback in any medium or format, whether now
known or later developed.

**F**. <u>Publicity</u>. Unless otherwise agreed by the parties in writing, User hereby agrees that
TechDisc may reference User in marketing and public relations materials, including a press
release announcing User as a customer. User hereby grants TechDisc a nonexclusive, worldwide
license to use and display User’s trademarks, trade names and logos in connection with the
foregoing.

##### 14. Confidentiality

Each party agrees to treat all Confidential Information as confidential and not to use or disclose such Confidential Information except as necessary to perform its obligations under, and in accordance with the terms and provisions of, these Terms. User Content posted on public facing areas of the Services, such as reviews, shall not be considered “Confidential Information” for the purposes of the Agreement.

TechDisc and any third-party vendors and hosting partners it utilizes to provide the Services shall hold User Content in strict confidence and shall not use or disclose User Content except (a) as required to perform their obligations under these Terms; (b) in compliance with our Privacy Policy and the section entitled “Legal Compliance”; or (c) as otherwise authorized by User in writing.

##### 15. Legal Compliance

TechDisc endeavors to protect the Confidential Information to the extent permitted by applicable law. TechDisc reserves the right to provide the Confidential Information to third parties as required and permitted by law (such as in response to a subpoena or court order), and to cooperate with law enforcement authorities in the investigation of any criminal or civil matter.

If TechDisc is required by law to make any disclosure of the Confidential Information that is prohibited or otherwise constrained by these Terms, then TechDisc will provide User with prompt written notice (to the extent permitted by law) prior to such disclosure so that User may seek a protective order or other appropriate relief. Subject to the foregoing sentence, TechDisc may furnish that portion (and only that portion) of the Confidential Information that it is legally compelled or otherwise legally required to disclose.

##### 16. Availability; Support

TechDisc represents and warrants that it will provide the Site and perform the Services in a manner consistent with general industry standards reasonably applicable to the provision thereof. TechDisc will use commercially reasonable efforts to cause the Site and the Services to be available at all times except during scheduled downtime for maintenance and upgrades and extraordinary circumstances or causes beyond our control (such as fire, flood, earthquake, elements of nature or acts of God, acts of war, terrorism, riots, civil disorders, rebellions or revolutions, strikes, lockouts, labor difficulties, pandemics, epidemics, generalized internet interruptions (through denial of service, worms, telecommunications problems or the like)) but does not guarantee, represent or warrant such availability. TechDisc, from time to time, may make enhancements or upgrades to the Site and/or Services which result in the Site and/or Services being unavailable, which TechDisc shall take reasonable steps to schedule so as to minimize the unavailability of the Site and/or Services and user inconvenience.

THE SITE AND THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. TECHDISC IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.

##### 17. Integrations, Third-Party Links

The Services may link to or otherwise allow User to access or use or integrate with third-party providers of products and services (“Third-Party Services”). Such Third-Party Services are not “Services” under these Terms and are not subject to any terms related to the Site or the Services, including related warranties, indemnities, service commitments or other obligations. The availability of any Third-Party Services through the Services does not imply TechDisc’s endorsement of or affiliation with the provider. Access to and use of any Third-Party Services are subject to the separate terms and conditions required by the providers of the Third-Party Services. TechDisc does not control the Third-Party Services and will have no liability to User in connection with any Third-Party Service. TechDisc has no obligation to monitor or maintain any Third-Party Service and may replace, disable, or restrict access to any Third-Party Service or cancel related integrations at any time, without notice. The calculation of downtime of the Site and the Services, if any, does not include the unavailability of any integration to a Third-Party Service.

If a Third-Party Service is enabled for User’s account, please be mindful that Account Information or User Content will be shared with the third-party provider and the purposes for which the provider requires access. We will not be responsible for any use, disclosure, modification or deletion of Account Information or User Content that is transmitted to, or accessed by, a Third-Party Service.

BY USING OR ENABLING ANY THIRD-PARTY SERVICE, USER EXPRESSLY ACKNOWLEDGES THAT ANY LIABILITY AND REMEDIES RELATED TO A THIRD-PARTY SERVICE IS WHOLLY GOVERNED BY THE APPLICABLE THIRD-PARTY AGREEMENT AND TECHDISC DISCLAIMS ALL LIABILITY RELATED TO SUCH THIRD-PARTY SERVICE.

##### 18. Disclaimer of Warranties

TECHDISC AND ITS LICENSORS MAKE NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, NON-INFRINGEMENT, ACCURACY OR COMPLETENESS OF THE SITE, THE SERVICES OR ANY CONTENT. TECHDISC AND ITS LICENSORS DO NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE SITE OR THE SERVICES WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE SITE OR THE SERVICES WILL MEET USER’S REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, SOFTWARE, OR OTHER MATERIAL PURCHASED OR OBTAINED BY USER THROUGH THE SITE OR THE SERVICES WILL MEET USER’S REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS WILL BE CORRECTED, OR (F) THE SERVERS THAT MAKE THE SITE AND THE SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.

THE SITE, THE SERVICES, AND ALL CONTENT THEREON, INCLUDING WITHOUT LIMITATION, OUR SOURCE CODE, CONTENT AND USER CONTENT, AS APPLICABLE, IS PROVIDED STRICTLY ON AN “**AS IS**” AND “**AS AVAILABLE**” BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY TECHDISC AND ITS LICENSORS. TECHDISC MAKES NO REPRESENTATION OR WARRANTY REGARDING ANY TRANSACTIONS SOUGHT TO BE EFFECTED THROUGH USER’S USE OF THE SITE OR THE SERVICES.

##### 19. Limitation of Liability

Except in the case of a violation by TechDisc of its obligations under the section entitled “Confidentiality,” and except as provided in the section entitled “Indemnification by TechDisc”, TechDisc shall not be liable for and User waives the right to claim any loss, injury, claim, liability or damage of any kind resulting in any way from the Services provided to User by TechDisc.

IN NO EVENT SHALL TECHDISC’S AGGREGATE LIABILITY PURSUANT TO THESE TERMS EXCEED THE FEES ACTUALLY PAID BY USER TO TECHDISC IN THE THREE (3) MONTHS PRECEDING THE EVENT GIVING RISE TO THE APPLICABLE CLAIM. IF USER IS USING A FREE, TRIAL, OR OTHER UNPAID VERSION OF THE SERVICES, TECHDISC’S MAXIMUM AGGREGATE LIABILITY SHALL BE $500.00. EXCEPT IN CONNECTION WITH USER’S INDEMNIFICATION OBLIGATIONS, IN NO EVENT SHALL EITHER PARTY AND/OR ITS LICENSORS BE LIABLE UNDER THESE TERMS TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE SITE OR THE SERVICES, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE SITE OR THE SERVICES, OR FOR ANY CONTENT OBTAINED FROM OR THROUGH THE SITE OR THE SERVICES, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE IN THE CONTENT, EVEN IF THE PARTY FROM WHICH DAMAGES ARE BEING SOUGHT OR SUCH PARTY’S LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

User will solely be responsible for any damage and/or loss of User Content contained in User’s technology which occurs as a result of User’s electronic equipment and/or User’s computer system.

##### 20. Additional Rights

Certain states and/or jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental, consequential or certain other types of damages, so the exclusions set forth above may not apply to User.

##### 21. Indemnification by TechDisc

**A**. TechDisc shall defend, indemnify, and hold User harmless against any loss, damage, or costs (including reasonable attorneys’ fees) in connection with claims, demands, suits, or proceedings made or brought against User by a third-party alleging that the Services, or use of the Services as contemplated hereunder, infringes a copyright, a U.S. patent issued as of the date of final execution of these Terms, or a trademark of a third-party or involves the misappropriation of any trade secret of a third-party (each, a “Third-Party Claim”); provided, however, that User:

**B**. promptly give written notice of the Third-Party Claim to TechDisc, and in no event later than five (5) days after learning of the Third-Party Claim (provided, however, that the failure to so notify shall not relieve TechDisc of its indemnification obligations unless TechDisc can show that it was materially prejudiced by such delay and then only to the extent of such prejudice);

**C**. give TechDisc sole control of the defense and settlement of the Third-Party Claim (provided that TechDisc may not settle any Third-Party Claim unless it unconditionally releases User of all liability); and

**D**. provide to TechDisc, at TechDisc’s cost, all reasonable assistance requested by TechDisc.

**E**. TechDisc shall not be required to indemnify User in the event of: (i) modification of the Services by User in conflict with User’s obligations or as a result of any prohibited activity as set forth herein to the extent that the infringement or misappropriation would not have occurred but for such modification; (ii) use of the Services in combination with any other product or service not provided by TechDisc to the extent that the infringement or misappropriation would not have occurred but for such use; or (iii) use of the Services in a manner not otherwise contemplated by these Terms to the extent that the infringement or misappropriation would not have occurred but for such use.

##### 22. Release and Indemnification

In the event User has a dispute with one or more subscribers to the Services, Users (including those User authorizes) of the Site or the Services (including, but not limited to, any dispute regarding any transaction, or user generated content or) or any third-party website or service that may be linked to or otherwise interact with the Site or the Services, including, without limitation, any social media website, application, or service, User hereby agrees to release and forever discharges TechDisc and its agents, directors, shareholders, members, partners, managers, officers, employees, information providers, suppliers, distributors, vendors, attorneys and affiliates (collectively, the “released parties”) from any and all rights, claims, complaints, demands, causes of action, proceedings, liabilities, obligations, legal fees, costs and disbursements of any nature whatsoever, whether known or unknown, which now or hereafter arise from, relate to, or are connected with such dispute and/or User’s use of the Site and the Services, to the fullest extent permitted by law.

User further agrees to indemnify, defend and hold harmless, to the fullest extent permitted by law, the released parties from and against all claims, demands, liabilities, losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from, arising out of or in connection with User’s use, unlawful use or other misuse, or inability to use the Site or the Services, User’s breach of these Terms, or otherwise relating to the business we conduct on the Site or the Services (including, without limitation, any potential or actual communication, transaction or dispute between User and any other third-party), any User Content or Confidential Information posted by User or on User’s behalf to the Site or Services, any use of any service provided by a third-party provider, or any use of a service offered by us that interacts with a third-party website or application, including any social media site or other communications platform (each, an “Indemnifiable Claim”). User shall cooperate as fully as reasonably required in the defense of any Indemnifiable Claim. We reserve the right, at our own expense, to assume the exclusive defense and control of any Indemnifiable Claim, and User shall not settle any Indemnifiable Claim without our written consent. This section survives termination of these Terms.

##### 23. Dispute Resolution

Certain portions of this Section 23 are deemed to be a “written agreement to arbitrate” pursuant to the Federal Arbitration Act. You and TechDisc agree that we intend that this Section 23 satisfies the “writing” requirement of the Federal Arbitration Act. This Section 23 can only be amended by mutual agreement. Where arbitration is not permitted by the mandatory laws in your country of residence, or where the parties have not otherwise agreed to arbitration in accordance with applicable laws, the provisions of Section 23 shall apply to all relevant disputes between you and us.

**A**. <u>First – Try To Resolve Disputes and Excluded Disputes</u>. To the fullest extent permitted by
applicable law, if any controversy, allegation, or claim arises out of or relates to the Site,
the Content, your User Content, these Terms whether heretofore or hereafter arising
(collectively, “Dispute”), or to any of TechDisc’s actual or alleged Intellectual Property
Rights (an “Excluded Dispute”, which includes those actions set forth in Section 23.D), then you
and we agree to send a written notice to the other providing a reasonable description of the
Dispute or Excluded Dispute, along with a proposed resolution of it. Our notice to you will be
sent to you based on the most recent contact information that you provide us. But if no such
information exists or if such information is not current, then we have no obligation under this
Section 23.A. Your notice to us must be sent to: TechDisc Inc., PO Box 11464, Overland Park,
Kansas 66207 Attention: Customer Service. For a period of sixty (60) days from the date of
receipt of notice from the other party, TechDisc and you will engage in a dialogue in order to
attempt to resolve the Dispute or Excluded Dispute, though nothing will require either you or
TechDisc to resolve the Dispute or Excluded Dispute on terms with respect to which you and
TechDisc, in each of our sole discretion, are not comfortable.

**B**. <u>Binding Arbitration</u>. If we cannot resolve a Dispute as set forth in Section 23.A (or
agree to arbitration in writing with respect to an Excluded Dispute) within sixty (60) days of
receipt of the notice, then ANY AND ALL DISPUTES ARISING BETWEEN YOU AND TECHDISC (WHETHER BASED
IN CONTRACT, STATUTE, REGULATION, ORDINANCE, TORT—INCLUDING, BUT NOT LIMITED TO, FRAUD, ANY
OTHER INTENTIONAL TORT OR NEGLIGENCE—COMMON LAW, CONSTITUTIONAL PROVISION, RESPONDEAT SUPERIOR,
AGENCY OR ANY OTHER LEGAL OR EQUITABLE THEORY), WHETHER ARISING BEFORE OR AFTER THE EFFECTIVE
DATE OF THESE TERMS, MUST BE RESOLVED BY FINAL AND BINDING ARBITRATION. THIS INCLUDES ANY AND
ALL DISPUTES BASED ON ANY PRODUCT, SERVICE OR ADVERTISING CONNECTED TO THE PROVISION OR USE OF
THE SITE. The Federal Arbitration Act (“FAA”), not state law, shall govern the arbitrability of
all disputes between TechDisc and you regarding these Terms and the Site. TechDisc and you
agree, however, that Kansas or federal law shall apply to and govern, as appropriate, any and
all claims or causes of action, remedies, and damages arising between you and TechDisc regarding
these Terms and the Site, whether arising or stated in contract, statute, common law, or any
other legal theory, without regard to Kansas’s choice of law principles. A Dispute will be
resolved solely by binding arbitration in accordance with the then-current Commercial
Arbitration Rules of the American Arbitration Association (“AAA”). If a party properly submits
the Dispute to the AAA for formal arbitration and the AAA is unwilling or unable to set a
hearing date within sixty (60) days of the filing of a “demand for arbitration,” then either
party can elect to have the arbitration administered by the Judicial Arbitration and Mediation
Services Inc. (“JAMS”) using JAMS’ streamlined “Arbitration Rules and Procedures,” or by any
other arbitration administration service that you and an officer or legal representative of
TechDisc consent to in writing. If an in-person arbitration hearing is required, then it will be
conducted in the “metropolitan statistical area” (as defined by the U.S. Census Bureau) where
you are a resident at the time the Dispute is submitted to arbitration. You and we will pay the
administrative and arbitrator’s fees and other costs in accordance with the applicable
arbitration rules; but if applicable arbitration rules or laws require TechDisc to pay a greater
portion or all of such fees and costs in order for this Section 22 to be enforceable, then
TechDisc will have the right to elect to pay the fees and costs and proceed to arbitration.

**C**. <u>Limited Time To File Claims</u>. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF YOU OR
WE WANT TO ASSERT A DISPUTE (BUT NOT AN EXCLUDED DISPUTE) AGAINST THE OTHER, THEN YOU OR WE MUST
COMMENCE IT (BY DELIVERY OF WRITTEN NOTICE AS SET FORTH IN SECTION 22(A)) WITHIN ONE (1) YEAR
AFTER THE DISPUTE ARISES — OR IT WILL BE FOREVER BARRED.

**D**. <u>Injunctive Relief</u>. To the fullest extent permitted by applicable law, the foregoing
provisions of this Section 23 will not apply to any legal action taken by TechDisc to seek an
injunction or other equitable relief in connection with, any loss, cost, or damage (or any
potential loss, cost, or damage) relating to the Site, any Content, your User Content and data
and/or TechDisc’s Intellectual Property Rights (including such TechDisc may claim that may be in
dispute), TechDisc’s operations, and/or TechDisc’s products or services.

**E**. <u>No Class Action Matters</u>. To the fullest extent permitted by applicable law, disputes will
be arbitrated only on an individual basis and will not be consolidated with any other
arbitrations or other proceedings that involve any claim or controversy of any other party. But
if, for any reason, any court with competent jurisdiction or any arbitrator selected pursuant to
Section 23.B holds that this restriction is unconscionable or unenforceable, then our agreement
in Section 23.B to arbitrate will not apply and the Dispute must be brought exclusively in court
pursuant to Section 23.G.

**F**. <u>Federal and State Courts in Kansas City, Kansas</u>. Except to the extent that arbitration is
required in Section 23.B, and except as to the enforcement of any arbitration decision or award,
any action or proceeding relating to any Dispute or Excluded Dispute may only be instituted in
state or federal court in Kansas City, Kansas. Accordingly, you and TechDisc consent to the
exclusive personal jurisdiction and venue of such courts for such matters.

##### 24. Other Dispute Resolution Where Arbitration Is Not Available

This Section 24 applies to all users of the Site, if, and only to the extent that, the mandatory laws in your country of residence do not permit arbitration of Disputes in accordance with Section 23 or the parties have not otherwise agreed to arbitration if required by applicable law.

**A**. <u>Section 24 Disputes</u>. To the fullest extent permitted by applicable law, if any
controversy, allegation, or claim (including any non-contractual claim) arises out of or relates
to the Site, the Content, these Terms, whether heretofore or hereafter arising or to any of
TechDisc’s actual or alleged Intellectual Property Rights (collectively, a “Section 24
Dispute”), then you and we agree to send a written notice to the other providing a reasonable
description of the Section 24 Dispute, along with a proposed resolution of it. Our notice to you
will be sent to you based on the most recent contact information that you provide us. But if no
such information exists or if such information is not current, then we have no obligation under
this Section 24.A. Your notice to us must be sent to: TechDisc Inc., PO Box 11464, Overland
Park, Kansas 66207 Attention: Customer Service. Subject to applicable law, for a period of sixty
(60) days from the date of receipt of notice from the other party, TechDisc and you will engage
in a dialogue in order to attempt to resolve the Section 23 Dispute, though nothing will require
either you or TechDisc to resolve the Section 23 Dispute on terms with respect to which you and
TechDisc, in each of our sole discretion, are not comfortable.

**B**. <u>Jurisdiction</u>. The parties agree that the state or federal courts in Johnson County,
Kansas shall have non-exclusive jurisdiction of any Section 24 Dispute, unless otherwise
required by applicable laws of your jurisdiction of residence.

**C**. <u>Governing Law</u>. To the maximum extent permitted by the mandatory laws in your country of
residence, these Terms and any Section 24 Dispute arising out of or in connection with it or its
subject matter or formation (including non-contractual disputes), will be governed by, and
construed in accordance with, the laws of the State of Kansas, without regard to its conflicts
of law provisions.

**D**. <u>Limited Time to File Claims</u>. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF YOU OR
WE WANT TO ASSERT A SECTION 24 DISPUTE AGAINST THE OTHER, THEN YOU OR WE MUST COMMENCE IT (BY
DELIVERY OF WRITTEN NOTICE AS SET FORTH IN SECTION 24) WITHIN ONE (1) YEAR AFTER DISCOVERY OF
THE SECTION 7 DISPUTE ARISES — OR IT WILL BE FOREVER BARRED.

**E**. <u>Injunctive Relief</u>. The foregoing provisions of this Section 24 will not apply to any
legal action taken by TechDisc to seek an injunction or other equitable relief in connection
with, any loss, cost, or damage (or any potential loss, cost, or damage) relating to the Site,
any Content, your User Content and data and/or TechDisc’s Intellectual Property Rights
(including such TechDisc may claim that may be in dispute), TechDisc’s operations, and/or
TechDisc’s products or services.

##### 25. Miscellaneous

**A**. <u>Entire Agreement</u>. The Agreement constitutes the entire agreement between User and
TechDisc and governs User’s use of the Services, superseding any prior agreements between User
and TechDisc (including, but not limited to, any prior versions of these Terms).

**B**. <u>Headings</u>. Headings in these Terms are for reference purposes only and in no way define,
limit, construe or describe the scope or extent of the applicable section to which such heading
applies.

**C**. <u>Waiver</u>. The failure of either party to enforce any provision of these Terms shall not
constitute or be construed as a waiver of such provision or of the right to enforce it at a
later time.

**D**. <u>Severability</u>. If any provision in these Terms is deemed unlawful, void or unenforceable,
then that provision will be deemed severed from these Terms and the remaining provisions shall
remain in full force and effect as if no invalid or unenforceable provision had been part of
these Terms.

**E**. <u>Local Laws and Export Control</u>. The Site and the Services provide services and uses
software and technology that may be subject to United States export controls administered by the
U.S. Department of Commerce, the United States Department of Treasury Office of Foreign Assets
Control, and other U.S. agencies and the export control regulations of other applicable
countries. User acknowledges and agrees that neither the Site nor the Services shall be used,
and none of the underlying information, software, or technology may be transferred or otherwise
exported or re-exported to countries as to which the United States or any other applicable
country maintains an embargo (collectively, “Embargoed Countries”), or to or by a national or
resident thereof, or any person or entity on the U.S. Department of Treasury’s List of Specially
Designated Nationals or the U.S. Department of Commerce’s Table of Denial Orders (collectively,
“Designated Nationals”). The lists of Embargoed Countries and Designated Nationals are subject
to change without notice. By using the Site and/or the Services, User represents and warrants
that User is not located in, under the control of, or a national or resident of an Embargoed
Country or Designated National. User agrees to comply strictly with all U.S. and other
applicable export laws and to assume sole responsibility for obtaining licenses to export or
re-export as may be required. TechDisc and its licensors make no representation that the Site or
the Services is appropriate or available for use in other locations. If User uses the Site
and/or the Services from outside the United States of America, User is solely responsible for
compliance with all applicable laws, including without limitation export and import regulations
of other countries.

**F**. <u>Security</u>. User acknowledges the risk that information and the User Content stored and
transmitted electronically through the Services may be intercepted by third parties. User agrees
to accept that risk and will not hold TechDisc liable for any loss, damage, or injury resulting
from the interception of information. The User Content is stored securely and encrypted. Only
TechDisc, with strict business reasons, may access and transfer the User Content and only in
accordance with the terms and conditions of the Agreement.{" "}

**G**. <u>Third-Party Services</u>. User acknowledges and agrees that TechDisc may use third-party
vendors and hosting partners to provide the necessary hardware, software, networking, storage,
and related technology required to provide the Site and the Services.

**H**. <u>Relationship</u>. Nothing herein shall be deemed to create an agency, partnership, joint
venture, employee-employer or franchise-franchisee relationship of any kind between TechDisc and
User or any other person or entity.

**I**. <u>Assignment; Change in Control</u>. These Terms may not be assigned by User without the prior
written approval of TechDisc but may be assigned without User’s consent by TechDisc to (i) a
parent or subsidiary, (ii) an acquirer of assets, or (iii) a successor by merger, consolidation,
stock sale or similar. Any purported assignment in violation of this section shall be void. Any
actual or proposed change in control of User that results or would result in a direct competitor
of TechDisc directly or indirectly owning or controlling 50% or more of User shall entitle
TechDisc to terminate these Terms for cause immediately upon written notice.

**J**. <u>Call Monitoring and Recording</u>. For quality assurance, TechDisc may record and/or monitor
incoming calls to, and outgoing calls from, TechDisc. By accepting these Terms, User also
consent to any and all call recording and monitoring performed by TechDisc or its agents,
employees and/or affiliates.

**K**. <u>Modification to Terms</u>. TechDisc reserves the right to modify the provisions of these
Terms, the Privacy Policy or any other policies relating to the Site and/or the Services at any
time, effective upon posting of an updated version of these Terms, the Privacy Policy, or such
other policies, if applicable, on the Site. User is responsible for regularly reviewing the Site
to check for changed or updated versions thereof. Continued use of the Site and/or the Services
after any such changes shall constitute User’s consent to such changes. AS OUR SITE AND SERVICES
EVOLVE, THE TERMS AND CONDITIONS UNDER WHICH WE OFFER THE SITE AND SERVICES MAY PROSPECTIVELY BE
MODIFIED AND WE MAY CEASE OFFERING THE SITE AND SERVICES UNDER THE TERMS FOR WHICH THEY WERE
PREVIOUSLY OFFERED. ACCORDINGLY, EACH TIME YOU SIGN IN TO OR OTHERWISE USE THE SITE OR SERVICES
YOU ARE ENTERING INTO A NEW AGREEMENT WITH US ON THE THEN-APPLICABLE TERMS AND CONDITIONS AND
YOU AGREE THAT WE MAY NOTIFY YOU OF ADDITIONAL TERMS BY POSTING THEM ON THE SITE (OR IN ANY
OTHER REASONABLE MANNER OF NOTICE WHICH WE ELECT), AND THAT YOUR USE OF THE SITE OR SERVICES
AFTER SUCH NOTICE CONSTITUTES YOUR GOING FORWARD AGREEMENT TO THE ADDITIONAL TERMS FOR YOUR NEW
USE AND TRANSACTIONS.

**L**. <u>Notice</u>. TechDisc may give notice by means of an electronic mail to User’s e-mail address
on record in User’s Account Information with TechDisc, or by written communication sent by first
class mail or pre-paid post to User’s address on record in User’s Account Information with
TechDisc. Such notice shall be deemed to have been given upon the expiration of 48 hours after
mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if
sent by email). User may give notice to TechDisc (such notice shall be deemed given when
received by TechDisc) at any time by letter to TechDisc delivered by nationally recognized
overnight delivery service or firs-class postage prepaid mail to TechDisc, in either case,
addressed to:

<Stack gap={1}>

TechDisc Inc.

PO Box 11464

Overland Park, Kansas 66207

United States

</Stack>

##### 26. Questions or Additional Information

If you have questions regarding these Terms or wish to obtain additional information, please send an e-mail to admin@techdisc.com.

</Stack>
</Container>
