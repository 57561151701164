import React from "react";
import DiscPresetForm from "../../discs/DiscPresetForm";
import { DiscClass } from "../../../model/discs";
import { useLocation, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { ErrorBoundary } from "../../ErrorBoundary";

export default function CreateDiscRoute() {
  const { discClass } = useParams<{ discClass: DiscClass }>();
  const [{ trueUserId, externalUserId, publicUserId }] = useUser();
  const userId = externalUserId ?? trueUserId ?? publicUserId;

  const { state } = useLocation();

  return (
    <ErrorBoundary eventName="create_disc_error">
      <DiscPresetForm
        userId={userId}
        presetTemplate={state?.presetTemplate}
        discClass={discClass}
      />
    </ErrorBoundary>
  );
}
