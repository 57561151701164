import { ExpandMore, VideocamOutlined } from "@mui/icons-material";
import { Box, Checkbox, Stack } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { motion } from "framer-motion";
import { t } from "i18next";
import { getDeviceColor } from "../../dashboard/dashboardUtils";
import { StockShot } from "../../firebase/converters/stockShot";
import UserSettings from "../../model/UserSettings";
import { Device } from "../../model/device";
import { ThrowSummary } from "../../model/throwSummary";
import { formatNumber } from "../../utils/measurement";
import { getThrowName } from "../../utils/throw";
import { Medium, Strong } from "../Text";
import { ThrowChip } from "./ThrowChip";
import { ThrowSetCell } from "./ThrowSetCell";
import { ThrowTypeCell } from "./ThrowTypeCell";

const throwColumnHelper = createColumnHelper<ThrowSummary>();
const stockShotColumnHelper = createColumnHelper<StockShot>();

export const columnThrowType = (userSettings?: UserSettings) =>
  throwColumnHelper.accessor(
    (row) => getThrowName(row, row?.handedness ?? userSettings?.handedness),
    {
      id: "throwType",
      header: () => <Strong spacing="dense">Throw Type</Strong>,
      minSize: 200,
      size: 250,
      cell: ({ row, getValue }) => (
        <Stack direction="row" gap={1.5} alignItems="center">
          <ThrowTypeCell summary={row.original}>{getValue()}</ThrowTypeCell>
        </Stack>
      ),
    },
  );

export const columnStockShotName = () =>
  stockShotColumnHelper.accessor((row) => row.original, {
    id: "throwStyle",
    header: () => <Strong spacing="dense">Name</Strong>,
    minSize: 200,
    size: 250,
    cell: ({ row }) => (
      <Stack direction="row" gap={1} alignItems="center">
        <ThrowChip summary={row.original.avg} /> {row.original.shotType}
      </Stack>
    ),
  });

export const columnThrowSet = () =>
  throwColumnHelper.accessor((row) => row.title, {
    id: "title",
    header: () => <Strong spacing="dense">Throw Set</Strong>,
    minSize: 200,
    size: 250,
    cell: ({ row, getValue }) => (
      <Stack direction="row" gap={1.5} alignItems="center">
        <ThrowSetCell throwSet={row.original}>{getValue()}</ThrowSetCell>
      </Stack>
    ),
  });

export const columnSpeed = (prefersMetric: boolean) =>
  throwColumnHelper.accessor(
    (row) =>
      formatNumber(prefersMetric ? row.speedKmh : row.speedMph, { maximumFractionDigits: 1 }),
    {
      id: "speed",
      minSize: 100,
      size: 150,
      meta: {
        align: "right",
      },
      header: () => (
        <Stack alignItems="flex-end">
          <Strong spacing="dense">Speed</Strong>
          <Medium spacing="dense" size={12}>
            ({prefersMetric ? t("units.kmh.upper") : t("units.mph.upper")})
          </Medium>
        </Stack>
      ),
    },
  );

export const columnHyzer = () =>
  throwColumnHelper.accessor((row) => row.hyzerAngle?.toFixed(1) ?? "", {
    id: "hyzer",
    minSize: 100,
    size: 150,
    meta: {
      align: "right",
    },
    header: () => (
      <Stack alignItems="flex-end">
        <Strong spacing="dense">Hyzer</Strong>
        <Medium spacing="dense" size={12}>
          (DEG)
        </Medium>
      </Stack>
    ),
  });

export const columnSpin = () =>
  throwColumnHelper.accessor((row) => Math.abs(row.spinRpm)?.toFixed(0) ?? "", {
    id: "rotPerSec",
    minSize: 100,
    size: 150,
    meta: {
      align: "right",
    },
    header: () => (
      <Stack alignItems="flex-end">
        <Strong spacing="dense">Spin</Strong>
        <Medium spacing="dense" size={12}>
          (RPM)
        </Medium>
      </Stack>
    ),
  });

export const columnAdvanceRatio = () =>
  throwColumnHelper.accessor((row) => row.advanceRatio?.toFixed(0) ?? "", {
    id: "advanceRatio",
    minSize: 100,
    size: 150,
    meta: {
      align: "right",
    },
    header: () => (
      <Stack alignItems="flex-end">
        <Strong spacing="dense">ADV</Strong>
        <Medium spacing="dense" size={12}>
          (%)
        </Medium>
      </Stack>
    ),
  });

export const columnNoseAngle = () =>
  throwColumnHelper.accessor((row) => row.noseAngle?.toFixed(1) ?? "", {
    id: "noseAngle",
    minSize: 100,
    size: 150,
    meta: {
      align: "right",
    },
    header: () => (
      <Stack alignItems="flex-end">
        <Strong spacing="dense">Nose</Strong>
        <Medium spacing="dense" size={12}>
          (DEG)
        </Medium>
      </Stack>
    ),
  });

export const columnLaunchAngle = () =>
  throwColumnHelper.accessor((row) => row.launchAngle?.toFixed(1) ?? "", {
    id: "launchAngle",
    minSize: 100,
    size: 150,
    meta: {
      align: "right",
    },
    header: () => (
      <Stack alignItems="flex-end">
        <Strong spacing="dense">Launch</Strong>
        <Medium spacing="dense" size={12}>
          (DEG)
        </Medium>
      </Stack>
    ),
  });

export const columnWobble = () =>
  throwColumnHelper.accessor((row) => row.wobble?.toFixed(1) ?? "", {
    id: "wobble",
    minSize: 100,
    size: 150,
    meta: {
      align: "right",
    },
    header: () => (
      <Stack alignItems="flex-end">
        <Strong spacing="dense">Wobble</Strong>
        <Medium spacing="dense" size={12}>
          (DEG)
        </Medium>
      </Stack>
    ),
  });

export const columnDistance = (prefersMetric: boolean) =>
  throwColumnHelper.accessor(
    (row) =>
      formatNumber((prefersMetric ? row.distanceMeters : row.distanceFeet) as number, {
        maximumFractionDigits: 0,
      }),
    {
      id: "distance",
      minSize: 100,
      size: 150,
      meta: {
        align: "right",
      },

      header: () => (
        <Stack alignItems="flex-end">
          <Strong spacing="dense">Distance</Strong>
          <Medium spacing="dense" size={12}>
            {prefersMetric ? "(M)" : "(FT)"}
          </Medium>
        </Stack>
      ),
    },
  );

export const columnExpandIndicator = (display: "compact" | "default" = "compact") =>
  throwColumnHelper.display({
    id: "expand",
    size: 36,
    minSize: 36,
    meta: {
      align: "center",
    },
    cell: ({ row }) => (
      <ExpandMore
        component={motion.svg}
        initial={{ rotate: 0, transformOrigin: "center" }}
        animate={{ rotate: row.getIsExpanded() ? 180 : 0, transformOrigin: "center" }}
        exit={{ rotate: 0, transformOrigin: "center" }}
        transition={{ duration: 0.2 }}
        fontSize={display === "compact" ? "small" : "medium"}
        sx={{ color: "grey.600" }}
      />
    ),
  });

export const columnDiscDot = (devices: Map<string, Device>) =>
  throwColumnHelper.display({
    id: "deviceId",
    meta: {
      align: "center",
    },
    cell: ({ row }) => (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={row.original.videoMetadata ? "space-between" : "flex-end"}
        sx={{ width: "100%" }}
      >
        {row.original.videoMetadata && (
          <VideocamOutlined sx={{ color: "primary.main", fontSize: 20 }} />
        )}
        <Box
          component={motion.div}
          sx={{
            borderRadius: "100%",
            width: "20px",
            height: "20px",
          }}
          initial={{ opacity: 0 }}
          animate={{
            opacity: devices?.size ? 1 : 0,
            backgroundColor: getDeviceColor(devices, row.original.deviceId) ?? "#CCC",
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        />
      </Stack>
    ),
  });

export const columnSelect = () =>
  throwColumnHelper.display({
    id: "select",
    size: 36,
    minSize: 36,
    meta: {
      align: "center",
    },
    cell: ({ row }) =>
      row.getCanMultiSelect() ? (
        <Checkbox
          color="secondary"
          checked={row.getIsSelected()}
          onChange={(e) => row.toggleSelected(e.target.checked)}
        />
      ) : null,
  });
