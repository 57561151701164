import { getUserThrowSummaries } from "@/firebase/collections";
import { ReplayOutlined, StopOutlined } from "@mui/icons-material";
import { Button, Card, Container, TextField } from "@mui/material";
import { limit, onSnapshot, orderBy, query, startAt } from "firebase/firestore";
import { useCallback, useDeferredValue, useEffect, useState } from "react";
import { ThrowSummary } from "../model/throwSummary";
import { loadDevices } from "../summaryUtils";
import { DashboardContent } from "./Dashboard2";

let replayInterval: NodeJS.Timeout | null = null;

export const ReplayDashboard = () => {
  const [userId, setUserId] = useState("kt4e2j9PpNZgkwUJwEYm46vxNNk1");
  const [startingThrowId, setStartingThrowId] = useState("1727966049");
  const [replayThrowData, setReplayThrowData] = useState<ThrowSummary[]>([]);
  const [isReplayActive, setIsReplayActive] = useState(false);
  const [throws, setThrows] = useState<ThrowSummary[]>([]);
  const [devices, setDevices] = useState(new Map());

  const userIdValue = useDeferredValue(userId);
  const startingThrowIdValue = useDeferredValue(startingThrowId);

  const startReplaySession = useCallback(() => {
    setIsReplayActive(true);
    replayInterval = setInterval(() => {
      if (replayThrowData.length > 0) {
        const nextThrow = replayThrowData.shift();
        if (nextThrow) {
          setThrows((prevThrows) => [replayThrowData[prevThrows.length], ...prevThrows]);
        }
      }
    }, 10000);

    return () => {
      setIsReplayActive(false);
      if (replayInterval) {
        clearInterval(replayInterval);
      }
    };
  }, [replayThrowData, setIsReplayActive]);

  const stopReplaySession = () => {
    setIsReplayActive(false);
    if (replayInterval) {
      clearInterval(replayInterval);
    }
  };

  useEffect(() => {
    if (!userId || !startingThrowId) {
      return;
    }
    const sessionThrowsRef = query(
      getUserThrowSummaries(userId),
      orderBy("throwTime", "desc"),
      startAt(startingThrowId),
      limit(10),
    );

    const unsubscribe = onSnapshot(sessionThrowsRef, (snapshot) => {
      const sessionData = snapshot.docs.map((doc) => doc.data());
      console.log(sessionData);
      setReplayThrowData(sessionData);
    });
    return () => {
      unsubscribe();
    };
  }, [userId, startingThrowId]);

  useEffect(() => {
    let isCancelled = false;
    if (userId) {
      loadDevices(userId).then((devices) => {
        if (!isCancelled) {
          setDevices(devices);
        }
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [userId]);

  return (
    <>
      <Container maxWidth="lg" sx={{ mx: "0 auto", mt: 4 }}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
            p: 2,
          }}
        >
          <TextField
            variant="outlined"
            label="User ID"
            value={userIdValue}
            onChange={(e) => setUserId(e.target.value)}
            onClick={(e) => (e.target as HTMLInputElement).select()}
            sx={{ width: "100%" }}
          />
          <TextField
            variant="outlined"
            label="Starting Throw ID"
            value={startingThrowIdValue}
            onChange={(e) => setStartingThrowId(e.target.value)}
            onClick={(e) => (e.target as HTMLInputElement).select()}
            sx={{ width: "100%" }}
          />
          {isReplayActive ? (
            <Button variant="destructive" onClick={stopReplaySession}>
              <StopOutlined />
            </Button>
          ) : (
            <Button variant="primary" onClick={startReplaySession}>
              <ReplayOutlined />
            </Button>
          )}
        </Card>
      </Container>
      {userId ? (
        <DashboardContent
          isDebugThrows={false}
          throws={throws ?? []}
          throwLoading={false}
          flightPathLoading={false}
          userId={userId}
          devices={devices}
          singleDoc={false}
          setLockedTags={(tags: string[]) => {}}
        />
      ) : null}
    </>
  );
};
