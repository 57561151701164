import { Button } from "@mui/material";
import { AnalyzeDashboard } from "../analysis/AnalyzeDashboard";
import { ArrowBackIosOutlined } from "@mui/icons-material";
import { Link, useLocation, useParams } from "react-router-dom";
import { Container } from "rsuite";
import { Box } from "@mui/system";
import { useUser } from "../../hooks/useUser";
import { Handedness } from "../../model/UserSettings";

export default function TestDriveReview() {
  const { state } = useLocation();
  const { handedness } = state;
  const { analysisId } = useParams();
  const [{ userId }] = useUser();
  return (
    analysisId && (
      <>
        <Container>
          <Box display="flex">
            <Button
              component={Link}
              to="/test-drive"
              variant="outlined"
              startIcon={<ArrowBackIosOutlined />}
            >
              Back to Test Drive
            </Button>
          </Box>
        </Container>
        <AnalyzeDashboard
          userId={userId}
          handedness={handedness ?? Handedness.RIGHT}
          throwSetId={analysisId}
        />
      </>
    )
  );
}
