import FlipMove from "react-flip-move";
import { useEffect, useMemo } from "react";
import { Paper, Avatar, Chip, Stack } from "@mui/material";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { TableRenderProps } from "./ThrowTable";
import { getTrueUserId, getUserId, POWER_USER_IDS } from "../summaryUtils";
import Title from "../dashboard/Title";
import { CoreStatsAndId } from "../model/throwSummary";
import TagManager from "../components/TagManager";
import { passesAllFilters } from "./shared/utility";
import useLive from "../hooks/useLive";
import { CoreMetrics } from "../firebase/converters/analysisSet";

// TODO brett -- moving this into TagManager but was messing up tag sort / animation
export function filterByTags(tags: string[], docs: CoreMetrics[]) {
  if (!tags || tags.length === 0) {
    return docs;
  }
  return docs.filter((doc) => {
    if (doc.tags && doc.tags.length > 0) {
      return tags.every((tag) => doc.tags?.includes(tag));
      // return doc.tags.some((tag) => tags.includes(tag));
    }
    return false;
  });
}
function TableTagInternal(props: TableRenderProps & { docTags: Map<string, number> }) {
  const { docTags } = props;
  return (
    <>
      <Title variant="secondary">Tags</Title>
      <Stack direction={"row"} spacing={3} sx={{ flexWrap: "wrap", gap: 1 }}>
        <FlipMove>
          {[...docTags.entries()].map((e) => {
            const [key, value] = e;
            return (
              <Chip
                avatar={value ? <Avatar>{value}</Avatar> : undefined}
                key={key}
                sx={{ height: 40, mr: "3px", mb: "3px" }}
                disabled={!value && !props.tags.includes(key)}
                label={key}
                variant={props.tags.includes(key) ? "filled" : "outlined"}
                onClick={(o) => {
                  props.setTags((old) =>
                    old.includes(key) ? old.filter((x) => x !== key) : [...old, key],
                  );
                }}
              />
            );
          })}
        </FlipMove>
      </Stack>
    </>
  );
}

export function TableTags(props: TableRenderProps) {
  const { isLiveRoute } = useLive();

  // TODO brett coming back to this
  // const docTags: Map<string, number> = useMemo(() => {
  //   const sorted = TagManager.getDocTags(props.docs, props.filters, props.tags);
  //   if (getTrueUserId() !== getUserId() && !POWER_USER_IDS.has(getTrueUserId())) {
  //     sorted.delete("DEBUG");
  //     sorted.delete("Speed Winner");
  //   }
  //   return sorted;
  // }, [props.docs, props.filters, props.limit]);

  const docTags: Map<string, number> = useMemo(() => {
    const map = new Map<string, number>();
    props.docs.forEach((doc) => {
      if (!doc.tags?.length) {
        return;
      }
      const passes =
        passesAllFilters(props.filters, doc) && filterByTags(props.tags, [doc]).length > 0;
      doc.tags.forEach((tag) => {
        if (!map.has(tag)) {
          map.set(tag, 0);
        }
        if (passes) {
          map.set(tag, map.get(tag) + 1);
        }
      });
    });
    // sort map by value then by key
    const sorted = new Map(
      [...map.entries()].sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0])),
    );

    if (getTrueUserId() !== getUserId() && !POWER_USER_IDS.has(getTrueUserId())) {
      sorted.delete("DEBUG");
      sorted.delete("Speed Winner");
    }
    return sorted;
  }, [props.docs, props.filters, props.limit]);
  // remove any tags that are selected that are missing from the new doc set
  useEffect(() => {
    if (props.isLoading) {
      return;
    }

    const hasAll = props.tags.every((tag) => docTags.has(tag));
    if (!hasAll) {
      props.setTags((old) => {
        return old.filter((tag) => docTags.has(tag));
      });
    }

    // updating suggested tags at proper condition
    if (
      !isLiveRoute &&
      props?.dateRange?.[1] &&
      !props?.filters?.length &&
      !props?.primaryTypes?.length &&
      !props?.secondaryTypes?.length &&
      !props?.defaultTags?.length &&
      !props?.tags?.length
    ) {
      // date check to ensure throws are latest
      const hourAgo = new Date();
      hourAgo.setHours(hourAgo.getHours() - 1);
      if (props.dateRange[1] > hourAgo) {
        // this is the data we care about to improve our suggested tags
        TagManager.updateSuggestedTags(props.uid, props.docs);
      }
    }
  }, [props.tags, docTags, props.isLoading]);

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.isLoading ? (
        <Spinner size={SpinnerSize.SMALL} />
      ) : (
        <TableTagInternal {...props} docTags={docTags} />
      )}
    </Paper>
  );
}
