import { TechDiscHQPin } from "@/components/icons/TechDiscHQPin";
import { MyLocation } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import { AdvancedMarker, Map, useMap } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useState } from "react";
import { TD_HQ_GEO } from "../components/consts";
import useScreen from "../hooks/useScreen";

export type StoreMapProps = {
  children?: React.ReactNode;
};

export default function StoreMap(props: StoreMapProps) {
  const { children } = props;
  const { isMobile } = useScreen();
  const map = useMap();
  const [isMarkerVisible, setIsMarkerVisible] = useState(false);

  const panToCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        map?.setZoom(isMobile ? 9 : 11);
        map?.panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  }, [map, isMobile]);

  useEffect(() => {
    if (map) {
      const listener = map.addListener("zoom_changed", () => {
        const zoom = map.getZoom();
        setIsMarkerVisible(Boolean(zoom && zoom >= 10)); // Set the desired zoom level
      });

      return () => {
        google.maps.event.removeListener(listener);
      };
    }
  }, [map]);

  return (
    <Map
      defaultCenter={TD_HQ_GEO}
      defaultZoom={isMobile ? 3 : 5}
      mapId="ca466929d5ef85bc"
      disableDefaultUI
      gestureHandling={"greedy"}
      clickableIcons={false}
      restriction={{
        latLngBounds: {
          north: 90,
          south: -90,
          east: 180,
          west: -180,
        },
        strictBounds: true,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box
          component="button"
          onClick={panToCurrentLocation}
          onTouchEnd={panToCurrentLocation}
          sx={{
            top: { tablet: 16 },
            bottom: { mobile: 28, tablet: "auto" },
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "primary.dark",
            py: 0.5,
            px: 1,
            color: "white",
            border: "1px solid",
            borderColor: "secondary.main",
            position: "absolute",
            borderRadius: 1,
            width: "max-content",
          }}
        >
          <Box sx={{ mr: 1, fontSize: "14px", display: "inline-block" }}>Use current location</Box>
          <MyLocation fontSize="small" sx={{ color: "primary.light" }} />
        </Box>
      </Stack>
      {isMarkerVisible && (
        <AdvancedMarker position={TD_HQ_GEO} zIndex={0}>
          <TechDiscHQPin />
        </AdvancedMarker>
      )}
      {children}
    </Map>
  );
}
