import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";

export interface DiscAutocompleteInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TData extends string = string,
> {
  methods: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  data: TData[];
}

export default function DiscAutocompleteInput<
  TFieldValues extends FieldValues = FieldValues,
  TData extends string = string,
>(props: DiscAutocompleteInputProps<TFieldValues, TData>) {
  const { methods, name, data } = props;
  const [open, setOpen] = useState(false);

  return (
    <Controller
      control={methods.control}
      name={name}
      render={({ field }) => (
        <Autocomplete
          options={data}
          autoComplete
          freeSolo
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(e, value, reason) => {
            field.onChange(value ?? "");
          }}
          onInputChange={(e, value, reason) => {
            field.onChange(value ?? "");
            if (!value) setOpen(false);
          }}
          value={field.value}
          componentsProps={{ popper: { sx: { maxHeight: "144px" } } }}
          renderInput={({ ...params }) => (
            <TextField
              {...params}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              inputProps={{ ...params.inputProps, value: field.value }}
              error={!!methods.formState.errors[name]}
              helperText={methods.formState.errors[name]?.message as string}
              sx={{ backgroundColor: "white", minWidth: "196px" }}
            />
          )}
          renderOption={(props, option) => <li {...props}>{option}</li>}
        />
      )}
    />
  );
}
