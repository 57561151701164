import { getLiveEventsCollection } from "@/firebase/collections";
import { isAfter, isBefore } from "date-fns";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { TechDiscBanner } from "../../dashboard/ShopBanner";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";
import { LiveBanner } from "../banners/LiveBanner";

export default function UnauthenticatedBanner() {
  const { isLiveRoute } = useLive();
  const [{ user, userLoading }] = useUser();
  const [liveEvents = [], loading] = useCollectionData(getLiveEventsCollection());
  const currentDate = new Date();

  const upcomingEvents = liveEvents?.filter(
    (event) =>
      isAfter(currentDate, event.previewDate?.toDate()) &&
      isBefore(currentDate, event.endDate?.toDate()),
  );

  const isEventLive = liveEvents?.some(
    (event) =>
      isBefore(currentDate, event.endDate?.toDate()) &&
      isAfter(currentDate, event.startDate?.toDate()),
  );
  const isEventUpcoming = upcomingEvents?.length > 0;

  return !loading && (isLiveRoute || isEventLive || isEventUpcoming) ? (
    <LiveBanner liveEvents={liveEvents} />
  ) : !user && !userLoading ? (
    <TechDiscBanner />
  ) : null;
}
