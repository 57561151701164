import { Stack } from "@mui/material";
import React from "react";
import { Black, Heavy, Medium, Strong } from "../Text";

export interface FlightNumberProps {
  value: React.ReactNode;
  metric?: React.ReactNode;
  auto?: boolean;
  variant?: "primary" | "secondary";
}

function FlightNumber(props: FlightNumberProps) {
  const { metric, value, auto, variant = "primary" } = props;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        userSelect: "none",
        aspectRatio: "1/1",
        width: "128px",
        maxWidth: "128px",
        border: "1px solid",
        borderColor: "grey.400",
        color: auto ? "grey.500" : `${variant}.main`,
        backgroundColor: "white",
        p: 2,
        borderRadius: 1,
      }}
      gap={0.5}
    >
      <Black size={32}>{value ?? "—"}</Black>
      {metric && <Strong color={auto ? "grey.500" : "grey.600"}>{metric}</Strong>}
    </Stack>
  );
}

export default FlightNumber;
