import { ReactNode } from "react";
import { Text } from "../Text";
import { Stack, StackProps } from "@mui/material";

export interface CellProps {
  children: ReactNode;
  alt?: boolean;
  display?: "compact" | "default";
  align?: "left" | "right" | "center";
}

function Cell(props: CellProps & StackProps) {
  const { display, children, alt, align = "left", ...rest } = props;

  const alignItems = {
    right: "flex-end",
    left: "flex-start",
    center: "center",
  };

  return (
    <Stack
      {...rest}
      sx={{
        px: display === "compact" ? 2 : 2,
        py: display === "compact" ? 1 : 1,
        alignItems: alignItems[align],
        justifyContent: "center",
        backgroundColor: alt ? "white" : "grey.100",
      }}
    >
      {typeof children === "string" ? (
        <Text size={16} spacing="loose">
          {children}
        </Text>
      ) : (
        children
      )}
    </Stack>
  );
}

export default Cell;
