import Title from "./Title";
import { TextField } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { storeSummary } from "../summaryUtils";
import { ThrowSummary } from "../model/throwSummary";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useNotify from "../hooks/useNotify";

export interface NoteProps {
  userId: string;
  throwSummary: ThrowSummary;
}

export default function Note(props: NoteProps) {
  const { userId, throwSummary } = props;

  const notesSchema = Yup.object().shape({
    notes: Yup.string().max(1000, "Notes must be 1000 characters or less"),
  });

  const { register, handleSubmit } = useForm({
    defaultValues: {
      notes: throwSummary.notes ?? "",
    },
    resolver: yupResolver(notesSchema),
  });
  const notify = useNotify();

  const onSubmit = ({ notes }: { notes?: string }) => {
    if (notes || notes === "") {
      store(notes);
      notify("info", "Note stored!");
    }
  };

  const store = async (newValue: string) => {
    try {
      await storeSummary(userId, throwSummary.id, {
        notes: newValue,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // @ts-ignore
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title variant="secondary">Add note to throw</Title>
      <TextField
        fullWidth
        multiline={true}
        label="Note"
        variant="outlined"
        {...register("notes")}
        onBlur={handleSubmit(onSubmit)}
      />
    </form>
  );
}
