import { useMemo } from "react";
import { useUser } from "../../hooks/useUser";
import { Device } from "../../model/device";
import { ThrowSummary } from "../../model/throwSummary";
import { useGlobal } from "../providers/GlobalProvider";
import * as throwColumns from "./helpers";
import { Table } from "./Table";
import ThrowsRowExpanded from "./ThrowsRowExpanded";

function ThrowsTable(props: {
  id: string;
  throws: ThrowSummary[];
  display?: "compact" | "default";
  devices?: Map<string, Device>;
}) {
  const { id, throws = [], devices, display = "default" } = props;
  const { userSettings, prefersMetric } = useGlobal();
  const [{ userId, publicUserId }] = useUser();
  const showDiscDot = useMemo(() => {
    if (userId === publicUserId) {
      return false;
    }
    const deviceIds = new Set(throws.map((data) => data.deviceId));

    return deviceIds.size > 1;
  }, [throws, userId, publicUserId]);

  // Define the columns based on the ThrowSummary model
  const columns = useMemo(() => {
    const columns: any[] = [
      throwColumns.columnThrowType(userSettings),
      throwColumns.columnSpeed(prefersMetric),
      throwColumns.columnHyzer(),
      throwColumns.columnSpin(),
      throwColumns.columnAdvanceRatio(),
      throwColumns.columnNoseAngle(),
      throwColumns.columnLaunchAngle(),
      throwColumns.columnWobble(),
      throwColumns.columnDistance(prefersMetric),
    ];

    if (showDiscDot && devices && devices.size > 0) {
      columns.unshift(throwColumns.columnDiscDot(devices));
    }

    if (userId !== "unknown") {
      columns.push(throwColumns.columnExpandIndicator(display));
    }

    return columns;
  }, [devices, showDiscDot, userSettings, userId, display, prefersMetric]);

  return (
    <Table
      id={id}
      data={throws}
      columns={columns}
      components={{ RowExpanded: ThrowsRowExpanded }}
      options={{ enableRowSelection: false, enableMultiRowSelection: false }}
    />
  );
}

export default ThrowsTable;
