import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Stack, FormControlLabel, Switch } from "@mui/material";
import Title from "../../../dashboard/Title";

export default function StoreVisibility() {
  const { control } = useFormContext(); // Destructure only what you need

  return (
    <Stack gap={3}>
      <Title variant="secondary">Visibility</Title>
      <Controller
        name="hideMapLocation"
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            control={
              <Switch
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                inputRef={ref}
              />
            }
            label="Hide store location on map"
          />
        )}
      />
    </Stack>
  );
}
