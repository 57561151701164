import type { LiveEvent } from "@/firebase/converters/events";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Icon,
  List,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from "@mui/material";
import { format, getDay, isAfter, isBefore } from "date-fns";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLive from "../../hooks/useLive";
import { useUser } from "../../hooks/useUser";

export const LiveBanner = ({ liveEvents }: { liveEvents: LiveEvent[] }) => {
  const { liveEvent } = useLive();
  const currentPath = useLocation().pathname;
  const [{ user, userLoading }] = useUser();

  const currentDate = new Date();

  const browsingLiveEvent = liveEvents?.find((event) => event.id === liveEvent);

  const upcomingEvents = liveEvents?.filter(
    (event) =>
      isAfter(currentDate, event.previewDate?.toDate()) &&
      isBefore(currentDate, event.endDate?.toDate()),
  );

  const pastEvents = liveEvents?.filter((event) => isAfter(currentDate, event.endDate?.toDate()));

  const eventToDisplay = browsingLiveEvent || upcomingEvents?.[0] || pastEvents?.[0];

  if (!eventToDisplay) return null;

  const currentEvent = liveEvents.find(
    (event) =>
      isBefore(new Date(), event.endDate?.toDate()) &&
      isAfter(new Date(), event.startDate?.toDate()),
  );
  const { startDate, endDate, id, name } = eventToDisplay;
  const isLiveRoute = currentPath.includes("/live");
  const isEventLive = liveEvents?.some(
    (event) =>
      isBefore(currentDate, event.endDate?.toDate()) &&
      isAfter(currentDate, event.startDate?.toDate()),
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: "primary.dark" }}>
      <Toolbar
        sx={{
          flexDirection: { mobile: "column", tablet: "row" },
          justifyContent: { tablet: "space-between" },
          py: 1,
          gap: 0.5,
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Stack direction="row" justifyContent={{ mobile: "center", tablet: "flex-start" }}>
            {isEventLive && eventToDisplay?.id === currentEvent?.id ? (
              <Box
                sx={{
                  color: "primary.main",
                  fontSize: "20px",
                  letterSpacing: "-1px",
                  fontWeight: 600,
                }}
              >
                LIVE
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  letterSpacing: "0px",
                  fontWeight: 500,
                }}
              >
                {getDay(startDate.toDate()) === getDay(endDate.toDate())
                  ? format(startDate.toDate(), "LLLL do")
                  : `${format(startDate.toDate(), "LLLL do")} - ${format(endDate.toDate(), "LLLL do")}`}
              </Box>
            )}
          </Stack>
          <Stack
            component={isEventLive ? Link : "span"}
            to={isEventLive ? `/live/${id}` : undefined}
            sx={{
              textAlign: { mobile: "center", tablet: "left" },
              justifyContent: { mobile: "center", tablet: "flex-start" },
              fontSize: "20px",
              width: "100%",
              color: "white",
              textWrap: "no-wrap",
              userSelect: "none",
            }}
          >
            {name}
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          {isLiveRoute ? (
            <>
              {isEventLive && eventToDisplay?.id !== currentEvent?.id && (
                <Button
                  component={Link}
                  variant="text" // or "outlined"
                  size="small"
                  sx={{
                    color: "white",
                    maxWidth: "100%",
                    textWrap: "no-wrap",
                    width: "fit-content",
                  }}
                  to={`/live/${currentEvent?.id}`}
                >
                  <Icon
                    component={EmojiEventsIcon}
                    fontSize="small"
                    sx={{ color: "accent.main" }}
                  />
                  {currentEvent?.name}
                </Button>
              )}
              {isEventLive && eventToDisplay?.id === currentEvent?.id ? (
                <>
                  <EventMenu
                    isEventLive={isEventLive}
                    eventToDisplay={eventToDisplay}
                    currentEvent={currentEvent}
                  />
                  <PastEvents liveEvents={pastEvents} currentEvent={currentEvent} />
                </>
              ) : (
                <PastEvents liveEvents={pastEvents} currentEvent={currentEvent} />
              )}

              {user && !userLoading && (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ display: { mobile: "none", tablet: "flex" } }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: "secondary.light", mx: 0.5 }}
                  />
                  <Button component={Link} color="inherit" to={"/"}>
                    <KeyboardReturnIcon fontSize="small" /> Return
                  </Button>
                </Stack>
              )}
            </>
          ) : isEventLive ? (
            <EventMenu
              isEventLive={isEventLive}
              eventToDisplay={eventToDisplay}
              currentEvent={currentEvent}
            />
          ) : (
            <PastEvents liveEvents={pastEvents} currentEvent={currentEvent} />
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

const PastEvents = ({
  liveEvents,
  currentEvent,
}: {
  liveEvents: LiveEvent[];
  currentEvent?: LiveEvent;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="menu"
        size="small"
        id="past-events-button"
        aria-controls={open ? "past-events-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Stack sx={{ color: "white" }}>Past Events</Stack>
        <ArrowDropDownCircleOutlined />
      </Button>
      <Menu
        id="past-events-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "past-events-button",
        }}
      >
        {liveEvents
          ?.filter((event) => event.id !== currentEvent?.id)
          .sort((a, b) => {
            return a.startDate.toMillis() - b.startDate.toMillis();
          })
          .map((event) => (
            <MenuItem
              key={event.id}
              onClick={() => {
                handleClose();
                navigate(`/live/${event.id}/throws`);
              }}
            >
              {event.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const EventMenu = ({
  isEventLive,
  eventToDisplay,
  currentEvent,
}: {
  isEventLive: boolean;
  eventToDisplay: LiveEvent;
  currentEvent?: LiveEvent;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const { liveEvent } = useLive();

  return (
    <>
      <Button
        variant="menu"
        size="small"
        id="explore-events-button"
        aria-controls={open ? "explore-events-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Stack>Explore</Stack>
        <ArrowDropDownCircleOutlined sx={{ color: "accent.main" }} />
      </Button>
      <Menu
        id="explore-events-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "explore-events-button",
        }}
      >
        <List>
          {isEventLive && (
            <MenuItem
              key={liveEvent}
              onClick={() => {
                handleClose();
                navigate(`/live/${currentEvent?.id}`);
              }}
            >
              Live
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(`/live/${currentEvent?.id}/throws`);
            }}
          >
            Throws
          </MenuItem>
          {/* TODO: Display past leaderboards and remove this logic */}
          {eventToDisplay?.id === currentEvent?.id && (
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`/live/leaderboards`);
              }}
            >
              Leaderboards
            </MenuItem>
          )}
        </List>
      </Menu>
    </>
  );
};
