import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/system";
import React from "react";

function useScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return { isMobile };
}

export default useScreen;
