import { deleteDoc } from "firebase/firestore";
import { StockShot } from "../converters/stockShot";
import { getUserStockShot } from "../docs";

export const deleteStockShot = async (userId: string, stockShotId: string): Promise<StockShot> => {
  const shotsRef = getUserStockShot(userId, stockShotId);

  if (shotsRef) {
    return await deleteDoc(shotsRef);
  }
};
