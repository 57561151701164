import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { DateRangePicker } from "rsuite";
import { DateRange, RangeType } from "rsuite/DateRangePicker";
import { Box, Button, Chip, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Moment from "react-moment";
import { SECONDARY_TYPES } from "../dashboard/DashboardTags";
import { TableSearchProps } from "./ThrowTable";
import Title from "../dashboard/Title";
import { Handedness } from "../model/UserSettings";
import { exportJSONToCSV } from "../utils/csvUtil";
import { getThrowName } from "../utils/throw";
import { CoreMetrics } from "../firebase/converters/analysisSet";
import { useGlobal } from "../components/providers/GlobalProvider";

const predefinedRanges: RangeType[] = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: "left",
  },
  {
    label: "All time",
    value: [new Date(2022, 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    appearance: "default",
  },
];

function hiddenBox(props: TableSearchProps) {
  const toggle = (
    <Switch
      checked={props.showHidden}
      // icon={<VisibleIcon />}
      // checkedIcon={<UnvisibleIcon />}
      onChange={(event) => {
        props.setShowHidden(event.target.checked);
      }}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
    >
      <Title variant="secondary">Date Range</Title>
      <FormControlLabel sx={{ flexDirection: 0 }} value={"start"} control={toggle} label="Hidden" />
    </Box>
  );
}

function defaultTagsStack(props: TableSearchProps) {
  // organizing checked first for tags
  const suggestedTagsLocal = props.suggestedTags?.slice(0, 10) || [];
  suggestedTagsLocal.sort((a: string, b: string) => {
    if (!props.defaultTags?.length) {
      return 0; // irrelevant
    }
    if (props.defaultTags.includes(a) && props.defaultTags.includes(b)) return 0;
    else if (!props.defaultTags.includes(a) && !props.defaultTags.includes(b)) return 0;
    else if (props.defaultTags.includes(a)) return -1;
    else return 1;
  });
  return (
    <Stack spacing={1}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
        {suggestedTagsLocal.map((t: string, i: number) => (
          <Chip
            key={i}
            label={t}
            color={props.defaultTags.includes(t) ? "primary" : "default"}
            variant={props.defaultTags.includes(t) ? "filled" : "outlined"}
            onClick={(o) => {
              props.setDefaultTags((old) =>
                old.includes(t) ? old.filter((x) => x !== t) : [...old, t],
              );
            }}
          />
        ))}
      </div>
    </Stack>
  );
}

function mainTypeStack(props: TableSearchProps) {
  return (
    <Stack spacing={1}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
        <Chip
          key={"RHBH"}
          label="Backhand"
          color={props.primaryTypes.includes("RHBH") ? "primary" : "default"}
          variant={props.primaryTypes.includes("RHBH") ? "filled" : "outlined"}
          onClick={(o) => {
            props.setPrimaryTypes((old) =>
              old.includes("RHBH")
                ? old.filter((x) => x !== "RHBH" && x !== "LHBH")
                : [...old, "RHBH", "LHBH"],
            );
          }}
        />
        <Chip
          key={"RHFH"}
          label="Forehand"
          color={props.primaryTypes.includes("RHFH") ? "primary" : "default"}
          variant={props.primaryTypes.includes("RHFH") ? "filled" : "outlined"}
          onClick={(o) => {
            props.setPrimaryTypes((old) =>
              old.includes("RHFH")
                ? old.filter((x) => x !== "RHFH" && x !== "LHFH")
                : [...old, "RHFH", "LHFH"],
            );
          }}
        />
        <Chip
          key="RHBHG"
          label="Grenade"
          disabled={!!props.secondaryTypes.length}
          color={props.primaryTypes.includes("RHBHG") ? "primary" : "default"}
          variant={props.primaryTypes.includes("RHBHG") ? "filled" : "outlined"}
          onClick={(o) => {
            props.setPrimaryTypes((old) =>
              old.includes("RHBHG")
                ? old.filter(
                    (x) => x !== "RHBHG" && x !== "RHFHG" && x !== "LHBHG" && x !== "LHFHG",
                  )
                : [...old, "RHBHG", "RHFHG", "LHBHG", "LHFHG"],
            );
          }}
        />
        <Chip
          key="RHTHU"
          label="Overhand"
          disabled={!!props.secondaryTypes.length}
          color={props.primaryTypes.includes("RHTHU") ? "primary" : "default"}
          variant={props.primaryTypes.includes("RHTHU") ? "filled" : "outlined"}
          onClick={(o) => {
            props.setPrimaryTypes((old) =>
              old.includes("RHTHU")
                ? old.filter(
                    (x) => x !== "RHTHU" && x !== "RHTOM" && x !== "LHTOM" && x !== "LHTHU",
                  )
                : [...old, "RHTHU", "RHTOM", "LHTOM", "LHTHU"],
            );
          }}
        />
      </div>
    </Stack>
  );
}

function secondaryTypeStack(props: TableSearchProps) {
  return (
    <Stack spacing={1}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
        {SECONDARY_TYPES.map((t, i: number) => (
          <Chip
            key={i}
            label={t}
            disabled={props.primaryTypes.includes("RHBHG") || props.primaryTypes.includes("RHTHU")}
            color={props.secondaryTypes.includes(t) ? "primary" : "default"}
            variant={props.secondaryTypes.includes(t) ? "filled" : "outlined"}
            onClick={(o) => {
              props.setSecondaryTypes((old) =>
                old.includes(t) ? old.filter((x) => x !== t) : [...old, t],
              );
            }}
          />
        ))}
      </div>
    </Stack>
  );
}

function getDateRangePicker(props: TableSearchProps) {
  return (
    <DateRangePicker
      defaultCalendarValue={props.dateRange}
      value={props.dateRange}
      onChange={(value: DateRange | null) => {
        if (!value) {
          return;
        }
        let date = endOfDay(value[1]);
        const now = new Date();
        if (date > now) {
          date = now;
        }
        props.setDateRange([startOfDay(value[0]), date]);
      }}
      showWeekNumbers={true}
      ranges={predefinedRanges}
    />
  );
}

function downloadCsv(docs: CoreMetrics[], handedness: Handedness = Handedness.RIGHT) {
  const toExport = docs.map((t) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const millis = t.throwTime.toMillis();
    const localDateString = t.throwTime.toDate().toLocaleString("en-US", options);
    // escape \ and | for tags and join them with |
    const tags = t.tags?.map((t) => t.replace(/\\/g, "\\\\").replace(/\|/g, "\\|")).join("|") ?? "";
    return {
      id: t.id,
      time: localDateString,
      timeSeconds: (millis / 1000).toFixed(0),
      speedMph: t.speedMph?.toFixed(2),
      speedKmh: t.speedKmh?.toFixed(2),
      advanceRatio: t.advanceRatio?.toFixed(2),
      spinRpm: t.spinRpm?.toFixed(1),
      launchAngle: t.launchAngle?.toFixed(2),
      noseAngle: t.noseAngle?.toFixed(2),
      hyzerAngle: t.hyzerAngle?.toFixed(2),
      wobbleAngle: t.wobble.toFixed(2),
      distanceFeet: t.distanceFeet?.toFixed(2),
      distanceMeters: t.distanceMeters?.toFixed(2),
      throwType: getThrowName(t, handedness),
      tags: tags,
      notes: t.notes ?? "",
    };
  });
  exportJSONToCSV(toExport, "throws");
}

export default function ThrowFilters<T extends TableSearchProps>(props: T) {
  const { userSettings } = useGlobal();
  return (
    <Stack sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }} gap={5}>
      <Stack gap={2} sx={{ gridColumn: "1 / -1" }}>
        {hiddenBox(props)}
        {getDateRangePicker(props)}
      </Stack>

      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title variant="secondary">Common Tags</Title>
        {defaultTagsStack(props)}
      </Stack>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title variant="secondary">Throw Type</Title>
        {mainTypeStack(props)}
      </Stack>
      <Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title variant="secondary">Specific Angle</Title>
          {secondaryTypeStack(props)}
        </Stack>
      </Stack>
      {/*// Add a bar across the whole page that summaries the current search*/}
      {/*// The current search is the current date range, primary type, and secondary type*/}
      {!props.isLoading && (
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            gridColumn: "1/-1",
          }}
        >
          <Stack>
            <Title variant="secondary">Search Summary</Title>
            <Typography variant="body2" color="text.secondary" sx={{ flexWrap: "wrap" }}>
              {Math.min(props.docs.length, props.limit)} results starting at &nbsp;
              {props.docs.length > 0 && (
                // format moment with minutes
                <Moment format="M/D/YYYY h:mma">
                  {props.docs[props.docs.length - 1].throwTime.toDate()}
                </Moment>
              )}
            </Typography>
          </Stack>
          {props.docs.length > 0 && (
            <Button
              startIcon={<FileDownloadIcon />}
              style={{
                width: "fit-content",
                marginTop: "20px",
              }}
              color="primary"
              variant="contained"
              onClick={() => downloadCsv(props.docs, userSettings?.handedness)}
            >
              Export Throws CSV
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
