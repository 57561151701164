import React, { useCallback } from "react";
import { DialogCta } from "../DialogCta";
import { Trans, useTranslation } from "react-i18next";
import { DiscPreset } from "../../model/discs";
import { Box, Button } from "@mui/material";
import { deleteUserDiscPreset } from "../discs/actions";
import useNotify from "../../hooks/useNotify";
import { Medium } from "../Text";

export default function DeleteDiscConfirmDialog(props: {
  userId: string;
  anchor: React.ComponentType<{ children: React.ReactNode; onClick: () => void }>;
  discPreset?: DiscPreset;
  onDelete?: () => void;
}) {
  const { userId, discPreset, anchor, onDelete } = props;
  const { t } = useTranslation();
  const notify = useNotify();

  const handleDelete = useCallback(
    (close: () => void) => () => {
      if (discPreset && discPreset.id) {
        deleteUserDiscPreset(userId, discPreset.id);
        close();
        notify(
          "info",
          <Trans
            style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}
            i18nKey={"discPreset.notification.deleted"}
            values={{ discName: discPreset.name }}
            components={{ 0: <b /> }}
          />,
        );
        onDelete?.();
      }
    },
    [discPreset, notify, onDelete, userId],
  );

  return discPreset ? (
    <DialogCta
      title={t("deletingItem", { item: t("disc") })}
      anchor={anchor}
      actions={({ close }) => (
        <>
          <Button variant="secondary" onClick={close}>
            {t("nevermind")}
          </Button>
          <Button variant="primary" onClick={handleDelete(close)}>
            {t("confirm.delete.yes", { name: discPreset?.name })}
          </Button>
        </>
      )}
    >
      <Medium>
        <Trans
          i18nKey={"confirm.delete.item"}
          values={{ item: discPreset?.name }}
          components={{ 0: <Box sx={{ fontWeight: 500 }} />, 1: <p /> }}
        />
      </Medium>
    </DialogCta>
  ) : null;
}
