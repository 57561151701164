import { useEffect, useState } from "react";

export const useAllCountryData = () => {
  const [allCountryData, setAllCountryData] = useState<CountryData[]>([]);
  useEffect(() => {
    const fetchCountryData = async () => {
      const countryData = await fetch("https://countriesnow.space/api/v0.1/countries/states");
      const countryDataJson = await countryData.json();
      setAllCountryData(countryDataJson.data);
    };
    fetchCountryData();
  }, []);
  return allCountryData;
};

type State = {
  name: string;
  state_code: string;
};

type CountryData = {
  name: string;
  iso2: string;
  states: State[];
};
