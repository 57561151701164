import { useUser } from "@/hooks/useUser";

import type { SimulatorCourseHole } from "@/types/simulator";
import { getUserPermissions } from "@/utils/auth";
import { useEffect, useState } from "react";

export const useSimulatorPermissions = () => {
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [allowedCourses, setAllowedCourses] = useState<
    { id?: string; displayName?: string; holes?: SimulatorCourseHole[] }[] | null
  >(null);
  const [{ userId }] = useUser();

  useEffect(() => {
    async function getPermissions() {
      const permissions = await getUserPermissions(userId);
      setAllowedCourses(permissions ?? []);
      setCoursesLoading(false);
    }
    getPermissions();
  }, [userId]);

  return [allowedCourses, coursesLoading] as const;
};
