import React, { useEffect } from "react";
import { createPortalLink } from "../../stripe";

const ONLY_COMMERCIAL_PRICING_TABLE = "prctbl_1OFkZsFBIwpy1XnfvJVtzARz";
const ALL_THREE_PRICING_TABLE = "prctbl_1O5aGvFBIwpy1Xnf3o7vXLe9";
const PRO_AND_COMMERCIAL = "prctbl_1OMil2FBIwpy1Xnf1K19FrrA";

const StripePricingTable = (props: { pricingTable?: string }) => {
  const pricingTable = props.pricingTable ?? ONLY_COMMERCIAL_PRICING_TABLE;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // client-reference-id
  // createPortalLink();
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": pricingTable,
    // "client-reference-id": "cus_ONUxrvSXtCkj4c",
    "publishable-key":
      "pk_live_51NZgfZFBIwpy1XnfOTt5RoMTKk8wKpZePnqMij7Yueakk7P2mXK7a1LP9T6onDAgGfLCztGlHOgP0WdA3GfStI1n00PprjApR0",
  });
};
export default StripePricingTable;
