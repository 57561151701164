import { Box, Stack } from "@mui/material";
import { Medium, Strong } from "../../Text";
import { useTranslation } from "react-i18next";
import { useGlobal } from "../../providers/GlobalProvider";

export default function ThrowHeaderInline() {
  const { t } = useTranslation();
  const { prefersMetric } = useGlobal();
  return (
    <Box
      sx={{
        display: { mobile: "none", tablet: "grid" },
        gridTemplateColumns: "repeat(auto-fit, minmax(48px, 1fr))",
        gridTemplateRows: "1fr",
        justifyItems: "end",
        rowGap: 2,
        pr: { mobile: 0, tablet: 1.5 },
      }}
    >
      <Stack alignItems="end">
        <Strong color="grey.600" sx={{ fontSize: { mobile: 14, tablet: 16 } }}>
          {t("metrics.speed.full")}
        </Strong>
        <Medium size={12} color="grey.500">
          {t(`units.${prefersMetric ? "kmh" : "mph"}.upper`)}
        </Medium>
      </Stack>
      <Stack alignItems="end">
        <Strong color="grey.600" sx={{ fontSize: { mobile: 14, tablet: 16 } }}>
          {t("metrics.spin.full")}
        </Strong>
        <Medium size={12} color="grey.500">
          {t("units.rpm.upper")}
        </Medium>
      </Stack>
      <Stack alignItems="end">
        <Strong color="grey.600" sx={{ fontSize: { mobile: 14, tablet: 16 } }}>
          {t("metrics.wobble.full")}
        </Strong>
        <Medium size={12} color="grey.500">
          {t("units.degrees.upper")}
        </Medium>
      </Stack>
      <Stack alignItems="end">
        <Strong color="grey.600" sx={{ fontSize: { mobile: 14, tablet: 16 } }}>
          {t("metrics.hyzer.full")}
        </Strong>
        <Medium size={12} color="grey.500">
          {t("units.degrees.upper")}
        </Medium>
      </Stack>
      <Stack alignItems="end">
        <Strong color="grey.600" sx={{ fontSize: { mobile: 14, tablet: 16 } }}>
          {t("metrics.nose.full")}
        </Strong>
        <Medium size={12} color="grey.500">
          {t("units.degrees.upper")}
        </Medium>
      </Stack>
      <Stack alignItems="end">
        <Strong color="grey.600" sx={{ fontSize: { mobile: 14, tablet: 16 } }}>
          {t("metrics.launch.full")}
        </Strong>
        <Medium size={12} color="grey.500">
          {t("units.degrees.upper")}
        </Medium>
      </Stack>
    </Box>
  );
}
