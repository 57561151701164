import { useGlobal } from "@/components/providers/GlobalProvider";
import { useUser } from "@/hooks/useUser";
import { QueryBasedDashboard } from "@/latestDashboard";

export default function DashboardDynamicSimulatorVersion() {
  const [{ userId }] = useUser();
  const { isUnityDeveloper } = useGlobal();
  console.log("unity", isUnityDeveloper);
  return <QueryBasedDashboard userId={userId} />;
}
