import { TabContext, TabList } from "@mui/lab";
import { Container, Stack, Tab } from "@mui/material";
import { Link, Outlet, useMatches, useNavigate, useParams } from "react-router-dom";
import { InspectForm } from "../admin/InspectForm";
import { InspectHeader } from "../admin/InspectHeader";
import { ErrorBoundary } from "../ErrorBoundary";

function InspectRoute() {
  const { userId } = useParams<{
    userId: string;
  }>();
  const navigate = useNavigate();
  const matches = useMatches();
  const currentTab = matches.some((match) => match.pathname.includes("throws/sets"))
    ? "sets"
    : "throws";
  return userId ? (
    <TabContext value={currentTab}>
      <Container sx={{ my: 4 }}>
        <Stack gap={2}>
          <InspectHeader userId={userId} />
          <TabList
            component={Stack}
            direction="row"
            sx={{ justifyContent: "center", width: "100%" }}
          >
            <Tab
              value="throws"
              LinkComponent={Link}
              onClick={() => navigate(`/admin/inspect/${userId}/throws`)}
              label="Throws"
            />
            <Tab
              value="sets"
              LinkComponent={Link}
              onClick={() => navigate(`/admin/inspect/${userId}/throws/sets`)}
              label="Throw Sets"
            />
          </TabList>
        </Stack>
      </Container>
      {/* <ErrorBoundary eventName="user_inspect_error"> */}
      <Outlet />
      {/* </ErrorBoundary> */}
    </TabContext>
  ) : (
    <Container sx={{ my: 4 }}>
      <InspectForm />
    </Container>
  );
}

export default InspectRoute;
