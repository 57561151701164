import { Box, Stack } from "@mui/material";
import React from "react";
import { Strong } from "./Text";

export interface BoxCounterProps {
  min?: number;
  max?: number;
  current: number;
}

function BoxCounter({ min, max, current }: BoxCounterProps) {
  const columns = max ? (max % 2 === 0 ? max / 2 : Math.ceil(max / 3)) : "auto-fill";
  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: {
          mobile: `repeat(${columns}, 36px)`,
          laptop: `repeat(${columns}, 48px)`,
        },
        columnGap: 2,
        rowGap: 2,
      }}
    >
      {Array(max)
        .fill(0)
        .map((_, i) => {
          const isCurrent = i + 1 === current;
          const isCounted = i + 1 <= current;
          const isFulfilled = isCounted && (min ? current >= min : max ? current <= max : true);
          const isMin = i + 1 === min;
          const isMax = i + 1 === max;

          return (
            <Box
              key={i}
              sx={{
                display: "flex",
                width: { mobile: 36, laptop: 48 },
                height: { mobile: 36, laptop: 48 },
                borderColor: isFulfilled
                  ? "primary.main"
                  : isCounted
                    ? "grey.600"
                    : isMin
                      ? "primary.light"
                      : "grey.300",
                borderWidth: 2,
                borderStyle: !isCounted ? "dashed" : "solid",
                backgroundColor: isCounted
                  ? isFulfilled
                    ? "primary.main"
                    : "grey.600"
                  : "transparent",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
              }}
            >
              <Strong
                sx={{
                  color: isCurrent ? "white" : "transparent",
                }}
                size={20}
              >
                {i + 1}
              </Strong>
            </Box>
          );
        })}
    </Stack>
  );
}

export default BoxCounter;
