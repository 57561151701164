import { useMemo, useState, useEffect, useRef } from "react";
import "chartjs-adapter-date-fns";
import { Chart as ChartJS, LineElement, PointElement, TimeScale } from "chart.js";
import { useTheme, Button, Paper, Stack, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Scatter } from "react-chartjs-2";
import ChartZoom from "chartjs-plugin-zoom";
import { Timestamp } from "firebase/firestore";
import { Spinner } from "@blueprintjs/core";
import { TableRenderProps } from "../ThrowTable";
import { CoreStats } from "../../model/CoreStats";
import Title from "../../dashboard/Title";
import {
  formatDate,
  getTitle,
  fetchData,
  passesOtherFilters,
  getMetricLabel,
} from "../shared/utility";
import TagManager from "../../components/TagManager";
import { useGlobal } from "../../components/providers/GlobalProvider";
ChartJS.register(ChartZoom);

const chartPlugins = [LineElement, PointElement, TimeScale];

const filterTypeStrings = [
  "speedMph",
  "rotPerSec",
  "throwTime",
  "correctedNoseAngle",
  "correctedHyzerAngle",
  "uphillAngle",
  "offAxisDegrees",
  "estimatedFeet",
  "primaryType",
];
type FilterPropertyType = (typeof filterTypeStrings)[number] & keyof CoreStats;

type Props = TableRenderProps & {
  type: FilterPropertyType;
};

export function TimelineChart(props: Props) {
  const [resetZoom, setResetZoom] = useState<boolean>(false);
  const { prefersMetric } = useGlobal();
  const theme = useTheme();
  const chartRef = useRef(null);
  const docs = useMemo(() => {
    return TagManager.filterByTags(props.tags, props.docs); // to get date in order
  }, [props.docs, props.tags, props.filters]);
  interface ScatterDataset {
    pointStyle: string;
    pointRadius: number;
    label: string;
    data: { x: number; y: number }[];
    // backgroundColor: string;
    pointBackgroundColor: string;
    borderColor: string;
  }
  const [dateTitleMap, setDateTitleMap] = useState<Record<string, string>>({});

  const [timelineData, setTimelineData] = useState<ScatterDataset[]>([]);
  useEffect(() => {
    // date map is gone
    const dateTitle = {};

    const grenadeDataset: ScatterDataset = {
      // showLine: true,
      pointStyle: "rectRot",
      label: "Grenade",
      pointRadius: 5,
      borderColor: "#589CE5",
      pointBackgroundColor: "#589CE5",
      data: [],
    };
    const overhandDataset: ScatterDataset = {
      pointStyle: "triangle",
      label: "Overhand",
      pointRadius: 5,
      borderColor: "#2A81DE",
      pointBackgroundColor: "#2A81DE",
      // pointBackgroundColor: `rgba(${hexToRgb(TECHDISC_COLOR.PINK).r}, ${
      //   hexToRgb(TECHDISC_COLOR.PINK).g
      // }, ${hexToRgb(TECHDISC_COLOR.PINK).b}, .6)`,
      data: [],
    };
    const fhDataset: ScatterDataset = {
      pointStyle: "rect",
      label: "Forehand",
      pointRadius: 5,
      borderColor: "#1C67B7",
      pointBackgroundColor: "#1C67B7",
      data: [],
    };
    const bhDataset: ScatterDataset = {
      pointStyle: "circle",
      label: "Backhand",
      pointRadius: 5,
      borderColor: "#154D89",
      pointBackgroundColor: "#154D89",
      data: [],
    };

    docs
      .slice()
      .reverse()
      .forEach((doc) => {
        let data = fetchData(props.type, doc);
        if (typeof data == "number") {
          // only entertaining the doc if is a number
          data = (props.type === "rotPerSec" ? 60 : 1) * data;
          if (passesOtherFilters(props.filters, props.type, doc, true)) {
            const options = { year: "numeric", day: "numeric", month: "numeric" };
            const date = new Date(doc.throwTime.toDate().getTime());
            const dateStr = date.toISOString();

            if (
              doc.primaryType == "RHBHG" ||
              doc.primaryType == "RHFHG" ||
              doc.primaryType == "LHBHG" ||
              doc.primaryType == "LHFHG" ||
              doc.primaryType == "Grenade"
            ) {
              grenadeDataset.data.push({ x: dateStr, y: parseFloat(data) });
            } else if (
              doc.primaryType == "RHTHU" ||
              doc.primaryType == "RHTOM" ||
              doc.primaryType == "LHTHU" ||
              doc.primaryType == "LHTOM" ||
              doc.primaryType == "Overhand"
            ) {
              overhandDataset.data.push({ x: dateStr, y: parseFloat(data) });
            } else if (
              doc.primaryType == "RHBH" ||
              doc.primaryType == "LHBH" ||
              doc.primaryType == "Backhand"
            ) {
              bhDataset.data.push({ x: dateStr, y: parseFloat(data) });
            } else if (
              doc.primaryType == "RHFH" ||
              doc.primaryType == "LHFH" ||
              doc.primaryType == "Forehand"
            ) {
              fhDataset.data.push({ x: dateStr, y: parseFloat(data) });
            } else {
              // TODO: handle this case
            }
            dateTitle[dateStr] = doc.title;
          }
        }
      });

    // thinking on adjusting size if less to show to fill screen?
    // if (bhDataset.data?.length < 25) {
    //   bhDataset.pointRadius = 10;
    //   if (bhDataset.data?.length < 10) { bhDataset.pointRadius = 15; }
    // }
    // if (fhDataset.data?.length < 25) {
    //   fhDataset.pointRadius = 10;
    //   if (fhDataset.data?.length < 10) { fhDataset.pointRadius = 15; }
    // }

    setDateTitleMap(dateTitle);

    const actualData = [];
    if (bhDataset?.data?.length) {
      actualData.push(bhDataset);
    }
    if (fhDataset?.data?.length) {
      actualData.push(fhDataset);
    }
    if (overhandDataset?.data?.length) {
      actualData.push(overhandDataset);
    }
    if (grenadeDataset?.data?.length) {
      actualData.push(grenadeDataset);
    }

    actualData.sort((a: ScatterDataset, b: ScatterDataset) => {
      if (a.data.length > b.data.length) {
        return -1;
      }
      return 1;
    });

    for (let i = 0; i < actualData.length; i++) {
      // coloring according to rank (data qty)
      if (i == 0) {
        actualData[i].pointBackgroundColor = "#2A81DE";
        actualData[i].borderColor = "#2A81DE";
      } else if (i == 1) {
        actualData[i].pointBackgroundColor = "#6FAAE9";
        actualData[i].borderColor = "#6FAAE9";
      } else if (i == 2) {
        actualData[i].pointBackgroundColor = "#195AA0";
        actualData[i].borderColor = "#195AA0";
      } else {
        actualData[i].pointBackgroundColor = "#124072";
        actualData[i].borderColor = "#124072";
      }
    }

    setTimelineData(actualData);
  }, [docs, props.filters, props.type]);

  // not used right now, but keeping as this chart may change around over time
  function getTimeUnit(): string {
    function getDateDifference(startDate: Timestamp, endDate: Timestamp): number {
      // returns days between 2 dates
      const startSeconds = startDate.seconds;
      const endSeconds = endDate.seconds;
      const startMilliseconds = startSeconds * 1000;
      const endMilliseconds = endSeconds * 1000;
      const difference = endMilliseconds - startMilliseconds;
      const days = difference / (1000 * 60 * 60 * 24);
      return days;
    }

    const days = Math.abs(getDateDifference(docs[0].throwTime, docs[docs.length - 1].throwTime));

    // coming up with some good chunking
    if (days > 365) {
      return "year";
    } else if (days > 120) {
      return "quarter";
    } else if (days > 59) {
      return "month";
    } else if (days > 13) {
      return "week";
    } else if (days > 2) {
      return "day";
    } else return "hour";
  }

  // Return after all hooks are called
  if (props.isLoading) {
    return <Spinner />;
  }

  const options = {
    maintainAspectRatio: true,
    normalized: true,
    scales: {
      x: {
        display: true,
        type: "time",
        // max: "2024-03-13", //
        ticks: {
          maxTicksLimit: 5,
          minTicksLimit: 3,
          beginAtZero: false,
          // callback: function(val, index) {
          //   // Hide every 2nd tick label
          //   return index % 2 === 0 ? "label" : '';
          // },
          source: "",
          autoSkip: true,
          major: true,
          // autoSkipPadding: 50,
          // maxRotation: 0,
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
        },
        time: {
          displayFormats: {
            year: "YYYY",
            quarter: "M/YYYY",
            week: "M/d",
            month: "M/d",
            day: "M/d",
            hour: "M/d haa",
            minute: "M/d h:mmaa",
            second: "M/d h:mm.ssaa",
            millisecond: "M/d h:mm.ssaa",
          },
          // unit: getTimeUnit(),
        },
      },
      y: {
        maxTicksLimit: 5,
        minTicksLimit: 3,
        display: true,
        position: "left",
        title: {
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
          display: true,
          text: getMetricLabel(props.type, prefersMetric),
        },
        ticks: {
          beginAtZero: false,
          stepSize: () => {
            if (props.type == "speedMph") {
              return 5;
            } else if (props.type == "rotPerSec") {
              return 100;
            } else if (props.type == "correctedNoseAngle") {
              return 2;
            } else if (props.type == "correctedHyzerAngle") {
              return 5;
            } else if (props.type == "uphillAngle") {
              return 2;
            } else if (props.type == "offAxisDegrees") {
              return 1;
            } else if (props.type == "estimatedFeet") {
              return 25;
            }
            return 10;
          },
          // min: 0,
          // max: 0,
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
        },
        scaleLabel: {
          display: true,
          fontSize: 12,
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          // enabled: true, // not working right
          enabled: true,
          modifierKey: "alt",
          onPanComplete: () => {
            // alert("pan")
            setResetZoom(true);
          },
          // pan options and/or events
        },
        limits: {
          // y: { min: 0, max: 100, minRange: 5 }, // TODO scale per data
          // x: { min: 0, max: 100 },
        },
        zoom: {
          drag: {
            enabled: true,
          },
          mode: "xy",
        },
      },
      title: {
        display: false,
        text: getTitle(props.type),
      },
      tooltip: {
        titleFont: {
          family: "'HCo Gotham SSm', sans-serif",
          size: 12,
        },
        bodyFont: {
          family: "'HCo Gotham SSm', sans-serif",
          size: 12,
        },
        callbacks: {
          title: (xDatapoint) => {
            const title = dateTitleMap[xDatapoint?.[0]?.raw?.x] || "";
            const date = formatDate(new Date(xDatapoint?.[0]?.raw?.x));
            // 3 line tooltip
            const returnData = [
              `${xDatapoint[0].raw.y.toFixed(1)} ${getMetricLabel(props.type, prefersMetric)}`,
              date,
            ];
            if (title && title.length) {
              returnData.unshift(title); // putting title first if found
            }
            return returnData;
          },
          label: (yDatapoint) => {
            return "";
          },
        },
      },
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          boxHeight: 10,
          font: {
            family: "'HCo Gotham SSm', sans-serif",
            size: 12,
          },
        },
      },
    },
  };

  return timelineData ? (
    <Paper
      sx={{
        p: 2,
      }}
    >
      <Stack justifyContent="space-between" direction="row">
        <Title variant="secondary">
          {getTitle(props.type, prefersMetric)}{" "}
          <Tooltip
            placement="right-end"
            sx={{ pointer: "cursor" }}
            title={
              <>
                <div>To zoom: Drag cursor to draw a box.</div>
                <div>To pan: Hold alt and drag cursor.</div>
              </>
            }
          >
            <HelpOutlineIcon sx={{ fontSize: "medium" }} />
          </Tooltip>
        </Title>

        <Button
          sx={{
            m: 0,
          }}
          // disabled={!resetZoom} // controlling this variable in the oncomplete is preventing the first zoom from happening
          color={"primary"}
          // variant="contained"
          onClick={async () => {
            chartRef?.current?.resetZoom();
          }}
        >
          Reset Zoom
        </Button>
      </Stack>
      <Scatter
        ref={chartRef}
        data={{ datasets: timelineData }}
        options={options}
        plugins={chartPlugins}
      />
    </Paper>
  ) : null;
}
