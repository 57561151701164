import React, { useEffect, useState, useMemo } from "react";
import { Typography, Stack, Paper } from "@mui/material";
import { basicThrowType } from "./ThrowTableInternal";
import { TECHDISC_COLOR } from "../colors";
import { FilterType, TableRenderProps } from "./ThrowTable";
import Title from "../dashboard/Title";
import { Handedness } from "../model/UserSettings";
import { passesOtherFilters } from "./shared/utility";
import { BasicThrowType, PrimaryThrowStrings } from "../dashboard/DashboardTags";
import { format } from "date-fns";
import TagManager from "../components/TagManager";
import { ThrowSummary } from "../model/throwSummary";
import { CoreMetrics, ThrowAnalysis } from "../firebase/converters/analysisSet";
import { useUser } from "../hooks/useUser";

function getBinTypes(
  bin: keyof typeof BasicThrowType,
  handedNess: Handedness | undefined,
): PrimaryThrowStrings[] {
  if (bin === "Backhand") {
    return ["RHBH", "LHBH", bin];
  }

  if (bin === "Forehand") {
    return ["RHFH", "LHFH", bin];
  }
  if (bin === "Grenade") {
    return ["RHFHG", "RHBHG", "LHBHG", "RHFHG", bin];
  }
  if (bin === "Overhand") {
    return ["RHTHU", "RHTOM", "LHTOM", "LHTHU", bin];
  }
  throw new Error("Unknown bin type: " + bin);
}

export function TableDataSummary<TDataType extends CoreMetrics = ThrowAnalysis>(
  props: Pick<TableRenderProps, "filters" | "tags" | "limit" | "setFilters"> & {
    isThrowSets?: boolean;
    throwSets?: TDataType[];
  },
) {
  const { throwSets, filters, tags, limit, isThrowSets, setFilters } = props;
  const [{ userSettings }] = useUser();
  const [limitHit, setLimitHit] = useState<boolean>(false);

  const getDateString = useMemo(() => {
    const startDate = throwSets?.at(-1)?.throwTime.toDate();
    const endDate = throwSets?.at(0)?.throwTime.toDate();
    const result =
      startDate && endDate ? `${format(startDate, "MM/dd")} - ${format(endDate, "MM/dd")}` : null;
    return result;
  }, [throwSets, props.limit]);

  const typeCounts = useMemo(() => {
    if (limit && throwSets && throwSets?.length > limit) {
      setLimitHit(true);
    } else {
      setLimitHit(false);
    }
    const docs = TagManager.filterByTags(tags, throwSets?.slice(0, limit) ?? []);
    const basicTypes: (keyof typeof BasicThrowType)[] = docs
      .filter((doc) => passesOtherFilters(filters, "primaryType", doc))
      .map((doc) => basicThrowType(doc, doc.handedness ?? userSettings?.handedness));
    // store count of each throw type
    const counts = basicTypes.reduce(
      (acc, curr) => {
        if (acc[curr]) {
          acc[curr]++;
        } else {
          acc[curr] = 1;
        }
        return acc;
      },
      {} as Record<keyof typeof BasicThrowType, number>,
    );
    return counts;
  }, [throwSets, filters, tags]);

  useEffect(() => {
    // reset filters if empty
    if (Object.keys(typeCounts).length === 0 && filters.length > 0) {
      setFilters([]);
    }
  }, [typeCounts]);

  const matchingThrowCount = Object.entries(typeCounts).reduce(
    (acc, [type, count]) => acc + count,
    0,
  );

  return (
    <Paper elevation={1}>
      <Stack height="100%" sx={{ pt: 1, px: 2, pb: 2 }}>
        <Title variant="secondary">Dataset</Title>
        <Stack justifyContent={"space-between"} height="100%">
          <Stack>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: (theme) => theme.palette.grey[600],
              }}
            >
              {matchingThrowCount +
                (isThrowSets ? " Sets" : matchingThrowCount === 1 ? " Throw" : " Throws")}
            </Typography>

            <Stack my={2}>
              {Object.entries(typeCounts).map(([type, count]) => {
                const bucket: FilterType = {
                  bin: type,
                  property: "primaryType",
                  min: 0,
                  max: 0,
                };

                // @ts-ignore
                const binList: string[] = getBinTypes(type, userSettings?.handedness);
                const buckets = binList.map((t) => ({
                  ...bucket,
                  bin: t,
                }));
                const active = filters.some(
                  (f) => f.property === "primaryType" && f.bin === buckets[0].bin,
                );
                return (
                  <Typography
                    key={type}
                    variant="subtitle1"
                    component="div"
                    onClick={() => {
                      if (active) {
                        setFilters((old) =>
                          old.filter(
                            (f) =>
                              f.property !== "primaryType" || !binList.includes(f.bin as string),
                          ),
                        );
                      } else {
                        setFilters((old) => [...old, ...buckets]);
                      }
                    }}
                    sx={{
                      fontWeight: "medium",
                      "&:hover": { fontWeight: "bold", cursor: "pointer" },
                      color: active ? TECHDISC_COLOR.GREEN : TECHDISC_COLOR.BLUE,
                    }}
                  >
                    {count} {type}
                  </Typography>
                );
              })}
            </Stack>
          </Stack>

          <Typography sx={{ fontSize: "0.9rem", color: "grey.500", fontWeight: 500 }}>
            {getDateString}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}
