import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import useChangeWarning from "../../hooks/useChangeWarning";
import { ComponentType, ReactNode } from "react";

export default function ChangeDetector(props: {
  predicate: () => boolean;
  title: string;
  message: string;
}) {
  const { predicate, title, message } = props;
  const { t } = useTranslation();
  const { handleProceed, handleCancel, isBlocked } = useChangeWarning({ predicate });

  return (
    <Dialog open={isBlocked} title={title}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleCancel}>
          {t("nevermind")}
        </Button>
        <Button variant="primary" onClick={handleProceed}>
          {t("discardChanges")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
